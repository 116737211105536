import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { 
  vendorDetailsSelector,
  vendorProductDataSelector,
  vendorProductDataLoadingSelector,
  vendorProductDataErrorsSelector
} from 'app/store/selectors/vendor';
import { getProductsByVendor } from 'app/store/actions/vendor';
// import VendorProductsFilterMenu from './VendorProductsFilterMenu';
import { Button, ButtonIcon, Card, Table, TableHeader, Pagination, LoadingAnimation, StatusChip, SearchBar } from 'app/components';
import { ThreeDotsVertical, ExclamationCircle } from 'react-bootstrap-icons';
import { formatCurrency } from 'app/utils';
import './index.scss';

const ProductsList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const { vendorId } = useParams();
  const loading = useSelector(vendorProductDataLoadingSelector);
  const productData = useSelector(vendorProductDataSelector);
  const errorsFound = useSelector(vendorProductDataErrorsSelector);
  const vendorDetails = useSelector(vendorDetailsSelector);

  const currentPage = parseInt(searchParams.get('page') || '1', 10);
  const pageSize = parseInt(searchParams.get('pageSize') || '25', 10);
  const searchString = searchParams.get('searchString') || null;

  useEffect(() => {
    dispatch(getProductsByVendor({vendorId, searchString, currentPage, pageSize}));
  }, [vendorId, searchString, currentPage, pageSize, dispatch]);

  const onSearchStringUpdated = (searchString) => {
    const updatedSearchParams = new URLSearchParams(searchParams.toString());
    updatedSearchParams.set('page', 1);
    searchString ? updatedSearchParams.set('searchString', searchString) : updatedSearchParams.delete('searchString');
    setSearchParams(updatedSearchParams.toString());
  }; 

  const productOptions = () => [
    // { value: 'Manage Inventory', label: 'Manage Inventory', onClick: () => { console.log("Not implemented yet") }},
    { value: 'Add Products', label: 'Add Products', onClick: () => { navigate(`add-products`); } },
    { value: 'Update Products', label: 'Update Products', onClick: () => console.log("Not implemented yet") },
  ];

  const facilityNameLookup = (facilityId) => vendorDetails?.facilities?.find(facility => facility.id === facilityId)?.name || null;
  
  const products = [];

  productData?.vendorProducts?.forEach(product => {
      const variantId = product.variantId;
      const orderMeshSKU = product.sku;
      product.facilities.forEach(facility => {
          const newObject = {
            facilityId: facility.id,
            facilityName: facilityNameLookup(facility.id),
            vendorSKU: facility.vendorSKU,
            variantId: variantId,
            orderMeshSKU: orderMeshSKU,
            productCost: facility.productCost.amount,
            productionTimeDays: facility.productionTimeDays,
            status: facility.status
          };
          products.push(newObject);
      });
  });

  return (
    <Card className="products-view">
      {loading && <LoadingAnimation />}
      <Card.Header hideDivider>
        Vendor Products
        {productData && !errorsFound && (
          <div className="product-options">
            <Button
              variant="primary"
              size="small"
              label="Export to XLSX"
              onClick={() => console.log('Create Vendor')}
            />
            <ButtonIcon
              icon={<ThreeDotsVertical />}
              options={productOptions()}
            />
          </div>
        )}
      </Card.Header>
      {!errorsFound && (
        <div className="search-bar-and-filters">
          <SearchBar
            searchPlaceholderText="Search for a Product"
            onSearchStringUpdated={onSearchStringUpdated}
            disabled={errorsFound || loading}
            debounceDelay={500}
          />
          {/* <VendorProductsFilterMenu /> */}
        </div>
      )}
      <Table size="medium">
        <TableHeader
          options={[
            { id: "vendorFacility", label: "Vendor Facility" },
            { id: "vendorSku", label: "Vendor SKU" },
            { id: "variantId", label: "Variant ID" },
            { id: "orderMeshSku", label: "SKU" },
            { id: "prodTime", label: "Production Time" },
            { id: "cost", label: "Cost"},
            { id: "status", label: "Status", align: 'center'},
          ]}
        />
        <tbody className="table-body">
          {products?.map((product, index) => (
            <tr className="products-row" key={`${product.id}-${product.vendorSKU}-${product.variantId}-${index}`}>
              <td>{product.facilityName || product.facilityId}</td>
              <td>{product.vendorSKU}</td>
              <td>{product.variantId}</td>
              <td>{product.orderMeshSKU}</td>
              <td>{product.productionTimeDays ? `${product.productionTimeDays} Days` : ''}</td>
              <td>{formatCurrency('USD', product.productCost)}</td>
              <td className="product-status-chip"><StatusChip status={product.status || 'Not Set'} type="product" /></td>
            </tr>
          ))}
          {products?.length === 0 && !loading && !errorsFound && (
            <tr>
              <td colSpan="6" className="no-results">{searchString ? 'No Matching Results Found' : 'No Products Found'}</td>
            </tr>
          )}
          {errorsFound && !loading && (
            <tr>
              <td colSpan="6" className="error-message"><ExclamationCircle />Error fetching vendor data</td>
            </tr>
          )}
        </tbody>
      </Table>
      <Pagination totalItems={productData?.total || 0} />
    </Card>
  )
}

export default ProductsList;