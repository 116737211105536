import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import './index.scss';

const OrdersFilterMenu = () => {
  const [, setSearchParams] = useSearchParams();
  const [selectedFilterIndex, setSelectedFilterIndex] = useState(0);


  const filterArray = [
    { name: 'All', customFilter: false, isPinned: false, options: []},
    { name: 'Image Issue', customFilter: false, isPinned: true, options: [{ key: 'status', value: 'imageerror' }]},
    { name: 'Needs Personalization', customFilter: false, isPinned: true, options: [{ key: 'status', value: 'needspersonalization' }]},
    { name: 'Out of Stock', customFilter: true, isPinned: false, options: [{ key: 'status', value: 'outofstock' }]},
    { name: 'Vendor API Issue', customFilter: true, isPinned: false, options: [{ key: 'status', value: 'vendorapiissue' }]},
  ]

  // const userActionOptions = () => [
  //   { value: 'Rename', label: 'Rename', onClick: () => { console.log("Not implemented yet") } },
  //   { value: 'Unpin', label: 'Unpin', onClick: () => { console.log("Not implemented yet") } },
  //   { value: 'Download as CSV', label: 'Download as CSV', onClick: () => { console.log("Not implemented yet") } },
  //   { value: 'Delete View', label: 'Delete View', destructive: true, onClick: () => { console.log("Not implemented yet") } },
  // ];

  const onFilterUpdated = (filter, index) => {
    const { options } = filter;

    const updatedSearchParams = new URLSearchParams();

    options.forEach(option => {
      updatedSearchParams.set(option.key, option.value);
    });

    setSearchParams(updatedSearchParams);
    setSelectedFilterIndex(index);
  };
      
  return (
    <div className="orders-filter-menu">
      <div className="filter-fields">
        {filterArray.map((filter, index) => {
          return (
            <div key={index} className={`filter-field ${selectedFilterIndex === index ? 'selected' : ''}`}>
              {/* {filter.isPinned && <PinFill className="pin-fill" />} */}
              <div
                className="filter-name"
                onClick={() => onFilterUpdated(filter, index) }
              >
                {filter.name}
              </div>
              {/* <ButtonIcon
                icon={<ChevronDown />}  
                options={userActionOptions()}
              /> */}
            </div>
          )
        })}
        {/* <ButtonIcon
          icon={<PlusLg />} 
          onClick={() => { console.log("Not implemented yet") } }
        /> */}
      </div>
    </div>
  )
}

export default OrdersFilterMenu;