import React from 'react';
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { ButtonIcon, Card, TabbedMenu, IconData } from 'app/components';
import { vendorDetailsSelector, vendorDetailsErrorsSelector } from 'app/store/selectors/vendor';
import DetailsView from './DetailsView';
import ProductsView from './ProductsView';
import FacilitiesView from './FacilitiesView';
import HistoryView from './HistoryView';
import { ArrowLeft, Upc, GeoAlt } from 'react-bootstrap-icons';
import './index.scss';

const tabs = [
  {
    path: 'facilities',
    label: 'Vendor Facilities',
    content: <FacilitiesView />
  },
  {
    path: 'products',
    label: 'All Products',
    content: <ProductsView />
  },
  {
    path: 'history',
    label: 'general.history',
    content: <HistoryView />
  },
  {
    path: 'details',
    label: 'Vendor Details',
    content: <DetailsView />
  },
];

const VendorDetails = () => {
  const { vendorId } = useParams();
  const navigate = useNavigate();
  const vendorDetails = useSelector(vendorDetailsSelector);
  const vendorDetailsErrorsFound = useSelector(vendorDetailsErrorsSelector);

  const displayHeader = () => {
    if (vendorId) {
      if (vendorDetails && vendorDetails.name) {
        return vendorDetails.name;
      } else if (vendorDetailsErrorsFound) {
        return (
          <span>Vendor Details</span>
        );
      } else {
        return (
          <span>&nbsp;</span>
        );
      }
    } else {
      return 'Create New Vendor';
    }
  }

  const filteredTabs = vendorDetails?.facilities?.length > 0 ? tabs : tabs.filter(tab => tab.path !== 'products');

  const address = vendorDetails ? [
    vendorDetails.address?.line1,
    vendorDetails.address?.line2,
    vendorDetails.address?.city,
    vendorDetails.address?.state ? `${vendorDetails.address?.state},` : '',
    vendorDetails.address?.zip,
    vendorDetails.address?.countryCode
  ].filter(Boolean).join(' ') : '';

  return (
    <div className="vendor-details">
      <Card className={`header-card ${!vendorDetails && vendorId ? 'loading' : ''}`}>
        <div className="vendor-name">
          <ButtonIcon icon={<ArrowLeft />} onClick={() => navigate('/admin/vendors')}  />
          {displayHeader()}
        </div>
        {vendorDetails && (
          <div className="header-data">
            {(vendorDetails.shortId || vendorDetails.vendorId) && (
              <IconData label={vendorDetails.shortId || vendorDetails.vendorId} icon={<Upc />} /> 
            )}
            {address && (
              <IconData label={address} icon={<GeoAlt />} />
            )}
          </div>
        )}
        <div className="action-buttons-placeholder"></div>
      </Card>
      <TabbedMenu tabs={filteredTabs} showTabs={Boolean(vendorId)} vendorId={vendorId} />
    </div>
  )
}

export default VendorDetails;