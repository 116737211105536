import React from 'react';
import { Navigate, Routes, Route } from 'react-router-dom';
import MerchantsView from './MerchantsView';
import MerchantDetails from './MerchantDetails';

function Merchants() {
  return (
    <Routes>
      <Route exact path="/" element={<MerchantsView />} />
      <Route exact path="/create-merchant" element={<Navigate to="details" replace={true} />} />
      <Route path="/create-merchant/*" element={<MerchantDetails />} />
      <Route exact path="/:merchantId" element={<Navigate to="details" replace={true} />} />
      <Route path="/:merchantId/*" element={<MerchantDetails />} />
    </Routes>
  );
}

export default Merchants;