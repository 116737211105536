import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

const ToggleSwitch = ({ size, isOn, disabled, onToggle }) => {

  const handleToggle = () => {
    if (!disabled) {
      onToggle && onToggle(!isOn);
    }
  };

  return (
    <div
      className={`gooten-toggle-switch size-${size} ${isOn ? 'on' : 'off'} ${disabled ? 'disabled' : ''}`}
      onClick={handleToggle}
    >
      <div className="toggle-slider" />
    </div>
  );
};

ToggleSwitch.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  isOn: PropTypes.bool,
  disabled: PropTypes.bool,
  onToggle: PropTypes.func.isRequired,
};

ToggleSwitch.defaultProps = {
  size: 'small',
  isOn: false,
  disabled: false,
};

export default ToggleSwitch;
