import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from "react-datepicker";
import './index.scss';

const Calendar = props => {
  const {
    label,
    placeholderText,
    name,
    value,
    disabled,
    readonly,
    errorMessage,
    ...rest
  } = props;

  const showError = errorMessage && errorMessage.trim().length > 0;

  return (
    <div className={`gooten-calendar ${showError ? 'has-error' : ''}`}>
      <label className="label">{label}</label>
      <DatePicker
        placeholderText={placeholderText}
        selected={value}
        name={name}
        readOnly={readonly}
        disabled={disabled}
        className={showError ? 'has-error' : ''}
        {...rest}
      />
      <div className="error">{showError && errorMessage}</div>
    </div>
  )
}

Calendar.propTypes = {
  label: PropTypes.string,
  placeholderText: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  disabled: PropTypes.bool,
  readonly: PropTypes.bool,
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

Calendar.defaultProps = {
  label: null,
  placeholderText: 'Select Date',
  name: null,
  value: '',
  disabled: false,
  readonly: false,
  errorMessage: '',
};

export default Calendar;