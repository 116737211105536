import { combineReducers } from 'redux';
import userReducer from 'app/store/reducers/user';
import orderReducer from 'app/store/reducers/order';
import catalogReducer from 'app/store/reducers/catalog';
import configReducer from 'app/store/reducers/config';
import vendorReducer from 'app/store/reducers/vendor';
import merchantReducer from 'app/store/reducers/merchant';
import shipmentReducer from 'app/store/reducers/shipment';
import routingReducer from 'app/store/reducers/routing';
import transformationReducer from 'app/store/reducers/transformation';
import historyReducer from 'app/store/reducers/history';
import notificationReducer from 'app/store/reducers/notification';
import insightReducer from 'app/store/reducers/insight';

const rootReducer = combineReducers({
  user: userReducer,
  order: orderReducer,
  catalog: catalogReducer,
  config: configReducer,
  vendor: vendorReducer,
  merchant: merchantReducer,
  shipment: shipmentReducer,
  routing: routingReducer,
  transformation: transformationReducer,
  history: historyReducer,
  notification: notificationReducer,
  insight: insightReducer,
});

export default rootReducer;