import React from 'react';
import PropTypes from 'prop-types';
import Button from 'app/components/Button';
import { XLg } from "react-bootstrap-icons";
import ButtonIcon from '../ButtonIcon';
import './index.scss';

const Modal = ({
  title,
  subtitle,
  showCloseButton,
  children,
  primaryButtonOnClick,
  primaryButtonVariant,
  primaryButtonLabel,
  primaryButtonEnabled,
  primaryButtonDestructive,
  secondaryButtonOnClick,
  secondaryButtonVariant,
  secondaryButtonLabel,
  secondaryButtonEnabled,
  hideButtons,
  className,
  onClose,
  isOpen,
}) => {
  if (!isOpen) return null;

  return (
    <div className={`gooten-modal  ${className}`}>
      <div className="gooten-modal-content">
        {title && (
          <div className="gooten-modal-header">
            <div>
              <div className="gooten-modal-title">{title}</div>
              {subtitle && <div className="gooten-modal-subtitle">{subtitle}</div>}
            </div>
            {showCloseButton && (
              <ButtonIcon onClick={onClose} icon={<XLg />} />
            )}
          </div>
        )}
        <div className={`gooten-modal-body ${title ? 'has-title' : ''}`}>{children}</div>
        {!hideButtons && (
          <div className="gooten-modal-footer">
            {secondaryButtonOnClick && (
              <Button
                variant={secondaryButtonVariant}
                size="small"
                label={secondaryButtonLabel}
                onClick={secondaryButtonOnClick}
                disabled={!secondaryButtonEnabled}
              />
            )}
            {primaryButtonOnClick && (
              <Button
                variant={primaryButtonVariant}
                size="small"
                label={primaryButtonLabel}
                onClick={primaryButtonOnClick}
                disabled={!primaryButtonEnabled}
                destructive={primaryButtonDestructive}
              />
            )}
          </div>
        )}
      </div>
      <div className="gooten-modal-backdrop" onClick={onClose}></div>
    </div>
  );
};

Modal.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  showCloseButton: PropTypes.bool,
  children: PropTypes.any,
  primaryButtonVariant: PropTypes.string,
  primaryButtonOnClick: PropTypes.func,
  primaryButtonLabel: PropTypes.string,
  primaryButtonEnabled: PropTypes.bool,
  primaryButtonDestructive: PropTypes.bool,
  secondaryButtonVariant: PropTypes.string,
  secondaryButtonOnClick: PropTypes.func,
  secondaryButtonLabel: PropTypes.string,
  secondaryButtonEnabled: PropTypes.bool,
  hideButtons: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
};

Modal.defaultProps = {
  className: '',
  title: '',
  subtitle: '',
  showCloseButton: true,
  secondaryButtonVariant: 'secondary',
  secondaryButtonLabel: 'Close',
  secondaryButtonEnabled: true,
  primaryButtonVariant: 'primary',
  primaryButtonLabel: 'Close',
  primaryButtonEnabled: true,
  primaryButtonDestructive: false,
  hideButtons: false,
  isOpen: true,
};

export default Modal;
