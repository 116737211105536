import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ProductSelection from './ProductSelection';
import OrderDetails from './OrderDetails';

function PlaceAnOrder() {
  return (
    <Routes>
      <Route exact path="/" element={<ProductSelection />} />
      <Route exact path="/details" element={<OrderDetails />} />
    </Routes>
  );
}

export default PlaceAnOrder;