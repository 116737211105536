import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { transformationsSelector, transformationsLoadingSelector, transformationsErrorSelector } from 'app/store/selectors/transformation';
import { merchantsSelector, merchantsLoadingSelector } from 'app/store/selectors/merchant';
import { vendorsDataSelector, vendorsLoadingSelector } from 'app/store/selectors/vendor';
import { getAllTransformations, reset } from 'app/store/actions/transformation';
import { getMerchants } from 'app/store/actions/merchant';
import { getVendors } from 'app/store/actions/vendor';
import { Link, Card, Pagination, LoadingAnimation, SearchBar, Table, TableHeader, Button } from 'app/components';
import { ExclamationCircle } from 'react-bootstrap-icons';
// import TransformationFilterMenu from './TransformationFilterMenu';
import './index.scss';

const TransformationView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const transformationsData = useSelector(transformationsSelector);
  const loading = useSelector(transformationsLoadingSelector);
  const errorsFound = useSelector(transformationsErrorSelector);
  const merchantsData = useSelector(merchantsSelector);
  const merchantsLoading = useSelector(merchantsLoadingSelector);
  const vendorsData = useSelector(vendorsDataSelector);
  const vendorsLoading = useSelector(vendorsLoadingSelector);

  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = parseInt(searchParams.get('page') || '1', 10);
  const pageSize = parseInt(searchParams.get('pageSize') || '50', 10);
  const searchString = searchParams.get('searchString') || null;
  const sortBy = searchParams.get('sortBy') || 'id';
  const sortDir = searchParams.get('sortDir') || 'desc';

  useEffect(() => {
    dispatch(getAllTransformations({ searchString, currentPage, pageSize, sortBy, sortDir }));
    if (!merchantsData) {
      dispatch(getMerchants({ currentPage: 1, pageSize: 250 }));
    }
    if (!vendorsData) {
      dispatch(getVendors({ currentPage: 1, pageSize: 250 }));
    }
  }, [searchString, currentPage, pageSize, sortBy, sortDir]);

  const onSearchStringUpdated = (searchString) => {
    const updatedSearchParams = new URLSearchParams(searchParams.toString());
    updatedSearchParams.set('page', 1);
    searchString ? updatedSearchParams.set('searchString', searchString) : updatedSearchParams.delete('searchString');
    setSearchParams(updatedSearchParams.toString());
  };

  const lookupUsername = (userType, username) => {
    if (userType === 'Merchant') {
      const merchant = merchantsData?.merchants?.find(merchant => merchant.id === username);
      return merchant?.name || username;
    } else if (userType === 'Vendor') {
      const vendor = vendorsData?.vendors?.find(vendor => vendor.vendorId === username);
      return vendor?.name || username;
    } else {
      return username;
    }
  }

  if (errorsFound) {
    return (
      <div className="transformation-view">
        <div className="transformation-load-failed"><ExclamationCircle />Transformation Data failed to load.  Refresh the page to try again.</div>
      </div>
    )
  }

  return (
    <div className="transformation-view">
      {(loading || merchantsLoading || vendorsLoading) && <LoadingAnimation />}
      {errorsFound && (
        <div className="transformation-load-failed"><ExclamationCircle />Transformation Data failed to load.  Refresh the page to try again.</div>
      )}
      {transformationsData && merchantsData && vendorsData && (
        <Card>
          <div className="transformation-header">
            Transformations
            <Button
              variant="primary"
              size="medium"
              label={"Add Transformation"}
              onClick={() => {
                dispatch(reset());
                navigate(`/admin/transformations/create-transformation`);
              }}
            />
          </div>
          <div className="search-bar-and-filters">
            <SearchBar
              searchPlaceholderText="Search for a Transformation"
              onSearchStringUpdated={onSearchStringUpdated}
              debounceDelay={500}
            />
            {/* <TransformationFilterMenu /> */}
          </div>
          <Table size="medium">
            <TableHeader
              sortBy={sortBy}
              sortDir={sortDir}
              options={[
                { id: 'id', label: 'Merchant / Vendor Name', orderable: true },
                { id: 'creatorType', label: 'User Type', orderable: true },
                { id: 'transformationType', label: 'Transformation Type', orderable: true },
                { id: 'topic', label: 'Topic', orderable: true },
              ]}
            />
            <tbody className="table-body">
              {transformationsData.transformations.map((transformation) => {
                return (
                  <tr className="snippet-row" key={transformation.id}>
                    <td>
                      <Link label={lookupUsername(transformation.creatorType, transformation.entityId)} onClick={() => {
                        dispatch(reset());
                        navigate(`/admin/transformations/${transformation.id}`)
                      }}/>
                    </td>
                    <td>{transformation.creatorType}</td>
                    <td>{transformation.transformationType}</td>
                    <td>{transformation.topic}</td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
          {transformationsData.transformations.length === 0 ? (
            <div className="no-matching-results">No Matching Results Found</div>
          ) : (
            <Pagination totalItems={transformationsData.total || 0} />
          )}
        </Card>
      )}
    </div>
  )
}

export default TransformationView;