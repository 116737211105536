import React from 'react';
import { sidebarSelector } from 'app/store/selectors/config';
import { logoutUser } from 'app/store/actions/user';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  House,
  HouseFill,
  Handbag,
  HandbagFill,
  // BagPlus,
  // BagPlusFill,
  // BoxSeam,
  // BoxSeamFill,
  // Clipboard2Data,
  // Clipboard2DataFill,
  // Book,
  // BookFill,
  // Gear,
  // GearFill,
  People,
  PeopleFill,
  Shop,
  Truck,
  SignTurnSlightRight,
  SignTurnSlightRightFill,
  CodeSquare,
  ArrowLeftSquare,
  Bell,
  BellFill,
  Plug,
  PlugFill,
 } from 'react-bootstrap-icons';
import { ReactComponent as ShopFilledIcon } from 'app/assets/images/icons/shop-filled.svg';
import { ReactComponent as TruckFilledIcon } from 'app/assets/images/icons/truck-filled.svg';
import { ReactComponent as CodeSquareFilled } from 'app/assets/images/icons/code-square-filled.svg';
import './index.scss';

const Sidebar = () => {
  const { t } = useTranslation();
  const sidebar = useSelector(sidebarSelector);
  const location = useLocation();
  const dispatch = useDispatch();

  // define an array of routes and their corresponding icons
  const routes = [
    { path: '/', name: t('general.home'), icon: House, iconSelected: HouseFill },
    { path: '/orders', name: t('general.orders'), icon: Handbag, iconSelected: HandbagFill },
    // { path: '/place-an-order', name: 'Place Order', icon: BagPlus, iconSelected: BagPlusFill },
    // { path: '/product-hub', name: 'Product Hub', icon: BoxSeam, iconSelected: BoxSeamFill },
    // { path: '/reports', name: 'Reports', icon: Clipboard2Data, iconSelected: Clipboard2DataFill },
    // { path: '/product-catalog', name: 'Product Catalog', icon: Book, iconSelected: BookFill },
    // { path: '/settings', name: 'Settings', icon: Gear, iconSelected: GearFill },
  ];

  const adminRoutes = [
    { path: '/admin/users', name: 'Users & Roles', icon: People, iconSelected: PeopleFill },
    { path: '/admin/merchants', name: 'Merchants', icon: Shop, iconSelected: ShopFilledIcon },
    { path: '/admin/vendors', name: 'Vendors', icon: Truck, iconSelected: TruckFilledIcon },
    { path: '/admin/routing', name: 'Routing', icon: SignTurnSlightRight, iconSelected: SignTurnSlightRightFill },
    { path: '/admin/webhooks', name: 'Webhooks', icon: Bell, iconSelected: BellFill },
    { path: '/admin/transformations', name: 'Transformations', icon: CodeSquare, iconSelected: CodeSquareFilled },
    { path: '/admin/integrations', name: 'Integrations', icon: Plug, iconSelected: PlugFill },
  ];

  return (
    <div className={`sidebar ${sidebar.open ? 'show' : ''}`}>
      <div className="sidebar-container">
        {routes.map((route, index) => {
          const basePath = location.pathname.split('/')[1];
          const isActive = `/${basePath}` === route.path;
  
          return (
            <React.Fragment key={route.path}>
              <Link
                key={index}
                to={route.path}
                className={`sidebar-link ${isActive ? 'active' : ''}`}
              >
                {isActive ? <route.iconSelected /> : <route.icon />}
                {route.name}
              </Link>
            </React.Fragment>
          );
        })}
        <div className="admin-header">ADMIN SETTINGS</div>
        {adminRoutes.map((route, index) => {
          const basePath = location.pathname.split('/')[2];
          const isActive = `/admin/${basePath}` === route.path;
  
          return (
            <Link
              key={index}
              to={route.path}
              className={`sidebar-link ${isActive ? 'active' : ''}`}
            >
              {isActive ? <route.iconSelected /> : <route.icon />}
              {route.name}
            </Link>
          );
        })}
        <div className="logout-button">
          <Link
            onClick={() => {
              dispatch(logoutUser());
            }}
            className="sidebar-link"
          >
            <ArrowLeftSquare />
            Logout
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;