import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

const Button = props => {
  const {
    variant,
    destructive,
    size,
    label,
    onClick,
    imageLeft,
    imageLeftOffset,
    imageRight,
    imageRightOffset,
    className,
    disabled,
  } = props;

  return (
    <button
      className={`gooten-button variant-${variant} ${destructive ? 'destructive' : ''} size-${size} ${className}`}
      type="button"
      onClick={() => onClick()}
      disabled={disabled}
    >
      {imageLeft && (
        <div className="image-left">
          {React.cloneElement(imageLeft, { 
            className: 'button-icon-left',
            style: { marginTop: `${imageLeftOffset}px` }
          })}
        </div>
      )}
      <div className="label">
        {label}
      </div>
      {imageRight && (
        <div className="image-right">
          {React.cloneElement(imageRight, { 
            className: 'button-icon-right',
            style: { marginTop: `${imageRightOffset}px` }
          })}
        </div>
      )}
    </button>
  )
}

Button.propTypes = {
  variant: PropTypes.oneOf(['primary', 'secondary']),
  destructive: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  label: PropTypes.string,
  onClick: PropTypes.func,
  imageLeft: PropTypes.object,
  imageLeftOffset: PropTypes.number,
  imageRight: PropTypes.object,
  imageRightOffset: PropTypes.number,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

Button.defaultProps = {
  variant: 'primary',
  destructive: false,
  size: 'medium',
  label: null,
  onClick: null,
  imageLeft: null,
  imageLeftOffset: 0,
  imageRight: null,
  imageRightOffset: 0,
  className: '',
  disabled: false,
};

export default Button;