import React from 'react';
import { useDispatch } from 'react-redux';
import { cancelShipment } from 'app/store/actions/shipment';
import { Modal } from 'app/components';

const CancelShipmentModal = props => {
  const {
    shipmentId,
    showCancelShipmentModal,
  } = props;
  const dispatch = useDispatch();

  return (
    <Modal
      secondaryButtonLabel="No, Keep It"
      secondaryButtonOnClick={() => { showCancelShipmentModal(false)}}
      primaryButtonLabel="Yes, Cancel It"
      primaryButtonVariant="primary"
      primaryButtonDestructive
      primaryButtonOnClick={() => {
        dispatch(cancelShipment({shipmentId}));
        showCancelShipmentModal(false);
      }}
      onClose={() => showCancelShipmentModal(false)}
    >
      <div>Are you sure you want to cancel this shipment?</div>
      <div>This action is not reversible.</div>
    </Modal>
  )
}

export default CancelShipmentModal;