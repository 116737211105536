import React from 'react';
import { Navigate, Routes, Route } from 'react-router-dom';
import VendorsView from './VendorsView';
import VendorDetails from './VendorDetails';
import FacilityDetails from './FacilityDetails';

function Vendors() {
  return (
    <Routes>
      <Route exact path="/" element={<VendorsView />} />
      <Route exact path="/create-vendor" element={<Navigate to="details" replace={true} />} />
      <Route path="/create-vendor/*" element={<VendorDetails />} />
      <Route exact path="/:vendorId" element={<Navigate to="facilities" replace={true} />} />
      <Route path="/:vendorId/add-products" element={<VendorDetails />} />
      <Route exact path="/:vendorId/add-facility" element={<Navigate to="details" replace={true} />} />
      <Route path="/:vendorId/add-facility/*" element={<FacilityDetails />} />
      <Route path="/:vendorId/facility/:facilityId/*" element={<FacilityDetails />} />
      <Route path="/:vendorId/*" element={<VendorDetails />} />
    </Routes>
  );
}

export default Vendors;