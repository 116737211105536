import { createAction } from 'redux-actions';

const prefix = "SHIPMENT";

export const getShipmentsByOrderId = createAction(`${prefix}/GET_SHIPMENTS_BY_ORDER_ID`);
export const getShipmentsByOrderIdSuccess = createAction(`${prefix}/GET_SHIPMENTS_BY_ORDER_ID_SUCCESS`);
export const getShipmentsByOrderIdFailure = createAction(`${prefix}/GET_SHIPMENTS_BY_ORDER_ID_FAILURE`);

export const updateShipmentById = createAction(`${prefix}/UPDATE_SHIPMENT_BY_ID`);
export const updateShipmentByIdSuccess = createAction(`${prefix}/UPDATE_SHIPMENT_BY_ID_SUCCESS`);
export const updateShipmentByIdFailure = createAction(`${prefix}/UPDATE_SHIPMENT_BY_ID_FAILURE`);

export const rerouteShipment = createAction(`${prefix}/REROUTE_SHIPMENT`);
export const rerouteShipmentSuccess = createAction(`${prefix}/REROUTE_SHIPMENT_SUCCESS`);
export const rerouteShipmentFailure = createAction(`${prefix}/REROUTE_SHIPMENT_FAILURE`);

export const cancelShipment = createAction(`${prefix}/CANCEL_SHIPMENT`);
export const cancelShipmentSuccess = createAction(`${prefix}/CANCEL_SHIPMENT_SUCCESS`);
export const cancelShipmentFailure = createAction(`${prefix}/CANCEL_SHIPMENT_FAILURE`);

export const reset = createAction(`${prefix}/RESET`);