import React from 'react';
import { Routes, Route } from 'react-router-dom';
import TransformationView from './TransformationView';
import TransformationDetails from './TransformationDetails';

function Transformations() {
  return (
    <Routes>
      <Route exact path="/" element={<TransformationView />} />
      <Route path="/create-transformation/*" element={<TransformationDetails />} />
      <Route path="/:transformationId/*" element={<TransformationDetails />} />
    </Routes>
  );
}

export default Transformations;