import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Input, Link } from "app/components";
import { signinUser } from "app/store/actions/user";
import { userAuthSelector } from "app/store/selectors/user";
import { LoadingAnimation } from "app/components";
import { EyeFill, EyeSlashFill } from "react-bootstrap-icons";
import { toast } from "react-toastify";
import { Formik } from "formik";
import { object, string } from "yup";
import "./index.scss";

const Signin = () => {
  const dispatch = useDispatch();
  const userAuth = useSelector(userAuthSelector);
  const [showPassword, setShowPassword] = useState(false);
  const onSigninFail = () => {
    toast.error("Invalid Username or Password");
  };

  return (
    <div className="signin">
      {userAuth.loading && <LoadingAnimation />}
      <div className="title-text">Welcome to OrderMesh</div>
      <Formik
        enableReinitialize
        initialValues={{
          username: "",
          password: "",
        }}
        validationSchema={() =>
          object().shape({
            username: string().required("Enter a valid Username"),
            password: string().required("Enter a valid Password"),
          })
        }
        onSubmit={async (values) => {
          dispatch(signinUser({ username: values.username, password: values.password, onSigninFail }));
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleSubmit,
          isSubmitting,
          submitCount,
        }) => (
          <form
            onSubmit={handleSubmit}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                handleSubmit();
                event.preventDefault();
              }
            }}
          >
            <div className="input-container">
              <Input
                label="Username"
                name="username"
                value={values.username}
                onChange={handleChange}
                placeholder="Username"
                errorMessage={submitCount > 0 && errors.username}
              />
              <Input
                label="Password"
                name="password"
                value={values.password}
                type={showPassword ? "text" : "password"}
                onChange={handleChange}
                placeholder="Password"
                icon={showPassword ? <EyeSlashFill /> : <EyeFill />}
                onIconClick={() => {
                  setShowPassword(!showPassword);
                }}
                errorMessage={submitCount > 0 && errors.password}
              />
            </div>
            <div>
              <Link label="Forgot password?" url={`/forgot-password`} />
            </div>
            <div className="submit-button-container">
              <Button
                variant="primary"
                size="small"
                label="Sign In"
                disabled={isSubmitting}
                onClick={() => (userAuth.loading ? null : handleSubmit())}
              />
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default Signin;
