import React from 'react';
import { useDispatch } from 'react-redux';
import { deleteIntegration } from 'app/store/actions/notification';
import { Modal } from 'app/components';

const DeleteIntegrationModal = props => {
  const {
    plugin,
    showDeleteIntegrationModal,
  } = props;
  const dispatch = useDispatch();

  return (
    <Modal
      secondaryButtonLabel="No, Keep It"
      secondaryButtonOnClick={() => { showDeleteIntegrationModal(false)}}
      primaryButtonLabel="Yes, Delete It"
      primaryButtonVariant="primary"
      primaryButtonDestructive
      primaryButtonOnClick={() => {
        dispatch(deleteIntegration({pluginId: plugin.id}))
        showDeleteIntegrationModal(false);
      }}
      onClose={() => showDeleteIntegrationModal(false)}
    >
      <div dangerouslySetInnerHTML={{ __html: `Are you sure you want to delete the <b>${plugin.name}</b> integration?` }} />
      <div>This action is not reversible.</div>
    </Modal>
  )
}

export default DeleteIntegrationModal;