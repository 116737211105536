import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';
import { ChevronDown, ChevronUp } from 'react-bootstrap-icons';
import './index.scss';

const ButtonMenu = props => {
  const { label, size, variant, direction, options, width } = props;

  const style = width ? { width: `${width}px` } : {};

  return (
    <div className={`gooten-button-menu size-${size} variant-${variant}`} style={style}>
      <Dropdown>
        <Dropdown.Toggle className="rows-per-page-dropdown" style={style}>
          {label}
          {direction === 'down' ? <ChevronDown /> : <ChevronUp />}
        </Dropdown.Toggle>
        <Dropdown.Menu style={style}>
          {options.map((option) => (
            <Dropdown.Item
              key={option.value}
              value={option.value}
              disabled={option.disabled}  
              className={option.destructive ? 'destructive' : ''}
              onClick={option.onClick}
            >
              {option.label}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}

ButtonMenu.propTypes = {
  label: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['slim', 'small', 'medium']),
  variant: PropTypes.oneOf(['primary', 'secondary']),
  direction: PropTypes.oneOf(['up', 'down']),
  disabled: PropTypes.bool,
  options: PropTypes.array.isRequired,
  width: PropTypes.number,
};

ButtonMenu.defaultProps = {
  size: 'small',
  variant: 'primary',
  direction: 'down',
  disabled: false,
  width: null,
};

export default ButtonMenu;