import React from 'react';
import { Card } from 'react-bootstrap';
import SelectedProductCard from './SelectedProductCard';
import './index.scss';

const SelectedProducts = props => {
  const {
    values,
    errors,
    handleChange,
    setFieldValue,
    submitCount, 
    validateForm,
    currentlySelectedProducts,
    onRemoveSelectedProduct
  } = props;

  return (
    <Card className="selected-products mb-4">
       <Card.Header as="h6" className="p-0">
        <div className="d-flex justify-content-between">
          <div className="py-2 px-3">Selected Products</div>
        </div>
      </Card.Header>
      <Card.Body>
        <div className="category-container">
          <div className="product-container">
            {currentlySelectedProducts.map((product, index) => (
              <SelectedProductCard
                key={product?.key}
                productIndex={index}
                values={values}
                errors={errors}
                productInfo={product?.data}
                onProductRemoved={() => onRemoveSelectedProduct(index)}
                submitCount={submitCount}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
                validateForm={validateForm}
              />
            ))}
            {currentlySelectedProducts.filter(product => product).length === 0 && (
              <>
                <div>No products currently selected.</div>
                {submitCount > 0 && errors.selectedProducts && (
                  <div className="no-selected-products">At least one product must be selected</div>
                )}
              </>
            )}
          </div>
        </div>
      </Card.Body>
    </Card>
  )
}

export default SelectedProducts;