import React, { useState } from 'react';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Input, Button, ButtonIcon, Dropdown, DataPoint, MessageBar } from 'app/components';
import { updateIntegration } from 'app/store/actions/notification';
import { ChevronDown, ChevronLeft, Trash3 } from 'react-bootstrap-icons';
import { object, string, lazy, array } from 'yup';
import Editor from '@monaco-editor/react';
import './index.scss';

const ActionsAndTrigger = (props) => {
  const { details, eventTypes, integrationDetails, pluginId, pluginVersionId, currentVersion, editMode, setEditModeId, editModeId, setNewActionsAndTriggers } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const [isMenuOpen, setIsMenuOpen] = useState(details?.eventId > 0 ? false : true);
  const [isMenuOpen, setIsMenuOpen] = useState(true);
  const [requestOrResponse, setRequestOrResponse] = useState('request');

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const getFormattedHeaders = (headers) => {
    if (Array.isArray(headers)) {
      return headers.map(header => {
        const [key, value] = header.split(':');
        return { key: key.trim(), value: value.trim() };
      });
    }
    return [{ key: '', value: '' }];
  };

  const handleHeaderChange = (index, field, value, values, setFieldValue) => {
    const headers = values.headerInfo;
    const lastIndex = headers.length - 1;

    const updatedHeaders = headers.map((header, idx) =>
      idx === index ? { ...header, [field]: value } : header
    );

    if (index === lastIndex && (updatedHeaders[lastIndex].key || updatedHeaders[lastIndex].value)) {
      if (updatedHeaders[lastIndex].key !== '' || updatedHeaders[lastIndex].value !== '') {
        updatedHeaders.push({ key: '', value: '' });
      }
    }

    setFieldValue('headerInfo', updatedHeaders);
  };

  const eventList = [
    { Name: "Order Creation", Value: "OrderCreation" },
    { Name: "Order Update", Value: "OrderUpdate" },
    { Name: "Shipment Created", Value: "ec46a68f-f45f-4673-9f2c-32e4e7948524" },
    { Name: "Shipment Update", Value: "ShipmentUpdate" }
  ];

  return (
    <div className="actions-and-trigger">
      <Formik
        enableReinitialize
        initialValues={{
          selectedEvent: details?.eventId || '',
          callbackMethod: details?.externalData?.method || 'POST',
          callbackUrl: details?.externalData?.url || '',
          headerInfo: details?.externalData?.headers ? getFormattedHeaders(details?.externalData?.headers) : [{ key: '', value: '' }],
          requestCodeSnippet: details?.requestTransformationData?.snippets?.[0]?.code || '',
          responseCodeSnippet: details?.responseTransformationData?.snippets?.[0]?.code || '',
        }}
        validationSchema={() =>
          object({
            selectedEvent: string().required('Integration Name is required'),
            callbackMethod: string().required("Callback method is required"),
            callbackUrl: string().required("Callback URL is required"),
            headerInfo: array()
              .of(
                lazy(header =>
                  object().shape({
                    key: header.key || header.value ? string().required("Key is required if value is provided") : string().notRequired(),
                    value: header.key || header.value ? string().required("Value is required if key is provided") : string().notRequired(),
                  })
                )
              ),
            requestCodeSnippet: string().required("Request code snippet is required"),
            responseCodeSnippet: string().required("Response code snippet is required"),
          })
        }
        onSubmit={async (values) => {
          const formattedHeaders = values.headerInfo
            .filter(header => header.key && header.value)
            .map(header => `${header.key}:${header.value}`);

          // make a copy of the events object
          const newEvents = integrationDetails.details && integrationDetails.details.events ? [...integrationDetails.details.events] : [];

          // find the index of the event we are editing
          let eventIndex = newEvents.findIndex(event => event.eventId === details.eventId);

          // update or add the event object with the new values
          const updatedEvent = {
            eventId: values.selectedEvent,
            requestTransformationId: details?.requestTransformationData?.id,
            responseTransformationId: details?.responseTransformationData?.id,
            externalData: {
              method: values.callbackMethod,
              url: values.callbackUrl,
              mediaType: "application/json",
              headers: formattedHeaders,
            }
          };

          if (eventIndex !== -1) {
            newEvents[eventIndex] = {
              ...newEvents[eventIndex],
              ...updatedEvent
            };
          } else {
            newEvents.push(updatedEvent);
            eventIndex = newEvents.length - 1;
          }

          const data = {
            events: newEvents
          };

          dispatch(updateIntegration({
            pluginId,
            pluginVersionId: pluginVersionId && pluginVersionId !== '0' ? pluginVersionId : null,
            currentVersion,
            data,
            requestCodeSnippet: values.requestCodeSnippet,
            responseCodeSnippet: values.responseCodeSnippet,
            integratorId: integrationDetails.basicInfo.integratorId,
            eventIndex,
            cb: (updatedPluginVersionId) => {
              navigate(`/admin/integrations/${pluginId}/${updatedPluginVersionId}`);
            }
          }));
        }}

      >
        {({
          values,
          errors,
          handleChange,
          handleSubmit,
          setFieldValue,
          submitCount,
        }) => (
          <form onSubmit={handleSubmit}>
            {details?.eventId && (
              <div className={`actions-and-trigger-header ${isMenuOpen ? 'menu-open' : ''}`}>
                <div className="action-name">{details.eventId === -1 ? 'New Action' : eventList.find(event => event.Value === details.eventId)?.Name}</div>
                <div className="action-buttons">
                  {!editModeId && editMode && (
                    <Button
                      variant="secondary"
                      size="small"
                      label="Edit Action"
                      onClick={() => {
                        if (!isMenuOpen) setIsMenuOpen(true);
                        setEditModeId(details.eventId);
                      }}
                    />
                  )}
                  <ButtonIcon
                    icon={isMenuOpen ? <ChevronDown /> : <ChevronLeft />}
                    onClick={toggleMenu}
                  />
                  {editModeId === details.eventId && (
                    <>
                      <Button
                        variant="secondary"
                        size="small"
                        label="Cancel"
                        onClick={() => {
                          if (editModeId === -1) {
                            // Remove the new action
                            setNewActionsAndTriggers([]);
                          }
                          setEditModeId(null);
                        }}
                      />
                      <Button
                        variant="primary"
                        size="small"
                        label="Save Changes"
                        onClick={handleSubmit}
                      />
                    </>
                  )}
                </div>
              </div>
            )}
            {submitCount > 0 && (errors.requestCodeSnippet || errors.responseCodeSnippet) && (
              <MessageBar
                message={`${errors.requestCodeSnippet && errors.responseCodeSnippet
                    ? 'The Request Code Snippet and Response Code Snippet are required.'
                    : errors.requestCodeSnippet
                      ? 'The Request Code Snippet is required.'
                      : 'The Response Code Snippet is required.'
                  }`}
                color="yellow"
              />
            )}
            {isMenuOpen && (
              <>
                <div className="actions-and-trigger-body">
                  {editMode && editModeId === details.eventId ? (
                    <>
                      <div className="edit-mode-header">
                        <Dropdown
                          label="Event"
                          name="selectedEvent"
                          className="selected-event"
                          placeholder="Select an Event"
                          value={values.selectedEvent}
                          onChange={handleChange}
                          options={eventTypes?.eventsCatalog.map((event) => ({ value: event.id, label: event.name }))}
                          errorMessage={submitCount > 0 && errors.events}
                        />
                      </div>
                      <div className="api-config-header">API Config</div>
                      <div className="api-request-details-section">
                        <div className="callback-info">
                          <Dropdown
                            label="Callback Method"
                            name="callbackMethod"
                            value={values.callbackMethod}
                            onChange={handleChange}
                            options={[
                              { value: 'POST', label: 'POST' },
                              { value: 'GET', label: 'GET' },
                              { value: 'PUT', label: 'PUT' },
                              { value: 'PATCH', label: 'PATCH' },
                              { value: 'DELETE', label: 'DELETE' },
                            ]}
                            errorMessage={submitCount > 0 && errors.callbackMethod}
                          />
                          <Input
                            label="Callback URL"
                            name="callbackUrl"
                            value={values.callbackUrl}
                            onChange={handleChange}
                            placeholder="Enter a Callback URL"
                            errorMessage={submitCount > 0 && errors.callbackUrl}
                          />
                        </div>
                        <div className="header-info-section">
                          <div className="header-row">
                            <div className="custom-header">Custom Headers</div>
                            <div className="custom-value">Value</div>
                            <div className="trash-can">&nbsp;</div>
                          </div>
                          {values.headerInfo.map((header, index) => (
                            <div className="header-row" key={index}>
                              <div className="custom-header">
                                <Input
                                  name={`headerInfo[${index}].key`}
                                  value={header.key}
                                  onChange={e => handleHeaderChange(index, 'key', e.target.value, values, setFieldValue)}
                                  placeholder="Enter a Key"
                                  errorMessage={submitCount > 0 && errors.headerInfo?.[index]?.key}
                                />
                              </div>
                              <div className="custom-value">
                                <Input
                                  name={`headerInfo[${index}].value`}
                                  value={header.value}
                                  onChange={e => handleHeaderChange(index, 'value', e.target.value, values, setFieldValue)}
                                  placeholder="Enter a Value"
                                  errorMessage={submitCount > 0 && errors.headerInfo?.[index]?.value}
                                />
                              </div>
                              <div className="trash-can">
                                {index < values.headerInfo.length - 1 && (
                                  <ButtonIcon
                                    icon={<Trash3 />}
                                    destructive={true}
                                    onClick={() => {
                                      const newHeaders = values.headerInfo.filter((_, i) => i !== index);
                                      setFieldValue('headerInfo', newHeaders.length ? newHeaders : [{ key: '', value: '' }]);
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="api-config-header">Customization</div>
                      <div className="request-response-selectors">
                        <div
                          className={`${requestOrResponse === 'request' ? 'selected' : ''}`}
                          onClick={() => setRequestOrResponse('request')}
                        >
                          Request
                        </div>
                        <div
                          className={`${requestOrResponse === 'response' ? 'selected' : ''}`}
                          onClick={() => setRequestOrResponse('response')}
                        >
                          Response
                        </div>
                      </div>
                      <div className="code-snippet-header">Code Snippet</div>
                      <div>
                        <Editor
                          className="code-snippet"
                          options={{
                            overviewRulerLanes: 0,
                            minimap: { enabled: false },
                            wordWrap: 'on',
                          }}
                          height="250px"
                          defaultLanguage="javascript"
                          defaultValue="// add your code here"
                          value={requestOrResponse === 'request' ? values.requestCodeSnippet : values.responseCodeSnippet}
                          onChange={(value) => {
                            setFieldValue(requestOrResponse === 'request' ? 'requestCodeSnippet' : 'responseCodeSnippet', value);
                          }}
                        />
                      </div>
                      <div className="code-snippet-header">Test Code Snippet</div>
                      <div className="input-output-container">
                        <div className="input-output-header">
                          Input String
                          <Editor
                            className="code-snippet"
                            options={{
                              overviewRulerLanes: 0,
                              minimap: { enabled: false },
                              wordWrap: 'on',
                            }}
                            height="250px"
                            defaultLanguage="javascript"
                            defaultValue="// add your code here"
                          />
                        </div>
                        <Button
                          variant="secondary"
                          size="small"
                          label="Test Code Snippet"
                          onClick={() => { console.log('Add Action') }}
                        />
                        <div className="input-output-header">
                          Output String
                          <Editor
                            className="code-snippet"
                            options={{
                              readOnly: true,
                              overviewRulerLanes: 0,
                              minimap: { enabled: false },
                              wordWrap: 'on',
                            }}
                            height="250px"
                            defaultLanguage="javascript"
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="view-mode-container">
                      {/* <DataPoint title="Event" data={eventList.find(event => event.Value === values.selectedEvent)?.Name || 'N/A'} /> */}
                      <div className="api-config-header">API Config</div>
                      <div className="api-request-details-section">
                        <DataPoint title="Callback Method" data={values.callbackMethod} />
                        <DataPoint className="callback-url" title="Callback URL" data={values.callbackUrl} />
                      </div>
                      {values.headerInfo.length > 0 && (
                        <div className="header-info-section">
                          <div className="column-headers">Custom Header</div>
                          <div className="column-headers">Value</div>
                          {values.headerInfo.filter(header => header.key || header.value).map((header, index) => (
                            <React.Fragment key={index}>
                              <div className="custom-header">{header.key}</div>
                              <div className="custom-value">{header.value}</div>
                            </React.Fragment>
                          ))}
                        </div>
                      )}
                      <div className="api-config-header">Customization</div>
                      <div className="request-response-selectors">
                        <div
                          className={`${requestOrResponse === 'request' ? 'selected' : ''}`}
                          onClick={() => setRequestOrResponse('request')}
                        >
                          Request
                        </div>
                        <div
                          className={`${requestOrResponse === 'response' ? 'selected' : ''}`}
                          onClick={() => setRequestOrResponse('response')}
                        >
                          Response
                        </div>
                      </div>
                      <div className="code-snippet-header">Code Snippet</div>
                      <div>
                        <Editor
                          className="code-snippet"
                          options={{
                            readOnly: true,
                            overviewRulerLanes: 0,
                            minimap: { enabled: false },
                            wordWrap: 'on',
                          }}
                          height="250px"
                          defaultLanguage="javascript"
                          defaultValue="// add your code here"
                          value={requestOrResponse === 'request' ? values.requestCodeSnippet : values.responseCodeSnippet}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
          </form>
        )}
      </Formik>
    </div>
  );
};

export default ActionsAndTrigger;
