import { CaretUpFill, CaretDownFill } from 'react-bootstrap-icons';
import PropTypes from 'prop-types';
import './index.scss';

const SortArrow = props => {
  const { direction } = props;

  if (direction === null) {
    return null;
  }

  return (
    <div className="sort-arrow">
      <CaretUpFill className={direction === 'asc' ? "arrow-up-active" : "arrow-up-inactive"} />
      <CaretDownFill className={direction  === 'asc' ? "arrow-down-inactive" : "arrow-down-active"} />
    </div>
  )
}

SortArrow.propTypes = {
  direction: PropTypes.oneOf(['asc', 'desc', null]),
};

SortArrow.defaultProps = {
  direction: null,
};

export default SortArrow;