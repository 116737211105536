import { put, takeEvery, call, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import {
  getAllTransformations,
  getAllTransformationsSuccess,
  getAllTransformationsFailure,
  getTransformationDetails,
  getTransformationDetailsSuccess,
  getTransformationDetailsFailure,
  createTransformation,
  createTransformationSuccess,
  createTransformationFailure,
  testDeployedTransformation,
  testDeployedTransformationSuccess,
  testDeployedTransformationFailure,
  promoteTransformation,
  promoteTransformationSuccess,
  promoteTransformationFailure,
} from "app/store/actions/transformation";
import TransformationServices from 'app/services/transformationServices';

function* fetchAllTransformations(action) {
  const { searchString, currentPage, pageSize, sortBy, sortDir } = action.payload;

  try {
    const resp = yield call([TransformationServices, TransformationServices.getAllTransformations], searchString, currentPage, pageSize, sortBy, sortDir);

    yield put(getAllTransformationsSuccess(resp));
  } catch (error) {
    console.error('error', error);
    yield put(getAllTransformationsFailure(error));
    toast.error("Loading transformations failed", {
      theme: 'colored',
    });
  }
}

function* fetchTransformationDetails(action) {
  const { transformationId } = action.payload;

  try {
    const resp = yield call([TransformationServices, TransformationServices.getTransformationDetails], transformationId);
    yield put(getTransformationDetailsSuccess(resp));
  } catch (error) {
    console.error('error', error);
    yield put(getTransformationDetailsFailure(error));
    toast.error("Loading Transformations failed", {
      theme: 'colored',
    });
  }
}

function* doCreateTransformation(action) {
  const { data, cb } = action.payload;
  try {
    const resp = yield call([TransformationServices, TransformationServices.createTransformation], data);
    const resp2 = yield call([TransformationServices, TransformationServices.getTransformationDetails], data.creatorType, data.topic, data.transformationType, data.entityId);
    yield put(getTransformationDetailsSuccess(resp2));
    toast.success("Transformation successfully created", {
      theme: 'colored',
    });
    if(cb) cb(data, resp);
    yield put(createTransformationSuccess());
  } catch (error) {
    console.error('error', error);
    yield put(createTransformationFailure(error));
    toast.error("Transformation creation failed", {
      theme: 'colored',
    });
  }
}

function* doTestDeployedTransformation(action) {
  const { data, cb } = action.payload;
  try {
    const resp = yield call([TransformationServices, TransformationServices.testDeployedTransformation], data);
    if(cb) cb(resp);
    yield put(testDeployedTransformationSuccess());
  } catch (error) {
    console.error('error', error);
    yield put(testDeployedTransformationFailure(error));
    if(cb) cb(error);
  }
}

function* doPromoteTransformation(action) {
  const { transformationId, cb } = action.payload;
  try {
    yield call([TransformationServices, TransformationServices.promoteTransformation], transformationId);
    toast.success("Transformation successfully published", {
      theme: 'colored',
    });
    if(cb) cb(transformationId);
    yield put(promoteTransformationSuccess(transformationId));
  } catch (error) {
    console.error('error', error);
    yield put(promoteTransformationFailure(error));
    if(cb) cb(error);
    toast.error("Transformation publish failed", {
      theme: 'colored',
    });
  }
}

function* watchData() {
  yield takeEvery(getAllTransformations().type, fetchAllTransformations);
  yield takeEvery(getTransformationDetails().type, fetchTransformationDetails);
  yield takeEvery(createTransformation().type, doCreateTransformation);
  yield takeEvery(testDeployedTransformation().type, doTestDeployedTransformation);
  yield takeEvery(promoteTransformation().type, doPromoteTransformation);
}

export default function* rootSaga() {
  yield all([
    watchData(),
  ]);
}