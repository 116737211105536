import React from 'react';
import { ChevronDown, ChevronLeft, Box, ThreeDotsVertical } from 'react-bootstrap-icons';
import { Card, ButtonIcon, IconData } from 'app/components';
import OrderItem from '../OrderItem';
import './index.scss';

const OrderItemsCard = props => {
  const {
    // orderId, 
    orderItems, 
    menuState, 
    toggleMenu,
    // showCancelOrderItemModal
  } = props;

  const itemOptions = () => [
    { value: 'Create Shipment', label: 'Create Shipment', onClick: () => { console.log("Not implemented yet") }},
    { value: 'Select Items to Cancel', label: 'Select Items to Cancel', onClick: () => console.log("Not implemented yet") },
  ];

  return (
    <Card className="unassigned-items-card">
      <Card.Header className={menuState['unassignedItems'] && 'collapsed'}>
        <div>
          No Shipment Assigned
        </div>
        <div className="item-options">
          <ButtonIcon
            icon={<ThreeDotsVertical />}
            options={itemOptions()}
          />
          {orderItems.length > 5 && (
            <ButtonIcon
              icon={menuState['unassignedItems'] ? <ChevronLeft /> : <ChevronDown />}
              onClick={() => toggleMenu('unassignedItems')}
            />
          )}
        </div>
      </Card.Header>
      {!menuState['unassignedItems'] ? (
        <Card.Body>
          {orderItems.map((orderItem) => (
            <OrderItem
              key={orderItem.id}
              itemInfo={orderItem}
            />
          ))}
        </Card.Body>
      ) : (
        <Card.Body>
          <IconData
            label={`${orderItems.length} Unassigned Item${orderItems.length === 1 ? '' : 's'}`}
            icon={<Box />}
          />
        </Card.Body>
      )}
    </Card>
  )
}

export default OrderItemsCard;