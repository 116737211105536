import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { getHistory, reset as resetHistoryData } from 'app/store/actions/history';
import { historyDataSelector, historyLoadingSelector, historyErrorsSelector } from 'app/store/selectors/history';
import { Table, TableHeader, Card, LoadingAnimation, Pagination } from 'app/components';
import moment from 'moment';
import './index.scss';

const WebhooksActionHistory = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const historyData = useSelector(historyDataSelector);
  const historyDataLoading = useSelector(historyLoadingSelector);
  const historyDataError = useSelector(historyErrorsSelector);

  const sortBy = searchParams.get('sortBy') || 'dateCreated';
  const sortDir = searchParams.get('sortDir') || 'desc';
  const currentPage = parseInt(searchParams.get('page') || '1', 10);
  const pageSize = parseInt(searchParams.get('pageSize') || '50', 10);

  useEffect(() => {
    dispatch(resetHistoryData());
  }, []);

  useEffect(() => {
    dispatch(getHistory({parentType: 'user', parentId: '46bae16f-3ccd-4afa-90d2-706551acbed7', entityType: 'notification-api', page: currentPage, pageSize: pageSize, sortAscending: sortDir}));
  }, [currentPage, pageSize, sortDir, dispatch]);

  return (
    <div className="webhook-action-history">
      {historyDataLoading && <LoadingAnimation />}
      {historyData && (
        <>
          <Table size="medium" verticalAlign="top" highlightRows={false}>
            <TableHeader
              sortBy={sortBy}
              sortDir={sortDir}
              options={[
                { id: 'dateCreated', label: 'Date Created', orderable: true },
                { id: 'id', label: 'Log ID' },
                { id: 'userId', label: 'User ID' },
                { id: 'description', label: 'Description' },
              ]}
            />
            <tbody className="table-body">
              {historyData.historyLogs.map((log) => (
                <tr className="history-row" key={log.id}>
                  <td>{moment(log.dateCreated).format('MMM DD, YYYY h:mm a Z')}</td>
                  <td>{log.id}</td>
                  <td>{log.userId}</td>
                  <td>{log.description}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Pagination totalItems={historyData?.total || 0} />
        </>
      )}
      {historyData && historyData.historyLogs.length === 0 && (
        <Card.Body>
          <div className="no-results">
            No history data found.
          </div>
        </Card.Body>
      )}
      {historyDataError && (
        <Card.Body>
          <div className="history-data-failed">
            History Data failed to load.  Refresh the page to try again.
          </div>
        </Card.Body>
      )}
    </div>
  );
};

export default WebhooksActionHistory;
