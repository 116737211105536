import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

const TextArea = ({
  className,
  label, 
  errorMessage, 
  name, 
  readonly,
  ...rest
}) => {
  const showError = errorMessage && errorMessage.trim().length > 0;

  return (
    <div className={`gooten-text-area ${className}`}>
      <label htmlFor={name}>{label}</label>
      <textarea
        className={`form-control ${showError ? 'has-error' : ''}`}
        name={name}
        readOnly={readonly}
        spellCheck="false"
        {...rest}
      />
      <div className="error">{errorMessage}</div>
    </div>
  );
};

TextArea.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  name: PropTypes.string.isRequired,
  readonly: PropTypes.bool,
};

TextArea.defaultProps = {
  className: '',
  label: '',
  errorMessage: null,
  readonly: false,
};

export default TextArea;