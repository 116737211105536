import React, { useMemo } from 'react';
import { Card, MessageBar } from 'app/components';
import ReactApexChart from 'react-apexcharts';
import Skeleton from 'react-loading-skeleton';
import { Check2Square } from 'react-bootstrap-icons';

const LateOrdersByFacility = ({ queryData = {} }) => {
  const { data, loading, error } = queryData;

  const countLateShipmentsByFacility = (shipments) => {
    return shipments.reduce((acc, shipment, index) => {
      const facilityName = shipment.facility_name || 'Unknown Facility ' + index;
      if (!acc[facilityName]) {
        acc[facilityName] = 0;
      }
      acc[facilityName]++;
      return acc;
    }, {});
  };

  const lateShipmentsCount = data ? countLateShipmentsByFacility(data) : [];

  const minValue = Math.max(0, Math.floor(Math.min(...Object.values(lateShipmentsCount))));
  const maxValue = Math.ceil(Math.max(...Object.values(lateShipmentsCount)));

  const chartData = useMemo(() => ({
    series: [{
      data: Object.keys(lateShipmentsCount).map(facility => ({
        x: facility,
        y: lateShipmentsCount[facility],
      }))
    }],
    options: {
      title: {
        text: 'Late Orders by Facility',
        align: 'left',
        style: {
          fontFamily: "Source Sans Pro",
          fontSize: '16px',
          fontWeight: 600,
          lineHeight: '26px',
          color: '#2E2E2E'
        }
      },
      chart: {
        type: 'bar',
        animations: {
          enabled: false,
        },
        events: {
          click: function (chart, w, e) {
            console.log(chart, w, e)
          }
        }
      },
      colors: ['#9CB4E2', '#F8B05B', '#E8D953', '#6DCB5D', '#9AA4FF'],
      plotOptions: {
        bar: {
          horizontal: true,
          columnWidth: '45%',
          distributed: true,
          dataLabels: {
            position: 'top'
          }
        }
      },
      dataLabels: {
        enabled: false,
        style: {
          fontSize: '12px',
          colors: ['#ffffff'],
        }
      },
      xaxis: {
        tickAmount: maxValue - minValue,
        labels: {
          style: {
            colors: ['#878787'],
            fontSize: '12px'
          },
          formatter: function (val) {
            return Math.floor(val);
          }
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: ['#878787'],
            fontSize: '12px'
          },
        },
      },
      legend: {
        show: false
      },
    },
  }), [lateShipmentsCount, minValue, maxValue]);

  return (
    <Card className={`late-orders-by-facility gooten-dashboard-chart ${data && data.length > 0 ? 'has-data' : ''}`}>
      {data && data.length > 0 && (
        <ReactApexChart options={chartData.options} series={chartData.series} type="bar" height={350} />
      )}
      {data && data.length === 0 && (
        <div className="loading">
          <div className="loading-header">
            {chartData.options.title.text}
          </div>
          <MessageBar
            message="No late orders by facility"
            color="blue"
            icon={<Check2Square />}
          />
        </div>
      )}
      {error && (
        <div className="loading">
          <div className="loading-header">
            {chartData.options.title.text}
          </div>
          <MessageBar
            message="An error occurred while fetching data"
            color="yellow"
          />
        </div>
      )}
      {loading && (
        <div className="loading">
          <div className="loading-header">
            {chartData.options.title.text}
          </div>
          <Skeleton height={150} count={2} borderRadius={15} />
        </div>
      )}
    </Card>
  )
}

export default LateOrdersByFacility;
