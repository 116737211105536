import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import './index.scss';

const Breadcrumbs = props => {
  const {
    pages,
    size,
    className
  } = props;

  const navigate = useNavigate();

  const handleClick = (url, e) => {
    e.preventDefault();
    navigate(url);
  }

  return (
    <div className={`gooten-breadcrumbs size-${size} ${className || ''}`}>
      {pages.map((page, index) => (
        <React.Fragment key={page.name}>
          <div>
            {page.url ? (
              <span
                className="gooten-breadcrumbs-link"
                onClick={(e) => handleClick(page.url, e)}
              >
                {page.name}
              </span>
            ) : (
              <span>{page.name}</span>
            )}
          </div>
          {index < pages.length - 1 && (
            <div>
              <span>/</span>
            </div>
          )}
        </React.Fragment>
      ))}
    </div>
  )
}

Breadcrumbs.propTypes = {
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      url: PropTypes.string,
    })
  ).isRequired,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  className: PropTypes.string,
};

Breadcrumbs.defaultProps = {
  className: '',
  size: 'medium',
};

export default Breadcrumbs;