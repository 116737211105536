import { handleActions } from 'redux-actions';
import {
  getWebhooks,
  getWebhooksSuccess,
  getWebhooksFailure,
  getWebhookDetails,
  getWebhookDetailsSuccess,
  getWebhookDetailsFailure,
  subscribeToWebhook,
  subscribeToWebhookSuccess,
  subscribeToWebhookFailure,
  updateWebhook,
  updateWebhookSuccess,
  updateWebhookFailure,
  deleteWebhookSubscription,
  deleteWebhookSubscriptionSuccess,
  deleteWebhookSubscriptionFailure,
  testWebhookSubscription,
  testWebhookSubscriptionSuccess,
  testWebhookSubscriptionFailure,
  getIntegrations,
  getIntegrationsSuccess,
  getIntegrationsFailure,
  getIntegrationCustomizations,
  getIntegrationCustomizationsSuccess,
  getIntegrationCustomizationsFailure,
  getIntegrationBasicInfo,
  getIntegrationBasicInfoSuccess,
  getIntegrationBasicInfoFailure,
  updateIntegrationBasicInfo,
  updateIntegrationBasicInfoSuccess,
  updateIntegrationBasicInfoFailure,
  createIntegration,
  createIntegrationSuccess,
  createIntegrationFailure,
  getIntegrationVersions,
  getIntegrationVersionsSuccess,
  getIntegrationVersionsFailure,
  getIntegrationDetails,
  getIntegrationDetailsSuccess,
  getIntegrationDetailsFailure,
  updateIntegration,
  updateIntegrationSuccess,
  updateIntegrationFailure,
  addCustomizationToIntegration,
  addCustomizationToIntegrationSuccess,
  addCustomizationToIntegrationFailure,
  publishIntegrationVersion,
  publishIntegrationVersionSuccess,
  publishIntegrationVersionFailure,
  cloneIntegrationVersion,
  cloneIntegrationVersionSuccess,
  cloneIntegrationVersionFailure,
  deleteIntegration,
  deleteIntegrationSuccess,
  deleteIntegrationFailure,
  getEventTypes,
  getEventTypesSuccess,
  getEventTypesFailure,
  clearErrors,
  reset,
} from "app/store/actions/notification";

const initialState = {
  webhooks: {
    loading: false,
    data: null,
    error: null,
  },
  webhook: {
    loading: false,
    data: null,
    error: null,
  },
  webhookTest: {
    loading: false,
    data: null,
    error: null,
  },
  integrations: {
    loading: false,
    data: null,
    error: null,
  },
  integration: {
    loading: false,
    data: null,
    error: null,
  },
  eventTypes: {
    loading: false,
    data: null,
    error: null,
  },
}

const notificationReducer = handleActions(
  {
    [getWebhooks]: (state) => ({ 
      ...state,
      webhooks: {
        ...state.webhooks,
        loading: true,
        error: null,
      },
    }),

    [getWebhooksSuccess]: (state, data) => ({
      ...state,
      webhooks: {
        loading: false,
        data: data.payload,
        error: null,
      },
    }),

    [getWebhooksFailure]: (state, data) => ({ 
      ...state,
      webhooks: {
        loading: false,
        data: null,
        error: data,
      },
    }),

    [getWebhookDetails]: (state) => ({ 
      ...state,
      webhook: {
        ...state.webhook,
        loading: true,
        error: null,
      },
    }),

    [getWebhookDetailsSuccess]: (state, data) => ({
      ...state,
      webhook: {
        loading: false,
        data: data.payload,
        error: null,
      },
    }),

    [getWebhookDetailsFailure]: (state, data) => ({ 
      ...state,
      webhook: {
        loading: false,
        data: null,
        error: data,
      },
    }),

    [subscribeToWebhook]: (state) => ({ 
      ...state,
      webhook: {
        ...state.webhook,
        loading: true,
        error: null,
      },
    }),

    [subscribeToWebhookSuccess]: (state) => ({
      ...state,
      webhook: {
        ...state.webhook,
        loading: false,
        error: null,
      },
    }),

    [subscribeToWebhookFailure]: (state, data) => ({ 
      ...state,
      webhook: {
        ...state.webhook,
        loading: false,
        error: data,
      },
    }),

    [updateWebhook]: (state) => ({ 
      ...state,
      webhook: {
        ...state.webhook,
        loading: true,
        error: null,
      },
    }),

    [updateWebhookSuccess]: (state, data) => ({
      ...state,
      webhook: {
        ...state.webhook,
        data: data.payload,
        loading: false,
        error: null,
      },
    }),

    [updateWebhookFailure]: (state, data) => ({ 
      ...state,
      webhook: {
        ...state.webhook,
        loading: false,
        error: data,
      },
    }),

    [deleteWebhookSubscription]: (state) => ({ 
      ...state,
      webhook: {
        ...state.webhook,
        loading: true,
        error: null,
      },
    }),

    [deleteWebhookSubscriptionSuccess]: (state) => ({
      ...state,
      webhook: {
        ...state.webhook,
        loading: false,
        error: null,
      },
    }),

    [deleteWebhookSubscriptionFailure]: (state, data) => ({ 
      ...state,
      webhook: {
        ...state.webhook,
        loading: false,
        error: data,
      },
    }),

    [testWebhookSubscription]: (state) => ({ 
      ...state,
      webhookTest: {
        data: null,
        loading: true,
        error: null,
      },
    }),

    [testWebhookSubscriptionSuccess]: (state, data) => ({
      ...state,
      webhookTest: {
        data: data.payload,
        loading: false,
        error: null,
      },
    }),

    [testWebhookSubscriptionFailure]: (state, data) => ({ 
      ...state,
      webhookTest: {
        data: null,
        loading: false,
        error: data,
      },
    }),

    [getIntegrations]: (state) => ({
      ...state,
      integrations: {
        ...state.integrations,
        loading: true,
        error: null,
      },
    }),

    [getIntegrationsSuccess]: (state, data) => ({
      ...state,
      integrations: {
        loading: false,
        data: data.payload,
        error: null,
      },
    }),

    [getIntegrationsFailure]: (state, data) => ({
      ...state,
      integrations: {
        loading: false,
        data: null,
        error: data,
      },
    }),

    [getIntegrationCustomizations]: (state) => ({
      ...state,
      integration: {
        ...state.integration,
        loading: true,
        error: null,
      },
    }),

    [getIntegrationCustomizationsSuccess]: (state, data) => ({
      ...state,
      integration: {
        loading: false,
        data: data.payload,
        error: null,
      },
    }),

    [getIntegrationCustomizationsFailure]: (state, data) => ({
      ...state,
      integration: {
        ...state.integration.data,
        loading: false,
        error: data,
      },
    }),

    [getIntegrationBasicInfo]: (state) => ({
      ...state,
      integration: {
        ...state.integration,
        loading: true,
        error: null,
      },
    }),

    [getIntegrationBasicInfoSuccess]: (state, data) => ({
      ...state,
      integration: {
        loading: false,
        data: {
          ...state.integration.data,
          basicInfo: data.payload.pluginData,
        },
        error: null,
      },
    }),

    [getIntegrationBasicInfoFailure]: (state, data) => ({
      ...state,
      integration: {
        ...state.integration.data,
        loading: false,
        error: data,
      },
    }),

    [updateIntegrationBasicInfo]: (state) => ({
      ...state,
      integration: {
        ...state.integration,
        loading: true,
        error: null,
      },
    }),

    [updateIntegrationBasicInfoSuccess]: (state, data) => ({
      ...state,
      integration: {
        ...state.integration,
        data: {
          ...state.integration.data,
          basicInfo: data.payload.pluginData,
        },
        loading: false,
        error: null,
      },
    }),

    [updateIntegrationBasicInfoFailure]: (state, data) => ({
      ...state,
      integration: {
        ...state.integration,
        loading: false,
        error: data,
      },
    }),

    [createIntegration]: (state) => ({
      ...state,
      integrations: {
        ...state.integrations,
        loading: true,
        error: null,
      },
    }),

    [createIntegrationSuccess]: (state, data) => ({
      ...state,
      integrations: {
        ...state.integrations,
        data: {
          ...state.integration.data,
          details: data.payload.plugin,
        },
        loading: false,
        error: null,
      },
    }),

    [createIntegrationFailure]: (state, data) => ({
      ...state,
      integrations: {
        ...state.integrations,
        loading: false,
        error: data,
      },
    }),

    [getIntegrationVersions]: (state) => ({
      ...state,
      integration: {
        loading: true,
        data: null,
        error: null,
      },
    }),

    [getIntegrationVersionsSuccess]: (state, data) => ({
      ...state,
      integration: {
        loading: false,
        data: {
          ...state.integration.data,
          versions: data.payload.pluginVersions,
        },
        error: null,
      },
    }),

    [getIntegrationVersionsFailure]: (state, data) => ({
      ...state,
      integration: {
        ...state.integration.data,
        loading: false,
        error: data,
      },
    }),

    [getIntegrationDetails]: (state) => ({
      ...state,
      integration: {
        ...state.integration,
        loading: true,
        error: null,
      },
    }),

    [getIntegrationDetailsSuccess]: (state, data) => ({
      ...state,
      integration: {
        loading: false,
        data: {
          ...state.integration.data,
          details: data.payload?.pluginVersion,
        },
        error: null,
      },
    }),

    [getIntegrationDetailsFailure]: (state, data) => ({
      ...state,
      integration: {
        ...state.integration.data,
        loading: false,
        error: data,
      },
    }),

    [updateIntegration]: (state) => ({
      ...state,
      integration: {
        ...state.integration,
        loading: true,
        error: null,
      },
    }),

    [updateIntegrationSuccess]: (state, data) => ({
      ...state,
      integration: {
        ...state.integration,
        data: {
          ...state.integration.data,
          details: data.payload.pluginVersion,
        },
        loading: false,
        error: null,
      },
    }),

    [updateIntegrationFailure]: (state, data) => ({
      ...state,
      integration: {
        ...state.integration,
        loading: false,
        error: data,
      },
    }),

    [addCustomizationToIntegration]: (state) => ({
      ...state,
      integration: {
        ...state.integration,
        loading: true,
        error: null,
      },
    }),

    [addCustomizationToIntegrationSuccess]: (state) => ({
      ...state,
      integration: {
        ...state.integration,
        loading: false,
        error: null,
      },
    }),

    [addCustomizationToIntegrationFailure]: (state, data) => ({
      ...state, 
      integration: {
        ...state.integration,
        loading: false,
        error: data,
      },
    }),

    [publishIntegrationVersion]: (state) => ({
      ...state,
      integration: {
        ...state.integration,
        loading: true,
        error: null,
      },
    }),

    [publishIntegrationVersionSuccess]: (state) => ({
      ...state,
      integration: {
        ...state.integration,
        loading: false,
        error: null,
      },
    }),

    [publishIntegrationVersionFailure]: (state, data) => ({
      ...state,
      integration: {
        ...state.integration,
        loading: false,
        error: data,
      },
    }),

    [cloneIntegrationVersion]: (state) => ({
      ...state,
      integration: {
        ...state.integration,
        loading: true,
        error: null,
      },
    }),

    [cloneIntegrationVersionSuccess]: (state) => ({
      ...state,
      integration: {
        ...state.integration,
        loading: false,
        error: null,
      },
    }),

    [cloneIntegrationVersionFailure]: (state, data) => ({
      ...state,
      integration: {
        ...state.integration,
        loading: false,
        error: data,
      },
    }),

    [deleteIntegration]: (state) => ({
      ...state,
      integrations: {
        ...state.integrations,
        loading: true,
        error: null,
      },
    }),

    [deleteIntegrationSuccess]: (state) => ({
      ...state,
      integrations: {
        ...state.integrations,
        loading: false,
        error: null,
      },
    }),

    [deleteIntegrationFailure]: (state, data) => ({
      ...state,
      integrations: {
        ...state.integrations,
        loading: false,
        error: data,
      },
    }),

    [getEventTypes]: (state) => ({
      ...state,
      eventTypes: {
        ...state.eventTypes,
        loading: true,
        error: null,
      },
    }),

    [getEventTypesSuccess]: (state, data) => ({
      ...state,
      eventTypes: {
        loading: false,
        data: data.payload,
        error: null,
      },
    }),

    [getEventTypesFailure]: (state, data) => ({
      ...state,
      eventTypes: {
        loading: false,
        data: null,
        error: data,
      },
    }),

    [clearErrors]: (state) => ({ 
      ...state,
      webhooks: {
        ...state.webhooks,
        error: null,
      },
      webhook: {
        ...state.webhook,
        error: null,
      },
      webhookTest: {
        ...state.webhookTest,
        error: null,
      },
    }),

    [reset]: () => ({ 
      ...initialState
     })
  },
  initialState,
)

export default notificationReducer;