import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { merchantsSelector, merchantsLoadingSelector, merchantsErrorsSelector } from 'app/store/selectors/merchant';
import { getMerchants, reset } from 'app/store/actions/merchant';
import { Link, Card, Button, Table, TableHeader, Pagination, LoadingAnimation, StatusChip, SearchBar } from 'app/components';
// import MerchantsFilterMenu from './MerchantsFilterMenu';
import { ExclamationCircle } from 'react-bootstrap-icons';
import moment from 'moment';
import './index.scss';

const MerchantsView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const merchantsData = useSelector(merchantsSelector);
  const loading = useSelector(merchantsLoadingSelector);
  const errorsFound = useSelector(merchantsErrorsSelector);

  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = parseInt(searchParams.get('page') || '1', 10);
  const pageSize = parseInt(searchParams.get('pageSize') || '50', 10);
  const searchString = searchParams.get('searchString') || null;
  const sortBy = searchParams.get('sortBy') || 'shortId';
  const sortDir = searchParams.get('sortDir') || 'desc';
  const statusFilter = searchParams.get('status') || null;

  useEffect(() => {
    if (!searchString || searchString.length !== 1) {
      dispatch(getMerchants({
        searchString,
        currentPage,
        pageSize,
        sortBy,
        sortDir
      }));
    }
  }, [searchString, statusFilter, currentPage, pageSize, sortBy, sortDir, dispatch]);

  const onSearchStringUpdated = (searchString) => {
    const updatedSearchParams = new URLSearchParams(searchParams.toString());
    updatedSearchParams.set('page', 1);
    searchString ? updatedSearchParams.set('searchString', searchString) : updatedSearchParams.delete('searchString');
    setSearchParams(updatedSearchParams.toString());
  };

  const merchantType = (merchant) => {
    if (merchant.parentId) {
      return 'Child Merchant';
    } else if (merchant.isParent) {
      return 'Parent Merchant';
    } else {
      return 'Merchant';
    }
  }

  return (
    <div className="merchants-view">
      {loading && <LoadingAnimation />}
      {(merchantsData || errorsFound) && (
        <Card>
          <div className="merchants-header">
            Merchants
            <Button
              variant="primary"
              size="medium"
              label={"Create Merchant"}
              disabled={errorsFound}
              onClick={() => {
                dispatch(reset());
                navigate(`/admin/merchants/create-merchant`);
              }}
            />
          </div>
          <div className="search-bar-and-filters">
            <SearchBar
              searchPlaceholderText="Search for a Merchant"
              onSearchStringUpdated={onSearchStringUpdated}
              debounceDelay={500}
              disabled={errorsFound}
            />
            {/* <MerchantsFilterMenu /> */}
          </div>
          <Table size="medium">
            <TableHeader
              sortBy={sortBy}
              sortDir={sortDir}
              options={[
                { id: 'shortId', label: 'Merchant ID', orderable: errorsFound ? false : true },
                { id: 'name', label: 'Merchant Name', orderable: errorsFound ? false : true },
                { id: 'createdAt', label: 'Created Date' },
                { id: 'merchantType', label: 'Merchant Type' },
                { id: 'status', label: 'Status', align: 'center' },
              ]}
            />
            <tbody className="table-body">
              {merchantsData && merchantsData.merchants.map((merchant) => (
                <tr className="merchant-row" key={merchant.id}>
                  <td>
                    <Link label={merchant.shortId || merchant.id} onClick={() => {
                      dispatch(reset());
                      navigate(`/admin/merchants/${merchant.id || merchant.shortId}`)
                    }}
                    />
                  </td>
                  <td>{merchant.name}</td>
                  <td>{moment(merchant.metaInformation?.createdAt).format('MMM DD, YYYY')}</td>
                  <td>{merchantType(merchant)}</td>
                  <td className="merchant-status-chip"><StatusChip status={merchant.enabled ? 'Active' : 'Disabled'} /></td>
                </tr>
              ))}
              {merchantsData?.merchants.length === 0 && !errorsFound && !loading && (
                <tr>
                  <td colSpan="6" className="error-message">No Results Found</td>
                </tr>
              )}
              {errorsFound && !loading && (
                <tr>
                  <td colSpan="6" className="error-message"><ExclamationCircle />Error fetching merchant data</td>
                </tr>
              )}
            </tbody>
          </Table>
          {merchantsData && (
            <Pagination
              totalItems={merchantsData?.total || 0}
              hideBelow={30}
            />
          )}
        </Card>
      )}
    </div>
  )
}

export default MerchantsView;