import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { webhooksDataSelector, webhooksLoadingSelector, webhooksErrorsSelector, webhookTestLoadingSelector, webhookDetailsLoadingSelector } from 'app/store/selectors/notification';
import { getWebhooks, deleteWebhookSubscription, testWebhookSubscription, updateWebhook, reset } from 'app/store/actions/notification';
import { Modal, Link, Pagination, LoadingAnimation, SearchBar, ButtonIcon, StatusChip, Table, TableHeader, Button, ToggleSwitch } from 'app/components';
import { Trash3, ArrowCounterclockwise, ExclamationCircle } from 'react-bootstrap-icons';
import './index.scss';

const WebhooksList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [searchParams] = useSearchParams();
  const [searchString, setSearchString] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('active');
  const [selectedSortBy, setSelectedSortBy] = useState('');
  const [selectedSortDir, setSelectedSortDir] = useState('');
  const [deleteWebhookModal, showDeleteWebhookModal] = useState(null);

  const webhooksData = useSelector(webhooksDataSelector);
  const loading = useSelector(webhooksLoadingSelector);
  const errorsFound = useSelector(webhooksErrorsSelector);
  const webhookTestLoading = useSelector(webhookTestLoadingSelector);
  const webhookDetailsLoading = useSelector(webhookDetailsLoadingSelector);

  const currentPage = parseInt(searchParams.get('page') || '1', 10);
  const pageSize = parseInt(searchParams.get('pageSize') || '50', 10);
  const sortBy = searchParams.get('sortBy') || 'id';
  const sortDir = searchParams.get('sortDir') || 'desc';

  useEffect(() => {
    dispatch(getWebhooks({ subscriberId: '46bae16f-3ccd-4afa-90d2-706551acbed7' }));
  }, [selectedStatus, currentPage, pageSize, dispatch]);

  useEffect(() => {
    handleLocationSearch();
  }, [location.search]);

  const handleLocationSearch = () => {
    const searchParams = new URLSearchParams(location.search);

    // Update status, sort by, and sort direction from query parameters
    setSelectedStatus(searchParams.get('status') || selectedStatus);
    setSelectedSortBy(searchParams.get('sortBy') || selectedSortBy);
    setSelectedSortDir(searchParams.get('sortDir') || selectedSortDir);
  }

  const getFormattedHeaders = (headers) => {
    // check if headers is an object and not null or undefined
    if (headers && typeof headers === 'object') {
      const formattedHeaders = Object.entries(headers).map(([key, value]) => ({
        key,
        value,
      }));
      // add an extra empty object to the end of the array
      formattedHeaders.push({ key: '', value: '' });
      return formattedHeaders;
    }
    // fallback if no headers or invalid format, includes one empty object
    return [{ key: '', value: '' }];
  };

  const sortWebhooks = (webhooks) => {
    return webhooks.sort((a, b) => {
      if (sortDir === 'asc') {
        return a[sortBy].toString().localeCompare(b[sortBy].toString());
      } else {
        return b[sortBy].toString().localeCompare(a[sortBy].toString());
      }
    });
  };

  const testWebhook = (webhook) => {
    dispatch(testWebhookSubscription({ callbackUrl: webhook.destinationUrl, callbackMethod: webhook.destinationMethod, headerInfo: getFormattedHeaders(webhook.customHeaders), cb: (status) => {
      const updatedWebhook = JSON.parse(JSON.stringify(webhook));
      updatedWebhook.isTestSuccessful = status;
      dispatch(updateWebhook({ data: updatedWebhook, showToast: false, cb: () => dispatch(getWebhooks({ subscriberId: '46bae16f-3ccd-4afa-90d2-706551acbed7' })) }));
    }}));
  };

  const filteredWebhooks = !webhooksData || !webhooksData.subscriptions || searchString === '' ?
    (webhooksData?.subscriptions || []) :
    webhooksData.subscriptions.filter(webhook =>
      webhook.id.toLowerCase().includes(searchString.toLowerCase()) ||
      webhook.destinationUrl.toLowerCase().includes(searchString.toLowerCase()) ||
      webhook.destinationMethod.toLowerCase().includes(searchString.toLowerCase())
    );

  const sortedWebhooks = sortWebhooks(filteredWebhooks);
  const paginatedWebhooks = sortedWebhooks.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  return (
    <div className="webhooks-list">
      {(loading || webhookTestLoading || webhookDetailsLoading) && <LoadingAnimation />}
      {(webhooksData || errorsFound) && (
        <>
          <SearchBar
            searchPlaceholderText="Search for a Webhook"
            onSearchStringUpdated={setSearchString}
            disabled={errorsFound}
          />
          <Table size="medium">
            <TableHeader
              sortBy={sortBy}
              sortDir={sortDir}
              options={[
                { id: 'id', label: 'Webhook ID', orderable: !errorsFound },
                { id: 'events', label: 'Events', orderable: !errorsFound },
                { id: 'destinationUrl', label: 'Callback URL', orderable: !errorsFound },
                { id: 'destinationMethod', label: 'Callback Method', orderable: !errorsFound },
                { id: 'transformation', label: 'Transformation' },
                { id: 'isEnabled', label: 'Active', align: 'center', orderable: !errorsFound },
                { id: 'tested', label: 'Tested', align: 'center', width: '140px'},
                { id: 'actions', label: 'Actions', align: 'center' },
              ]}
            />
            <tbody className="table-body">
              {paginatedWebhooks.map((webhook) => (
                <tr className="webhook-row" key={webhook.id}>
                  <td>
                    <Link
                      label={webhook.id}
                      onClick={() => {
                        dispatch(reset());
                        navigate(`/admin/webhooks/${webhook.id}`);
                      }}
                    />
                  </td>
                  <td>{`${webhook.events.length} Event${webhook.events.length === 1 ? '' : 's'}`}</td>
                  <td>{webhook.destinationUrl}</td>
                  <td>{webhook.destinationMethod}</td>
                  <td>{webhook.events.some(e => e.snippetId) ? 'Yes' : 'No'}</td>
                  <td>
                    <ToggleSwitch
                      isOn={webhook.isEnabled}
                      disabled={webhook.isTestSuccessful !== true}
                      onToggle={(val) => {
                        const updatedWebhook = JSON.parse(JSON.stringify(webhook));
                        updatedWebhook.isEnabled = val;
                        dispatch(updateWebhook({ data: updatedWebhook, cb: () => dispatch(getWebhooks({ subscriberId: '46bae16f-3ccd-4afa-90d2-706551acbed7' })) }));
                      }}
                    />
                  </td>
                  <td className="webhook-status">
                    {webhook.isTestSuccessful !== undefined ? webhook.isTestSuccessful ?
                      <StatusChip type="webhooks" status={'Success'} /> :
                      <StatusChip type="webhooks" status={'Error'} icon={<ArrowCounterclockwise />} onClick={() => testWebhook(webhook)} />
                      :
                      <Button
                        variant="secondary"
                        size="small"
                        label="Test Webhook"
                        onClick={() => testWebhook(webhook)}
                      />}
                  </td>
                  <td className="webhook-actions-menu">
                    <ButtonIcon
                      icon={<Trash3 />}
                      destructive
                      onClick={() => showDeleteWebhookModal(webhook.id)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {errorsFound && !loading && (
            <div className="no-matching-results"><ExclamationCircle />Error fetching vendor data</div>
          )}
          {!errorsFound && filteredWebhooks.length === 0 ? (
            <div className="no-matching-results">No Matching Results Found</div>
          ) : (
            <Pagination totalItems={filteredWebhooks.length || 0} />
          )}
          {deleteWebhookModal && (
            <Modal
              size="md"
              secondaryButtonLabel="No, Keep It"
              secondaryButtonOnClick={() => { showDeleteWebhookModal(null) }}
              primaryButtonLabel="Yes, Delete It"
              primaryButtonVariant="primary"
              primaryButtonDestructive
              primaryButtonOnClick={() => {
                dispatch(deleteWebhookSubscription({ webhookId: deleteWebhookModal, cb: () => dispatch(getWebhooks({ subscriberId: '46bae16f-3ccd-4afa-90d2-706551acbed7' })) }));
                showDeleteWebhookModal(null);
              }}
              onClose={() => showDeleteWebhookModal(null)}
            >
              <div>Are you sure you want to delete this webhook subscription?</div>
              <div>This action is not reversible.</div>
            </Modal>
          )}
        </>
      )}
    </div>
  )
}

export default WebhooksList;