import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { getHistory, reset as resetHistoryData } from 'app/store/actions/history';
import { getOrderDetails } from 'app/store/actions/order';
import { getShipmentsByOrderId } from 'app/store/actions/shipment';
import { historyDataSelector, historyLoadingSelector, historyErrorsSelector } from 'app/store/selectors/history';
import { orderDetailsSelector, orderDetailsLoadingSelector } from 'app/store/selectors/order';
import { shipmentsSelector, shipmentsLoadingSelector } from 'app/store/selectors/shipment';
import { Table, TableHeader, Card, Dropdown, LoadingAnimation, Pagination } from 'app/components';
import moment from 'moment';
import './index.scss';

const HistoryView = (props) => {
  const { orderId } = props;

  const dispatch = useDispatch();

  const historyData = useSelector(historyDataSelector);
  const historyDataLoading = useSelector(historyLoadingSelector);
  const historyDataError = useSelector(historyErrorsSelector);
  const orderDetails = useSelector(orderDetailsSelector);
  const orderDetailsLoading = useSelector(orderDetailsLoadingSelector);
  const shipmentsLoading = useSelector(shipmentsLoadingSelector);
  const shipments = useSelector(shipmentsSelector);

  const [searchParams, setSearchParams] = useSearchParams();
  const entityType = searchParams.get('entityType') || '';
  const currentPage = parseInt(searchParams.get('page') || '1', 10);
  const pageSize = parseInt(searchParams.get('pageSize') || '50', 10);
  const sortDir = searchParams.get('sortDir') || 'desc';
  const entityTypes = [{ "Name": "Order", "Value": "Order" }, { "Name": "Order Item", "Value": "OrderItem" }, { "Name": "Shipment", "Value": "Shipment" }, { "Name": "Package", "Value": "Package" }, { "Name": "Routing", "Value": "Routing" }, { "Name": "Inventory", "Value": "Inventory" }];

  useEffect(() => {
    if (orderDetails?.id !== orderId) {
      dispatch(resetHistoryData());
      dispatch(getOrderDetails(orderId));
      dispatch(getShipmentsByOrderId(orderId));
    }
  }, []);

  useEffect(() => {
    dispatch(getHistory({parentType: 'Order', parentId: orderId, entityType, page: currentPage, pageSize: pageSize, sortAscending: sortDir}));
  }, [entityType, currentPage, pageSize, sortDir, dispatch]);


  return (
    <Card className="history-view">
      <Card.Header>
        History Log Details
        <Dropdown
          name="entityType"
          className="entity-type-dropdown"
          allowClear={true}
          showErrorMessages={false}
          options={entityTypes.map(entityType => (
            { value: entityType.Value, label: entityType.Name }
          ))}
          placeholder="Filter by Entity Type"
          value={entityType}
          onChange={(e) => {
            const updatedSearchParams = new URLSearchParams(searchParams.toString());
            updatedSearchParams.set('entityType', e.target.value);
            setSearchParams(updatedSearchParams.toString());
          }}
        />
      </Card.Header>
      {(historyDataLoading || orderDetailsLoading || shipmentsLoading) && <LoadingAnimation />}
      {historyData && orderDetails && shipments && (
        <Card.Body>
          <Table size="small" verticalAlign="top" highlightRows={false}>
            <TableHeader
              sortBy="date"
              sortDir={sortDir}
              options={[
                { id: 'timestamp', label: 'Timestamp', orderable: true },
                { id: 'user', label: 'User' },
                { id: 'source', label: 'Source' },
                { id: 'event', label: 'Event' },
                { id: 'message', label: 'Message', width: "30%" },
              ]}
            />
            <tbody className="table-body">
              {historyData.historyLogs.map((history) => (
                <tr className="history-row" key={history.id}>
                  <td>{moment(history.dateCreated).format('MMM DD, YYYY h:mm a Z')}</td>
                  <td>{history.userName}</td>
                  <td>{history.entityType}</td>
                  <td>{history.event}</td>
                  <td className="history-description">{history.description}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Pagination totalItems={historyData?.total || 0} />
        </Card.Body>
      )}
      {historyData && historyData.historyLogs.length === 0 && (
        <Card.Body>
          <div className="history-data-empty">
            No history data found.
          </div>
        </Card.Body>
      )}
      {historyDataError && (
        <Card.Body>
          <div className="history-data-failed">
            History Data failed to load.  Refresh the page to try again.
          </div>
        </Card.Body>
      )}
    </Card>
  );
};

export default HistoryView;
