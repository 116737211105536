import React from 'react';
import { Routes, Route } from 'react-router-dom';
import UsersView from './UsersView';
import UserDetails from './UserDetails';
import RolesView from './RolesView';
import RoleDetails from './RoleDetails';

function Users() {
  return (
    <Routes>
      <Route path="/" element={<UsersView />} />
      <Route path="create-user/*" element={<UserDetails />} />
      <Route path=":userId/*" element={<UserDetails />} />
      <Route path="roles/" element={<RolesView />} />
      <Route path="roles/create-role/*" element={<RoleDetails />} />
      <Route path="roles/:roleId/*" element={<RoleDetails />} />
    </Routes>
  );
}

export default Users;
