import axios from 'axios';
import Config from '../config';
import SetupInterceptors from './axiosInterceptors';

class NotificationServices {
  constructor() {
    this.api = axios.create({
      baseURL: Config.NotificationMS.Url
    });

    SetupInterceptors(this.api);
  }

  // WEBHOOKS ----------------------------------------------------------------

  getWebhooks = async (subscriberId) => {
    try {
      const response = await this.api.get(`/v1?subscriberId=${subscriberId}`);
      return response.data;
    } catch (error) {
      throw new Error('Error fetching data from API');
    }
  };

  getWebhookDetails = async (webhookId) => {
    try {
      const response = await this.api.get(`/v1/${webhookId}`);
      return response.data;
    } catch (error) {
      throw new Error('Error fetching data from API');
    }
  };

  subscribeToWebhook = async (data) => {
    try {
      const response = await this.api.post('/v1', data);
      return response.data;
    } catch (error) {
      throw new Error('Error subscribing to webhook');
    }
  };

  updateWebhook = async (data) => {
    try {
      const response = await this.api.put('/v1', data);
      return response.data;
    } catch (error) {
      throw new Error('Error updating webhook details');
    }
  };

  deleteWebhookSubscription = async (webhookId) => {
    try {
      const response = await this.api.delete(`/v1/${webhookId}`);
      return response.data;
    } catch (error) {
      throw new Error('Error deleting webhook subscription');
    }
  };

  testWebhookSubscription = async (callbackUrl, callbackMethod, headers) => {
    try {
      const response = await axios({
        method: callbackMethod.toLowerCase(),
        url: callbackUrl,
        headers: headers,
        data: { test: "data" } 
      });
      return response;
    } catch (error) {
      throw new Error(error);
    }
  };  

  // INTEGRATIONS / PLUGINS ----------------------------------------------------------------

  getIntegrations = async (data = {}) => {
    const {
      searchString,
      page,
      pageSize,
      sortBy,
      sortDir
    } = data;

    let queryString = "";

    // helper function to append parameter if it's not null
    const addParam = (key, value) => {
      if (value !== null && value !== undefined && value !== "") {
        queryString += `&${key}=${encodeURIComponent(value)}`;
      }
    };

    // append parameters dynamically if they are not null
    addParam('q', searchString);
    addParam('page', page);
    addParam('pageSize', pageSize);
    addParam('sortBy', sortBy);
    addParam('sortAsc', sortDir === 'asc');

    try {
      const response = await this.api.get(`/v1/plugin?${queryString}`);
      return response.data;
    } catch (error) {
      throw new Error('Error fetching data from API');
    }
  };

  getIntegrationBasicInfo = async (pluginId) => {
    try {
      const response = await this.api.get(`/v1/plugin/${pluginId}`);
      return response.data;
    } catch (error) {
      throw new Error('Error fetching data from API');
    }
  }

  updateIntegrationBasicInfo = async (pluginId, data) => {
    try {
      const response = await this.api.patch(`/v1/plugin/${pluginId}`, data);
      return response.data;
    } catch (error) {
      throw new Error('Error updating integration');
    }
  }

  createIntegration = async (data) => {
    try {
      const response = await this.api.post('/v1/plugin', data);
      return response.data;
    } catch (error) {
      throw new Error('Error creating integration');
    }
  }

  getIntegrationVersions = async (pluginId) => {
    try {
      const response = await this.api.get(`/v1/plugin/${pluginId}/version`);
      return response.data;
    } catch (error) {
      throw new Error('Error fetching data from API');
    }
  }

  getIntegrationDetails = async (pluginId, pluginVersionId) => {
    try {
      const response = await this.api.get(`/v1/plugin/${pluginId}/version/${pluginVersionId}`);
      return response.data;
    } catch (error) {
      throw new Error('Error fetching data from API');
    }
  }

  createInitialIntegrationVersion = async (pluginId, data) => {
    try {
      const response = await this.api.post(`/v1/plugin/${pluginId}/version`, data);
      return response.data;
    } catch (error) {
      throw new Error('Error creating integration version');
    }
  }

  updateExistingIntegrationVersion = async (pluginId, pluginVersionId, data) => {
    try {
      const response = await this.api.patch(`/v1/plugin/${pluginId}/version/${pluginVersionId}`, data);
      return response.data;
    } catch (error) {
      throw new Error('Error updating integration version');
    }
  }

  publishIntegrationVersion = async (pluginId, pluginVersionId) => {
    try {
      const response = await this.api.patch(`/v1/plugin/${pluginId}/version/${pluginVersionId}/promote`);
      return response.data;
    } catch (error) {
      throw new Error('Error publishing integration version');
    }
  }

  cloneIntegrationVersion = async (pluginId, pluginVersionId) => {
    try {
      const response = await this.api.post(`/v1/plugin/${pluginId}/version/${pluginVersionId}/clone`);
      return response.data;
    } catch (error) {
      throw new Error('Error cloning integration version');
    }
  }

  deleteIntegration = async (pluginId) => {
    try {
      const response = await this.api.delete(`/v1/plugin/${pluginId}`);
      return response.data;
    } catch (error) {
      throw new Error('Error deleting integration');
    }
  }

  getEventTypes = async () => {
    try {
      const response = await this.api.get(`/v1/event`);
      return response.data;
    } catch (error) {
      throw new Error('Error fetching data from API');
    }
  }
}

export default new NotificationServices();
