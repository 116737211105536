import React from 'react';
import { Radar, Receipt } from 'react-bootstrap-icons';
import { IconData, MessageBar } from 'app/components';
import OrderItem from '../../OrderItem';
import { ExclamationCircle } from 'react-bootstrap-icons';
import './index.scss';

const PackageCard = (props) => {
  const {
    shipment,
    orderPackage,
    numPackages,
    showCarrierLabelModal,
    showChangeVendorModal,
  } = props;

  const lookupOrderItemInfo = (itemId) => {
    return shipment.orderItems.find(item => item.id === itemId);
  }

  return (
    <div className={`package-card ${numPackages > 1 ? 'multiple' : ''}`} key={orderPackage.id}>
      {numPackages > 1 && (
        <div className="package-card-header">
          <div className="package-num">Package {orderPackage.shortId}</div>
          {orderPackage.carrierName && orderPackage.carrierMethodName && orderPackage.carrierLink && (
            <IconData
              label={`${orderPackage.carrierName} ${orderPackage.carrierMethodName}`}
              icon={<Radar />}
              onClick={() => {
                window.open(orderPackage.carrierLink, '_blank');
              }}
            />
          )}
          <IconData
            label="View Shipping Label"
            icon={<Receipt />}
            onClick={() => {showCarrierLabelModal(true)}}
          />
        </div>
      )}
      {orderPackage.orderItemIDs.map((item) => {
        const itemInfo = lookupOrderItemInfo(item);
        if (!itemInfo) {
          return (
            <MessageBar key={item} variant="warning" icon={<ExclamationCircle />} message={`Order item ${item} not found`} />
          )
        }
        return (
          <OrderItem
            key={itemInfo.id}
            itemInfo={itemInfo}
            shipmentId={shipment.id}
            showChangeVendorModal={showChangeVendorModal}
          />
        )})
      }
    </div>
  )
}

export default PackageCard;