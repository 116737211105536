import { Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';
import "./index.scss";

const LoadingAnimation = (props) => {
  const { variant, fullscreen } = props;
  return (
    <div className={`loading-animation ${fullscreen ? 'fullscreen' : ''}`}>
      <Spinner animation="border" variant={variant} />
    </div>
  );
}

LoadingAnimation.propTypes = {
  variant: PropTypes.string,
  fullscreen: PropTypes.bool,
};

LoadingAnimation.defaultProps = {
  variant: 'primary',
  fullscreen: true,
};


export default LoadingAnimation;
