import axios from 'axios';
import Config from '../config';
import { persistor } from 'app/store/store';

const SetupInterceptors = (api) => {
  api.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('btoken');
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      config.headers = {
        ...config.headers,
        'Content-Type': 'application/json',
      };
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  api.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalRequest = error.config;
      const isLoginRequest = originalRequest.url.includes('/login'); // Adjust '/login' to match your login endpoint URL

      if (error.response.status === 401 && !originalRequest._retry && !isLoginRequest) {
        originalRequest._retry = true;
        try {
          return await axiosRetry(originalRequest);
        } catch (refreshError) {
          // getting to this point means our bearer token AND the refresh token have expired.  The only thing we can do
          // is log them out and force them to re-login.  Before we can do that, we need to completely clear the redux store
          // so when the page is reloaded our redux-persist library doesn't refresh it with the old data.
          console.error('Refresh token expired');

          // pause persisting to avoid saving any new state changes
          persistor.pause(); 
          persistor.flush().then(() => {
            persistor.purge(); 
            window.location.reload();
          });

          // delay the throw using setTimeout.  The page reload is not immediate.  We don't want to throw the error back to the
          // calling function as it will then display an error message on the screen.  Instead, let's just force the code to
          // sit idle while we wait for the page to reload (which restarts the application fresh)
          return new Promise((resolve, reject) => {
            setTimeout(() => {
              reject(refreshError);
            }, 5000); // some arbiturary time that is larger than the time it takes for the page to reload
          });
        }
      }
      return Promise.reject(error);
    }
  );
};

const axiosRetry = async (originalRequest) => {
  const refreshedToken = await refreshToken();
  if (refreshedToken) {
    originalRequest.headers.Authorization = `Bearer ${refreshedToken}`;
    return axios(originalRequest);
  } else {
    // refresh token is expired
    throw new Error();
  }
};


const refreshToken = async () => {
  try {
    const data = JSON.stringify({ refreshToken: localStorage.getItem('rtoken') });

    const response = await axios.post(`${Config.UserMS.Url}/v1/refresh`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!response.data?.isError) {
      localStorage.setItem('btoken', response.data.accessToken);
      localStorage.setItem('rtoken', response.data.refreshToken);
    } else {
      return null;
    }

    return response.data.accessToken;
  } catch (error) {
    throw new Error('Error fetching data from API');
  }
};

export default SetupInterceptors;
