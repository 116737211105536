import { createAction } from 'redux-actions';

const prefix = "ORDER";

export const createOrder = createAction(`${prefix}/CREATE_ORDER`);
export const createOrderSuccess = createAction(`${prefix}/CREATE_ORDER_SUCCESS`);
export const createOrderFailure = createAction(`${prefix}/CREATE_ORDER_FAILURE`);

export const globalOrdersSearch = createAction(`${prefix}/GLOBAL_ORDERS_SEARCH`);
export const globalOrdersSearchSuccess = createAction(`${prefix}/GLOBAL_ORDERS_SEARCH_SUCCESS`);
export const globalOrdersSearchFailure = createAction(`${prefix}/GLOBAL_ORDERS_SEARCH_FAILURE`);
export const globalOrdersSearchClear = createAction(`${prefix}/GLOBAL_ORDERS_SEARCH_CLEAR`);

export const getOrders = createAction(`${prefix}/GET_ORDERS`);
export const getOrdersSuccess = createAction(`${prefix}/GET_ORDERS_SUCCESS`);
export const getOrdersFailure = createAction(`${prefix}/GET_ORDERS_FAILURE`);

export const getOrderDetails = createAction(`${prefix}/GET_ORDER_DETAILS`);
export const getOrderDetailsSuccess = createAction(`${prefix}/GET_ORDER_DETAILS_SUCCESS`);
export const getOrderDetailsFailure = createAction(`${prefix}/GET_ORDER_DETAILS_FAILURE`);

export const updateShippingAddress = createAction(`${prefix}/UPDATE_SHIPPING_ADDRESS`, (orderId, data) => ({ orderId, data }));
export const updateShippingAddressSuccess = createAction(`${prefix}/UPDATE_SHIPPING_ADDRESS_SUCCESS`, (data, resp) => ({ data, resp }));
export const updateShippingAddressFailure = createAction(`${prefix}/UPDATE_SHIPPING_ADDRESS_FAILURE`);

export const updateBillingAddress = createAction(`${prefix}/UPDATE_BILLING_ADDRESS`, (orderId, data) => ({ orderId, data }));
export const updateBillingAddressSuccess = createAction(`${prefix}/UPDATE_BILLING_ADDRESS_SUCCESS`, (data, resp) => ({ data, resp }));
export const updateBillingAddressFailure = createAction(`${prefix}/UPDATE_BILLING_ADDRESS_FAILURE`);

export const updateItemImageUrl = createAction(`${prefix}/UPDATE_ITEM_IMAGE_URL`, (orderId, itemId, data) => ({ orderId, itemId, data }));
export const updateItemImageUrlSuccess = createAction(`${prefix}/UPDATE_ITEM_IMAGE_URL_SUCCESS`, (itemId, data) => ({ itemId, data }));
export const updateItemImageUrlFailure = createAction(`${prefix}/UPDATE_ITEM_IMAGE_URL_FAILURE`);

export const cancelOrder = createAction(`${prefix}/CANCEL_ORDER`);
export const cancelOrderSuccess = createAction(`${prefix}/CANCEL_ORDER_SUCCESS`);
export const cancelOrderFailure = createAction(`${prefix}/CANCEL_ORDER_FAILURE`);

export const cancelOrderItems = createAction(`${prefix}/CANCEL_ORDER_ITEMS`);
export const cancelOrderItemsSuccess = createAction(`${prefix}/CANCEL_ORDER_ITEMS_SUCCESS`);
export const cancelOrderItemsFailure = createAction(`${prefix}/CANCEL_ORDER_ITEMS_FAILURE`);

export const orderItemSelection = createAction(`${prefix}/ORDER_ITEM_SELECTION`, (itemId, filter) => ({ itemId, filter }));

export const saveSelectedProducts = createAction(`${prefix}/SAVE_SELECTED_PRODUCTS`);

export const clearErrors = createAction(`${prefix}/CLEAR_ERRORS`);
export const reset = createAction(`${prefix}/RESET`);