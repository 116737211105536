import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAuditLogs } from 'app/store/actions/vendor';
import { auditLogsSelector, auditLogsLoadingSelector, auditLogsErrorsSelector } from 'app/store/selectors/vendor';
import { Table, TableHeader, Card, LoadingAnimation } from 'app/components';
import { capitalizeFirstLetter } from 'app/utils';
import { ExclamationCircle } from 'react-bootstrap-icons';
import './index.scss';

const HistoryView = (props) => {
  const { vendorId } = props;
  const dispatch = useDispatch();

  const auditData = useSelector(auditLogsSelector);
  const auditDataLoading = useSelector(auditLogsLoadingSelector);
  const auditDataError = useSelector(auditLogsErrorsSelector);

  useEffect(() => {
    dispatch(getAuditLogs({vendorId}));
  }, [vendorId, dispatch]);

  return (
    <Card className="vendor-history-view">
      <Card.Header>
        Vendor History
      </Card.Header>
      {auditDataLoading && <LoadingAnimation />}
      {(auditData || auditDataError) && (
        <Card.Body>
          <Table size="medium" verticalAlign="top">
            <TableHeader
              options={[
                { id: 'requestId', label: 'Request ID' },
                { id: 'clientId', label: 'Client ID' },
                { id: 'userId', label: 'User ID' },
                { id: 'action', label: 'Action' },
              ]}
            />
            <tbody className="table-body">
              {auditData?.events.map((event) => (
                <tr className="history-row" key={event.requestId}>
                  <td>{event.requestId}</td>
                  <td>{event.clientId}</td>
                  <td>{event.userId}</td>
                  <td>{capitalizeFirstLetter(event.action)}</td>
                </tr>
              ))}
              {auditData?.events.length === 0 && !auditDataError && !auditDataLoading && (
                <tr>
                  <td colSpan="4" className="history-data-empty">
                    No vendor history data found.
                  </td>
                </tr>
              )}
              {auditDataError && !auditDataLoading && (
                <tr>
                  <td colSpan="6" className="error-message"><ExclamationCircle />Error fetching history data</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Card.Body>
      )}
    </Card>
  );
};

export default HistoryView;
