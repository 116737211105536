import { createAction } from 'redux-actions';

const prefix = "MERCHANT";

export const getMerchants = createAction(`${prefix}/GET_MERCHANTS`);
export const getMerchantsSuccess = createAction(`${prefix}/GET_MERCHANTS_SUCCESS`);
export const getMerchantsFailure = createAction(`${prefix}/GET_MERCHANTS_FAILURE`);

export const getMerchantDetails = createAction(`${prefix}/GET_MERCHANT_DETAILS`);
export const getMerchantDetailsSuccess = createAction(`${prefix}/GET_MERCHANT_DETAILS_SUCCESS`);
export const getMerchantDetailsFailure = createAction(`${prefix}/GET_MERCHANT_DETAILS_FAILURE`);

export const createMerchant = createAction(`${prefix}/CREATE_MERCHANT`);
export const createMerchantSuccess = createAction(`${prefix}/CREATE_MERCHANT_SUCCESS`);
export const createMerchantFailure = createAction(`${prefix}/CREATE_MERCHANT_FAILURE`);

export const updateMerchant = createAction(`${prefix}/UPDATE_MERCHANT`);
export const updateMerchantSuccess = createAction(`${prefix}/UPDATE_MERCHANT_SUCCESS`);
export const updateMerchantFailure = createAction(`${prefix}/UPDATE_MERCHANT_FAILURE`);

export const updateMerchantsParent = createAction(`${prefix}/UPDATE_MERCHANTS_PARENT`);
export const updateMerchantsParentSuccess = createAction(`${prefix}/UPDATE_MERCHANTS_PARENT_SUCCESS`);
export const updateMerchantsParentFailure = createAction(`${prefix}/UPDATE_MERCHANTS_PARENT_FAILURE`);

export const updateMerchantsChildren = createAction(`${prefix}/UPDATE_MERCHANTS_CHILDREN`);
export const updateMerchantsChildrenSuccess = createAction(`${prefix}/UPDATE_MERCHANTS_CHILDREN_SUCCESS`);
export const updateMerchantsChildrenFailure = createAction(`${prefix}/UPDATE_MERCHANTS_CHILDREN_FAILURE`);

export const removeAllAssociations = createAction(`${prefix}/REMOVE_ALL_ASSOCIATIONS`);
export const removeAllAssociationsSuccess = createAction(`${prefix}/REMOVE_ALL_ASSOCIATIONS_SUCCESS`);
export const removeAllAssociationsFailure = createAction(`${prefix}/REMOVE_ALL_ASSOCIATIONS_FAILURE`);

export const clearErrors = createAction(`${prefix}/CLEAR_ERRORS`);
export const reset = createAction(`${prefix}/RESET`);