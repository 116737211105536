import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import { object, string } from 'yup';
import { PencilSquare, PlusLg, Trash3 } from 'react-bootstrap-icons';
import { Card, Button, ButtonIcon, Table, TableHeader, Dropdown } from 'app/components'
import { updateMerchantsParent, updateMerchantsChildren, removeAllAssociations, reset } from 'app/store/actions/merchant';
import DataPoint from 'app/components/DataPoint';
import './index.scss';

const AssociationsCard = props => {
  const { merchantDetails, allMerchants, getMerchantList } = props;
  const [editMode, setEditMode] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!merchantDetails) {
      setEditMode(true);
    }
  }, []);

  const onMerchantUpdated = () => {
    setEditMode(false);
  }

  const getMerchantTypes = () => {
    return [
      { value: 'Child Merchant', label: 'Child Merchant'},
      { value: 'Parent Merchant', label: 'Parent Merchant'},
      { value: 'No Associations', label: 'No Associations'},
    ];
  }

  const getMerchantType = () => {
    if (merchantDetails.isParent) {
      return 'Parent Merchant';
    } else if (merchantDetails.parent) {
      return 'Child Merchant';
    } else {
      return 'No Associations';
    }
  }

  const childMerchantsOptions = (values) => {
    if (!allMerchants) {
      return [{ value: '', label: '-'}];
    }

    const merchants = allMerchants.merchants.filter(
        merchant => merchant.id !== merchantDetails.id && 
        !merchant.parentId &&
        !merchant.isParent &&
        !values.merchantDetails.children.find(child => child.id.toString() === merchant.id.toString())
      ).map(merchant => (
        { value: merchant.id, label: merchant.name }
      )
    );
    
    merchants.unshift({ value: '', label: '-'});
    return merchants;
  }

  const parentMerchantOptions = () => {
    if (!allMerchants) {
      return [{value: '', label: '-'}];
    }
    const merchants = allMerchants.merchants.filter(merchant => merchant.isParent && !merchant.parentId && merchant.id !== merchantDetails.id).map(merchant => (
      { value: merchant.id, label: merchant.name }
    ));
    merchants.unshift({value: '', label: '-'});
    return merchants;
  }

  return (
    <Card className="associations-card">
      <Card.Header>
        Associations
        {!editMode && (
          <ButtonIcon
            icon={<PencilSquare />}
            onClick={() => {
              getMerchantList();
              setEditMode(true);
            }}
          />
        )}
      </Card.Header>
      <Card.Body>
        {!editMode && merchantDetails && (
          <>
            <div className="association-details">
              <div className="association-top">
                <DataPoint title="Merchant Type" data={getMerchantType()} />
                {merchantDetails.parent && (
                  <DataPoint title="Parent Merchant" data={merchantDetails.parent.name} />
                )}
              </div>
              {merchantDetails.isParent && (
                <div className="association-bottom">
                  <div className="merchant-children-header-container">
                    <div className="children-header">
                      Child Merchants
                    </div>
                  </div>
                  <div className="child-merchants">
                    <Table size="small">
                      <TableHeader
                        options={[
                          { id: 'shortId', label: 'Merchant ID' },
                          { id: 'name', label: 'Merchant Name', orderable: true },
                        ]}
                      />
                      <tbody className="table-body">
                        {merchantDetails.children.map(childMerchant => (
                          <tr className="merchant-row" key={childMerchant.id} onClick={() => {
                            dispatch(reset());
                            navigate(`/admin/merchants/${childMerchant.id}`)
                          }}>
                            <td>{childMerchant.shortId}</td>
                            <td>{childMerchant.name}</td>
                          </tr>
                        ))}
                        {merchantDetails.children.length === 0 && (
                          <tr className="merchant-row">
                            <td>No Child Merchants Added</td>
                            <td>&nbsp;</td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
        {editMode && (
          <Formik
            enableReinitialize
            initialValues={{
              merchantType: getMerchantType(),
              merchantDetails: merchantDetails,
            }}
            validationSchema={() =>
              object().shape({
                merchantType: string().required('Select a Merchant Type'),
              })
            }
            onSubmit={async (values, { setFieldError, setSubmitting }) => {
              if (values.merchantType === 'Child Merchant' && !values.merchantDetails.parent?.id) {
                setFieldError('parentId', 'Parent Merchant is required');
                return;
              }

              // remove any child merchants that have a temp id (starts with 'new-')
              values.merchantDetails.children = values.merchantDetails.children.filter(child => !child.id.toString().startsWith('new-'));

              setSubmitting(true);
              if (values.merchantType === 'No Associations') {
                dispatch(removeAllAssociations({merchantDetails, cb: onMerchantUpdated}));
              } else if (values.merchantType === 'Child Merchant') {
                dispatch(updateMerchantsParent({merchantDetails: values.merchantDetails, cb: onMerchantUpdated}));
              } else if (values.merchantType === 'Parent Merchant') {
                const childrenToUpdate = [];

                // loop over every child merchant within the merchantDetails object and see if it exists in the values object
                // if it doesn't, return it and stgore it in the childrenToRemove array
                const details = JSON.parse(JSON.stringify(merchantDetails));
                details.children.forEach(child => {
                  const found = values.merchantDetails.children.find(valueChild => valueChild.id.toString() === child.id.toString());
                  if (!found) {
                    // this child was removed.  Set their parentId to null and add it to the childrenToUpdate array
                    child.parentId = null;
                    childrenToUpdate.push(child);
                  }
                });
                // now do the same to see if any new merchants were added to the values object.  If so, store them in the childrenToAdd array
                // ignore any merchants that have a temp id (starts with 'new-')
                values.merchantDetails.children.forEach(child => {
                  if (child.id.toString().startsWith('new-')) {
                    return;
                  } else {
                    const found = details.children.find(valueChild => valueChild.id.toString() === child.id.toString());
                    if (!found) {
                      // this child was added.  Set their parentId to the parentId of the parent merchant and add it to the childrenToUpdate array
                      child.parentId = merchantDetails.id;
                      childrenToUpdate.push(child);
                    }
                  }
                });
                
                // make an api call to update all merchants with their new parent id (or remove it if the child was removed)
                dispatch(updateMerchantsChildren({merchantDetails: details, updatedMerchantDetails: values.merchantDetails, childrenToUpdate, cb: onMerchantUpdated}));
              }
              setSubmitting(false);
            }}
          >
            {({
              values,
              errors,
              handleChange,
              handleSubmit,
              isSubmitting,
              submitCount,
              setFieldValue,
              resetForm,
              dirty,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className={`association-details ${editMode ? 'edit-mode' : ''}`}>
                  <div className="association-top">
                    <div className="merchant-selector">
                      <Dropdown
                        label="Merchant Type"
                        name="merchantType"
                        value={values.merchantType}
                        onChange={handleChange}
                        options={getMerchantTypes()}
                        errorMessage={submitCount > 0 && errors.merchantType}
                      />
                      {values.merchantType === 'Child Merchant' ? (
                        <Dropdown
                          label="Parent Merchant"
                          name="parentId"
                          placeholder="Select a Parent Merchant"
                          value={values.merchantDetails.parent?.id || ''}
                          onChange={(e) => {
                            // they selected a parent by id, lookup the parent object in the merchants array
                            const merchant = allMerchants.merchants.find(merchant => merchant.id.toString() === e.target.value);

                            const details = JSON.parse(JSON.stringify(values.merchantDetails));
                            details.parent = merchant;
                            setFieldValue('merchantDetails', details);
                          }}
                          options={parentMerchantOptions()}
                          errorMessage={submitCount > 0 && errors.parentId}
                        />
                      ) : <div></div>}
                      <div className="action-buttons">
                        <Button
                          variant="secondary"
                          size="small"
                          label="Cancel"
                          onClick={() => {
                            if (!merchantDetails) {
                              navigate('/admin/merchants/');
                            } else {
                              resetForm();
                              setEditMode(false);
                            }
                          }}
                        />
                        <Button
                          variant="primary"
                          size="small"
                          label="Update"
                          onClick={() => (isSubmitting ? null : handleSubmit())}
                          disabled={!dirty || isSubmitting || values.parentMerchant === ''}
                        />
                      </div>
                    </div>
                  </div>
                  {values.merchantType === 'Parent Merchant' && (
                    <div className="association-bottom">
                      <div className="children-header">
                        Child Merchants
                        <ButtonIcon
                          icon={<PlusLg />}
                          onClick={() => {
                            const merchantDetails = JSON.parse(JSON.stringify(values.merchantDetails));
                            // make a tempId value with a random number so we can identify it later
                            const newChildMerchant = { id: `new-${Math.floor(Math.random() * 1000000000).toString()}`, name: '' };
                            merchantDetails.children.push(newChildMerchant);
                            setFieldValue('merchantDetails', merchantDetails);
                          }}
                        />
                      </div>
                      <div className="children-merchants">
                        <Table size="small">
                          <TableHeader
                            options={[
                              { id: 'shortId', label: 'Merchant ID' },
                              { id: 'name', label: 'Merchant Name', orderable: true },
                              { id: '', label: '', align: 'center' },
                            ]}
                          />
                          <tbody className="table-body">
                            {values.merchantDetails.children.map(childMerchant => {
                              if (childMerchant.id.toString().startsWith('new-')) {
                                return (
                                  <tr className="merchant-row new-merchant" key={childMerchant.id}>
                                    <td>-</td>
                                    <td>
                                      <Dropdown
                                        label="Child Merchant"
                                        name="childMerchant"
                                        placeholder="Select a Child Merchant"
                                        value={childMerchant.id}
                                        onChange={(e) => {
                                          // get a deep copy of the merchantDetails object
                                          const merchantDetails = JSON.parse(JSON.stringify(values.merchantDetails));
                                          // based on the merchant id, find the child merchant they selected
                                          const merchant = allMerchants.merchants.find(merchant => merchant.id.toString() === e.target.value);
                                          // locate the index of the temp merchant
                                          const tempMerchantIndex = merchantDetails.children.findIndex(child => child.id.toString() === childMerchant.id.toString());
                                          // replace the temp merchant with the real merchant
                                          merchantDetails.children[tempMerchantIndex] = merchant;
                                          setFieldValue('merchantDetails', merchantDetails);
                                        }}
                                        options={childMerchantsOptions(values)}
                                        errorMessage={submitCount > 0 && errors.childMerchant}
                                      />
                                    </td>
                                    <td>
                                      <ButtonIcon
                                        icon={<Trash3 />}
                                        destructive={true}
                                        onClick={() => {
                                          const merchantDetails = JSON.parse(JSON.stringify(values.merchantDetails));
                                          merchantDetails.children = merchantDetails.children.filter(child => child.id.toString() !== childMerchant.id.toString());
                                          setFieldValue('merchantDetails', merchantDetails);
                                        }}
                                      />
                                    </td>
                                  </tr>
                                )
                              } else {
                                return (
                                  <tr className="merchant-row" key={childMerchant.id}>
                                    <td>{childMerchant.shortId}</td>
                                    <td>{childMerchant.name}</td>
                                    <td>
                                      <ButtonIcon
                                        icon={<Trash3 />}
                                        destructive={true}
                                        onClick={() => {
                                          const merchantDetails = JSON.parse(JSON.stringify(values.merchantDetails));
                                          merchantDetails.children = merchantDetails.children.filter(child => child.id.toString() !== childMerchant.id.toString());
                                          setFieldValue('merchantDetails', merchantDetails);
                                        }}
                                      />
                                    </td>
                                  </tr>
                                )}
                              })
                            }
                            {values.merchantDetails.children.length === 0 && (
                              <tr className="merchant-row">
                                <td>No Child Merchants Added</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  )} 
                </div>
              </form>
            )}
          </Formik>
        )}
      </Card.Body>
    </Card>
  )
}

export default AssociationsCard;