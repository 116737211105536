import { put, takeEvery, call, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import {
  createOrder,
  createOrderSuccess,
  createOrderFailure,
  globalOrdersSearch,
  globalOrdersSearchSuccess,
  globalOrdersSearchFailure,
  getOrders,
  getOrdersSuccess,
  getOrdersFailure,
  getOrderDetails,
  getOrderDetailsSuccess,
  getOrderDetailsFailure,
  updateShippingAddress,
  updateShippingAddressSuccess,
  updateShippingAddressFailure,
  updateBillingAddress,
  updateBillingAddressSuccess,
  updateBillingAddressFailure,
  cancelOrder,
  cancelOrderSuccess,
  cancelOrderFailure,
  cancelOrderItems,
  cancelOrderItemsSuccess,
  cancelOrderItemsFailure,
  updateItemImageUrl,
  updateItemImageUrlSuccess,
  updateItemImageUrlFailure,
} from "app/store/actions/order"
import OrderServices from 'app/services/orderServices';

function* doCreateOrder(action) {
  const { data, cb } = action.payload;
  try {
    const resp = yield call([OrderServices, OrderServices.createOrder], data);
    
    if (resp.orderId) {
      yield put(createOrderSuccess(data, resp));
      toast.success("Order Successfully Created", {
        theme: 'colored',
      });
      if(cb) cb(resp.orderId);
    } else {
      throw (resp);
    }
  } catch (error) {
    console.error('error', error);
    yield put(createOrderFailure(error));
    toast.error("Order Creation Failed", {
      theme: 'colored',
    });
  }
}

function* doGlobalOrdersSearch(action) {
  const data = action.payload;
  
  try {
    const resp = yield call([OrderServices, OrderServices.getOrders], data);
    yield put(globalOrdersSearchSuccess(resp));
    if(data.cb) data.cb(resp);
  } catch (error) {
    console.error('error', error);
    yield put(globalOrdersSearchFailure());
    toast.error("Searching Orders Failed", {
      theme: 'colored',
    })
  }
}

function* fetchOrders(action) {
  const data = action.payload;
  
  try {
    const resp = yield call([OrderServices, OrderServices.getOrders], data);
    yield put(getOrdersSuccess(resp));
  } catch (error) {
    console.error('error', error);
    yield put(getOrdersFailure());
    toast.error("Loading Order Data Failed", {
      theme: 'colored',
    })
  }
}

function* fetchOrderDetails(action) {
  const orderId = action.payload;
  try {
    const resp = yield call([OrderServices, OrderServices.getOrderDetails], orderId);
    yield put(getOrderDetailsSuccess(resp));
  } catch (error) {
    console.error('error', error);
    yield put(getOrderDetailsFailure(error));
    toast.error("Loading Order Data Failed", {
      theme: 'colored',
    });
  }
}

function* doUpdateShippingAddress(action) {
  const { orderId, data } = action.payload;
  try {
    const resp = yield call([OrderServices, OrderServices.updateOrderDetails], orderId, data);
    if (resp.updated) {
      yield put(updateShippingAddressSuccess(data, resp));
      toast.success("Details Successfully Updated", {
        theme: 'colored',
      })
    } else {
      throw (resp);
    }
  } catch (error) {
    console.error('error', error);
    yield put(updateShippingAddressFailure(error));
    toast.error("Details Failed To Update", {
      theme: 'colored',
    })
  }
}

function* doUpdateBillingAddress(action) {
  const { orderId, data } = action.payload;
  try {
    const resp = yield call([OrderServices, OrderServices.updateOrderDetails], orderId, data);
    if (resp.updated) {
      yield put(updateBillingAddressSuccess(data, resp));
      toast.success("Details Successfully Updated", {
        theme: 'colored',
      });
    } else {
      throw (resp);
    }
  } catch (error) {
    console.error('error', error);
    yield put(updateBillingAddressFailure(error));
    toast.error("Details Failed To Update", {
      theme: 'colored',
    })
  }
}

function* doCancelOrder(action) {
  const { orderId } = action.payload;
  try {
    yield call([OrderServices, OrderServices.cancelOrder], orderId);
    yield put(cancelOrderSuccess());
    toast.success("Order cancellation successful", {
      theme: 'colored',
    });
  } catch (error) {
    console.error('error', error);
    yield put(cancelOrderFailure(error));
    toast.error("Order cancellation Failed", {
      theme: 'colored',
    })
  }
}

function* doCancelOrderItems(action) {
  const { orderId, data } = action.payload;
  try {
    yield call([OrderServices, OrderServices.cancelOrderItems], orderId, data);
    yield put(cancelOrderItemsSuccess(data));
    toast.success("Item cancellation successful", {
      theme: 'colored',
    });
  } catch (error) {
    console.error('error', error);
    yield put(cancelOrderItemsFailure(error));
    toast.error("Item cancellation Failed", {
      theme: 'colored',
    })
  }
}

function* doUpdateItemImageUrl(action) {
  const { orderId, itemId, data } = action.payload;
  try {
    const resp = yield call([OrderServices, OrderServices.updateItemImageUrl], orderId, itemId, data);
    if (resp.updated) {
      yield put(updateItemImageUrlSuccess(itemId, data));
      toast.success("Item image successfully updated", {
        theme: 'colored',
      });
    } else {
      throw (resp);
    }
  } catch (error) {
    console.error('error', error);
    yield put(updateItemImageUrlFailure(error));
    toast.error("Failed to update item image", {
      theme: 'colored',
    })
  }
}

function* watchData() {
  yield takeEvery(createOrder().type, doCreateOrder);
  yield takeEvery(globalOrdersSearch().type, doGlobalOrdersSearch);
  yield takeEvery(getOrders().type, fetchOrders);
  yield takeEvery(getOrderDetails.toString(), fetchOrderDetails);
  yield takeEvery(updateShippingAddress.toString(), doUpdateShippingAddress);
  yield takeEvery(updateBillingAddress.toString(), doUpdateBillingAddress);
  yield takeEvery(cancelOrder.toString(), doCancelOrder);
  yield takeEvery(cancelOrderItems.toString(), doCancelOrderItems);
  yield takeEvery(updateItemImageUrl.toString(), doUpdateItemImageUrl);
}

export default function* rootSaga() {
  yield all([
    watchData(),
  ]);
}