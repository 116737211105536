import { createSelector } from 'reselect';

const notificationSelector = state => state.notification;

export const webhooksDataSelector = createSelector([notificationSelector], notificationView => notificationView.webhooks.data);
export const webhooksLoadingSelector = createSelector([notificationSelector], notificationView => notificationView.webhooks.loading);
export const webhooksErrorsSelector = createSelector([notificationSelector], notificationView => notificationView.webhooks.error);

export const webhookDetailsSelector = createSelector([notificationSelector], notificationView => notificationView.webhook.data);
export const webhookDetailsLoadingSelector = createSelector([notificationSelector], notificationView => notificationView.webhook.loading);
export const webhookDetailsErrorsSelector = createSelector([notificationSelector], notificationView => notificationView.webhook.error);

export const webhookTestSelector = createSelector([notificationSelector], notificationView => notificationView.webhookTest.data);
export const webhookTestLoadingSelector = createSelector([notificationSelector], notificationView => notificationView.webhookTest.loading);
export const webhookTestErrorsSelector = createSelector([notificationSelector], notificationView => notificationView.webhookTest.error);

export const integrationsDataSelector = createSelector([notificationSelector], notificationView => notificationView.integrations.data);
export const integrationsLoadingSelector = createSelector([notificationSelector], notificationView => notificationView.integrations.loading);
export const integrationsErrorsSelector = createSelector([notificationSelector], notificationView => notificationView.integrations.error);

export const integrationDetailsSelector = createSelector([notificationSelector], notificationView => notificationView.integration.data);
export const integrationDetailsLoadingSelector = createSelector([notificationSelector], notificationView => notificationView.integration.loading);
export const integrationDetailsErrorsSelector = createSelector([notificationSelector], notificationView => notificationView.integration.error);

export const eventTypesSelector = createSelector([notificationSelector], notificationView => notificationView.eventTypes.data);
export const eventTypesLoadingSelector = createSelector([notificationSelector], notificationView => notificationView.eventTypes.loading);
export const eventTypesErrorsSelector = createSelector([notificationSelector], notificationView => notificationView.eventTypes.error);