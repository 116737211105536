import React, { useState } from 'react';
import { Upc, ThreeDotsVertical } from 'react-bootstrap-icons';
import { IconData, StatusChip, ButtonIcon } from 'app/components';
import AdditionalItemDetails from './AdditionalItemDetails';
import { formatCurrency } from 'app/utils';
import './index.scss';

const OrderItem = (props) => {
  const { itemInfo, shipmentId, showAll, showChangeVendorModal } = props;
  const [additionalItemDetails, showAdditionalItemDetails] = useState(showAll || false);

  const orderItemOptions = (shipmentId, itemInfo) => [
    // { value: 'Edit Item', label: 'Edit Item', onClick: () => { console.log("Not implemented yet") }},
    ...(shipmentId ? [{value: 'Reroute', label: 'Reroute', onClick: () => { showChangeVendorModal({shipmentId, itemInfo}); }}] : []),
    { value: 'Download Image', label: 'Download Image', onClick: () => { handleDownload(itemInfo) } },
    { value: `${additionalItemDetails ? 'Hide' : 'Show'} Additional Item Details`, label: `${additionalItemDetails ? 'Hide' : 'Show'} Additional Item Details`, onClick: () => showAdditionalItemDetails(!additionalItemDetails) }
    // { value: 'Cancel Item', label: 'Cancel Item', destructive: true, onClick: () => showCancelOrderItemModal({ orderId, itemId: item.id }) },
  ];

  const handleDownload = (item) => {
    const imageSrc = item?.images?.[0]?.url;
    let filename = imageSrc.substring(imageSrc.lastIndexOf('/') + 1);

    // Check if the filename ends with an image extension. If not, add '.jpg'
    if (!filename.match(/\.(jpg|jpeg|png|gif)$/i)) {
      filename += '.jpg';
    }

    fetch(imageSrc)
      .then(response => response.blob())
      .then(blob => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = filename;
        link.click();
        URL.revokeObjectURL(url);
      })
      .catch(error => {
        console.error('Error downloading image:', error);
      });
  };

  return (
    <div className="order-item">
      <div className="item-header">
        <div className="item-details">
          <div className="details-container">
            <div className="id-status">
              <div className="item-name">{`Item ${itemInfo.shortId}`}</div>
              <StatusChip type="shipment" size="slim" status={itemInfo.status} />
            </div>
            <div className="sku-info">
              {itemInfo.externalId && (
                <IconData
                  className="external-id"
                  label={itemInfo.externalId}
                  icon={<Upc />}
                />
              )}
              {itemInfo.sku && (
                <IconData
                  className="sku"
                  label={itemInfo.sku}
                  icon={<Upc />}
                  allowOverflow
                />
              )}
            </div>
          </div>
          <div className="pricing-info">
            <div className="pricing-item">
              <div className="pricing-title">Qty</div>
              <div className="pricing-value">{itemInfo.quantity || '-'}</div>
            </div>
            <div className="pricing-item">
              <div className="pricing-title">Unit Price</div>
              <div className="pricing-value">
                {itemInfo.customerPrice?.amount && itemInfo.customerPrice?.currency
                  ? formatCurrency(itemInfo.customerPrice.currency, itemInfo.customerPrice.amount)
                  : '-'}
              </div>
            </div>
            <div className="pricing-item">
              <div className="pricing-title">Total Item Price</div>
              <div className="pricing-value">
                {itemInfo.customerPrice?.amount && itemInfo.customerPrice?.currency
                  ? formatCurrency(itemInfo.customerPrice.currency, itemInfo.customerPrice?.amount * itemInfo.quantity)
                  : '-'}
              </div>
            </div>
          </div>
        </div>
        <div className="shipment-options">
          <ButtonIcon
            icon={<ThreeDotsVertical />}
            options={orderItemOptions(shipmentId, itemInfo)}
          />
        </div>
      </div>
      {additionalItemDetails && (
        <AdditionalItemDetails itemInfo={itemInfo} />
      )}
    </div>
  )
}

export default OrderItem;