import { createSelector } from 'reselect';

const userSelector = state => state.user;

export const userAuthSelector = createSelector([userSelector], userView => userView.auth);

export const currentUserSelector = createSelector([userSelector], userView => userView.currentUser?.data);

export const usersDataSelector = createSelector([userSelector], userView => userView.users.data);
export const usersLoadingSelector = createSelector([userSelector], userView => userView.users.loading);
export const usersErrorsSelector = createSelector([userSelector], userView => userView.users.error);

export const userDetailsSelector = createSelector([userSelector], userView => userView.user.data);
export const userDetailsLoadingSelector = createSelector([userSelector], userView => userView.user.loading);
export const userDetailsErrorsSelector = createSelector([userSelector], userView => userView.user.errors);

// those are all the roles that system has...
export const rolesDataSelector = createSelector([userSelector], userView => userView.roles?.data);
export const rolesLoadingSelector = createSelector([userSelector], userView => userView.roles?.loading);
export const rolesErrorsSelector = createSelector([userSelector], userView => userView.roles?.errors);

export const rolesWithPermissionsDataSelector = createSelector([userSelector], userView => userView.roles?.data);
export const rolesWithPermissionsLoadingSelector = createSelector([userSelector], userView => userView.roles?.loading);
export const rolesWithPermissionsErrorsSelector = createSelector([userSelector], userView => userView.roles?.errors);

export const roleDetailsSelector = createSelector([userSelector], userView => userView.role?.data);
export const roleDetailsLoadingSelector = createSelector([userSelector], userView => userView.role?.loading);
export const roleDetailsErrorsSelector = createSelector([userSelector], userView => userView.role?.errors);

export const resourcesDataSelector = createSelector([userSelector], userView => userView.resources?.data);
export const resourcesLoadingSelector = createSelector([userSelector], userView => userView.resources?.loading);
export const resourcesErrorsSelector = createSelector([userSelector], userView => userView.resources?.errors);

export const searchRolesDataSelector = createSelector([userSelector], userView => userView.roles?.data);
export const searchRolesLoadingSelector = createSelector([userSelector], userView => userView.roles?.loading);
export const searchRolesErrorsSelector = createSelector([userSelector], userView => userView.roles?.errors);

export const groupsSelector = createSelector([userSelector], userView => userView.groups?.items);
export const selectedGroupSelector = createSelector([userSelector], userView => userView.groups?.selected);