import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveSelectedProducts } from 'app/store/actions/order';
import { getCategories, getProducts } from 'app/store/actions/catalog';
import {
  categoriesDataSelector,
  categoriesLoadingSelector,
  categoriesErrorsSelector,
  productsDataSelector,
  productsLoadingSelector,
  productsErrorsSelector,
} from 'app/store/selectors/catalog';
import { placeAnOrderProductsSelector } from 'app/store/selectors/order';
import { Formik } from 'formik';
import { object, string, array } from 'yup';
import { Container, Col, Row, Button } from 'react-bootstrap';
import { LoadingAnimation } from 'app/components';
import { ExclamationCircle } from 'react-bootstrap-icons';
import ProductSelector from './ProductSelector';
import SelectedProducts from './SelectedProducts';
import SearchBar from 'app/components/SearchBar';

import { useNavigate } from 'react-router-dom';
import './index.scss';

const ProductSelection = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchString, setSearchString] = useState('');

  const categories = useSelector(categoriesDataSelector);
  const categoriesLoading = useSelector(categoriesLoadingSelector);
  const categoriesErrors = useSelector(categoriesErrorsSelector);
  const products = useSelector(productsDataSelector);
  const productsLoading = useSelector(productsLoadingSelector);
  const productsErrors = useSelector(productsErrorsSelector);
  const selectedProducts = useSelector(placeAnOrderProductsSelector);

  useEffect(() => {
    if (!categories) {
      dispatch(getCategories());
    }
    if (!products) {
      dispatch(getProducts(1, 250));
    }
  }, [categories, products, dispatch]);

  const addProduct = (setFieldValue, values, id) => {
    const newProduct = {
      id,
      sku: '',
      key: Math.random(),
      variant: '',
      printLocation: ''
    };

    newProduct.data = products.find(product => product.id === id);

    const updatedProducts = [...values.selectedProducts, newProduct];
    setFieldValue('selectedProducts', updatedProducts);
  }

  const removeProduct = (setFieldValue, values, productIndex) => {
    let updatedProducts = [...values.selectedProducts];
    updatedProducts.splice(productIndex, 1);
    setFieldValue('selectedProducts', updatedProducts);
  };

  const onSelectedProductsValidation = (selectedProducts) => {
    if (!selectedProducts || selectedProducts.length === 0 || selectedProducts.some((product) => !product)) {
      return false;
    }
    return true;
  };

  return (
    <div className="place-an-order">
      {(categoriesLoading || productsLoading) && <LoadingAnimation />}
      {(categoriesErrors || productsErrors) && (
        <div className="vendor-load-failed"><ExclamationCircle />Data failed to load.  Refresh the page to try again.</div>
      )}
      {products && categories && (
        <Formik
          enableReinitialize
          initialValues={{
            selectedProducts: selectedProducts || []
          }}
          validationSchema={() =>
            object().shape({
              selectedProducts: array()
              .test('selectedProducts', 'At least one product must be selected', onSelectedProductsValidation)
              .of(
                object().shape({
                  id: string().required('Id is required'),
                  sku: string(),
                  variant: string().required('Variant is required'),
                  printLocation: string().required('Print Location is required'),
                })
              )
            })
          }
          onSubmit={async (values) => {
            dispatch(saveSelectedProducts(values));
            navigate('/place-an-order/details');
          }}
        >
          {({
            values,
            errors,
            handleChange,
            handleSubmit,
            isSubmitting,
            submitCount,
            setFieldValue,
            validateForm,
          }) => (
            <form onSubmit={handleSubmit}>
              <Container fluid className="g-0">
                <Row className="pb-4">
                  <Col lg={8}>
                    <div className="pb-3">
                      To place an order, select one or more product types in the Product Selector below and then configure your selection on the right. After you have completed your selections, click the &apos;Continue&apos; button.
                    </div>
                    <SearchBar
                      searchPlaceholderText="Search Products"
                      onSearchStringUpdated={setSearchString}
                    />
                  </Col>
                  <Col lg={4} className="action-buttons">
                    <Button variant="primary" onClick={() => (isSubmitting ? null : handleSubmit())}>Continue</Button>
                  </Col>
                </Row>
                <Row>
                  <Col lg={8}>
                    <ProductSelector
                      products={products}
                      categories={categories}
                      searchString={searchString}
                      onProductSelected={(id) => addProduct(setFieldValue, values, id)}
                      currentlySelectedProducts={values.selectedProducts}
                    />
                  </Col>
                  <Col lg={4}>
                    <SelectedProducts
                      values={values}
                      errors={errors}
                      categories={categories}
                      currentlySelectedProducts={values.selectedProducts}
                      onRemoveSelectedProduct={(index) => removeProduct(setFieldValue, values, index)}
                      submitCount={submitCount}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                      validateForm={validateForm}
                    />
                  </Col>
                </Row>
              </Container>
            </form>
          )}
        </Formik>
      )}
    </div>
  )
}

export default ProductSelection;