import React from 'react';
import { ExclamationTriangle } from 'react-bootstrap-icons';
import './index.scss';

const OrderMessageCard = ({ orderDetails }) => {
  if (!orderDetails || !orderDetails?.status) {
    return null;
  }

  const getMessage = (status) => {
    switch (status) {
      case 'imageerror': return "There is a problem with one or more images in the order.";
      case 'addresserror': return "There is a problem with shipping address in the order.";
      default: return null;
    }
  }

  const message = getMessage(orderDetails.status);
  if (!message) return null;

  return (
    <div className="order-message">
      <ExclamationTriangle/>
      {message}
    </div>
  )
}

export default OrderMessageCard;