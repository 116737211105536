import { createAction } from 'redux-actions';

const prefix = "CATALOG";

export const getCategories = createAction(`${prefix}/GET_CATEGORIES`);
export const getCategoriesSuccess = createAction(`${prefix}/GET_CATEGORIES_SUCCESS`);
export const getCategoriesFailure = createAction(`${prefix}/GET_CATEGORIES_FAILURE`);

export const getProducts = createAction(`${prefix}/GET_PRODUCTS`, (currentPage, pageSize) => ({ currentPage, pageSize }));
export const getProductsSuccess = createAction(`${prefix}/GET_PRODUCTS_SUCCESS`);
export const getProductsFailure = createAction(`${prefix}/GET_PRODUCTS_FAILURE`);

export const getProductVariants = createAction(`${prefix}/GET_PRODUCT_VARIANTS`);
export const getProductVariantsSuccess = createAction(`${prefix}/GET_PRODUCT_VARIANTS_SUCCESS`);
export const getProductVariantsFailure = createAction(`${prefix}/GET_PRODUCT_VARIANTS_FAILURE`);
