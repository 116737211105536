import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { merchantsSelector, merchantsLoadingSelector, merchantsErrorsSelector } from 'app/store/selectors/merchant';
import { getMerchants, reset as merchantReset } from 'app/store/actions/merchant';
import { reset as routingReset } from 'app/store/actions/routing';
import { Link, Card, Table, TableHeader, Pagination, LoadingAnimation, SearchBar } from 'app/components';
import { ExclamationCircle } from 'react-bootstrap-icons';
import './index.scss';

const RoutingView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const [searchString, setSearchString] = useState(null);
  // const [isFilterMenuOpen, showHideFilterMenu] = useState(false);
  const merchantsData = useSelector(merchantsSelector);
  const merchantsLoading = useSelector(merchantsLoadingSelector);
  const merchantsErrorsFound = useSelector(merchantsErrorsSelector);

  const [searchParams] = useSearchParams();
  const currentPage = parseInt(searchParams.get('page') || '1', 10);
  const pageSize = parseInt(searchParams.get('pageSize') || '50', 10);

  useEffect(() => {
    dispatch(getMerchants({currentPage, pageSize}));
  }, [currentPage, pageSize, dispatch]);

  return (
    <div className="routing-view">
      {merchantsLoading && <LoadingAnimation />}
      {(merchantsData || merchantsErrorsFound) && (
        <Card>
          <div className="routing-header">Routing</div>
          <SearchBar
            searchPlaceholderText="Search Merchants"
            onSearchStringUpdated={() => { console.log('search') }}
            disabled={!!merchantsErrorsFound}
          />
          <Table className="merchant-table mb-3" size="medium">
            <TableHeader
              options={[
                { id: 'id', label: 'ID'},
                { id: 'merchantName', label: 'Merchant Name'},
                { id: 'activeStrategy', label: 'Active Strategy'},
              ]}
            />
            <tbody className="table-body">
              {merchantsData && merchantsData.merchants.map((merchant) => (
                  <tr className="merchant-row" key={merchant.id}>
                    <td>
                      <Link
                        label={merchant.shortId || merchant.id}
                        onClick={() => {
                          dispatch(merchantReset());
                          dispatch(routingReset());
                          navigate(`/admin/routing/${merchant.id}`);
                        }}
                      />
                    </td>
                    <td>{merchant.name}</td>
                    <td>{merchant.activeStrategy}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
          {merchantsData?.length === 0 && (
            <div className="no-matching-results">No Matching Results Found</div>
          )}
          {merchantsData?.length > 0 && (
            <Pagination totalItems={merchantsData?.total || 0} />
          )}
          {merchantsErrorsFound && (
            <div className="routing-load-failed"><ExclamationCircle />Merchant Data failed to load.  Refresh the page to try again.</div>
          )}
        </Card>
      )}
    </div>
  )
}

export default RoutingView;