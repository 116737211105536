import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getCategories, getProducts, getProductVariants } from 'app/store/actions/catalog';
import { vendorDetailsSelector, vendorDetailsLoadingSelector, addUpdateProductsLoadingSelector, addUpdateProductsErrorsSelector } from 'app/store/selectors/vendor';
import { addProductsToVendor, clearErrors } from 'app/store/actions/vendor';
import {
  categoriesDataSelector,
  categoriesLoadingSelector,
  categoriesErrorsSelector,
  productsDataSelector,
  productsLoadingSelector,
  productsErrorsSelector,
  productVariantsSelector,
} from 'app/store/selectors/catalog';
import { Modal, ProgressBar } from 'react-bootstrap';
import { Card, LoadingAnimation, Button, MessageBar } from 'app/components';
import ProductSelector from './ProductSelector';
import SelectedProducts from './SelectedProducts';
import SearchBar from 'app/components/SearchBar';
import * as XLSX from 'xlsx';
import './index.scss';

const AddProducts = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { vendorId } = useParams();

  const [searchString, setSearchString] = useState('');
  const [numVarientsDownloaded, setNumVariantsDownloaded] = useState('');
  const [currentlySelectedProducts, setCurrentlySelectedProducts] = useState([]);
  const [numVarientsDownloading, setNumVariantsDownloading] = useState(0);

  const vendorDetails = useSelector(vendorDetailsSelector);
  const vendorDetailsLoading = useSelector(vendorDetailsLoadingSelector);
  const categories = useSelector(categoriesDataSelector);
  const categoriesLoading = useSelector(categoriesLoadingSelector);
  const categoriesErrors = useSelector(categoriesErrorsSelector);
  const products = useSelector(productsDataSelector);
  const productsLoading = useSelector(productsLoadingSelector);
  const productsErrors = useSelector(productsErrorsSelector);
  const productVariants = useSelector(productVariantsSelector);
  const addUpdateProductsLoading = useSelector(addUpdateProductsLoadingSelector);
  const addUpdateProductsErrors = useSelector(addUpdateProductsErrorsSelector);

  useEffect(() => {
    dispatch(clearErrors());

    // if they have not passed in all the correct info, just forward them back to the vendors page
    if (!vendorDetails || vendorDetails.vendorId !== vendorId) {
      navigate('/admin/vendors');
    }
  }, []);

  useEffect(() => {
    dispatch(getCategories());
    dispatch(getProducts(1, 250));
  }, [dispatch]);

  function getProductTitleById(id) {
    // lookup the product name by product id
    const foundObject = products.find(obj => obj.id === id);
    return foundObject ? foundObject.title.slice(0, 30) : null;
  }

  useEffect(() => {
    // have they completed downloading all the product variants?
    if (numVarientsDownloaded === numVarientsDownloading) {
      // create a workbook to store all the data
      const workbook = XLSX.utils.book_new();

      // the list of product variants in redux contains all the product varients we have previous retrieved data for.  This list may 
      // include product variants they didn't select for this csv download.  Process only the product variants they have currently selected.
      for (const id of currentlySelectedProducts) {
        // is this item one of the products they selected?
        if (Object.prototype.hasOwnProperty.call(productVariants, id)) {
          // every product type will be stored on a different sheet/tab in the workbook
          const sheet = [];
          productVariants[id].forEach(function (item) {
            const obj = {
              "Variant Id": item.id,
              "SKU": item.sku,
              "Vendor SKU": "",
              "Facility IDs": "All",
              "Description": item.description,
              "Production Time (in days)": item.productionTime,
              "Product Cost": "",
              "Currency": item.price.currency
            }

            for (let i = 0; i < item.decorations.length; i++) {
              obj["DecorationCost_" + (i + 1)] = "";
              obj["Area_" + (i + 1)] = item.decorations[i].area;
              obj["VendorArea_" + (i + 1)] = "";
              obj["DPI_" + (i + 1)] = item.decorations[i].dpi;
              obj["Height_" + (i + 1)] = item.decorations[i].height;
              obj["Width_" + (i + 1)] = item.decorations[i].width;
              obj["ImageFormat_" + (i + 1)] = item.decorations[i].format;
              obj["PrintMethod_" + (i + 1)] = item.decorations[i].printMethod;
            }

            sheet.push(obj);
          });
          // convert the json to a worksheet
          const worksheet = XLSX.utils.json_to_sheet(sheet);

          // and add the worksheet to the workbook
          XLSX.utils.book_append_sheet(workbook, worksheet, getProductTitleById(id));
        }
      }

      // the workbook has been generated.  Prepare it for download by converting it to a blob
      const workbookBlob = new Blob([XLSX.write(workbook, { type: 'buffer' })], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      // Create a download link
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(workbookBlob);
      downloadLink.download = 'ProductVariants.xlsx';

      // Simulate a click on the download link
      downloadLink.click();

      // setting the number of variants currently downloading to zero will close the download modal
      setNumVariantsDownloading(0);
    }
  }, [numVarientsDownloaded, numVarientsDownloading]);

  const downloadProductCSV = () => {
    //  reset the number of variants we have downloaded to zero
    setNumVariantsDownloaded(0);
    // and store how many we are about to download
    setNumVariantsDownloading(currentlySelectedProducts.length)
    // loop through all the items and download them asyncronously
    currentlySelectedProducts.forEach((product) => {
      dispatch(getProductVariants({ productId: product, currentPage: 1, pageSize: 250, cb: onProductVariantDataLoaded }));
    });
  }

  const onProductVariantDataLoaded = () => {
    // one of the variants have finished downloading
    setNumVariantsDownloaded(prevNumVariantsDownloaded => prevNumVariantsDownloaded + 1);
  }

  const onUploadCSVButtonPressed = () => {
    const inputElement = document.createElement('input');
    inputElement.type = 'file';
    inputElement.accept = '.xls,.xlsx';
    inputElement.onchange = handleFileUpload;
    inputElement.click();
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });

      const sheetNames = workbook.SheetNames;
      const jsonData = {};

      sheetNames.forEach((sheetName) => {
        const worksheet = workbook.Sheets[sheetName];
        const sheetData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        jsonData[sheetName] = sheetData;
      });

      dispatch(addProductsToVendor({ data: convertJsonDataToApiFormat(jsonData), cb: onProductsSuccessfullyAdded }));
    };

    reader.readAsArrayBuffer(file);
  };

  const convertJsonDataToApiFormat = (jsonData) => {
    const data = {
      bulk: [],
    };

    const variantMap = new Map();
    const expectedFields = ["Variant Id", "SKU", "Vendor SKU", "Facility IDs", "Description", "Production Time (in days)", "Product Cost", "Currency"];
    const decorationPrefix = ["Area_", "DPI_", "Height_", "Width_", "PrintMethod_", "ImageFormat_", "DecorationCost_", "VendorArea_"];

    // loop through each tab in the excel file
    // eslint-disable-next-line no-unused-vars
    Object.entries(jsonData).forEach(([_prop, variants]) => {
      const headers = variants[0];

      // if there are no headers for this variant, skip it
      if (!headers) {
        return;
      }

      // determine additional decoration fields dynamically
      const additionalFields = headers.filter(header =>
        !expectedFields.includes(header) &&
        !decorationPrefix.some(prefix => header.startsWith(prefix))
      ).map(header => header.replace(/_\d+$/, '')).filter((value, index, self) => self.indexOf(value) === index);

      // loop through each variant found on this tab
      for (let i = 1; i < variants.length; i++) {
        const variant = variants[i];

        // if someone deleted a row from the excel file, it still may be part of the array (but with empty values), just ignore it
        if (!variant[headers.indexOf("Variant Id")]) {
          continue;
        }

        const variantId = String(variant[headers.indexOf("Variant Id")] ?? '');
        const sku = String(variant[headers.indexOf("SKU")] ?? '');
        const vendorId = String(vendorDetails.vendorId ?? '');
        const key = `${variantId}-${sku}-${vendorId}`;

        if (!variantMap.has(key)) {
          variantMap.set(key, {
            variantId,
            sku,
            vendorId,
            facilities: []
          });
        }

        const facilitiesField = String(variant[headers.indexOf("Facility IDs")]);
        const facilityIds = facilitiesField === "All" ? vendorDetails.facilities.map(f => f.id) : facilitiesField.split(',').map(id => id.trim());

        // now loop through each facility this vendor owns and add the product to it
        facilityIds.forEach(facilityId => {
          const facility = {
            id: String(facilityId ?? ''),
            vendorSKU: String(variant[headers.indexOf("Vendor SKU")] ?? ''),
            vendorSKUDescription: String(variant[headers.indexOf("Description")] ?? ''),
            outOfStock: false,
            productionTimeDays: variant[headers.indexOf("Production Time (in days)")] || null,
            productCost: {
              amount: variant[headers.indexOf("Product Cost")] || null,
              currency: variant[headers.indexOf("Currency")] || null,
            },
            decorations: []
          };

          // let's loop through all the images (there could be more than 1)
          let currentImage = 1;

          // eslint-disable-next-line no-constant-condition
          while (true) {
            // get the index of the next image area (if it's -1 then we know there are no more images to process)
            let index = headers.indexOf("Area_" + currentImage);
            // did we find the next image area and is there an image there (just because there is an image header we could have no image)
            if (index >= 0 && variant[index]) {
              // there is an image here, add it
              const decoration = {
                imageSpecs: {
                  width: variant[headers.indexOf("Width_" + currentImage)] || null,
                  height: variant[headers.indexOf("Height_" + currentImage)] || null,
                  dpi: variant[headers.indexOf("DPI_" + currentImage)] || null,
                },
                attributes: {},
              };

              // add area if it exists and it has a value
              if (headers.indexOf("Area_" + currentImage) >= 0 && variant[headers.indexOf("Area_" + currentImage)]) {
                decoration.area = String(variant[headers.indexOf("Area_" + currentImage)] ?? '');
              }

              // add vendor area if it exists and it has a value
              if (headers.indexOf("VendorArea_" + currentImage) >= 0 && variant[headers.indexOf("VendorArea_" + currentImage)]) {
                decoration.vendorArea = String(variant[headers.indexOf("VendorArea_" + currentImage)] ?? '');
              }

              // add width if it exists and it has a value
              if (headers.indexOf("Width_" + currentImage) >= 0 && variant[headers.indexOf("Width_" + currentImage)]) {
                decoration.width = String(variant[headers.indexOf("Width_" + currentImage)] ?? '');
              }

              // add print method if it exists and it has a value
              if (headers.indexOf("PrintMethod_" + currentImage) >= 0 && variant[headers.indexOf("PrintMethod_" + currentImage)]) {
                decoration.printMethod = String(variant[headers.indexOf("PrintMethod_" + currentImage)] ?? '');
              }

              // add image format if it exists and it has a value
              if (headers.indexOf("ImageFormat_" + currentImage) >= 0 && variant[headers.indexOf("ImageFormat_" + currentImage)]) {
                decoration.imageSpecs.format = String(variant[headers.indexOf("ImageFormat_" + currentImage)] ?? '');
              }

              // add the decoration cost if it exists and it has a value
              if (headers.indexOf("DecorationCost_" + currentImage) >= 0 && variant[headers.indexOf("DecorationCost_" + currentImage)]) {
                decoration.decorationCost = {
                  amount: variant[headers.indexOf("DecorationCost_" + currentImage)] || null,
                  currency: String(variant[headers.indexOf("Currency")] ?? null),
                };
              }

              // add additional decoration fields dynamically
              additionalFields.forEach(field => {
                const fieldIndex = headers.indexOf(field + "_" + currentImage);
                if (fieldIndex >= 0 && variant[fieldIndex]) {
                  decoration.attributes[field] = String(variant[fieldIndex] ?? '');
                }
              });

              facility.decorations.push(decoration);
              currentImage++;
            } else {
              // no more images, end the loop
              break;
            }
          }

          // check for an existing facility with the same id
          const existingVariant = variantMap.get(key);
          const existingFacilityIndex = existingVariant.facilities.findIndex(fac => fac.id === facilityId);

          if (existingFacilityIndex !== -1) {
            // replace the existing facility data
            existingVariant.facilities[existingFacilityIndex] = facility;
          } else {
            // add the new facility data
            existingVariant.facilities.push(facility);
          }
        });
      }
    });

    // ddd all variants to data.bulk
    variantMap.forEach(variantObj => data.bulk.push(variantObj));

    return data;
  };

  const onProductsSuccessfullyAdded = () => {
    navigate(`/admin/vendors/${vendorDetails.vendorId}/products`);
  }

  const parseAxiosError = (axiosError) => {
    if (!axiosError || !axiosError.response || !axiosError.response.data) {
      return 'An unexpected error occurred. Please try again later.';
    }

    const { data } = axiosError.response;

    if (data.errorCode !== 'ValidationError') {
      return 'Upload Error : An unexpected error occurred. Please try again later.';
    }

    const { errors } = data;
    if (!errors || errors.length === 0) {
      return 'Upload Error : Validation failed but no specific errors were provided.';
    }

    const parsedErrors = errors.map(error => {
      const { errorMessage, propertyName } = error;
      const match = errorMessage.match(/Path: \$.bulk\[(\d+)\].facilities\[(\d+)\]\.(\w+)/);
      // from the errorMessage, remove anythign after the first period
      const errorMessageParts = errorMessage.split('Path');

      if (match) {
        // eslint-disable-next-line no-unused-vars
        const [_, bulkIndex, facilityIndex, property] = match;
        return `Upload Error : Error in entry ${parseInt(bulkIndex, 10) + 1}, facility ${parseInt(facilityIndex, 10) + 1}, property "${property}": ${errorMessageParts[0]}<br><br>Error in property : ${propertyName}.`;
      }

      return `Upload Error : Error in property "${propertyName}": ${errorMessage}`;
    });

    return parsedErrors.join('\n');
  }

  return (
    <div className="vendor-add-products">
      {addUpdateProductsErrors && (<MessageBar className="upload-products-error" message={parseAxiosError(addUpdateProductsErrors)} color="red" />)}
      <Card>
        {(categoriesLoading || productsLoading || vendorDetailsLoading || addUpdateProductsLoading) && <LoadingAnimation />}
        <Card.Header hideDivider>
          Add Products
          <div className="header-buttons">
            <Button
              variant="secondary"
              size="small"
              label="Cancel"
              disabled={categoriesLoading || productsLoading || vendorDetailsLoading || productsErrors || categoriesErrors}
              onClick={() => navigate(`products`)}
            />
            <Button
              variant="primary"
              size="small"
              label="Upload Product XLSX"
              disabled={categoriesLoading || productsLoading || vendorDetailsLoading || productsErrors || categoriesErrors}
              onClick={() => onUploadCSVButtonPressed()}
            />
          </div>
        </Card.Header>
        {categories && products && vendorDetails && !productsErrors && !categoriesErrors && (
          <Card.Body>
            <div className="left-panel">
              <SearchBar
                searchPlaceholderText="Search for a Product"
                onSearchStringUpdated={setSearchString}
              />
              <ProductSelector
                products={products}
                categories={categories}
                searchString={searchString}
                onProductSelected={(id) => setCurrentlySelectedProducts([...currentlySelectedProducts, id])}
                currentlySelectedProducts={currentlySelectedProducts}
              />
            </div>
            <div className="right-panel">
              <div className="selected-products-header">
                Selected Products
                <Button
                  variant="secondary"
                  size="small"
                  label="Download XLSX"
                  disabled={currentlySelectedProducts.length === 0}
                  onClick={() => downloadProductCSV()}
                />
              </div>
              <SelectedProducts
                products={products}
                categories={categories}
                currentlySelectedProducts={currentlySelectedProducts}
                onRemoveSelectedProduct={(id) => setCurrentlySelectedProducts(currentlySelectedProducts.filter((productId) => productId !== id))}
              />
            </div>
          </Card.Body>
        )}
        {(productsErrors || categoriesErrors) && (
          <MessageBar
            message="An error occurred while fetching data"
            color="yellow"
            className="mt-3"
          />
        )}
        <Modal show={numVarientsDownloading > 0}>
          <Modal.Header>
            <Modal.Title>Creating Product XLSX</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ProgressBar
              animated
              now={numVarientsDownloaded}
              max={numVarientsDownloading}
            />
          </Modal.Body>
        </Modal>
      </Card>
    </div>
  )
}

export default AddProducts;