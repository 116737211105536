import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

const Table = ({ children, size, verticalAlign, className, highlightRows, ...props }) => {
  return (
    <table {...props} className={`gooten-table ${className} vertical-align-${verticalAlign} size-${size} ${highlightRows ? 'highlight-rows' : ''}`}>
      {children}
    </table>
  );
};

Table.propTypes = {
  children: PropTypes.array.isRequired,
  verticalAlign: PropTypes.oneOf(['top', 'middle', 'bottom']),
  className: PropTypes.string,
  size: PropTypes.oneOf(['slim', 'small', 'medium']),
  highlightRows: PropTypes.bool,
};

Table.defaultProps = {
  verticalAlign: 'middle',
  className: '',
  highlightRows: true,
  size: 'medium',
};

export default Table;