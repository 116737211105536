import { createAction } from 'redux-actions';

const prefix = "VENDOR";

export const getVendors = createAction(`${prefix}/GET_VENDORS`);
export const getVendorsSuccess = createAction(`${prefix}/GET_VENDORS_SUCCESS`);
export const getVendorsFailure = createAction(`${prefix}/GET_VENDORS_FAILURE`);

export const getVendorsBulk = createAction(`${prefix}/GET_VENDORS_BULK`);
export const getVendorsBulkSuccess = createAction(`${prefix}/GET_VENDORS_BULK_SUCCESS`);
export const getVendorsBulkFailure = createAction(`${prefix}/GET_VENDORS_BULK_FAILURE`);

export const getVendorDetails = createAction(`${prefix}/GET_VENDOR_DETAILS`);
export const getVendorDetailsSuccess = createAction(`${prefix}/GET_VENDOR_DETAILS_SUCCESS`);
export const getVendorDetailsFailure = createAction(`${prefix}/GET_VENDOR_DETAILS_FAILURE`);

export const createVendor = createAction(`${prefix}/CREATE_VENDOR`, (data) => (data));
export const createVendorSuccess = createAction(`${prefix}/CREATE_VENDOR_SUCCESS`, (data, resp) => ({ data, resp }));
export const createVendorFailure = createAction(`${prefix}/CREATE_VENDOR_FAILURE`);

export const updateVendor = createAction(`${prefix}/UPDATE_VENDOR`, (data) => (data));
export const updateVendorSuccess = createAction(`${prefix}/UPDATE_VENDOR_SUCCESS`, (data, resp) => ({ data, resp }));
export const updateVendorFailure = createAction(`${prefix}/UPDATE_VENDOR_FAILURE`);

export const getProductsByVendor = createAction(`${prefix}/GET_PRODUCTS_BY_VENDOR`, (data) => (data));
export const getProductsByVendorSuccess = createAction(`${prefix}/GET_PRODUCTS_BY_VENDOR_SUCCESS`, (data, resp) => ({ data, resp }));
export const getProductsByVendorFailure = createAction(`${prefix}/GET_PRODUCTS_BY_VENDOR_FAILURE`);

export const addProductsToVendor = createAction(`${prefix}/ADD_PRODUCTS_TO_VENDOR`);
export const addProductsToVendorSuccess = createAction(`${prefix}/ADD_PRODUCTS_TO_VENDOR_SUCCESS`);
export const addProductsToVendorFailure = createAction(`${prefix}/ADD_PRODUCTS_TO_VENDOR_FAILURE`);

export const addFacility = createAction(`${prefix}/ADD_FACILITY`);
export const addFacilitySuccess = createAction(`${prefix}/ADD_FACILITY_SUCCESS`);
export const addFacilityFailure = createAction(`${prefix}/ADD_FACILITY_FAILURE`);

export const getFacilityDetails = createAction(`${prefix}/GET_FACILITY_DETAILS`);
export const getFacilityDetailsSuccess = createAction(`${prefix}/GET_FACILITY_DETAILS_SUCCESS`);
export const getFacilityDetailsFailure = createAction(`${prefix}/GET_FACILITY_DETAILS_FAILURE`);

export const updateFacility = createAction(`${prefix}/UPDATE_FACILITY`);
export const updateFacilitySuccess = createAction(`${prefix}/UPDATE_FACILITY_SUCCESS`);
export const updateFacilityFailure = createAction(`${prefix}/UPDATE_FACILITY_FAILURE`);

export const getAuditLogs = createAction(`${prefix}/GET_AUDIT_LOGS`);
export const getAuditLogsSuccess = createAction(`${prefix}/GET_AUDIT_LOGS_SUCCESS`);
export const getAuditLogsFailure = createAction(`${prefix}/GET_AUDIT_LOGS_FAILURE`);

export const getAllValueAddedServices = createAction(`${prefix}/GET_ALL_VALUE_ADDED_SERVICES`);
export const getAllValueAddedServicesSuccess = createAction(`${prefix}/GET_ALL_VALUE_ADDED_SERVICES_SUCCESS`);
export const getAllValueAddedServicesFailure = createAction(`${prefix}/GET_ALL_VALUE_ADDED_SERVICES_FAILURE`);

export const clearErrors = createAction(`${prefix}/CLEAR_ERRORS`);
export const reset = createAction('RESET');