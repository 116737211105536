import React from 'react';
import OrderStatusDetails from './orderStatusDetails';
import OrderItemStatusDetails from './orderItemStatusDetails';
import ShipmentStatusDetails from './shipmentStatusDetails';
import DefaultStatusDetails from './defaultStatusDetails';
import UserStatusDetails from './userStatusDetails';
import WebhooksStatusDetails from './webhooksStatusDetails';
import ProductStatusDetails from './productStatusDetails';
import PropTypes from 'prop-types';
import './index.scss';

const StatusChip = ({ size, type, status, icon, onClick }) => {
  let statusDetails;

  switch (type) {
    case 'order': statusDetails = OrderStatusDetails; break;
    case 'orderItem': statusDetails = OrderItemStatusDetails; break;
    case 'shipment': statusDetails = ShipmentStatusDetails; break;
    case 'user': statusDetails = UserStatusDetails; break;
    case 'webhooks': statusDetails = WebhooksStatusDetails; break;
    case 'product': statusDetails = ProductStatusDetails; break;
    default: statusDetails = DefaultStatusDetails; break;
  }
  
  const lowerCaseStatus = String(status).toLowerCase() || '';

  const color = statusDetails[lowerCaseStatus] ? statusDetails[lowerCaseStatus].color : '#000000';
  const bgColor = statusDetails[lowerCaseStatus] ? statusDetails[lowerCaseStatus].bgColor : 'rgba(0, 0, 0, 0.10)';
  const borderColor = statusDetails[lowerCaseStatus] ? statusDetails[lowerCaseStatus].borderColor : 'rgba(0, 0, 0, 1)';
  const displayName = statusDetails[lowerCaseStatus] ? statusDetails[lowerCaseStatus].displayName : `${status} - Unknown`;  

  return (
    <div
      className={`status-chip size-${size} ${onClick ? 'clickable' : ''}`}
      style={{ color: color, backgroundColor: bgColor, boxShadow: `inset 0px 0px 0px 2px ${borderColor}`, border: 'none' }}
      onClick={onClick}
    >
      <div className="status-name">{displayName}</div>
      {icon && (
        <div className="status-icon">
          {React.cloneElement(icon)}
        </div>
      )}
    </div>
  );
};

StatusChip.propTypes = {
  size: PropTypes.oneOf(['slim', 'small', 'medium']),
  type: PropTypes.string,
  status: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  icon: PropTypes.element,
  onClick: PropTypes.func,
};

StatusChip.defaultProps = {
  size: 'small',
  type: 'default',
  icon: null,
  onClick: null,
};

export default StatusChip;
