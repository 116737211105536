import { handleActions } from 'redux-actions';
import {
  getUserDetails,
  getUserDetailsSuccess,
  getUserDetailsError,
  signinUser,
  signinUserSuccess,
  signinUserError,
  getUsers,
  getUsersSuccess,
  getUsersFailure,
  createUser,
  createUserSuccess,
  createUserFailure,
  updateUser,
  updateUserSuccess,
  updateUserFailure,
  resetPassword,
  resetPasswordSuccess,
  resetPasswordFailure,
  logoutUser,
  getRoles,
  getRolesSuccess,
  getRolesFailure,
  updateUserStatus,
  updateUserStatusFailure,
  updateUserStatusSuccess,
  deleteUser,
  deleteUserSuccess,
  deleteUserFailure,
  getRolesWithPermissions,
  getRolesWithPermissionsSuccess,
  getRolesWithPermissionsError,
  deleteRole,
  deleteRoleFailure,
  deleteRoleSuccess,
  getRoleDetails,
  getRoleDetailsSuccess,
  getRoleDetailsError,
  createRole,
  createRoleSuccess,
  createRoleFailure,
  updateRole,
  updateRoleSuccess,
  updateRoleFailure,
  clearErrors,
  reset,
  requestForgotPassword,
  requestForgotPasswordSuccess,
  requestForgotPasswordFailure,
  updateForgotPassword,
  updateForgotPasswordSuccess,
  updateForgotPasswordFailure,
  getResources,
  getResourcesFailure,
  getResourcesSuccess,
  searchRoles,
  searchRolesSuccess,
  searchRolesFailure,
  getGroupsSuccess,
  getGroupsFailure,
  getGroups,
  setSelectedGroup,
  addAttributesToUser,
  addAttributesToUserFailure,
  addAttributesToUserSuccess,
  removeAttributesToUser,
  removeAttributesToUserFailure,
  removeAttributesToUserSuccess
} from "app/store/actions/user";

const initialState = {
  auth: {
    loading: false,
    bearerToken: '',
    refreshToken: '',
    error: null,
  },
  currentUser: {
    data: null,
  },
  preferences: {
    loading: false,
    data: null,
    error: null,
  },
  settings: {
    loading: false,
    data: null,
    error: null,
  },
  users: {
    loading: false,
    data: null,
    error: null,
  },
  user: {
    loading: false,
    data: null,
    error: null,
  },
}

const userReducer = handleActions(
  {
    [signinUser]: (state) => ({ 
      ...state,
      auth: {
        ...state.auth,
        loading: true,
        error: null,
      },
    }),

    [signinUserSuccess]: (state, data) => ({
      ...state,
      auth: {
        loading: false,
        bearerToken: data.payload.tokenResponse?.accessToken || data.payload.accessToken,
        refreshToken: data.payload.tokenResponse?.refreshToken || data.payload.refreshToken,
        error: null,
      },
      currentUser: {
        data: {
          ...data.payload,
          tokenResponse: undefined,
          accessToken: undefined,
          refreshToken: undefined,
        },
      },
    }),

    [signinUserError]: (state, data) => ({ 
      ...state,
      auth: {
        loading: false,
        bearerToken: null,
        refreshToken: null,
        error: data,
      },
    }),

    [logoutUser]: (state) => ({ 
      ...state,
      auth: {
        loading: false,
        bearerToken: null,
        refreshToken: null,
        error: null,
      },
    }),

    [getUsers]: (state) => ({ 
      ...state,
      users: {
        ...state.users,
        loading: true,
        error: null,
      }
    }),

    [getUsersSuccess]: (state, data) => ({
      ...state,
      users: {
        ...state.users,
        loading: false,
        data: data.payload,
      }
    }),

    [getUsersFailure]: (state, data) => ({ 
      ...state,
      users: {
        ...state.users,
        loading: false,
        error: data.payload,
      }
    }),

    [getUserDetails]: (state) => ({ 
      ...state,
      user: {
        loading: true,
        data: null,
        error: null,
      },
    }),

    [getUserDetailsSuccess]: (state, data) => ({
      ...state,
      user: {
        loading: false,
        data: data.payload,
        error: null,
      },
    }),

    [getUserDetailsError]: (state, data) => ({ 
      ...state,
      user: {
        ...state.user,
        loading: false,
        error: data.payload,
      },
    }),

    [getRolesWithPermissions]: (state) => ({ 
      ...state,
      roles: {
        loading: true,
        data: null,
        error: null,
      },
    }),

    [getRolesWithPermissionsSuccess]: (state, data) => ({
      ...state,
      roles: {
        loading: false,
        data: data.payload,
        error: null,
      },
    }),

    [getRolesWithPermissionsError]: (state, data) => ({ 
      ...state,
      roles: {
        loading: false,
        error: data.payload,
      },
    }),

    [createUser]: (state) => ({ 
      ...state,
      user: {
        ...state.user,
        loading: true,
        error: null,
      },
    }),

    [createUserSuccess]: (state) => ({
      ...state,
      user: {
        ...state.user,
        loading: false,
      },
    }),

    [createUserFailure]: (state, data) => ({ 
      ...state,
      user: {
        ...state.user,
        loading: false,
        error: data.payload,
      },
    }),

    [updateUser]: (state) => ({ 
      ...state,
      user: {
        ...state.user,
        loading: true,
        error: null,
      },
    }),

    [updateUserSuccess]: (state) => ({
      ...state,
      user: {
        loading: false,
        error: null,
      },
    }),

    [updateUserFailure]: (state, data) => ({ 
      ...state,
      user: {
        ...state.user,
        loading: false,
        error: data.payload,
      },
    }),

    [resetPassword]: (state) => ({ 
      ...state,
      user: {
        ...state.user,
        loading: true,
        error: null,
      },
    }),

    [resetPasswordSuccess]: (state) => ({
      ...state,
      user: {
        ...state.user,
        loading: false,
        error: null,
      },
    }),

    [resetPasswordFailure]: (state, data) => ({ 
      ...state,
      user: {
        ...state.user,
        loading: false,
        error: data.payload,
      },
    }),

    [requestForgotPassword]: (state) => ({ 
      ...state,
      user: {
        ...state.user,
        loading: true,
        error: null,
      },
    }),

    [requestForgotPasswordSuccess]: (state) => ({
      ...state,
      user: {
        ...state.user,
        loading: false,
        error: null,
      },
    }),

    [requestForgotPasswordFailure]: (state, data) => ({ 
      ...state,
      user: {
        ...state.user,
        loading: false,
        error: data.payload,
      },
    }),

    [updateForgotPassword]: (state) => ({ 
      ...state,
      user: {
        ...state.user,
        loading: true,
        error: null,
      },
    }),

    [updateForgotPasswordSuccess]: (state) => ({
      ...state,
      user: {
        ...state.user,
        loading: false,
        error: null,
      },
    }),

    [updateForgotPasswordFailure]: (state, data) => ({ 
      ...state,
      user: {
        ...state.user,
        loading: false,
        error: data.payload,
      },
    }),

    [getRoles]: (state) => ({
      ...state,
      roles: {
        loading: true,
        error: null
      },
    }),

    [getRolesSuccess]: (state, data) => ({
      ...state,
      roles: {
        loading: false,
        data: data.payload,
        error: null,
      },
    }),

    [getRolesFailure]: (state, data) => ({
      ...state,
      roles: {
        loading: false,
        error: data.payload,
      },
    }),

    [getResources]: (state) => ({
      ...state,
      resources: {
        loading: true,
        error: null
      },
    }),

    [getResourcesSuccess]: (state, data) => ({
      ...state,
      resources: {
        loading: false,
        data: data.payload,
        error: null,
      },
    }),

    [getResourcesFailure]: (state, data) => ({
      ...state,
      resources: {
        loading: false,
        error: data.payload,
      },
    }),

    [updateUserStatus]: (state) => ({
      ...state,
      users: {
        ...state.users,
        loading: true,
        error: null,
      }
    }),

    [updateUserStatusSuccess]: (state) => ({
      ...state,
      users: {
        ...state.users,
        loading: false,
        error: null,
      }
    }),

    [updateUserStatusFailure]: (state, data) => ({
      ...state,
      users: {
        ...state.users,
        loading: false,
        error: data.payload,
      }
    }),

    [deleteUser]: state => ({
      ...state,
      users: {
        ...state.users,
        loading: true,
        error: null,
      }
    }),

    [deleteUserSuccess]: state => ({
      ...state,
      users: {
        ...state.users,
        loading: false,
        error: null,
      }
    }),

    [deleteUserFailure]: (state, data) => ({
      ...state,
      users: {
        ...state.users,
        loading: false,
        error: data.payload,
      }
    }),

    [deleteRole]: state => ({
      ...state,
      roles: {
        ...state.roles,
        loading: true,
        error: null,
      }
    }),

    [deleteRoleSuccess]: state => ({
      ...state,
      roles: {
        ...state.roles,
        loading: false,
        error: null,
      }
    }),

    [deleteRoleFailure]: (state, data) => ({
      ...state,
      roles: {
        ...state.roles,
        loading: false,
        error: data.payload,
      }
    }),

    [getRoleDetails]: (state) => ({ 
      ...state,
      role: {
        loading: true,
        data: null,
        error: null,
      },
    }),

    [getRoleDetailsSuccess]: (state, data) => ({
      ...state,
      role: {
        loading: false,
        data: data.payload,
        error: null,
      },
    }),

    [getRoleDetailsError]: (state, data) => ({ 
      ...state,
      role: {
        ...state.role,
        loading: false,
        error: data.payload,
      },
    }),

    [createRole]: (state) => ({ 
      ...state,
      role: {
        ...state.role,
        loading: true,
        error: null,
      },
    }),

    [createRoleSuccess]: (state) => ({
      ...state,
      role: {
        ...state.role,
        loading: false,
      },
    }),

    [createRoleFailure]: (state, data) => ({ 
      ...state,
      role: {
        ...state.role,
        loading: false,
        error: data.payload,
      },
    }),

    [updateRole]: (state) => ({ 
      ...state,
      role: {
        ...state.role,
        loading: true,
        error: null,
      },
    }),

    [updateRoleSuccess]: (state) => ({
      ...state,
      role: {
        loading: false,
        error: null,
      },
    }),

    [updateRoleFailure]: (state, data) => ({ 
      ...state,
      role: {
        ...state.role,
        loading: false,
        error: data.payload,
      },
    }),

    [searchRoles]: (state) => ({ 
      ...state,
      roles: {
        ...state.roles,
        loading: true,
        error: null,
      }
    }),

    [searchRolesSuccess]: (state, data) => ({
      ...state,
      roles: {
        ...state.roles,
        loading: false,
        data: data.payload,
      }
    }),

    [searchRolesFailure]: (state, data) => ({ 
      ...state,
      roles: {
        ...state.roles,
        loading: false,
        error: data.payload,
      }
    }),

    [getGroups]: (state) => ({
      ...state,
      groups: {
        ...state.groups,
        items: [],
        error: null
      }
    }),

    [getGroupsSuccess]: (state, data) => ({
      ...state,
      groups: {
        ...state.groups,
        items: data.payload
      }
    }),

    [getGroupsFailure]: (state, data) => ({ 
      ...state,
      groups: {
        ...state.groups,
        error: data.payload
      }
    }),

    [setSelectedGroup]: (state, data) => ({
      ...state,
      groups: {
        ...state.groups,
        selected: data.payload
      }
    }),

    [addAttributesToUser]: (state) => ({ 
      ...state,
      users: {
        ...state.users,
        loading: true,
        error: null,
      },
    }),

    [addAttributesToUserSuccess]: (state) => ({
      ...state,
      users: {
        loading: false,
        error: null,
      },
    }),

    [addAttributesToUserFailure]: (state, data) => ({ 
      ...state,
      users: {
        ...state.users,
        loading: false,
        error: data.payload,
      },
    }),

    [removeAttributesToUser]: (state) => ({ 
      ...state,
      users: {
        ...state.users,
        loading: true,
        error: null,
      },
    }),

    [removeAttributesToUserSuccess]: (state) => ({
      ...state,
      users: {
        loading: false,
        error: null,
      },
    }),

    [removeAttributesToUserFailure]: (state, data) => ({ 
      ...state,
      users: {
        ...state.users,
        loading: false,
        error: data.payload,
      },
    }),

    [clearErrors]: (state) => ({ 
      ...state,
      auth: {
        ...state.auth,
        error: null,
      },
      preferences: {
        ...state.preferences,
        error: null,
      },
      settings: {
        ...state.settings,
        error: null,
      },
      users: {
        ...state.users,
        error: null,
      },
      user: {
        ...state.user,
        error: null,
      },
    }),

    [reset]: (state) => ({ 
      ...state,
      users: {
        ...initialState.users,
      },
      user: {
        ...initialState.user,
      },
      roles: {
        ...initialState.roles
      },
      role: {
        ...initialState.role
      },
      resources: {
        ...initialState.resources
      }
    }),
  },
  initialState,
)

export default userReducer;