import { put, takeEvery, call, all, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";
import {
  getUserDetails,
  getUserDetailsSuccess,
  getUserDetailsError,
  signinUser,
  signinUserSuccess,
  signinUserError,
  getUsers,
  getUsersSuccess,
  getUsersFailure,
  createUser,
  createUserSuccess,
  createUserFailure,
  updateUser,
  updateUserSuccess,
  updateUserFailure,
  resetPassword,
  resetPasswordSuccess,
  resetPasswordFailure,
  requestForgotPassword,
  requestForgotPasswordSuccess,
  requestForgotPasswordFailure,
  updateForgotPassword,
  updateForgotPasswordSuccess,
  updateForgotPasswordFailure,
  logoutUser,
  getRoles,
  getRolesSuccess,
  getRolesFailure,
  updateUserStatus,
  updateUserStatusFailure,
  updateUserStatusSuccess,
  deleteUser,
  deleteUserSuccess,
  deleteUserFailure,
  getRolesWithPermissions,
  getRolesWithPermissionsSuccess,
  getRolesWithPermissionsError,
  deleteRole,
  deleteRoleFailure,
  deleteRoleSuccess,
  updateRole,
  updateRoleFailure,
  updateRoleSuccess,
  createRole,
  createRoleFailure,
  createRoleSuccess, 
  getRoleDetails,
  getRoleDetailsSuccess,
  getRoleDetailsError,  
  getResources,
  getResourcesSuccess,
  getResourcesFailure,
  searchRolesSuccess,
  searchRolesFailure,
  searchRoles,
  updateRoleName,
  getGroups,
  getGroupsSuccess,
  getGroupsFailure
} from "app/store/actions/user";
import UserServices from 'app/services/userServices';
import { GroupsEnum } from 'app/constants/groups';
import merchantServices from "app/services/merchantServices";

function* doSigninUser(action) {
  const { username, password, onSigninFail } = action.payload;

  // clear the bearer and refresh tokens from local storage
  localStorage.removeItem("btoken");
  localStorage.removeItem("rtoken");

  try {
    // make the api call to sign the user in
    const data = yield call(
      [UserServices, UserServices.signinUser],
      username,
      password
    );
    // an invalid username or password will return an array of errors (with a 200 status code)
    if (data.errors) {
      throw new Error(data.errors[0].errorMessage);
    }
    // if there are no errors, then save the bearer and refresh tokens in local storage
    localStorage.setItem(
      "btoken",
      data.tokenResponse?.accessToken || data.accessToken
    );
    localStorage.setItem(
      "rtoken",
      data.tokenResponse?.refreshToken || data.refreshToken
    );
    // update the redux state with the status
    yield put(signinUserSuccess(data));
  } catch (error) {
    if (onSigninFail) onSigninFail();
    // an error occurred (invalid credentials, api down, etc).   Report the error to the user
    yield put(signinUserError(error));
  }
}

function* fetchUserDetails(action) {
  const userId = action.payload;
  try {
    const data = yield call([UserServices, UserServices.getUserById], userId);
    yield put(getUserDetailsSuccess(data));
  } catch (error) {
    console.error("error", error);
    yield put(getUserDetailsError(error));
  }
}

function* fetchUsers(action) {
  const {
    searchString,
    selectedStatus,
    selectedSortBy,
    selectedSortDir,
    currentPage,
    pageSize,
  } = action.payload;

  let apiStatus = "";
  let apiSortBy = "";
  let apiDesc = "";

  // handle the status parameter
  switch (selectedStatus) {
    case "active":
      apiStatus = "enabled";
      break;
    case "inactive":
      apiStatus = "disabled";
      break;
    default:
      apiStatus = "";
  }

  // handle sort...
  switch (selectedSortBy) {
    case "id":
      apiSortBy = "shortId";
      break;
    case "email":
      apiSortBy = selectedSortBy;
      break;
    case "name":
      apiSortBy = "firstName";
      break;
    case "roles":
      apiSortBy = "role";
      break;
    case "createdOn":
      apiSortBy = "dateCreated";
      break;
    default:
      apiSortBy = "";
  }

  // handle sorting direction...
  switch (selectedSortDir) {
    case "asc":
      apiDesc = "false";
      break;
    case "desc":
      apiDesc = "true";
      break;
    default:
      apiDesc = "";
  }

  try {
    const data = yield call(
      [UserServices, UserServices.getUsers],
      searchString,
      apiStatus,
      apiSortBy,
      apiDesc,
      currentPage,
      pageSize
    );
    //data.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
    yield put(getUsersSuccess(data));
  } catch (error) {
    console.error("error", error);
    yield put(getUsersFailure());
    toast.error("Loading User Data Failed", {
      theme: "colored",
    });
  }
}

function* doCreateUser(action) {
  const { values, cb } = action.payload;

  try {
    // make the api call to create the user
    const createUserResp = yield call(
      [UserServices, UserServices.createUser],
      values
    );

    // make sure the createUser call was successful
    if (!createUserResp.id) {
      const errMsg = "Create User Failed";
      yield put(createUserFailure(errMsg));
      toast.error(errMsg, {
        theme: "colored",
      });
    }

    // assign the role to the user
    yield call(
      [UserServices, UserServices.assignRole],
      createUserResp.id,
      values.role
    );

    // assign the permissions to the user
    yield call(
      [UserServices, UserServices.assignUserPermissions],
      createUserResp.id,
      values.role,
      values.roles
    );

    if (values.group && values.group.name !== GroupsEnum.InternalOM) {
      // add attributes to the user
      if (values.group.name === GroupsEnum.ParentMerchant) {
        var merchantIdsWithChild = [];
        merchantIdsWithChild.push(values.merchantIds[0]);
        var parentMerchantDetails = yield call(
          [merchantServices, merchantServices.getMerchantById],
          values.merchantIds[0]
        );
        const selectedMerchantChilds = parentMerchantDetails?.children?.map(merchant => merchant.id);

        if (selectedMerchantChilds?.length > 0) {
          selectedMerchantChilds.forEach(merchantChild => {
            merchantIdsWithChild.push(merchantChild);
          });
        }

        yield call(
          [UserServices, UserServices.addAttributesToUser],
          createUserResp.id,
          merchantIdsWithChild,
          values.vendorIds,
          values.facilityIds,
        );
      }
      else {
        yield call(
          [UserServices, UserServices.addAttributesToUser],
          createUserResp.id,
          values.merchantIds,
          values.vendorIds,
          values.facilityIds,
        );
      }
    }

    yield put(createUserSuccess(createUserResp));
    if (cb) cb(createUserResp.id);
    toast.success("New User Successfully Created", {
      theme: "colored",
    });
  } catch (error) {
    console.error("error", error);
    yield put(createUserFailure(error));
    toast.error(error.toString(), {
      theme: "colored",
    });
  }
}

function* doUpdateUser(action) {
  const { userId, values, cb } = action.payload;

  try {
    const resp = yield call(
      [UserServices, UserServices.updateUser],
      userId,
      values
    );

    //this will be performed only when we change the role
    if (values.initialRole != values.role) {
      if (values.initialRole != "") {
        // unassign the old role from the user
        yield call(
          [UserServices, UserServices.unassignRole],
          userId,
          values.initialRole
        );
      }

      // assign the new role to the user
      yield call([UserServices, UserServices.assignRole], userId, values.role);
    }

    // assign the permissions to the user
    yield call(
      [UserServices, UserServices.assignUserPermissions],
      userId,
      values.role,
      values.roles
    );

    // remove old attributes of user
    yield call(
      [UserServices, UserServices.removedAttributesToUser],
      userId,
      values.initialAttributes
    );

    if (values.group && values.group.name !== GroupsEnum.InternalOM) {
      // add attributes to the user

      if (values.group.name === GroupsEnum.ParentMerchant) {
        var merchantIdsWithChild = [];
        merchantIdsWithChild.push(values.merchantIds[0]);
        var parentMerchantDetails = yield call(
          [merchantServices, merchantServices.getMerchantById],
          values.merchantIds[0]
        );

        const selectedMerchantChilds = parentMerchantDetails?.children?.map(merchant => merchant.id);
        if (selectedMerchantChilds?.length > 0) {
          selectedMerchantChilds.forEach(merchantChild => {
            merchantIdsWithChild.push(merchantChild);
          });
        }

        yield call(
          [UserServices, UserServices.addAttributesToUser],
          userId,
          merchantIdsWithChild,
          values.vendorIds,
          values.facilityIds,
        );
      }
      else {
        yield call(
          [UserServices, UserServices.addAttributesToUser],
          userId,
          values.merchantIds,
          values.vendorIds,
          values.facilityIds,
        );
      }
    }
    yield put(updateUserSuccess(resp));
    if (cb) cb(userId);
    toast.success("User Successfully Updated", {
      theme: "colored",
    });
  } catch (error) {
    console.error("error", error);
    yield put(updateUserFailure(error));
    toast.error(error.toString(), {
      theme: "colored",
    });
  }
}

function* doResetPassword(action) {
  const { userId, cb } = action.payload;

  try {
    const resp = yield call([UserServices, UserServices.resetPassword], userId);
    yield put(resetPasswordSuccess(resp));
    if (cb) cb(resp);
    toast.success("Password Reset Successful", {
      theme: "colored",
    });
  } catch (error) {
    console.error("error", error);
    yield put(resetPasswordFailure(error));
    toast.error(error.toString(), {
      theme: "colored",
    });
  }
}

function* doForgotPassword(action) {
  const { email, isAdmin, cb, failCB } = action.payload;

  try {
    const resp = yield call([UserServices, UserServices.forgotPassword], email);
    yield put(requestForgotPasswordSuccess(resp));
    if (cb) cb(resp);
    if (isAdmin) {
      toast.success(
        `An email with instructions for password reset was sent to ${email}.`,
        {
          theme: "colored",
        }
      );
    } else {
      toast.success( 
        "You will receive an email with instructions for password reset.",
        {
          theme: "colored",
        }
      );
    }
  } catch (error) {
    console.error("error", error);
    yield put(requestForgotPasswordFailure(error));
    toast.error(error.toString(), {
      theme: "colored",
    });
    if (failCB){
      failCB();
    }
  }
}

function* doUpdateForgotPassword(action) {
  const { token, password, cb, failCB } = action.payload;

  try {
    const resp = yield call(
      [UserServices, UserServices.updateForgotPassword],
      token,
      password
    );
    yield put(updateForgotPasswordSuccess(resp));
    if (cb) cb(resp);
    toast.success(
      "You have successfully reset your password. You can login using your new password.",
      {
        theme: "colored",
      }
    );
  } catch (error) {
    console.error("error", error);
    yield put(updateForgotPasswordFailure(error));
    toast.error(error.toString(), {
      theme: "colored",
    });
    if (failCB){
      failCB();
    }
  }
}

function doLogoutUser() {
  // clear the bearer and refresh tokens from local storage
  localStorage.clear();
}

function* fetchRoles() {
  try {
    const data = yield call([UserServices, UserServices.getRoles]);
    yield put(getRolesSuccess(data));
  } catch (error) {
    console.error("error", error);
    yield put(getRolesFailure());
    toast.error("Loading Roles Failed", {
      theme: "colored",
    });
  }
}

function* fetchRolesWithPermissions() {
  try {
    const data = yield call([
      UserServices,
      UserServices.getRoleWithPermissions,
    ]);
    if (!data) return [];

    // do a sorting here...
    let sortedData = data
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((role) => ({
        ...role,
        permissionSet: role.permissionSet
          .sort((a, b) => a.resourceName.localeCompare(b.resourceName))
          .map((permission) => ({
            ...permission,
            availablePermissions: permission.availablePermissions.sort(),
          })),
      }));
    yield put(getRolesWithPermissionsSuccess(sortedData));
  } catch (error) {
    console.error("Fetching roles with permissions failed: ", error);
    yield put(getRolesWithPermissionsError(error));
    toast.error(error.toString(), {
      theme: "colored",
    });
  }
}

function* fetchResources() {
  try {
    const data = yield call([
      UserServices,
      UserServices.getResources,
    ]);

    yield put(getResourcesSuccess(data));
  } catch (error) {
    yield put(getResourcesFailure(error));
    toast.error(error.toString(), {
      theme: "colored",
    });
  }
}

function* doCreateRole(action) {
  const { values, cb } = action.payload;

  try {
    // make the api call to create the role
    const createRoleResp = yield call([UserServices, UserServices.createRole], values);

    // make sure the createRole call was successful
    if (!createRoleResp.id) {
      const errMsg = 'Create Role Failed';
      yield put(createRoleFailure(errMsg));
      toast.error(errMsg, {
        theme: 'colored',
      });
    }
    else {
      //add permissions for created role
      if(values.permissions?.length > 0)
        {
          yield call([UserServices, UserServices.updateRole], createRoleResp.id, values.permissions);
        }

      yield put(createRoleSuccess(createRoleResp));
      if (cb) cb(createRoleResp.id);
      toast.success("New Role Successfully Created", {
        theme: 'colored',
      });
    }

  } catch (error) {
    console.error('error', error);
    yield put(createRoleFailure(error));
    toast.error(error.toString(), {
      theme: 'colored',
    });
  }
}

function* doUpdateRole(action) {
  const { roleId, values, cb } = action.payload;

  try {
    const resp = yield call([UserServices, UserServices.updateRole], roleId, values.permissions);

    yield put(updateRoleSuccess(resp));
    if (cb) cb(roleId);
    toast.success("Role Successfully Updated", {
      theme: 'colored',
    });
  } catch (error) {
    console.error('error', error);
    yield put(updateRoleFailure(error));
    toast.error(error.toString(), {
      theme: 'colored',
    });
  }
}

function* handleUpdateUserStatus(action) {
  const { id, status, cb } = action.payload;

  const enabled = status === "activate";

  try {
    const resp = yield call(
      [UserServices, UserServices.updateUserStatus],
      id,
      enabled
    );
    yield put(updateUserStatusSuccess(resp));
    if (cb) cb();
    toast.success("User Status Successfully Updated", {
      theme: "colored",
    });
  } catch (error) {
    console.error("error", error);
    yield put(updateUserStatusFailure(error));
    toast.error(error.toString(), {
      theme: "colored",
    });
  }
}

function* handleDeleteUser(action) {
  const { id, cb } = action.payload;

  try {
    const resp = yield call([UserServices, UserServices.deleteUser], id);
    yield put(deleteUserSuccess(resp));
    if (cb) cb();
    toast.success("User Successfully Deleted", {
      theme: "colored",
    });
  } catch (error) {
    console.error("error", error);
    yield put(deleteUserFailure(error));
    toast.error(error.toString(), {
      theme: "colored",
    });
  }
}

function* handleDeleteRole(action) {
  const { id, cb } = action.payload;

  try {
    const resp = yield call([UserServices, UserServices.deleteRole], id);
    yield put(deleteRoleSuccess(resp));
    if (cb) cb();
    toast.success("Role Successfully Deleted", {
      theme: "colored",
    });
  } catch (error) {
    console.error("error", error);
    yield put(deleteRoleFailure(error));
    toast.error(error.toString(), {
      theme: "colored",
    });
  }
}

function* fetchRoleDetails(action) {
  const roleId = action.payload;
  try {
    const data = yield call([UserServices, UserServices.getRoleById], roleId);
    yield put(getRoleDetailsSuccess(data));
  } catch (error) {
    console.error('error', error);
    yield put(getRoleDetailsError(error));
  }
}

function* fetchSearchRoles(action) {
  const {
    searchString,
    selectedSortBy,
    selectedSortDir,
    currentPage,
    pageSize,
  } = action.payload;

  let apiSortBy = "";
  let apiDesc = "";

  // handle sort...
  switch (selectedSortBy) {
    case "name":
      apiSortBy = "name";
      break;
    case "userCount":
      apiSortBy = "userCount";
      break;
    default:
      apiSortBy = "";
  }

  // handle sorting direction...
  switch (selectedSortDir) {
    case "asc":
      apiDesc = "false";
      break;
    case "desc":
      apiDesc = "true";
      break;
    default:
      apiDesc = "";
  }

  try {
    const data = yield call(
      [UserServices, UserServices.searchRoles],
      searchString,
      apiSortBy,
      apiDesc,
      currentPage,
      pageSize
    );
    yield put(searchRolesSuccess(data));
  } catch (error) {
    console.error("error", error);
    yield put(searchRolesFailure());
    toast.error("Loading User Data Failed", {
      theme: "colored",
    });
  }
}

function* handleUpdateRoleName(action) {
  const { roleId, name, cb } = action.payload;

  try {
    yield call([UserServices, UserServices.updateRoleName], roleId, name);
    
    if (cb) cb();
    toast.success("Role Name Successfully Updated", {
      theme: "colored",
    });
  } catch (error) {
    toast.error(error.toString(), {
      theme: "colored",
    });
  }
}

function* fetchGroups() {
  try {
    const data = yield call([UserServices, UserServices.getGroups]);
    yield put(getGroupsSuccess(data));
  } catch (error) {
    yield put(getGroupsFailure());
    toast.error("Loading Groups Failed", {
      theme: "colored",
    });
  }
}

function* watchData() {
  yield takeEvery(signinUser.toString(), doSigninUser);
  yield takeEvery(getUserDetails.toString(), fetchUserDetails);
  yield takeEvery(getUsers().type, fetchUsers);
  yield takeEvery(searchRoles().type, fetchSearchRoles);
  yield takeEvery(createUser.toString(), doCreateUser);
  yield takeEvery(updateUser.toString(), doUpdateUser);
  yield takeEvery(resetPassword.toString(), doResetPassword);
  yield takeEvery(logoutUser.toString(), doLogoutUser);
  yield takeLatest(getRoles.toString(), fetchRoles);
  yield takeLatest(updateUserStatus.toString(), handleUpdateUserStatus);
  yield takeLatest(deleteUser.toString(), handleDeleteUser);
  yield takeLatest(
    getRolesWithPermissions.toString(),
    fetchRolesWithPermissions
  );
  yield takeLatest(requestForgotPassword.toString(), doForgotPassword);
  yield takeLatest(updateForgotPassword.toString(), doUpdateForgotPassword);
  yield takeLatest(deleteRole.toString(), handleDeleteRole);
  yield takeLatest(getResources.toString(), fetchResources);
  yield takeEvery(createRole.toString(), doCreateRole);
  yield takeEvery(updateRole.toString(), doUpdateRole);
  yield takeEvery(getRoleDetails.toString(), fetchRoleDetails);
  yield takeLatest(updateRoleName.toString(), handleUpdateRoleName);
  yield takeLatest(getGroups.toString(), fetchGroups);
}

export default function* rootSaga() {
  yield all([watchData()]);
}
