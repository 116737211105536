import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import './index.scss';

const CustomLink = ({ size, label, url, isRoute, onClick, disabled }) => {
  const navigate = useNavigate();

  const handleClick = (event) => {
    if (disabled) {
      event.preventDefault();
      return;
    }
    // if onClick is passed in, let the parent handle the click
    if (onClick) {
      event.preventDefault();
      onClick();
      return;
    }
    if (isRoute) {
      event.preventDefault();
      navigate(url);
    }
  };

  return (
    <div className={`gooten-link size-${size} ${disabled ? 'disabled' : ''}`}>
      <a
        href={url}
        onClick={handleClick}
        tabIndex={disabled ? -1 : 0}
        className={isRoute ? 'route-link' : ''}
        target={!isRoute ? '_blank' : undefined} 
        rel={!isRoute ? 'noopener noreferrer' : undefined} // for security with external links.
      >
        {label}
      </a>
    </div>
  );
}

CustomLink.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  label: PropTypes.string.isRequired,
  url: PropTypes.string,
  isRoute: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

CustomLink.defaultProps = {
  size: 'medium',
  url: '',
  isRoute: true,
  onClick: null,
  disabled: false,
};

export default CustomLink;
