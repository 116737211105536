import { handleActions } from 'redux-actions';
import {
  getCategories,
  getCategoriesSuccess,
  getCategoriesFailure,
  getProducts,
  getProductsSuccess,
  getProductsFailure,
  getProductVariants,
  getProductVariantsSuccess,
  getProductVariantsFailure,
} from "app/store/actions/catalog";

const initialState = {
  categories: {
    data: null,
    loading: false,
    error: null,
  },
  products: {
    data: null,
    loading: false,
    error: null,
  },
  productVariants: {
    data: {},
    loading: false,
    error: null,
  }
}

const catalogReducer = handleActions(
  {
    [getCategories]: (state) => ({ 
      ...state,
      categories: {
        data: null,
        loading: true,
        error: null,
      },
    }),

    [getCategoriesSuccess]: (state, data) => ({ 
      ...state,
      categories: {
        data: data.payload.categories,
        loading: false,
        error: null,
      },
    }),

    [getCategoriesFailure]: (state, data) => ({ 
      ...state,
      categories: {
        data: null,
        loading: false,
        error: data,
      },
    }),

    [getProducts]: (state) => ({ 
      ...state,
      products: {
        data: null,
        loading: true,
        error: null,
      },
    }),

    [getProductsSuccess]: (state, data) => ({
      ...state,
      products: {
        data: data.payload.products,
        loading: false,
        error: null,
      },
    }),

    [getProductsFailure]: (state, data) => ({ 
      ...state,
      products: {
        data: null,
        loading: false,
        error: data.payload,
      },
    }),

    [getProductVariants]: (state) => {
      return ({ 
      ...state,
      productVariants: {
        ...state.productVariants,
        loading: true,
        error: null,
      },
    })},

    [getProductVariantsSuccess]: (state, data) => {
      return ({
      ...state,
      productVariants: {
        ...state.productVariants,
        data: {
          ...state.productVariants.data,
          [data.payload.productId]: data.payload.variants,
          loading: false,
        },
      },
    })},

    [getProductVariantsFailure]: (state, data) => ({ 
      ...state,
      productVariants: {
        ...state.productVariants,
        error: data.payload,
      },
    })
  },
  initialState,
)

export default catalogReducer;