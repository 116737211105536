import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ButtonIcon, Card, IconData, TabbedMenu } from 'app/components';
import { merchantDetailsSelector, merchantDetailsErrorsSelector } from 'app/store/selectors/merchant';
import DetailsView from './DetailsView';
// import SettingsView from './SettingsView';
import { ArrowLeft, Upc } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import './index.scss';

const tabs = [
  {
    path: 'details',
    label: 'Details',
    content: <DetailsView />
  },
  // {
  //   path: 'settings',
  //   label: 'Settings',
  //   content: <SettingsView />
  // },
];

const MerchantDetails = () => {
  const { merchantId } = useParams();
  const navigate = useNavigate();
  const merchantDetails = useSelector(merchantDetailsSelector);
  const merchantDetailsErrorsFound = useSelector(merchantDetailsErrorsSelector);

  const displayHeader = () => {
    if (merchantDetailsErrorsFound) {
      return 'Merchant Details';
    }
    if (merchantId) {
      if (merchantDetails && merchantDetails.name) {
        return `${merchantDetails.name}`;
      } else {
        return (
          <span>&nbsp;</span>
        );
      }
    } else {
      return 'Create New Merchant';
    }
  }

  const filteredTabs = merchantDetails?.name ? tabs : tabs.filter(tab => tab.path !== 'settings');

  return (
    <div className="merchant-details">
      <Card className="header-card">
        <div className="merchant-name">
          <ButtonIcon icon={<ArrowLeft />} onClick={() => navigate('/admin/merchants')}  />
          {displayHeader()}
        </div>
        {merchantDetails && (
          <IconData label={merchantDetails.shortId || merchantDetails.vendorId} icon={<Upc />} /> 
        )}
      </Card>
      <TabbedMenu tabs={filteredTabs} showTabs={Boolean(merchantDetails)} merchantId={merchantId} />
    </div>
  )
}

export default MerchantDetails;