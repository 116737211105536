import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { vendorsDataSelector, vendorsLoadingSelector, vendorsErrorsSelector } from 'app/store/selectors/vendor';
import { getVendors, reset } from 'app/store/actions/vendor';
import { Card, Button, Table, TableHeader, Pagination, LoadingAnimation, StatusChip, SearchBar, Link } from 'app/components';
import { ExclamationCircle, } from 'react-bootstrap-icons';
import './index.scss';

const VendorsView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchString, setSearchString] = useState(null);
  const vendorsData = useSelector(vendorsDataSelector);
  const loading = useSelector(vendorsLoadingSelector);
  const errorsFound = useSelector(vendorsErrorsSelector);

  const [searchParams] = useSearchParams();
  const currentPage = parseInt(searchParams.get('page') || '1', 10);
  const pageSize = parseInt(searchParams.get('pageSize') || '50', 10);
  const sortBy = searchParams.get('sortBy') || 'ShortId';
  const sortDir = searchParams.get('sortDir') || 'desc';

  useEffect(() => {
    if (!searchString || searchString.length !== 1) {
      dispatch(getVendors({ searchString, currentPage, pageSize, sortBy, sortDir }));
    }
  }, [searchString, currentPage, pageSize, sortBy, sortDir, dispatch]);

  return (
    <div className="vendors-view">
      {loading && <LoadingAnimation />}
      {(vendorsData || errorsFound) && (
        <Card>
          <div className="vendors-header">
            Vendors
            <Button
              variant="primary"
              size="medium"
              label={"Create Vendor"}
              disabled={errorsFound}
              onClick={() => {
                dispatch(reset());
                navigate(`/admin/vendors/create-vendor`)
              }}
            />
          </div>
          <div className="search-bar-and-filters">
            <SearchBar
              searchPlaceholderText="Search for a Vendor"
              onSearchStringUpdated={setSearchString}
              debounceDelay={500}
              disabled={errorsFound}
            />
            {/* <div className="filter-field" onClick={ () => console.log("Not implemented yet") }>
              <div
                className="filter-name"
              >
                Filters
              </div>
              <ButtonIcon
                icon={<ChevronDown />}
              />
            </div> */}
          </div>

          <Table size="medium">
            <TableHeader
              sortBy={sortBy}
              sortDir={sortDir}
              options={[
                { id: 'ShortId', label: 'Vendor ID', orderable: true },
                { id: 'Name', label: 'Vendor Name', orderable: true },
                { id: 'NumLocations', label: '# Of Locations' },
                { id: 'Status', label: 'Status', align: 'center' },
              ]}
            />
            <tbody className="table-body">
              {vendorsData?.vendors?.map((vendor) => (
                <tr className="vendor-row" key={vendor.shortId || vendor.id}>
                  <td>
                    <Link label={vendor.shortId || vendor.id} onClick={() => {
                      dispatch(reset());
                      navigate(`/admin/vendors/${vendor.id || vendor.shortId}`)
                    }} />
                  </td>
                  <td>{vendor.name}</td>
                  <td>{vendor.facilities.length}</td>
                  <td className="vendor-status-chip"><StatusChip status="active" /></td>
                </tr>
              ))}
              {vendorsData?.total === 0 && !loading && (
                <tr>
                  <td colSpan="6" className="error-message">No results found</td>
                </tr>
              )}
              {errorsFound && !loading && (
                <tr>
                  <td colSpan="6" className="error-message"><ExclamationCircle />Error fetching vendor data</td>
                </tr>
              )}
            </tbody>
          </Table>
          {vendorsData && (
            <Pagination totalItems={vendorsData?.total || 0} />
          )}
        </Card>
      )}
    </div>
  )
}

export default VendorsView;