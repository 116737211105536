import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { vendorProductDataSelector, vendorProductDataLoadingSelector, vendorProductDataErrorsSelector } from 'app/store/selectors/vendor';
import { getProductsByVendor } from 'app/store/actions/vendor';
import { Button, SearchBar, StatusChip, ButtonIcon, Card, Table, TableHeader, LoadingAnimation } from 'app/components';
import { ThreeDotsVertical, ExclamationCircle } from 'react-bootstrap-icons';
import { formatCurrency } from 'app/utils';
import './index.scss';

const FacilitiesView = props => {
  const { vendorId, facilityId } = props;

  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const loading = useSelector(vendorProductDataLoadingSelector);
  const productData = useSelector(vendorProductDataSelector);
  const errorsFound = useSelector(vendorProductDataErrorsSelector);

  const currentPage = parseInt(searchParams.get('page') || '1', 10);
  const pageSize = parseInt(searchParams.get('pageSize') || '25', 10);
  const searchString = searchParams.get('searchString') || null;

  useEffect(() => {
    dispatch(getProductsByVendor({ vendorId, facilityId, searchString, currentPage, pageSize }));
  }, [vendorId, searchString, currentPage, pageSize, dispatch]);

  const onSearchStringUpdated = (searchString) => {
    const updatedSearchParams = new URLSearchParams(searchParams.toString());
    updatedSearchParams.set('page', 1);
    searchString ? updatedSearchParams.set('searchString', searchString) : updatedSearchParams.delete('searchString');
    setSearchParams(updatedSearchParams.toString());
  }; 

  const productOptions = () => [
    // { value: 'Manage Inventory', label: 'Manage Inventory', onClick: () => { console.log("Not implemented yet") }},
    { value: 'Add Products', label: 'Add Products', onClick: () => { console.log("Not implemented yet") } },
    { value: 'Update Products', label: 'Update Products', onClick: () => console.log("Not implemented yet") },
  ];

  const products = [];

  productData?.vendorProducts?.forEach(product => {
    const variantId = product.variantId;
    const orderMeshSKU = product.sku;
    product.facilities.forEach(facility => {
      if (facility.id !== facilityId) return;
      const newObject = {
        vendorSKU: facility.vendorSKU,
        variantId: variantId,
        orderMeshSKU: orderMeshSKU,
        productCost: facility.productCost.amount,
        productionTimeDays: facility.productionTimeDays,
        status: facility.status
      };
      products.push(newObject);
    });
  });

  return (
    <div className="facility-products-view">
      {loading && <LoadingAnimation />}
      {(productData || errorsFound) && (
        <Card className="vendor-products-card">
          <Card.Header hideDivider>
            Facility Products
            {productData && !errorsFound && (
              <div className="product-options">
                <Button
                  variant="primary"
                  size="small"
                  label="Export to XLSX"
                  onClick={() => console.log('Create Vendor')}
                />
                <ButtonIcon
                  icon={<ThreeDotsVertical />}
                  options={productOptions()}
                />
              </div>
            )}
          </Card.Header>
          {!errorsFound && (
            <div className="search-bar-and-filters">
              <SearchBar
                searchPlaceholderText="Search for a Product"
                onSearchStringUpdated={onSearchStringUpdated}
                disabled={errorsFound || loading}
                debounceDelay={500}
              />
            </div>
          )}
          <Card.Body>
            <Table size="medium">
              <TableHeader
                options={[
                  { id: "vendorSku", label: "Vendor SKU" },
                  { id: "variantId", label: "Variant ID" },
                  { id: "orderMeshSku", label: "OrderMesh SKU" },
                  { id: "prodTime", label: "Production Time" },
                  { id: "cost", label: "Cost" },
                  { id: "status", label: "Status", align: 'center' },
                ]}
              />
              <tbody className="table-body">
                {products?.map((product) => (
                  <tr className="products-row" key={`${product.id}-${product.vendorSKU}-${product.variantId}`}>
                    <td>{product.vendorSKU}</td>
                    <td>{product.variantId}</td>
                    <td>{product.orderMeshSKU}</td>
                    <td>{product.productionTimeDays ? `${product.productionTimeDays} Days` : ''}</td>
                    <td>{formatCurrency('USD', product.productCost)}</td>
                    <td className="product-status-chip"><StatusChip status={product.status || 'Not Set'} type="product" /></td>
                  </tr>
                ))}
                {products?.length === 0 && (
                  <tr>
                    <td colSpan="6" className="no-product-data">{searchString ? 'No Matching Results Found' : 'No Products Found'}</td>
                  </tr>
                )}
                {errorsFound && (
                  <tr>
                    <td colSpan="6" className="no-product-data"><ExclamationCircle />Error fetching facility products</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      )}
    </div>
  )
}

export default FacilitiesView;