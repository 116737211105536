import axios from 'axios';
import Config from '../config';
import SetupInterceptors from './axiosInterceptors';

class CatalogServices {
  constructor() {
    this.api = axios.create({
      baseURL: Config.CatalogMS.Url
    });

    SetupInterceptors(this.api);
  }

  getCategories = async () => {
    try {
      const response = await this.api.get(`${Config.CatalogMS.Url}/v1/categories`);
      return response.data;
    } catch (error) {
      throw new Error('Error fetching catalog data');
    }
  };

  getProducts = async (currentPage, pageSize) => {
    try {
      const response = await this.api.get(`${Config.CatalogMS.Url}/v1/products?page=${currentPage}&pageSize=${pageSize}`);
      return response.data;
    } catch (error) {
      throw new Error('Error fetching product listing');
    }
  };

  getProductVariants = async (productId, currentPage, pageSize) => {
    try {
      //const response = await this.api.get(`/v2/products/${productId}/variants`);
      const response = await this.api.get(`${Config.CatalogMS.Url}/v1/products/${productId}/variants?page=${currentPage}&pageSize=${pageSize}`);
      return response.data;
    } catch (error) {
      throw new Error('Error fetching product variants');
    }
  };
}

export default new CatalogServices();