import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dropdown as BSDropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { QuestionCircleFill, XCircleFill, ChevronDown, Search } from 'react-bootstrap-icons';
import './index.scss';

const Dropdown = (props) => {
  const { label, placeholder, name, value, className, disabled, readonly, onChange, options, tooltip, allowClear, showErrorMessages, errorMessage, searchable } = props;
  const [search, setSearch] = useState('');
  const showError = errorMessage && errorMessage.trim().length > 0;

  const selectedOption = options.find((option) => String(option.value) === String(value));
  const filteredOptions = options.filter((option) =>  option.label.toString().toLowerCase().includes(search.toLowerCase()));

  const clearSelection = (event) => {
    event.stopPropagation();
    onChange({ target: { name, value: '' } });
  };

  return (
    <div className={`gooten-dropdown ${disabled ? 'disabled' : ''} ${readonly ? 'readonly' : ''} ${showError ? 'has-error' : ''} ${className}`}>
      {label && <label htmlFor={name}>{label}</label>}
      <BSDropdown className="gooten-dropdown">
        <BSDropdown.Toggle disabled={disabled} className={`${value ? '' : 'gooten-placeholder'} ${readonly ? 'readonly' : ''} ${showError ? 'has-error' : ''}`}>
          <span className="dropdown-button-label">{selectedOption?.label !== null && selectedOption?.label !== undefined ? String(selectedOption.label) : placeholder || 'Select an option'}</span>
          {allowClear && value && !readonly && (
            <XCircleFill className="clear-icon" onClick={clearSelection} />
          )}
          <ChevronDown />
        </BSDropdown.Toggle>
        {!readonly && (
          <BSDropdown.Menu style={{ maxHeight: '300px', overflowY: 'auto' }}>
            {searchable && (
              <div className="search-container">
                <Search className="search-icon" />
                <input
                  type="text"
                  className="dropdown-search"
                  placeholder="Search..."
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            )}
            {filteredOptions.length === 0 ? (
              <BSDropdown.Item className="no-matches" disabled>
                No matches found
              </BSDropdown.Item>
            ) : (
              filteredOptions.map((option) => (
                <BSDropdown.Item
                  key={option.value}
                  disabled={option.disabled}
                  className={option.destructive ? 'destructive' : ''}
                  onClick={() => onChange({ target: { name: props.name, label: option.label, value: option.value } })}
                >
                  {option.label}
                </BSDropdown.Item>
              ))
            )}
          </BSDropdown.Menu>
        )}
      </BSDropdown>
      {tooltip && (
        <OverlayTrigger
          key="top"
          placement="top"
          overlay={
            <Tooltip id={`tooltip-top`} style={{ maxWidth: 400 }}>
              <div dangerouslySetInnerHTML={{ __html: tooltip }} />
            </Tooltip>
          }
        >
          <QuestionCircleFill className={`question-icon ${!label ? 'no-label' : ''}`} />
        </OverlayTrigger>
      )}
      {showErrorMessages && (
        <div className="error">{showError && errorMessage}</div>
      )}
    </div>
  );
};

Dropdown.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  readonly: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  tooltip: PropTypes.string,
  allowClear: PropTypes.bool,
  showErrorMessages: PropTypes.bool,
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  searchable: PropTypes.bool,
};

Dropdown.defaultProps = {
  label: '',
  placeholder: 'Select an option',
  value: '',
  className: '',
  disabled: false,
  readonly: false,
  tooltip: null,
  allowClear: false,
  showErrorMessages: true,
  errorMessage: null,
  searchable: false,
};

export default Dropdown;