import { createSelector } from 'reselect';

const merchantSelector = state => state.merchant;

export const merchantsSelector = createSelector([merchantSelector], merchantStore => merchantStore.merchants.data);
export const merchantsLoadingSelector = createSelector([merchantSelector], merchantStore => merchantStore.merchants.loading);
export const merchantsErrorsSelector = createSelector([merchantSelector], merchantStore => merchantStore.merchants.error);

export const merchantDetailsSelector = createSelector([merchantSelector], merchantStore => merchantStore.merchant.data);
export const merchantDetailsLoadingSelector = createSelector([merchantSelector], merchantStore => merchantStore.merchant.loading);
export const merchantDetailsErrorsSelector = createSelector([merchantSelector], merchantStore => merchantStore.merchant.error);