import React from 'react';
import PropTypes from 'prop-types';
import { copyToClipboard } from 'app/utils';
import { Clipboard } from 'react-bootstrap-icons';
import './index.scss';

const IconData = props => {
  const { label, className, onClick, iconOffset, enableClipboardCopy, allowOverflow, icon, hoverIcon, maxWidth } = props;

  return (
    <div className={`icon-data ${className}`} style={{ maxWidth }}>
      <div className={`icon-data-value ${enableClipboardCopy ? 'allow-copy' : ''} ${allowOverflow ? 'allow-overflow' : ''}`}>
        <span
          className={`text-content ${onClick ? 'clickable' : ''}`}
          onClick={() => onClick ? onClick() : enableClipboardCopy ? copyToClipboard(label) : null}
        >
          <div className="icon-container">
            {React.cloneElement(icon, { 
              className: 'button-icon',
              style: { marginTop: iconOffset ? `${iconOffset}px` : undefined }
            })}
          </div>
          <div className="label">{label}</div>
        </span>
        {enableClipboardCopy && !onClick && (
          <div className="data-point-icon">
            {React.cloneElement(hoverIcon || <Clipboard />)}
          </div>
        )}
      </div>
    </div>
  )
}

IconData.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  onClick: PropTypes.func,
  iconOffset: PropTypes.number,
  enableClipboardCopy: PropTypes.bool,
  allowOverflow: PropTypes.bool,
  icon: PropTypes.element.isRequired,
  hoverIcon: PropTypes.element,
  maxWidth: PropTypes.string,
};

IconData.defaultProps = {
  label: '',
  className: '',
  data: '',
  onClick: null,
  iconOffset: undefined,
  enableClipboardCopy: false,
  allowOverflow: false,
  hoverIcon: null,
  maxWidth: null,
};

export default IconData;