import { put, takeEvery, call, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import {
  getWebhooks,
  getWebhooksSuccess,
  getWebhooksFailure,
  getWebhookDetails,
  getWebhookDetailsSuccess,
  getWebhookDetailsFailure,
  subscribeToWebhook,
  subscribeToWebhookSuccess,
  subscribeToWebhookFailure,
  updateWebhook,
  updateWebhookSuccess,
  updateWebhookFailure,
  deleteWebhookSubscription,
  deleteWebhookSubscriptionSuccess,
  deleteWebhookSubscriptionFailure,
  testWebhookSubscription,
  testWebhookSubscriptionSuccess,
  testWebhookSubscriptionFailure,
  getIntegrations,
  getIntegrationsSuccess,
  getIntegrationsFailure,
  getIntegrationCustomizations,
  getIntegrationCustomizationsSuccess,
  getIntegrationCustomizationsFailure,
  getIntegrationBasicInfo,
  getIntegrationBasicInfoSuccess,
  getIntegrationBasicInfoFailure,
  updateIntegrationBasicInfo,
  updateIntegrationBasicInfoSuccess,
  updateIntegrationBasicInfoFailure,
  createIntegration,
  createIntegrationSuccess,
  createIntegrationFailure,
  getIntegrationVersions,
  getIntegrationVersionsSuccess,
  getIntegrationVersionsFailure,
  getIntegrationDetails,
  getIntegrationDetailsSuccess,
  getIntegrationDetailsFailure,
  updateIntegration,
  updateIntegrationSuccess,
  updateIntegrationFailure,
  addCustomizationToIntegration,
  addCustomizationToIntegrationSuccess,
  addCustomizationToIntegrationFailure,
  publishIntegrationVersion,
  publishIntegrationVersionSuccess,
  publishIntegrationVersionFailure,
  deleteIntegration,
  deleteIntegrationSuccess,
  deleteIntegrationFailure,
  getEventTypes,
  getEventTypesSuccess,
  getEventTypesFailure,
} from "app/store/actions/notification"
import NotificationServices from 'app/services/notificationServices';
import VendorServices from 'app/services/vendorServices';
import TransformationServices from 'app/services/transformationServices';
import { getVendorsBulkSuccess } from '../actions/vendor';

function* fetchWebhooks(action) {
  const { subscriberId } = action.payload;
  try {
    const data = yield call([NotificationServices, NotificationServices.getWebhooks], subscriberId);
    yield put(getWebhooksSuccess(data));
  } catch (error) {
    console.error('error', error);
    yield put(getWebhooksFailure(error));
  }
}

function* fetchWebhookDetails(action) {
  const { webhookId } = action.payload;
  try {
    const data = yield call([NotificationServices, NotificationServices.getWebhookDetails], webhookId);
    yield put(getWebhookDetailsSuccess(data));
  } catch (error) {
    console.error('error', error);
    yield put(getWebhookDetailsFailure(error));
  }
}

function* doSubscribeToWebhook(action) {
  const { data, cb } = action.payload;

  try {
    const resp = yield call([NotificationServices, NotificationServices.subscribeToWebhook], data);
    if (resp.id) {
      yield put(subscribeToWebhookSuccess());
      toast.success("Webhook Subscription Successful", {
        theme: 'colored',
      });
      if (cb) cb();
    } else {
      throw (resp);
    }
  } catch (error) {
    console.error('error', error);
    yield put(subscribeToWebhookFailure(error));
    toast.error("Webhook Subscription Failed", {
      theme: 'colored',
    });
  }
}

function* doUpdateWebhook(action) {
  const { data, showToast = false, cb } = action.payload;

  try {
    yield call([NotificationServices, NotificationServices.updateWebhook], data);
    yield put(updateWebhookSuccess(data));
    if (showToast) {
      toast.success("Webhook Updated Successfully", {
        theme: 'colored',
      });
    }
    if (cb) cb();
  } catch (error) {
    console.error('error', error);
    yield put(updateWebhookFailure(error));
    if (showToast) {
      toast.error("Webhook Update Failed", {
        theme: 'colored',
      });
    }
  }
}

function* doDeleteWebhookSubscription(action) {
  const { webhookId, cb } = action.payload;

  try {
    yield call([NotificationServices, NotificationServices.deleteWebhookSubscription], webhookId);
    yield put(deleteWebhookSubscriptionSuccess());
    toast.success("Webhook Successfully Deleted", {
      theme: 'colored',
    });
    if (cb) cb();
  } catch (error) {
    console.error('error', error);
    yield put(deleteWebhookSubscriptionFailure(error));
    toast.error("Webhook Deletion Failed", {
      theme: 'colored',
    });
  }
}

function* doTestWebhookSubscription(action) {
  const { callbackUrl, callbackMethod, headerInfo, cb } = action.payload;

  const headers = headerInfo.reduce((acc, header) => {
    if (header.key && header.value) {
      acc[header.key] = header.value;
    }
    return acc;
  }, {});

  try {
    const resp = yield call([NotificationServices, NotificationServices.testWebhookSubscription], callbackUrl, callbackMethod, headers);
    // only 200 status codes are considered successful
    if (resp.status !== 200) {
      throw new Error(`Server responded with status: ${resp.status}`);
    }
    const message = `Webhook Test Successful<br/>HTTP Status Code : ${resp.status}`;
    toast.success(<div dangerouslySetInnerHTML={{ __html: message }} />, {
      theme: 'colored',
    });
    yield put(testWebhookSubscriptionSuccess(resp));
    if (cb) cb(true);
  } catch (error) {
    console.error("Webhook Test Error : ", error);

    let errorMessage = 'Webhook test failed.';
    if (error.response) {
      errorMessage += ` Server responded with status: ${error.response.status}.`;
    } else if (error.request) {
      errorMessage += ' No response received.';
    } else {
      errorMessage += ` Error message: ${error.message}.`;
    }

    if (error.message.includes('Network Error') || error.message.includes('CORS')) {
      errorMessage += ' This may be due to a network or CORS configuration error.';
    }
    toast.error(errorMessage, {
      theme: 'colored',
    });
    yield put(testWebhookSubscriptionFailure(error));
    if (cb) cb(false);
  }
}

function* fetchIntegrations(action) {
  const data = action.payload;

  try {
    const response = yield call([NotificationServices, NotificationServices.getIntegrations], data);
    const vendorIds = response.plugins.map(obj => obj.integratorId);
    const response2 = yield call([VendorServices, VendorServices.getVendorsBulk], vendorIds);
    yield put(getVendorsBulkSuccess(response2));
    yield put(getIntegrationsSuccess(response));
  } catch (error) {
    console.error('error', error);
    yield put(getIntegrationsFailure(error));
  }
}

function* fetchIntegrationCustomizations(action) {
  const { integrationDetails, cb } = action.payload;

  try {
    const events = integrationDetails?.details?.events;
    if (events) {
      for (const event of events) {
        const { requestTransformationId, responseTransformationId } = event;

        if (requestTransformationId) {
          const transformationRequest = yield call(
            [TransformationServices, TransformationServices.getTransformationDetails],
            requestTransformationId
          );
          event.requestTransformationData = transformationRequest;
        }

        if (responseTransformationId) {
          const transformationResponse = yield call(
            [TransformationServices, TransformationServices.getTransformationDetails],
            responseTransformationId
          );
          event.responseTransformationData = transformationResponse;
        }
      }
    }

    yield put(getIntegrationCustomizationsSuccess(integrationDetails));
    if (cb) cb();
  } catch (error) {
    console.error('error', error);
    yield put(getIntegrationCustomizationsFailure(error));
  }
}

function* fetchIntegrationBasicInfo(action) {
  const { pluginId } = action.payload;

  try {
    const resp = yield call([NotificationServices, NotificationServices.getIntegrationBasicInfo], pluginId);
    yield put(getIntegrationBasicInfoSuccess(resp));
  } catch (error) {
    console.error('error', error);
    yield put(getIntegrationBasicInfoFailure(error));
  }
}

function* doUpdateIntegrationBasicInfo(action) {
  const { pluginId, data, cb } = action.payload;

  try {
    const resp = yield call([NotificationServices, NotificationServices.updateIntegrationBasicInfo], pluginId, data);
    yield put(updateIntegrationBasicInfoSuccess(resp));
    toast.success("Integration Basic Info Updated Successfully", {
      theme: 'colored',
      autoClose: 3000,
    });
    if (cb) cb();
  } catch (error) {
    console.error('error', error);
    yield put(updateIntegrationBasicInfoFailure(error));
    toast.error("Integration Basic Info Update Failed", {
      theme: 'colored',
    });
  }
}

function* doCreateIntegration(action) {
  const { data, cb } = action.payload;

  try {
    const resp = yield call([NotificationServices, NotificationServices.createIntegration], data);
    yield put(createIntegrationSuccess(resp));
    toast.success("Integration Created Successfully", {
      theme: 'colored',
      autoClose: 3000,
    });
    if (cb) cb(resp?.plugin?.id);
  } catch (error) {
    console.error('error', error);
    yield put(createIntegrationFailure(error));
    toast.error("Integration Creation Failed", {
      theme: 'colored',
    });
  }
}

function* fetchIntegrationVersions(action) {
  const { pluginId, cb } = action.payload;

  function getHighestVersionId(data) {
    let majorVersions = [];
    let minorVersions = [];

    data.forEach(item => {
      if (item.isDraft) {
        minorVersions.push(item)
      } else {
        majorVersions.push(item);
      }
    });

    if (majorVersions.length > 0) {
      return majorVersions.sort((a, b) => b.name - a.name)[0].id;
    } else {
      return minorVersions.sort((a, b) => b.name - a.name)[0].id;
    }
  }

  try {
    // first get all the versions of the plugin
    const response = yield call([NotificationServices, NotificationServices.getIntegrationVersions], pluginId);

    // then get the basic info of the plugin
    const response2 = yield call([NotificationServices, NotificationServices.getIntegrationBasicInfo], pluginId);

    // find the highest major version.  If no major version is found, get the highest minor version
    const highestVersionId = response?.pluginVersions?.length > 0 ? getHighestVersionId(response.pluginVersions) : null;

    // if there is a highest major version, get the details of that version, otherwise justy set the integrationb details to null
    if (!highestVersionId) {
      yield put(getIntegrationDetailsSuccess({ pluginVersion: {} }));
    } else {
      // make an api call to get the details of the highest major version
      const response3 = yield call([NotificationServices, NotificationServices.getIntegrationDetails], pluginId, highestVersionId);
      yield put(getIntegrationDetailsSuccess(response3));
    }
    yield put(getIntegrationVersionsSuccess(response));
    yield put(getIntegrationBasicInfoSuccess(response2));
    if (cb) cb(highestVersionId);
  } catch (error) {
    console.error('error', error);
    yield put(getIntegrationVersionsFailure(error));
  }
}

function* fetchIntegrationDetails(action) {
  const { pluginId, pluginVersionId, cb } = action.payload;

  try {
    const response = yield call([NotificationServices, NotificationServices.getIntegrationDetails], pluginId, pluginVersionId);
    yield put(getIntegrationDetailsSuccess(response));
    if (cb) cb();
  } catch (error) {
    console.error('error', error);
    yield put(getIntegrationDetailsFailure(error));
  }
}

function* doUpdateIntegration(action) {
  const { pluginId, pluginVersionId, currentVersion, data, requestCodeSnippet, responseCodeSnippet, integratorId, eventIndex, cb } = action.payload;
  let updatedPluginVersionId = pluginVersionId ? { id: pluginVersionId } : null;

  // if there is an eventIndex, then we are updating an event.  We need to create new transformation versions for both the request and response transformations
  if (eventIndex >= 0) {
    const requestData = {
      "code": requestCodeSnippet,
      "creatorId": integratorId,
      "creatorType": "Vendor",
      "topic": "ShipmentCreated",
      "transformationType": "inbound",
      "entityId": integratorId,
    }
    
    const transformationRequest = yield call([TransformationServices, TransformationServices.createTransformation], requestData); 
    // update the event with the new transformation id
    data.events[eventIndex].requestTransformationId = transformationRequest.transformationId;
    // now promote the transformation to a published version
    yield call([TransformationServices, TransformationServices.promoteTransformation], transformationRequest);

    const responseData = {
      "code": responseCodeSnippet,
      "creatorId": integratorId,
      "creatorType": "Vendor",
      "topic": "ShipmentCreated",
      "transformationType": "outbound",
      "entityId": integratorId,
    }
    
    const transformationResponse = yield call([TransformationServices, TransformationServices.createTransformation], responseData); 
    // update the event with the new transformation id
    data.events[eventIndex].responseTransformationId = transformationResponse.transformationId;
    // now promote the transformation to a published version
    yield call([TransformationServices, TransformationServices.promoteTransformation], transformationResponse);
  }

  let response = null;
  try {
    // if we have a pluginVersionId, then we are updating an existing version, otherwise we are creating a new version  
    if (pluginVersionId) {
      // if this plugin version is a major version, then we need to create a new version
      if (currentVersion?.name?.includes('.0')) {
        const resp = yield call([NotificationServices, NotificationServices.cloneIntegrationVersion], pluginId, pluginVersionId);
        response = yield call([NotificationServices, NotificationServices.updateExistingIntegrationVersion], pluginId, resp.pluginVersion.id, data);
        updatedPluginVersionId = response.pluginVersion.id;
      } else {
        response = yield call([NotificationServices, NotificationServices.updateExistingIntegrationVersion], pluginId, pluginVersionId, data);
        updatedPluginVersionId = response.pluginVersion.id
      }
    } else {
      response = yield call([NotificationServices, NotificationServices.createInitialIntegrationVersion], pluginId, data);
      updatedPluginVersionId = response.pluginVersion.id;
    }
    
    // we also need to get the versions of the plugin
    const versions = yield call([NotificationServices, NotificationServices.getIntegrationVersions], pluginId);
    yield put(updateIntegrationSuccess(response));
    yield put(getIntegrationVersionsSuccess(versions));

    toast.success("Integration Updated Successfully", {
      theme: 'colored',
      autoClose: 1500,
    });
    if (cb) cb(updatedPluginVersionId);
  } catch (error) {
    console.error('error', error);
    yield put(updateIntegrationFailure(error));
    toast.error("Integration Update Failed", {
      theme: 'colored',
    });
  }
}

function* doAddCustomizationToIntegration(action) {
  const { data, webhookDetails, cb } = action.payload;

  try {
    // first create the transformation
    const response = yield call([TransformationServices, TransformationServices.createTransformation], data);
    // locate the event that matches the topic of the transformation and add the transformation id to the event
    const eventIndex = webhookDetails?.draft?.events?.findIndex(event => event.eventType === data.topic);
    if (eventIndex >= 0) {
      // the event was found.  We need to update the transformationId
      webhookDetails.draft.events[eventIndex].transformationId = response.transformationId;  
    } else if (!webhookDetails?.draft) {
      // this is a new webhook.  We need to create the draft object (which contains the same content as webhookDetails)
      webhookDetails.draft = {
        ...webhookDetails,
        events: [
          {
            eventType: data.topic,
            transformationId: response.transformationId,
          }
        ]
      };
    } else {
      // the event was not found (but the draft object does exist).  We need to add a new event to the draft object
      webhookDetails.draft.events.push({
        eventType: data.topic,
        transformationId: response.transformationId,
      });
    }
    // now update the webhook with the new event
    yield call([NotificationServices, NotificationServices.updateWebhook], webhookDetails);
    yield put(addCustomizationToIntegrationSuccess());
    toast.success("Customization Added Successfully", {
      theme: 'colored',
      autoClose: 3000,
    });
    if (cb) cb(response);
  } catch (error) {
    console.error('error', error);
    yield put(addCustomizationToIntegrationFailure(error));
    toast.error("Customization Addition Failed", {
      theme: 'colored',
    });
  }
}

function* doPublishIntegrationVersion(action) {
  const { pluginId, pluginVersionId, cb } = action.payload;

  try {
    yield call([NotificationServices, NotificationServices.publishIntegrationVersion], pluginId, pluginVersionId);
    // get all the versions of the plugin
    const response = yield call([NotificationServices, NotificationServices.getIntegrationVersions], pluginId);
    // then load the details of the highest major version
    const highestVersionId = response?.pluginVersions?.length > 0 ? response.pluginVersions.sort((a, b) => b.name - a.name)[0].id : null;
    const response2 = yield call([NotificationServices, NotificationServices.getIntegrationDetails], pluginId, highestVersionId);
    yield put(getIntegrationDetailsSuccess(response2));

    yield put(publishIntegrationVersionSuccess());
    toast.success("Integration Version Published Successfully", {
      theme: 'colored',
      autoClose: 3000,
    });
    if (cb) cb(highestVersionId);
  } catch (error) {
    console.error('error', error);
    yield put(publishIntegrationVersionFailure(error));
    toast.error("Integration Version Publish Failed", {
      theme: 'colored',
    });
  }
}

function* doDeleteIntegration(action) {
  const { pluginId, cb } = action.payload;

  try {
    yield call([NotificationServices, NotificationServices.deleteIntegration], pluginId);
    yield put(deleteIntegrationSuccess());

    const response = yield call([NotificationServices, NotificationServices.getIntegrations]);
    yield put(getIntegrationsSuccess(response));

    toast.success("Integration Deleted Successfully", {
      theme: 'colored',
      autoClose: 3000,
    });
    if (cb) cb();
  } catch (error) {
    console.error('error', error);
    yield put(deleteIntegrationFailure(error));
    toast.error("Integration Deletion Failed", {
      theme: 'colored',
    });
  }
}

function* fetchEventTypes() {
  try {
    const response = yield call([NotificationServices, NotificationServices.getEventTypes]);
    yield put(getEventTypesSuccess(response));
  } catch (error) {
    console.error('error', error);
    yield put(getEventTypesFailure(error));
  }
}

function* watchData() {
  yield takeEvery(getWebhooks.toString(), fetchWebhooks);
  yield takeEvery(getWebhookDetails.toString(), fetchWebhookDetails);
  yield takeEvery(subscribeToWebhook.toString(), doSubscribeToWebhook);
  yield takeEvery(updateWebhook.toString(), doUpdateWebhook);
  yield takeEvery(deleteWebhookSubscription.toString(), doDeleteWebhookSubscription);
  yield takeEvery(testWebhookSubscription.toString(), doTestWebhookSubscription);
  yield takeEvery(getIntegrations.toString(), fetchIntegrations);
  yield takeEvery(getIntegrationCustomizations.toString(), fetchIntegrationCustomizations);
  yield takeEvery(getIntegrationBasicInfo.toString(), fetchIntegrationBasicInfo);
  yield takeEvery(updateIntegrationBasicInfo.toString(), doUpdateIntegrationBasicInfo);
  yield takeEvery(createIntegration.toString(), doCreateIntegration);
  yield takeEvery(getIntegrationVersions.toString(), fetchIntegrationVersions);
  yield takeEvery(getIntegrationDetails.toString(), fetchIntegrationDetails);
  yield takeEvery(updateIntegration.toString(), doUpdateIntegration);
  yield takeEvery(addCustomizationToIntegration.toString(), doAddCustomizationToIntegration);
  yield takeEvery(publishIntegrationVersion.toString(), doPublishIntegrationVersion);
  yield takeEvery(deleteIntegration.toString(), doDeleteIntegration);
  yield takeEvery(getEventTypes.toString(), fetchEventTypes);
  
}

export default function* rootSaga() {
  yield all([
    watchData(),
  ]);
}