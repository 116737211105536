const OrderItemStatusDetails = {
  'open': {
    color: '#15602B', // dark green
    bgColor: 'rgba(29, 177, 72, 0.10)', // light green
    borderColor: 'rgba(29, 177, 72, 0.10)', // light green
    displayName: 'Open',
    description: 'Order and items were created and ready for next steps.'
  },
  'imageerror': {
    color: '#821218', // dark red
    bgColor: 'rgba(218, 30, 40, 0.10)', // light red
    borderColor: 'rgba(218, 30, 40, 0.10)', // light red
    displayName: 'Image Error',
    description: 'Image Error is raised when the image associated with an item fails validation (similar to Image Issue).'
  },
  'canceled': {
    color: '#821218', // dark red
    bgColor: 'rgba(218, 30, 40, 0.10)', // light red
    borderColor: 'rgba(218, 30, 40, 0.10)', // light red
    displayName: 'Canceled',
    description: 'Order, shipment, or item was canceled.'
  },
  'fulfilled': {
    color: '#0E50B3', // dark blue
    bgColor: 'rgba(25, 153, 255, 0.10)', // light blue
    borderColor: 'rgba(25, 153, 255, 0.10)', // light blue
    displayName: 'Fulfilled',
    description: 'Shipped = Fulfilled; All shipments in the order are shipped; Shipment has been shipped by the vendor; Item has been shipped by the vendor.'
  }
};

export default OrderItemStatusDetails;