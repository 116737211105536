import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { orderDetailsSelector } from 'app/store/selectors/order';
import { shipmentsSelector } from 'app/store/selectors/shipment';
import UnassignedItemsCard from './Cards/UnassignedItemsCard';
import DetailsCard from './Cards/DetailsCard';
import ShipmentCard from './Cards/ShipmentCard';
import CancelShipmentModal from './Modals/CancelShipmentModal';
import CancelOrderItemModal from './Modals/CancelOrderItemModal';
import RerouteOrderItemModal from './Modals/RerouteOrderItemModal';
import FullImagePreview from './Modals/FullImagePreview';
import defaultCarrierLabel from 'app/assets/images/defaultCarrierLabel.png';
import './index.scss';

const SummaryView = () => {
  const orderDetails = useSelector(orderDetailsSelector);
  const shipments = useSelector(shipmentsSelector);

  const [menuState, setMenuState] = useState({});
  const [changeVendorModal, showChangeVendorModal] = useState(null);
  const [cancelShipmentModal, showCancelShipmentModal] = useState(null);
  const [cancelOrderItemModal, showCancelOrderItemModal] = useState(null);
  const [carrierLabelModal, showCarrierLabelModal] = useState(null);

  useEffect(() => {
    // initialize menu state for shipments with multiple items (if more than 5 items, default to collapsed)
    if (shipments) {
      const updatedMenuState = { ...menuState };
      
      shipments.forEach((shipment) => {
        const itemsCount = shipment.packages.reduce((total, pkg) => total + pkg.orderItemIDs.length, 0);
        if (itemsCount > 5) {
          updatedMenuState[shipment.shortId] = true;
        }
      });
      setMenuState(updatedMenuState);
    }
  }, [shipments]);

  const toggleMenu = (menuId) => {
    setMenuState((prevState) => ({
      ...prevState,
      [menuId]: !prevState[menuId]
    }));
  };

  const { parsedShipments, unmatchedOrderItems } = useMemo(() => {
    if (!orderDetails || !shipments) {
      return { parsedShipments: [], unmatchedOrderItems: [] };
    }
  
    const parsedShipments = [];
    // Create a copy of the order items.  We will look through this array to determine which items are not assigned to a shipment
    const unmatchedOrderItems = orderDetails.items.map(item => ({ ...item }));
  
    shipments
      // filter out archived and canceled shipments
      .filter(shipment => shipment.shipmentStatus !== 'Archived' && shipment.shipmentStatus !== 'Canceled')
      // then for each shipment
      .forEach((shipment) => {
        // create a copy of the shipment and remove the orderItems
        const shipmentWithItems = { ...shipment, orderItems: [] };
  
        // then for each shipment, loop over every orderItemID within it
        shipment.orderItems.forEach((shipmentItem) => {
          // find the orderItem in the orderDetails
          const orderItemIndex = unmatchedOrderItems.findIndex(orderItem => orderItem.id === shipmentItem.id);
          
          if (orderItemIndex !== -1) {
            const orderItem = unmatchedOrderItems[orderItemIndex];
            const quantityToMove = shipmentItem.quantity; 
  
            // reduce the quantity from unmatchedOrderItems
            orderItem.quantity -= quantityToMove;

            // remove the item if quantity is 0
            if (orderItem.quantity === 0) {
              unmatchedOrderItems.splice(orderItemIndex, 1);
            } else if (orderItem.quantity < 0) {
              console.error(`Order item ${orderItem.id} has negative quantity`);
            }

            // finally, add the orderItem to the shipment
            shipmentWithItems.orderItems.push({
              ...orderItem,
              quantity: quantityToMove
            });
          } else {
            console.error(`Order item ${shipmentItem.id} not found in order items`);
          }
        });
  
        parsedShipments.push(shipmentWithItems);
      });
  
    // if unmatchedOrderItems has more than items 5, collapse the card by default
    if (unmatchedOrderItems.length > 5) {
      setMenuState((prevState) => ({
        ...prevState,
        unassignedItems: true
      }));
    }
  
    return { parsedShipments, unmatchedOrderItems };
  }, [shipments, orderDetails]);
  
  return (
    <div className="summary-view">
      {orderDetails && shipments && (
        <div className="summary-cards">
          <DetailsCard orderDetails={orderDetails} shipments={shipments} />
          {unmatchedOrderItems.length > 0 && (
            <UnassignedItemsCard
              orderId={orderDetails.id} 
              orderItems={unmatchedOrderItems} 
              menuState={menuState} 
              toggleMenu={toggleMenu}
              showCancelOrderItemModal={data => showCancelOrderItemModal({ orderId: data.orderId, itemId: data.itemId })}
            />
          )}
          {parsedShipments.map((shipment) => (
            <ShipmentCard
              key={shipment.id}
              orderDetails={orderDetails}
              shipment={shipment}
              menuState={menuState}
              toggleMenu={toggleMenu}
              showCancelShipmentModal={data => showCancelShipmentModal(data)}
              showCancelOrderItemModal={data => showCancelOrderItemModal({ orderId: data.orderId, itemId: data.itemId })}
              showCarrierLabelModal={showCarrierLabelModal}
              showChangeVendorModal={data => showChangeVendorModal(data)}
            />  
          ))}
        </div>
      )}
      {carrierLabelModal && (
        <FullImagePreview
          imageUrl={defaultCarrierLabel}
          onClose={() => showCarrierLabelModal(false)}
        />
      )}
      {changeVendorModal && (
        <RerouteOrderItemModal
          shipmentId={changeVendorModal.shipmentId}
          shipmentShortId={changeVendorModal.shipmentShortId}
          itemInfo={changeVendorModal.itemInfo}
          showChangeVendorModal={showChangeVendorModal}
        />
      )}
      {cancelShipmentModal && (
        <CancelShipmentModal
          shipmentId={cancelShipmentModal}
          showCancelShipmentModal={showCancelShipmentModal}
        />
      )}
      {cancelOrderItemModal && (
        <CancelOrderItemModal
          orderId={cancelOrderItemModal.orderId}
          itemId={cancelOrderItemModal.itemId}
          showCancelOrderItemModal={showCancelOrderItemModal}
        />
      )}
    </div>
  )
}

export default SummaryView;