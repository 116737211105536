import React from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './index.scss';

const TabbedMenu = (props) => {
  const { tabs, showTabs, ...restProps }  = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  let activeTab = tabs.findIndex(tab => {
    const urlPath = location.pathname;
    const urlSearch = location.search;
  
    // split tab.path into pathname and search parts
    const [tabPath, tabSearch] = tab.path.split('?');
  
    if (tab.path === "") {
      // if tab.path is empty, match when there's no query string
      return urlSearch === "";
    } else {
      // compare pathname and search (query string) separately
      const pathMatch = urlPath.endsWith(tabPath) || tabPath === "";
      const searchMatch = urlSearch === `?${tabSearch}` || tabSearch === undefined;
  
      return pathMatch && searchMatch;
    }
  });
  
  activeTab = activeTab === -1 ? 0 : activeTab;
  
  return (
    <div className="gooten-tabbed-menu">
      {showTabs && (
        <div className="tabbed-menu__tabs">
          {tabs.map((tab, index) => (
            <div
              key={index}
              className={`tabbed-menu__tab ${activeTab === index ? 'active' : ''}`}
              onClick={() => navigate(tab.path)}
            >
              {t(tab.label)}
              <div className={`tabbed-menu__tab-underline ${activeTab === index ? 'animate' : ''}`}></div>
            </div>
          ))}
        </div>
      )}
      {tabs[activeTab].content && (
        <div className="tabbed-menu__content">{React.cloneElement(tabs[activeTab].content, { ...restProps })}</div>
      )}
    </div>
  );
}

TabbedMenu.propTypes = {
  tabs: PropTypes.array.isRequired,
  showTabs: PropTypes.bool,
  idName: PropTypes.string,
  idValue: PropTypes.string,
};

TabbedMenu.defaultProps = {
  showTabs: true,
  idName: null,
  idValue: null,
};

export default TabbedMenu;
