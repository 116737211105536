
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import { object, string } from 'yup';
import { roleDetailsSelector, roleDetailsLoadingSelector, roleDetailsErrorsSelector, resourcesDataSelector, resourcesErrorsSelector, resourcesLoadingSelector } from 'app/store/selectors/user';
import { getRoleDetails, createRole, updateRole, updateRoleName, getResources } from 'app/store/actions/user';
import { Button, Card, DataPoint, LoadingAnimation, Input, ButtonMenu, ButtonIcon, Checkbox } from 'app/components';
import { ArrowLeft, ExclamationCircle } from 'react-bootstrap-icons';
import './index.scss';
import { formatUsersPermissionsName, formatUsersResourceName, formatUsersRolesName } from '../utils';

const RoleDetails = () => {
    const { roleId } = useParams();

    const submitFormRef = useRef(null);
    const [editMode, setEditMode] = useState(true);
    const dispatch = useDispatch();

    const roleDetails = useSelector(roleDetailsSelector);
    const roleDetailsLoading = useSelector(roleDetailsLoadingSelector);
    const roleDetailsErrorsFound = useSelector(roleDetailsErrorsSelector);

    const resources = useSelector(resourcesDataSelector);
    const resourcesLoading = useSelector(resourcesLoadingSelector);
    const resourcesErrorsFound = useSelector(resourcesErrorsSelector);

    const navigate = useNavigate();

    useEffect(() => {
        if (roleId && roleDetails?.id !== roleId) {
            dispatch(getRoleDetails(roleId));
        } else {
            dispatch(getResources());
        }
    }, []);

    const displayHeader = () => {
        if (roleId) {
            return 'Edit Role';
        } else {
            return 'Create Role';
        }
    }

    const onRoleCreated = () => {
        navigate(`/admin/users/roles`);
    }

    const onRoleUpdated = () => {
        navigate(`/admin/users/roles`);
    }

    if (roleDetailsErrorsFound || resourcesErrorsFound) {
        return (
            <div className="role-details">
                {roleDetailsErrorsFound && <div className="data-load-failed"><ExclamationCircle />Role Data failed to load.  Refresh the page to try again.</div>}
                {resourcesErrorsFound && <div className="data-load-failed"><ExclamationCircle />Resources Data failed to load.  Refresh the page to try again.</div>}
            </div>
        )
    }
    return (
        <div className="role-details">
            {(roleDetailsLoading || resourcesLoading) && <LoadingAnimation />}
            <div className='gooten-card header-card'>
                <div className="role-name">
                    <ButtonIcon icon={<ArrowLeft />} onClick={() => navigate('/admin/users/roles')} />
                    {displayHeader()}
                </div>
                {!editMode && roleDetails ? (
                    <ButtonMenu
                        label="Role Options"
                        variant="primary"
                        size="mediu"
                        options={[
                            { value: 'Edit Role', label: 'Edit Role', onClick: () => setEditMode(true) },

                        ]}
                        width={148}
                    />
                ) : (
                    <>
                        <Button
                            variant="primary"
                            size="medium"
                            label={roleId ? 'Save Changes' : "Create Role"}
                            onClick={() => submitFormRef.current && submitFormRef.current.handleSubmit()}
                        />
                    </>
                )}
            </div>
            {(roleDetails || !roleId) && (
                <>
                    <Card className={`role-details-card ${editMode ? 'edit-mode' : ''}`}>
                        <Card.Header>
                            Role Details
                        </Card.Header>
                        <Card.Body>
                            {!editMode && roleDetails && (
                                <>
                                    <div className="role-details-inputs view-mode">
                                        <DataPoint title="Role Name" data={formatUsersRolesName(roleDetails?.name)} />
                                    </div>
                                </>
                            )}
                            {editMode && (
                                <Formik
                                    innerRef={submitFormRef}
                                    enableReinitialize
                                    initialValues={{
                                        roleId: roleDetails?.id || '',
                                        name: roleDetails?.name ? formatUsersRolesName(roleDetails?.name) : '',
                                        permissionsSet: roleDetails?.permissionSet || resources?.map(permission => ({
                                            ...permission, associatedPermissions: []
                                        })),
                                        permissions: roleDetails?.permissionSet?.map(permission => ({
                                            "resourceId": permission.resourceId,
                                            "permissions": permission.associatedPermissions,
                                        })) || {},
                                    }}
                                    validationSchema={() =>
                                        object().shape({
                                            name: string().required('Enter a valid name'), // required
                                        })
                                    }
                                    onSubmit={async (values, { setSubmitting }) => {
                                        setSubmitting(true);

                                        if (roleDetails) {
                                            // update existing role
                                            dispatch(updateRole({ roleId: roleDetails.id, values, cb: onRoleUpdated }));

                                            // update role name
                                            if (formatUsersRolesName(roleDetails?.name) !== values.name) {
                                                dispatch(updateRoleName({ roleId: roleDetails.id, name: values.name }));
                                            }
                                        } else {
                                            // create new role
                                            dispatch(createRole({ values, cb: onRoleCreated }));
                                        }
                                        setSubmitting(false);
                                    }}
                                >
                                    {({
                                        values,
                                        errors,
                                        handleChange,
                                        handleSubmit,
                                        setFieldValue,
                                        submitCount,
                                    }) => (
                                        <form onSubmit={handleSubmit}>
                                            <div className="role-details-inputs">
                                                <Input
                                                    label="Role Name"
                                                    name="name"
                                                    value={values.name}
                                                    onChange={handleChange}
                                                    placeholder="Name"
                                                    errorMessage={submitCount > 0 && errors.name}
                                                />
                                            </div>
                                            <div className="role-permissions-header" hidden={values.permissionsSet ? false : true}>
                                                <div>Select Permissions</div>
                                                <div>Add Permissions</div>
                                            </div>
                                            <div className="role-permissions-details">
                                                {values.permissionsSet?.map(permission => (
                                                    <div key={values.id + permission.resourceId}>
                                                        <div className="role-permissions-container">
                                                            <div className="role-permissions-title">{formatUsersResourceName(permission.resourceName)} </div>
                                                            <div className="role-permissions-checkbox">
                                                                <Checkbox size='medium' checked={permission.associatedPermissions?.length == permission.availablePermissions?.length}
                                                                    onChange={(e) => {
                                                                        if (e) {
                                                                            permission.associatedPermissions = permission.availablePermissions;
                                                                        } else {
                                                                            permission.associatedPermissions = [];
                                                                        }
                                                                        let permissions = values.permissionsSet?.map(permission => ({
                                                                            "resourceId": permission.resourceId,
                                                                            "permissions": permission.associatedPermissions,
                                                                        }));
                                                                        setFieldValue('permissions', permissions);
                                                                    }} />
                                                            </div>
                                                        </div>
                                                        <div className="role-permissions">
                                                            {permission?.availablePermissions?.map(available =>
                                                            (
                                                                <div key={values.id + permission.resourceId + available} className="role-permissions-line" >
                                                                    <div className="role-permissions-title">{formatUsersPermissionsName(available)}</div>
                                                                    <div className="role-permissions-checkbox">
                                                                        <Checkbox size='medium' checked={permission.associatedPermissions?.includes(available)} onChange={() => {
                                                                            if (permission.associatedPermissions?.includes(available)) {
                                                                                permission.associatedPermissions = permission.associatedPermissions?.filter(q => q !== available);
                                                                            } else {
                                                                                permission.associatedPermissions?.push(available);
                                                                            }
                                                                            let permissions = values.permissionsSet?.map(permission => ({
                                                                                "resourceId": permission.resourceId,
                                                                                "permissions": permission.associatedPermissions,
                                                                            }));
                                                                            setFieldValue('permissions', permissions);
                                                                        }} />
                                                                    </div>
                                                                </div>
                                                            )
                                                            )}</div>
                                                    </div>))}
                                            </div>
                                        </form>
                                    )}
                                </Formik>
                            )}
                        </Card.Body>
                    </Card>
                </>
            )}
        </div>
    )
}

export default RoleDetails;