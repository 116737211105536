import { put, takeEvery, call, all } from 'redux-saga/effects';
import { 
  getCategories,
  getCategoriesSuccess,
  getCategoriesFailure,
  getProducts,
  getProductsSuccess,
  getProductsFailure,
  getProductVariants,
  getProductVariantsSuccess,
  getProductVariantsFailure
} from "app/store/actions/catalog"
import CatalogServices from 'app/services/catalogServices';

function* fetchCategories() {
  try {
    const data = yield call([CatalogServices, CatalogServices.getCategories]);
    yield put(getCategoriesSuccess(data));
  } catch (error) {
    console.error('error', error);
    yield put(getCategoriesFailure(error));
  }
}

function* fetchProducts(action) {
  const { currentPage, pageSize } = action.payload;
  try {
    const data = yield call([CatalogServices, CatalogServices.getProducts], currentPage, pageSize);
    yield put(getProductsSuccess(data));
  } catch (error) {
    console.error('error', error);
    yield put(getProductsFailure(error));
  }
}

function* fetchProductVariants(action) {
  const { productId, currentPage, pageSize, cb } = action.payload;
  try {
    const data = yield call([CatalogServices, CatalogServices.getProductVariants], productId, currentPage, pageSize);
    data.productId = productId;
    yield put(getProductVariantsSuccess(data));

    if (cb) cb(data);
  } catch (error) {
    console.error('error', error);
    yield put(getProductVariantsFailure(error));
  }
}

function* watchData() {
  yield takeEvery(getCategories.toString(), fetchCategories);
  yield takeEvery(getProducts.toString(), fetchProducts);
  yield takeEvery(getProductVariants.toString(), fetchProductVariants);
}

export default function* rootSaga() {
  yield all([
    watchData(),
  ]);
}