import React from 'react';
import './index.scss';

const ImageModal = ({ imageUrl, onClose }) => {
  return (
    <div className="modal-overlay" onClick={onClose}>
      <img src={imageUrl} alt="Enlarged" />
    </div>
  );
};

export default ImageModal;