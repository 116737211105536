import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import { object, string } from 'yup';
import {
  userDetailsSelector, userDetailsLoadingSelector, userDetailsErrorsSelector, rolesWithPermissionsDataSelector, rolesWithPermissionsLoadingSelector, rolesWithPermissionsErrorsSelector, selectedGroupSelector, groupsSelector } from 'app/store/selectors/user';
import { getUserDetails, createUser, updateUser, getRolesWithPermissions, getGroups,setSelectedGroup } from 'app/store/actions/user';
import {
  merchantsSelector,
  merchantsLoadingSelector,
  merchantsErrorsSelector
} from 'app/store/selectors/merchant';
import { getMerchants } from 'app/store/actions/merchant';
import { vendorsDataSelector, vendorsLoadingSelector, vendorsErrorsSelector } from 'app/store/selectors/vendor';
import { getVendors } from 'app/store/actions/vendor';
import { Button, Card, DataPoint, LoadingAnimation, Input, Dropdown, ButtonMenu, ButtonIcon, Checkbox } from 'app/components';
import { ExclamationCircle, ArrowLeft, EyeFill, EyeSlashFill } from 'react-bootstrap-icons';
import { toast } from 'react-toastify';
import './index.scss';
import { formatUsersPermissionsName, formatUsersResourceName, formatUsersRolesName } from '../utils';
import { GroupsEnum } from 'app/constants/groups';

const UserDetails = () => {
  const { userId } = useParams();

  const submitFormRef = useRef(null);
  const [editMode, setEditMode] = useState(true);
  const dispatch = useDispatch();

  const userDetails = useSelector(userDetailsSelector);
  const userDetailsLoading = useSelector(userDetailsLoadingSelector);
  const userDetailsErrorsFound = useSelector(userDetailsErrorsSelector);

  const allMerchants = useSelector(merchantsSelector);
  const allMerchantsLoading = useSelector(merchantsLoadingSelector);
  const allMerchantsErrorsFound = useSelector(merchantsErrorsSelector);

  const allVendors = useSelector(vendorsDataSelector);
  const allVendorsLoading = useSelector(vendorsLoadingSelector);
  const allVendorsErrorsFound = useSelector(vendorsErrorsSelector);

  const allRolesWithPermissions = useSelector(rolesWithPermissionsDataSelector);
  const allRolesWithPermissionsLoading = useSelector(rolesWithPermissionsLoadingSelector);
  const allRolesWithPermissionsErrorsFound = useSelector(rolesWithPermissionsErrorsSelector);

  const selectedGroup = useSelector(selectedGroupSelector);
  const groups = useSelector(groupsSelector);

  const navigate = useNavigate();

  useEffect(() => {
    if (userId && userDetails?.id !== userId) {
      dispatch(getUserDetails(userId));
    }
    if (!allMerchants) {
      dispatch(getMerchants({ currentPage: 1, pageSize: 250 }));
    }
    if (!allVendors) {
      dispatch(getVendors({ currentPage: 1, pageSize: 250 }));
    }
    if(!groups){
      dispatch(getGroups());
    }
    dispatch(getRolesWithPermissions());
  }, []);

  useEffect(()=> {
    if(userId && userDetails){
      var group;
      if(userDetails?.attributes?.vendorIds?.length > 0)
        { group = groups.find(x=>x.name == GroupsEnum.Vendor)}
    
      if(userDetails?.attributes?.facilityIds?.length > 0)
        { group = groups.find(x=>x.name == GroupsEnum.VendorFacility)}

      if(userDetails?.attributes?.merchantIds?.length > 1)
        { group = groups.find(x=>x.name == GroupsEnum.ParentMerchant)}
    
      if(userDetails?.attributes?.merchantIds?.length == 1)
        { group = groups.find(x=>x.name == GroupsEnum.Merchant)}
      
      if(!group)
        { group = groups.find(x=>x.name == GroupsEnum.InternalOM)}
        dispatch(setSelectedGroup(group));
    }},[userDetails])

  const onUserCreated = () => {
    navigate(`/admin/users`);
  }

  const onUserUpdated = () => {
    navigate(`/admin/users`);
  }
  const [showPassword, setShowPassword] = useState(false);

  const displayHeader = () => {
    if (userId) {
      return 'Edit User';
    } else {
      return 'Create User';
    }
  }

  const childMerchantOptions = () => {
    if (!allMerchants) {
      return [{ value: '', label: '-' }];
    }

    const merchants = allMerchants.merchants
      .filter(
        merchant => (userDetails ? merchant.id !== userDetails.id : true) &&
          !merchant.parentId &&
          !merchant.isParent
      ).map(merchant => (
        { value: merchant.id, label: merchant.name }
      )
      );

    return merchants;
  }

  const childVendorOptions = () => {
    if (!allVendors) {
      return [{ value: '', label: '-' }];
    }

    const vendors = allVendors.vendors
      .filter(
        vendors => (userDetails ? vendors.vendorId !== userDetails.id : true) &&
          !vendors.parentId &&
          !vendors.isParent
      ).map(vendors => (
        { value: vendors.vendorId, label: vendors.name }
      )
      );
    return vendors;
  }

  const parentMerchantOptions = () => {
    if (!allMerchants) {
      return [{ value: '', label: '-' }];
    }
    const merchants = allMerchants.merchants.filter(merchant => merchant.isParent && !merchant.parentId && (userDetails ? merchant.id !== userDetails.id : true)).map(merchant => (
      { value: merchant.id, label: merchant.name }
    ));
    return merchants;
  }

  const vendorFacilitiesOptions = () => {
    if (!allVendors) {
      return [{ value: '', label: '-' }];
    }

    const facilities = allVendors?.vendors?.flatMap(ar => ar.facilities?.map(facility => ({ value: facility.id, label: facility.name ?? 'No Name' })));
    return facilities;
  }

  const onChangeVendorSelection = (e, handleChange, setFieldValue) => {
    const selectedVendor = allVendors?.vendors?.find(vendor => vendor.id === e.target.value);
    if (selectedVendor && selectedVendor.id !== '') {
      var vendorIdsToSave = [];
      vendorIdsToSave.push(selectedVendor.id);
      setFieldValue('vendorIds', vendorIdsToSave);
      setFieldValue('vendorIdAttribute', selectedVendor.id);
      setFieldValue('facilityIdAttribute', '');
      setFieldValue('merchantIdAttribute', '');
      setFieldValue('merchantParentIdAttribute', '');
      setFieldValue('merchantIds', []);
      setFieldValue('facilityIds', []);
    }
    handleChange(e);
  }

  const onChangeMerchantSelection = (e, handleChange, setFieldValue) => {
    const selectedMerchant = allMerchants?.merchants?.find(merchant => merchant.id === e.target.value);
    if (selectedMerchant && selectedMerchant.id !== '') {
      var merchantIdsToSave = [selectedMerchant.id];
      setFieldValue('merchantIdAttribute', selectedMerchant.id);
      setFieldValue('vendorIdAttribute', '');
      setFieldValue('facilityIdAttribute', '');
      setFieldValue('merchantParentIdAttribute', '');
      setFieldValue('merchantIds', merchantIdsToSave);
      setFieldValue('facilityIds', []);
      setFieldValue('vendorIds', []);
    }
    handleChange(e);
  }

  const onChangeParentMerchantSelection = (e, handleChange, setFieldValue) => {
    const selectedParentMerchant = allMerchants?.merchants?.find(merchant => merchant.id === e.target.value);
    if (selectedParentMerchant && selectedParentMerchant.id !== '') {
      var merchantIdsToSave = [];
      merchantIdsToSave.push(selectedParentMerchant.id);
      setFieldValue('merchantParentIdAttribute', selectedParentMerchant.id);
      setFieldValue('vendorIdAttribute', '');
      setFieldValue('facilityIdAttribute', '');
      setFieldValue('merchantIdAttribute', '');
      setFieldValue('facilityIds', []);
      setFieldValue('vendorIds', []);
      setFieldValue('merchantIds', merchantIdsToSave);
    }
    handleChange(e);
  }

  const onChangeFacilitySelection = (e, handleChange, setFieldValue) => {
    const selectedFacility = allVendors?.vendors?.flatMap(ar => ar.facilities?.map(facility => ({ id: facility.id, name: facility.name ?? 'No name' }))).find(facility => facility.id === e.target.value);
    if (selectedFacility && selectedFacility.id !== '') {
      var facilityIdsToSave = [];
      facilityIdsToSave.push(selectedFacility.id);
      setFieldValue('facilityIdAttribute', selectedFacility.id);
      setFieldValue('vendorIdAttribute', '');
      setFieldValue('merchantIdAttribute', '');
      setFieldValue('merchantParentIdAttribute', '');
      setFieldValue('merchantIds', []);
      setFieldValue('facilityIds', facilityIdsToSave);
      setFieldValue('vendorIds', []);
    }
    handleChange(e);
  }

  const displayEntityItemsSearchDropdown = (values, handleChange, setFieldValue) => {
    switch (selectedGroup?.name) {
      case GroupsEnum.Vendor:
        {
          if (allVendors) {

            return <Dropdown
              className='entity-dropdown'
              name={`vendorId`}
              value={values.vendorIdAttribute ?? ''}
              searchable={true}
              onChange={(e) => onChangeVendorSelection(e, handleChange, setFieldValue)}
              options={allVendors?.vendors?.map(vendor => (
                { value: vendor.id, label: vendor.name }
              ))}
              showErrorMessages={false}
              placeholder={`Choose ${selectedGroup.name}`}
            />
          }
          break;
        }
      case GroupsEnum.Merchant:
        {
          if (allMerchants) {

            return <Dropdown
              className='entity-dropdown'
              name={`merchantId`}
              value={values.merchantIdAttribute ?? ''}
              searchable={true}
              onChange={(e) => onChangeMerchantSelection(e, handleChange, setFieldValue)}
              options={allMerchants?.merchants?.filter(merchant => !merchant.isParent).map(merchant => (
                { value: merchant.id, label: merchant.name }
              ))}
              showErrorMessages={false}
              placeholder={`Choose ${selectedGroup.name}`}
            />
          }
          break;
        }
      case GroupsEnum.ParentMerchant:
        {
          if (allMerchants) {

            return <Dropdown
              className='entity-dropdown'
              name={`merchantParentId`}
              value={values.merchantParentIdAttribute ?? ''}
              searchable={true}
              onChange={(e) => onChangeParentMerchantSelection(e, handleChange, setFieldValue)}
              options={allMerchants?.merchants?.filter(merchant => merchant.isParent).map(merchant => (
                { value: merchant.id, label: merchant.name }
              ))}
              showErrorMessages={false}
              placeholder={`Choose ${selectedGroup.name}`}
            />
          }
          break;
        }
      case GroupsEnum.VendorFacility:
        {
          if (allVendors) {
            return <Dropdown
              className='entity-dropdown'
              name={`facilityId`}
              value={values.facilityIdAttribute ?? ''}
              searchable={true}
              onChange={(e) => onChangeFacilitySelection(e, handleChange, setFieldValue)}
              options={vendorFacilitiesOptions()}
              showErrorMessages={false}
              placeholder={`Choose a ${selectedGroup.name}`}
            />
          }
          break;
        }
    }
  }

  const displayAccountDetailValues = (values, setFieldValue, submitCount, errors) => {

    if (values.role === 'merchant_parent_admin') {
      return <Dropdown
        label="Parent Merchant"
        name="subAdminId"
        value={values.subAdminId}
        onChange={(e) => {
          const merchant = allMerchants.merchants.find(merchant => merchant.id.toString() === e.target.value);
          setFieldValue('subAdminId', merchant.id);
        }}
        options={parentMerchantOptions()}
        errorMessage={submitCount > 0 && errors.subAdminId}
      />
    }

    if (values.role === 'merchant_admin') {
      return <Dropdown
        label="Merchant"
        name="subAdminId"
        value={values.subAdminId}
        onChange={(e) => {
          const merchant = allMerchants.merchants.find(merchant => merchant.id.toString() === e.target.value);
          setFieldValue('subAdminId', merchant.id);
        }}
        options={childMerchantOptions()}
        errorMessage={submitCount > 0 && errors.subAdminId}
      />
    }

    if (values.role === 'vendor_admin') {
      return <Dropdown
        label="Vendor"
        name="subAdminId"
        value={values.subAdminId}
        onChange={(e) => {
          const vendor = allVendors.vendors.find(vendor => vendor.vendorId.toString() === e.target.value);
          setFieldValue('subAdminId', vendor.vendorId);
        }}
        options={childVendorOptions()}
        errorMessage={submitCount > 0 && errors.subAdminId}
      />
    }

    return <div></div>;
  }



  if (userDetailsErrorsFound || allMerchantsErrorsFound || allVendorsErrorsFound || allRolesWithPermissionsErrorsFound) {
    return (
      <div className="user-details">
        {userDetailsErrorsFound && <div className="data-load-failed"><ExclamationCircle />User Data failed to load.  Refresh the page to try again.</div>}
        {allMerchantsErrorsFound && <div className="data-load-failed"><ExclamationCircle />Merchant Data failed to load.  Refresh the page to try again.</div>}
        {allVendorsErrorsFound && <div className="data-load-failed"><ExclamationCircle />Vendor Data failed to load.  Refresh the page to try again.</div>}
        {allRolesWithPermissionsErrorsFound && <div className="data-load-failed"><ExclamationCircle />Roles Data failed to load.  Refresh the page to try again.</div>}
      </div>
    )
  }

  const displayAccountDetails = () => {
    const merchantIDs = userDetails.attributes?.merchantId;
    const vendorIDs = userDetails.attributes?.vendorId;
    const roles = userDetails?.roles?.map(r => formatUsersRolesName(r?.name)).join(', ');

    return <>
      <DataPoint title="Roles" data={roles || '-'} />
      {merchantIDs?.length && <DataPoint title={`Merchant ID${merchantIDs?.length == 1 ? '' : 's'}`} data={merchantIDs?.join(", ")} />}
      {vendorIDs?.length && <DataPoint title={`Vendor ID${vendorIDs?.length == 1 ? '' : 's'}`} data={vendorIDs?.join(", ")} />}
    </>
  }

  return (
    <div className="user-details">
      {!editMode && userDetails && (
        <>
          <div className="user-details-inputs view-mode">
            <DataPoint title="First Name" data={userDetails.firstName} />
            <DataPoint title="Last Name" data={userDetails.lastName} />
            <DataPoint title="Email" data={userDetails.email} />
          </div>
          <div className="account-details">
            <div className="account-details-header">
              Account Details
            </div>
            <div className="account-details-inputs">
              {displayAccountDetails()}
            </div>
          </div>
        </>
      )}
      {editMode && (
        <Formik
          innerRef={submitFormRef}
          enableReinitialize
          initialValues={{
            merchantParentIdAttribute: userDetails?.attributes?.merchantIds?.length > 1 ? userDetails?.attributes?.merchantIds[0] : '',
            merchantIdAttribute: userDetails?.attributes?.merchantIds?.length == 1 ? userDetails?.attributes?.merchantIds[0] : '',
            merchantIds: userDetails?.attributes?.merchantIds || [],
            vendorIds: userDetails?.attributes?.vendorIds || [],
            facilityIds: userDetails?.attributes?.facilityIds || [],
            vendorIdAttribute: userDetails?.attributes?.vendorIds?.length > 0 ? userDetails?.attributes?.vendorIds[0] : '',
            facilityIdAttribute: userDetails?.attributes?.facilityIds?.length > 0 ? userDetails?.attributes?.facilityIds[0] : '',
            initialAttributes: userDetails?.attributes || {},
            group: selectedGroup || {},
            firstName: userDetails?.firstName || '',
            lastName: userDetails?.lastName || '',
            email: userDetails?.email || '',
            password: userDetails?.password || '',
            parentId: userDetails?.parentId || '',
            merchantId: userDetails?.merchantId || '',
            vendorId: userDetails?.vendorId || '',
            initialRole: userDetails?.roles[0]?.id || '',
            role: userDetails?.roles[0]?.id || '',
            roles: userDetails?.roles[0]?.permissionSet?.map(permission => ({
              "resourceId": permission.resourceId,
              "permissions": permission.associatedPermissions,
            })) || {},
            roleAndPermissions: userDetails?.roles[0] || allRolesWithPermissions?.filter(role => role.id == userDetails?.roles[0]?.id)[0],
          }}
          validationSchema={() =>
            object().shape({
              firstName: string().required('Enter a valid First Name'), // required
              lastName: string().required('Enter a valid Last Name'), // required
              email: string()
                .matches(
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  'Enter a valid Email'
                ).required('Enter a valid Email'),
              password: string().when([], () => {
                return userId ? string().notRequired() : string().required('Enter a Password');
              }),
              role: string().required('Enter a Role'), // required
              subAdminId: string().when('role', {
                is: role =>
                  ['Parent Merchant Admin', 'Merchant Admin', 'Vendor Admin'].includes(
                    role
                  ),
                then: () => string().required(
                  'Required Field'
                ),
                otherwise: () => string().notRequired(),
              }),
            })
          }
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);

            if (userDetails) {
              // update existing user
              dispatch(updateUser({ userId: userDetails.id, values, cb: onUserUpdated }));
            } else {
              // create new user
              dispatch(createUser({ values, cb: onUserCreated }));
            }
            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            handleChange,
            handleSubmit,
            setFieldValue,
            submitCount,
          }) => (
            <form onSubmit={handleSubmit}>
              {(userDetailsLoading || allMerchantsLoading || allVendorsLoading || allRolesWithPermissionsLoading) && <LoadingAnimation />}
              <div className='gooten-card header-card'>
                <div className="user-name">
                  <ButtonIcon icon={<ArrowLeft />} onClick={() => navigate('/admin/users/')} />
                  <div>{displayHeader()} </div>
                  <div> {displayEntityItemsSearchDropdown(values, handleChange, setFieldValue)}</div>

                </div>
                {!editMode && userDetails ? (
                  <ButtonMenu
                    label="User Options"
                    variant="primary"
                    size="small"
                    options={[
                      { value: 'Edit User', label: 'Edit User', onClick: () => setEditMode(true) },
                      {
                        value: 'Deactivate User', label: 'Deactivate User', onClick: () => toast.error("Deactivate User Not Implemented Yet", {
                          theme: 'colored',
                        })
                      },
                      {
                        value: 'Reset Password', label: 'Reset Password', onClick: () => toast.error("Reset Password Not Implemented Yet", {
                          theme: 'colored',
                        })
                      },
                      {
                        value: 'Delete User', label: 'Delete User', destructive: true, onClick: () => toast.error("Delete User Not Implemented Yet", {
                          theme: 'colored',
                        })
                      },
                    ]}
                    width={148}
                  />
                ) : (
                  <>
                    <Button
                      variant="primary"
                      size="medium"
                      label={userId ? 'Save Changes' : "Create User"}
                      onClick={() => submitFormRef.current && submitFormRef.current.handleSubmit()}
                    />
                  </>
                )}
              </div>
              {(userDetails || !userId) && (
                <>
                  <Card className={`user-details-card ${editMode ? 'edit-mode' : ''}`}>
                    <Card.Header>
                      User Details
                    </Card.Header>
                    <Card.Body>

                      <div className="user-details-inputs">
                        <Input
                          label="First Name"
                          name="firstName"
                          value={values.firstName}
                          onChange={handleChange}
                          placeholder="First Name"
                          errorMessage={submitCount > 0 && errors.firstName}
                        />
                        <Input
                          label="Last Name"
                          name="lastName"
                          value={values.lastName}
                          onChange={handleChange}
                          placeholder="Last Name"
                          errorMessage={submitCount > 0 && errors.lastName}
                        />
                        <Input
                          label="Email"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          placeholder="Email"
                          errorMessage={submitCount > 0 && errors.email}
                        />
                        {!userId && (
                          <Input
                            label="Password"
                            name="password"
                            value={values.password}
                            onChange={handleChange}
                            placeholder="Password"
                            type={showPassword ? "text" : "password"}
                            icon={showPassword ? <EyeSlashFill /> : <EyeFill />}
                            onIconClick={() => {
                              setShowPassword(!showPassword);
                            }}
                            errorMessage={submitCount > 0 && errors.password}
                          />
                        )}
                      </div>
                      <div className="account-details">
                        <div className="account-details-header">
                          Account Details
                        </div>
                        <div className="account-details-inputs">
                          <Dropdown
                            label="Role"
                            name="role"
                            value={values.role}
                            onChange={(e) => {
                              let roleAndPermissions = allRolesWithPermissions?.filter(role => role.id == e.target.value.toString())[0];
                              setFieldValue('roleAndPermissions', roleAndPermissions);
                              setFieldValue('role', roleAndPermissions?.id);
                              let roles = roleAndPermissions?.permissionSet?.map(permission => ({
                                "resourceId": permission.resourceId,
                                "permissions": permission.associatedPermissions,
                              }));
                              setFieldValue('roles', roles);
                              handleChange(e);
                            }}
                            options={allRolesWithPermissions?.map(role => ({ value: role.id, label: formatUsersRolesName(role?.name) })) || []}
                            errorMessage={submitCount > 0 && errors.role}
                          />
                          {displayAccountDetailValues(values, setFieldValue, submitCount, errors)}
                        </div>
                        <div className="account-permissions-header" hidden={values.role ? false : true}>
                          <div>Select Permissions</div>
                          <div>Add Permissions</div>
                        </div>
                        <div className="account-permissions-details">
                          {values.roleAndPermissions?.permissionSet?.map(permission => (
                            <div key={values.roleAndPermissions?.id + permission.resourceId}>
                              <div className="account-permissions-container">
                                <div className="account-permissions-title">{formatUsersResourceName(permission.resourceName)} </div>
                                <div className="account-permissions-checkbox">
                                  <Checkbox size='medium' checked={permission.associatedPermissions.length == permission.availablePermissions.length}
                                    onChange={(e) => {
                                      if (e) {
                                        permission.associatedPermissions = permission.availablePermissions;
                                      } else {
                                        permission.associatedPermissions = [];
                                      }
                                      let roles = values.roleAndPermissions?.permissionSet?.map(permission => ({
                                        "resourceId": permission.resourceId,
                                        "permissions": permission.associatedPermissions,
                                      }));
                                      setFieldValue('roles', roles);
                                    }} />
                                </div>
                              </div>
                              <div className="account-permissions">
                                {permission?.availablePermissions?.map(available =>
                                (
                                  <div key={values.roleAndPermissions?.id + permission.resourceId + available} className="account-permissions-line" >
                                    <div className="account-permissions-title">{formatUsersPermissionsName(available)}</div>
                                    <div className="account-permissions-checkbox">
                                      <Checkbox size='medium' checked={permission.associatedPermissions.includes(available)} onChange={() => {
                                        if (permission.associatedPermissions.includes(available)) {
                                          permission.associatedPermissions = permission.associatedPermissions.filter(q => q !== available);
                                        } else {
                                          permission.associatedPermissions.push(available);
                                        }
                                        let roles = values.roleAndPermissions?.permissionSet?.map(permission => ({
                                          "resourceId": permission.resourceId,
                                          "permissions": permission.associatedPermissions,
                                        }));
                                        setFieldValue('roles', roles);
                                      }} />
                                    </div>
                                  </div>
                                )
                                )}</div>
                            </div>))}
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </>
              )}
            </form>
          )}
        </Formik>
      )}
    </div>
  )
}

export default UserDetails;