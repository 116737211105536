import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ButtonMenu, TabbedMenu, Modal, StatusChip, Card, ButtonIcon, IconData, LoadingAnimation } from 'app/components';
import SummaryView from './SummaryView';
import HistoryView from './HistoryView';
import HistoryCard from './HistoryCard';
// import OrderNotes from './OrderNotes';
import CustomerDetails from './CustomerDetails';
import ShippingAddress from './ShippingAddress';
import PaymentInformation from './PaymentInformation';
import OrderMessageCard from './OrderMessageCard';
import ItemDetailsView from './ItemDetailsView';
import { orderDetailsSelector, orderDetailsLoadingSelector } from 'app/store/selectors/order';
import { shipmentsLoadingSelector } from 'app/store/selectors/shipment';
import { latestHistoryLoadingSelector } from 'app/store/selectors/history';
import { getOrderDetails, reset as resetOrderData, cancelOrder } from 'app/store/actions/order';
import { reset as resetMerchantData } from 'app/store/actions/merchant';
import { getLatestHistory, reset as resetHistoryData } from 'app/store/actions/history';
import { getShipmentsByOrderId } from 'app/store/actions/shipment';
import { ArrowLeft, Upc, Calendar2Event, Truck } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import { capitalizeFirstLetter } from 'app/utils';
import moment from 'moment';
import './index.scss';

const tabs = [
  {
    path: 'summary',
    label: 'Order Details',
    content: <SummaryView />
  },
  {
    path: 'item-details',
    label: 'Item Details',
    content: <ItemDetailsView />
  },
  {
    path: 'history',
    label: 'History',
    content: <HistoryView />
  },
];

const OrderDetails = () => {
  const [cancelOrderModal, showCancelOrderModal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { orderId } = useParams();
  const orderDetails = useSelector(orderDetailsSelector);
  const orderDetailsLoading = useSelector(orderDetailsLoadingSelector);
  const shipmentsLoading = useSelector(shipmentsLoadingSelector);
  const historyLoading = useSelector(latestHistoryLoadingSelector);

  useEffect(() => {
    if (orderDetails?.id !== orderId) {
      dispatch(getOrderDetails(orderId));
      dispatch(getShipmentsByOrderId(orderId));
      dispatch(getLatestHistory({parentType: 'Order', parentId: orderId, entityType: '', page: 1, pageSize: 5, sortAscending: 'desc'}));
    }

    return () => {
      dispatch(resetOrderData());
      dispatch(resetMerchantData());
      dispatch(resetHistoryData());
    };
  }, [dispatch]);

  const orderOptions = () => {
    return [
      // { value: 'Duplicate Order', label: 'Duplicate Order', onClick: () => { console.log("Not implemented yet") }},
      { value: 'Refund', label: 'Refund', onClick: () => { console.log("Not implemented yet") }},
      // { value: 'Reprint', label: 'Reprint', onClick: () => { console.log("Not implemented yet") }},
      { value: 'Cancel Order', label: 'Cancel Order', destructive: true, onClick: () => { showCancelOrderModal(true) }},
    ];
  }

  return (
    <div className="order-details">
      {(orderDetailsLoading || shipmentsLoading || historyLoading) && <LoadingAnimation />}
      {orderDetails && (
        <>
          <div className="left-panel">
            <Card className="order-details-header">
              {orderDetails?.merchantName && (
                <div className="merchant-name">
                  {orderDetails?.merchantName || '\u00A0'}
                </div>
              )}
              <div className="order-num-container">
                <div className="order-num-group">
                  <ButtonIcon icon={<ArrowLeft />} onClick={() => navigate('/orders')}  />
                  <div className="order-num">
                    Order #{orderDetails?.shortId}
                  </div>
                  {orderDetails && <StatusChip type="order" status={orderDetails.status} />}
                </div>
                <div className="order-options-group">
                  {/* <ButtonIcon icon={<EyeFill />} onClick={() => console.log("Not implemented yet")}  /> */}
                  <ButtonMenu
                    label="Order Options"
                    variant="primary"
                    size="small"
                    options={orderOptions()}
                    width={145}
                  />
                </div>
              </div>
              <div className="extra-details-container">
                {orderDetails?.externalId && (
                  <IconData label={orderDetails.externalId} icon={<Upc />} /> 
                )}
                {orderDetails?.createdAt && (
                  <IconData label={moment(orderDetails.createdAt).format('MMM DD, YYYY h:mm a Z')} icon={<Calendar2Event />} /> 
                )}
                {orderDetails?.customerShippingMethod && (
                  <IconData label={capitalizeFirstLetter(orderDetails.customerShippingMethod)} icon={<Truck />} /> 
                )}
              </div>
              <OrderMessageCard orderDetails={orderDetails} />
            </Card>
            <TabbedMenu tabs={tabs} orderId={orderId} />
            {cancelOrderModal && (
              <Modal
                secondaryButtonLabel="No, Keep It"
                secondaryButtonOnClick={() => { showCancelOrderModal(false)}}
                primaryButtonLabel="Yes, Cancel It"
                primaryButtonVariant="primary"
                primaryButtonDestructive
                primaryButtonOnClick={() => {
                  dispatch(cancelOrder({orderId}));
                  showCancelOrderModal(false);
                }}
                onClose={() => showCancelOrderModal(false)}
              >
                <div>Are you sure you want to cancel this order?</div>
                <div>This action is not reversible.</div>
              </Modal>
            )}
          </div>
          <div className="right-panel">
            <HistoryCard />
            {/* <OrderNotes /> */}
            {orderDetails && <CustomerDetails orderId={orderId} shippingAddress={orderDetails.shippingAddress} />}
            {orderDetails && <ShippingAddress orderId={orderId} shippingAddress={orderDetails.shippingAddress} />}
            {orderDetails && <PaymentInformation />}
          </div>
        </>
      )}
    </div>
  )
}

export default OrderDetails;