import React, { useState } from 'react';
import { Card, Accordion, Table } from 'react-bootstrap';
import ProductCard from './ProductCard';
import './index.scss';

const ProductSelector = props => {
  const {
    products,
    categories,
    searchString,
    onProductSelected,
  } = props;

  const [currentCategory, setCategory] = useState(0)

  // assuming categories is passed in as a prop
  const categoriesCopy = [...categories];

  // modify the categoriesCopy array by adding "products" and "children" arrays to each category object
  categoriesCopy.forEach(category => {
    category.products = [];
    category.children = [];
  });

  // move products into categories and their subcategories
  products
    .filter(product => (product.status === 'active' && (searchString === null || product.title.toLowerCase().includes(searchString.toLowerCase()))))
    .forEach(product => {
      // remove duplicates (bad data from the api)
      const uniqueCategories = [...new Set(product.categories)];
      const uniqueSubCategories = [...new Set(product.subCategories)];

      uniqueCategories.forEach(categoryName => {
        const category = categoriesCopy.find(category => category.title === categoryName);
        if (category) {
          category.products.push(product);
        }
      });
      uniqueSubCategories.forEach(categoryName => {
        const category = categoriesCopy.find(category => category.title === categoryName);
        if (category) {
          category.products.push(product);
        }
      }
    );
  });

  // iterate through each category in reverse and move categories with a parent ID into the corresponding parent's "children" array
  for (let i = categoriesCopy.length - 1; i >= 0; i--) {
    const category = categoriesCopy[i];
    if (category.parentId) {
      const parentCategory = categoriesCopy.find(parent => parent.id === category.parentId);
      if (parentCategory) {
        parentCategory.children.push(category);
        // remove the category from its original position in the array
        categoriesCopy.splice(i, 1);
      }
    }
  }

  const topLevelCategories = categoriesCopy.filter(category => {
    if (category.children.length > 0) {
      // filter out children with no products
      const filteredChildren = category.children.filter(child => child.products.length > 0);
      // assign the filtered children back to the category
      category.children = filteredChildren;
    }
    // include categories with products directly or filtered children
    return category.products.length > 0 || category.children.length > 0;
  });

  const displayProducts = () => {
    if (searchString.length > 0) {
      return (
        topLevelCategories
          .filter(topLevelCategory => topLevelCategory.id !== 'trending' && topLevelCategory.id !== 'all')
          .map(topLevelCategory => {
            if (topLevelCategory.children.length > 0) {
              return topLevelCategory.children.map(category => (
                <React.Fragment key={category.title}>
                  {category.products.length > 0 && (
                    <div className="category-container">
                      <div className="category-title" id={category.title.replace(/[^A-Za-z]/g, '')} dangerouslySetInnerHTML={{ __html: category.title }}></div>
                      <div className="product-container">
                        {category.products.map(product => (
                          <ProductCard
                            key={`${category.title}-${product.id}`}
                            productInfo={product}
                            onProductSelected={() => onProductSelected(product.id)}
                            isSelected={false}
                          />
                        ))}
                      </div>
                    </div>
                  )}
                </React.Fragment>
              ))
            } else {
              return (
                <div className="category-container" key={topLevelCategory.title}>
                  <div className="category-title" id={topLevelCategory.title.replace(/[^A-Za-z]/g, '')} dangerouslySetInnerHTML={{ __html: topLevelCategory.title }}></div>
                  <div className="product-container">
                    {topLevelCategory.products.map(product => (
                      <ProductCard
                        key={`${topLevelCategory.title}-${product.id}`}
                        productInfo={product}
                        onProductSelected={() => onProductSelected(product.id)}
                        isSelected={false}
                      />
                    ))}
                  </div>
                </div>
              )
            }
          })
      )
    }

    if (topLevelCategories[currentCategory]?.children.length > 0) {
      return (
        topLevelCategories[currentCategory].children.map(category => {
          return (
            <React.Fragment key={category.id}>
              {category.products.length > 0 && (
                <div className="category-container">
                  <div className="category-title" id={category.title.replace(/[^A-Za-z]/g, '')} dangerouslySetInnerHTML={{ __html: category.title }}></div>
                  <div className="product-container">
                    {category.products.map(product => (
                      <ProductCard
                        key={`${category.title}-${product.id}`}
                        productInfo={product}
                        onProductSelected={() => onProductSelected(product.id)}
                        isSelected={false}
                      />
                    ))}
                  </div>
                </div>
              )}
            </React.Fragment>
          )
        })
      )
    }

    return (
      <div className="category-container">
        <div className="product-container">
          {topLevelCategories[currentCategory]?.products.map(product => (
            <ProductCard
              key={`${currentCategory}-${product.id}`}
              productInfo={product}
              onProductSelected={() => onProductSelected(product.id)}
              isSelected={false}
            />
          ))}
        </div>
      </div>
    )
  }

  const scrollToDiv = (div) => {
    const targetDiv = document.querySelector("#" + div);
    targetDiv.scrollIntoView({ behavior: "smooth" });
  }

  return (
    <Card className="product-selector-place-order mb-4">
      <Card.Header as="h6" className="p-0">
        <div className="d-flex justify-content-between">
          <div className="py-2 px-3">Product Selector</div>
        </div>
      </Card.Header>
      <Card.Body className="product-selector-body">
        {topLevelCategories.length === 0 && (
          <div className="no-matching-products">No products match your search.</div>
        )}
        {searchString.length === 0 && (
          <Accordion activeKey={currentCategory}>
            {topLevelCategories.map((category, index) => (
              <Accordion.Item key={category.id} eventKey={index}>
                <div onClick={() => setCategory(index)}>
                  <Accordion.Header className={category.children.length ? '' : 'empty-header'}><span dangerouslySetInnerHTML={{ __html: category.title }}></span></Accordion.Header>
                </div>
                {category.children.length > 0 && (
                  <Accordion.Body className="p-2">
                    <Table>
                      <tbody className="table-body">
                        {category.children.map(subCategory => {
                          return (
                            <tr className="order-row" key={subCategory.title} onClick={() => scrollToDiv(subCategory.title.replace(/[^A-Za-z]/g, ''))}>
                              <td dangerouslySetInnerHTML={{ __html: subCategory.title }}></td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </Table>
                  </Accordion.Body>
                )}
              </Accordion.Item>
            ))}
          </Accordion>
        )}
        <div className="product-display">
          {displayProducts()}
        </div>
      </Card.Body>
    </Card>
  )
}

export default ProductSelector;