import React from 'react';
import PropTypes from 'prop-types';
import { ExclamationTriangleFill } from 'react-bootstrap-icons';
import './index.scss';

const MessageBar = props => {
  const { icon, message, color, className } = props;

  let bgColor = '';
  let textColor = '';
  switch (color) {
    case 'red':
      bgColor = 'rgba(218, 30, 40, 0.10)';
      textColor = '#821218';
      break;
    case 'green':
      bgColor = 'rgba(29, 177, 72, 0.10)';
      textColor = '#15602B';
      break;
    case 'yellow':
      bgColor = 'rgba(241, 194, 27, 0.15)';
      textColor = '#655006';
      break;
    case 'blue':
      bgColor = 'rgba(25, 153, 255, 0.10)';
      textColor = '#0E50B3';
      break;
    default:
      bgColor = 'rgba(218, 30, 40, 0.10)';
      textColor = '#821218';
      break;
  }

  return (
    <div
      className={`gooten-error-message ${className}`}
      style={{ color: textColor, backgroundColor: bgColor }}
    >
      {React.cloneElement(icon)}
      <span 
        className="message" 
        dangerouslySetInnerHTML={{ __html: message }} 
      />
    </div>
  )
}

MessageBar.propTypes = {
  icon: PropTypes.element,
  message: PropTypes.string.isRequired,
  color: PropTypes.oneOf(['red', 'green', 'yellow', 'blue']),
  className: PropTypes.string,
};

MessageBar.defaultProps = {
  icon: <ExclamationTriangleFill />,
  color: 'red',
  className: '',
};

export default MessageBar;
