import React, { useEffect } from 'react';
import Home from 'app/pages/Home';
import Orders from 'app/pages/Orders';
import PlaceAnOrder from 'app/pages/PlaceAnOrder';
import Users from 'app/pages/Users';
import Merchants from 'app/pages/Merchants';
import Vendors from 'app/pages/Vendors';
import Routing from 'app/pages/Routing';
import Transformations from 'app/pages/Transformations';
import Webhooks from 'app/pages/Webhooks';
import Integrations from 'app/pages/Integrations';
import { Sidebar, Topbar, MainContent } from 'app/components';
import SignIn from 'app/pages/Signin';
import { useSelector } from 'react-redux';
import { BrowserRouter, Routes, Route, Navigate, useLocation  } from 'react-router-dom';
import { userAuthSelector } from 'app/store/selectors/user';
import { ToastContainer } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";
import 'typeface-source-sans-pro';
import './lang/i18n';
import './App.scss';
import ForgotPassword from './pages/ForgotPassword';

function App() {
  const userAuth = useSelector(userAuthSelector);

  return (
    <BrowserRouter>
      <div className="app">
        <ToastContainer pauseOnFocusLoss={false} hideProgressBar={true} draggable={false} />
        { userAuth.bearerToken ? (
          <>
            <ScrollToTop />
            <Topbar/>
            <div className="main-container">
              <Sidebar />
              <MainContent>
                <Routes>
                  <Route exact path="/" element={<Home />} />
                  <Route path="/about/*" element={<About />} />
                  <Route path="/orders/*" element={<Orders />} />
                  <Route path="/place-an-order/*" element={<PlaceAnOrder />} />
                  <Route path="/product-hub/*" element={<ProductHub />} />
                  <Route path="/reports/*" element={<Reports />} />
                  <Route path="/product-catalog/*" element={<ProductCatalog />} />
                  <Route path="/settings/*" element={<Settings />} />
                  <Route path="/admin/users/*" element={<Users />} />
                  <Route path="/admin/vendors/*" element={<Vendors />} />
                  <Route path="/admin/merchants/*" element={<Merchants />} />
                  <Route path="/admin/routing/*" element={<Routing />} />
                  <Route path="/admin/webhooks/*" element={<Webhooks />} />
                  <Route path="/admin/transformations/*" element={<Transformations />} />
                  <Route path="/admin/integrations/*" element={<Integrations />} />
                  <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
              </MainContent>
            </div>
          </>
        ) : (
          <Routes>
            <Route path="/signin" element={<SignIn />} />
            <Route path="/register" element={<Register />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/forgot-password/:token" element={<ForgotPassword />} />
            <Route path="*" element={<Navigate to="/signin" replace />} />
          </Routes> 
        )}
      </div>
    </BrowserRouter>
  );
}

export default App;

function About() {
  const { t } = useTranslation();
  return <h4 style={{ padding: 20 }}>{t('general.about')}</h4>;
}

function Register() {
  const { t } = useTranslation();
  return <h4 style={{ padding: 20 }}>{t('general.home')}</h4>;
}

function ProductHub() {
  return <h4 style={{ padding: 20 }}>Product Hub</h4>;
}

function Reports() {
  return <h4 style={{ padding: 20 }}>Reports</h4>;
}

function ProductCatalog() {
  return <h4 style={{ padding: 20 }}>Product Catalog</h4>;
}

function Settings() {
  return <h4 style={{ padding: 20 }}>Settings</h4>;
}

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant'
    });
  }, [pathname]);

  return null;
}