import { put, takeEvery, call, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import {
  createRoutingStrategy,
  createRoutingStrategySuccess,
  createRoutingStrategyFailure,
  updateRoutingStrategy,
  updateRoutingStrategySuccess,
  updateRoutingStrategyFailure,
  deleteRoutingStrategy,
  deleteRoutingStrategySuccess,
  deleteRoutingStrategyFailure,
  getStrategiesForMerchant,
  getStrategiesForMerchantSuccess,
  getStrategiesForMerchantFailure,
  getStrategyInfo,
  getStrategyInfoSuccess,
  getStrategyInfoFailure,
  activateStrategy,
  activateStrategySuccess,
  activateStrategyFailure
} from "app/store/actions/routing";
import RoutingServices from 'app/services/routingServices';

function* fetchBlockAssignmentsForMerchant(action) {
  const merchantId = action.payload;
  try {
    const resp = yield call([RoutingServices, RoutingServices.getStrategiesForMerchant], merchantId);
    yield put(getStrategiesForMerchantSuccess(resp));
  } catch (error) {
    console.error('error', error);
    yield put(getStrategiesForMerchantFailure(error));
  }
}

function* doCreateRoutingStrategy(action) {
  const {routingStrategy, cb} = action.payload;

  try {
    const resp = yield call([RoutingServices, RoutingServices.createStrategy], routingStrategy);
    yield put(createRoutingStrategySuccess(resp));
    toast.success("Routing Strategy Successfully Created", {
      theme: 'colored',
    });
    if(cb) cb();
  } catch (error) {
    console.error('error', error);
    yield put(createRoutingStrategyFailure());
    toast.error("Error Creating Routing Strategy", {
      theme: 'colored',
    })
  }
}

function* doUpdateRoutingStrategy(action) {
  const {routingStrategy, cb} = action.payload;

  try {
    const resp = yield call([RoutingServices, RoutingServices.updateStrategy], routingStrategy);
    yield call([RoutingServices, RoutingServices.clearCache]);
    yield put(updateRoutingStrategySuccess(resp));
    toast.success("Routing Strategy Successfully Updated", {
      theme: 'colored',
    });
    if(cb) cb();
  } catch (error) {
    console.error('error', error);
    yield put(updateRoutingStrategyFailure());
    toast.error("Error updating routing strategy", {
      theme: 'colored',
    })
  }
}

function* doDeleteStrategy(action) {
  const {merchantId, strategyId, cb} = action.payload;
  try {
    const resp = yield call([RoutingServices, RoutingServices.deleteStrategy], strategyId);
    yield put(deleteRoutingStrategySuccess(resp));
    yield call([RoutingServices, RoutingServices.clearCache]);

    const resp2 = yield call([RoutingServices, RoutingServices.getStrategiesForMerchant], merchantId);
    yield put(getStrategiesForMerchantSuccess(resp2));
    toast.success("Routing Strategy Successfully Deleted", {
      theme: 'colored',
    });
    if(cb) cb();
  } catch (error) {
    console.error('error', error);
    yield put(deleteRoutingStrategyFailure(error));
    toast.error("Deleting Strategy failed", {
      theme: 'colored',
    });
  }
}

function* doActivateStrategy(action) {
  const {strategyId, cb} = action.payload;
  try {
    yield call([RoutingServices, RoutingServices.activateStrategy], strategyId);
    yield call([RoutingServices, RoutingServices.clearCache]);
    yield put(activateStrategySuccess(strategyId));
    toast.success("Routing Strategy Successfully Activated", {
      theme: 'colored',
    });
    if(cb) cb();
  } catch (error) {
    console.error('error', error);
    yield put(activateStrategyFailure(error));
    toast.error("Activate Strategy failed", {
      theme: 'colored',
    });
  }
}

function* fetchStrategyInfo(action) {
  const {strategyId} = action.payload;

  try {
    const resp = yield call([RoutingServices, RoutingServices.getStrategy], strategyId);
    yield put(getStrategyInfoSuccess(resp));
  } catch (error) {
    console.error('error', error);
    yield put(getStrategyInfoFailure(error));
  }
}

function* watchData() {
  yield takeEvery(getStrategiesForMerchant().type, fetchBlockAssignmentsForMerchant);
  yield takeEvery(createRoutingStrategy().type, doCreateRoutingStrategy);
  yield takeEvery(updateRoutingStrategy().type, doUpdateRoutingStrategy);
  yield takeEvery(deleteRoutingStrategy().type, doDeleteStrategy);
  yield takeEvery(activateStrategy().type, doActivateStrategy);
  yield takeEvery(getStrategyInfo().type, fetchStrategyInfo);
}

export default function* rootSaga() {
  yield all([
    watchData(),
  ]);
}