import React from 'react';
import PropTypes from 'prop-types';
import { Pagination as BSPagination } from 'react-bootstrap';
import { ButtonMenu } from 'app/components';
import { useSearchParams } from 'react-router-dom';
import './index.scss';

export const Pagination = ({
  totalItems,
  pageSizes,
  between,
  next,
  last,
  ellipsis,
  hideBelow,
  // the next 3 props are optional and only used when the parent component is controlling the pagination
  currentPage,
  pageSize,
  onChange,
  ...paginationProps
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  let _currentPage = currentPage || parseInt(searchParams.get('page') || '1', 10);
  let _pageSize = pageSize || parseInt(searchParams.get('pageSize') || pageSizes[1], 10);

  const getShowingToFromMessage = () => {
    const from = (_currentPage - 1) * _pageSize + 1;
    const to = (_currentPage) * _pageSize >= totalItems ? totalItems : (_currentPage) * _pageSize;
    return `Showing ${from} - ${to} of ${totalItems}`;
  }

  const updatePaginationInfo = (page, _pageSize) => {
    if (onChange) {
      onChange(page, _pageSize);
    } else {
      const updatedSearchParams = new URLSearchParams(searchParams.toString());
      updatedSearchParams.set('page', page);
      updatedSearchParams.set('pageSize', _pageSize);
      setSearchParams(updatedSearchParams.toString());
    }
  }

  const totalPages = Math.ceil(totalItems / _pageSize)
  between = between < 1 ? 1 : between
  _currentPage = (_currentPage < 1 ? 1 : _currentPage > totalPages ? totalPages : _currentPage)
  ellipsis = ellipsis < 1 ? 0 : ellipsis + 2 >= between ? between - 2 : ellipsis

  let positions = Array.from({ length: totalPages }, (_, i) => i)

  const qtd_pages = (between * 2) + 1
  const range = (
    totalPages <= qtd_pages
      // Show active without slice
      ? positions
      : _currentPage - 1 <= between
        // Show active in left
        ? positions.slice(0, qtd_pages - (ellipsis > 0 ? ellipsis + 1 : 0))
        : _currentPage + between >= totalPages
          // Show active in right
          ? positions.slice(totalPages - qtd_pages + (ellipsis > 0 ? ellipsis + 1 : 0), totalPages)
          // Show active in middle
          : positions.slice((_currentPage - 1) - (between - (ellipsis > 0 ? ellipsis + 1 : 0)), _currentPage + (between - (ellipsis > 0 ? ellipsis + 1 : 0)))
  )

  // if there are less than X items, don't bother showing the pagination at all
  if (totalItems < hideBelow) return null;

  return (
    <>
      <div className="gooten-pagination">
        {totalItems > 0 && (
          <>
            <ButtonMenu
              label={`Show ${_pageSize}`}
              variant="secondary"
              direction="up"
              size="small"
              options={pageSizes.map(val => ({
                value: val,
                label: val,
                onClick: () => updatePaginationInfo(1, val)
              }))}
              width={140}
            />
            <div className="showing-count">{getShowingToFromMessage()}</div>

            {totalItems && totalItems > 0 && (
              <BSPagination {...paginationProps}>
                {
                  last
                  && <BSPagination.First
                    className="pagination-arrow"
                    onClick={() => _currentPage > 1 ? updatePaginationInfo(1, _pageSize) : null}
                    disabled={_currentPage <= 1} />
                }
                {
                  next
                  && <BSPagination.Prev
                    className="pagination-arrow"
                    onClick={() => _currentPage > 1 ? updatePaginationInfo(_currentPage - 1, _pageSize) : null}
                    disabled={_currentPage <= 1} />
                }
                {
                  totalPages > (between * 2) + 1 && ellipsis > 0
                  && positions.slice(0, _currentPage - 1 <= between ? 0 : ellipsis).map(value => {
                    return <BSPagination.Item key={value}
                      onClick={() => value !== _currentPage - 1 ? updatePaginationInfo(value + 1, _pageSize) : null}>
                      {value + 1}
                    </BSPagination.Item>
                  })
                }
                {
                  // Show ellipsis when "page" is bigger than "between"
                  totalPages > (between * 2) + 1 && ellipsis > 0 && _currentPage - 1 > between
                  && <BSPagination.Ellipsis disabled />
                }
                {range.map(value => {
                  return <BSPagination.Item active={value === _currentPage - 1}
                    key={value}
                    onClick={() => value !== _currentPage - 1 ? updatePaginationInfo(value + 1, _pageSize) : null}>
                    {value + 1}
                  </BSPagination.Item>
                })}
                {
                  // Show ellipsis when "page" is lower than "between"
                  totalPages > (between * 2) + 1 && ellipsis > 0 && _currentPage < totalPages - between
                  && <BSPagination.Ellipsis disabled />
                }
                {
                  totalPages > (between * 2) + 1 && ellipsis > 0
                  && positions.slice(_currentPage >= totalPages - between ? totalPages : totalPages - ellipsis, totalPages).map(value => {
                    return <BSPagination.Item key={value}
                      onClick={() => value !== _currentPage - 1 ? updatePaginationInfo(value + 1, _pageSize) : null}>
                      {value + 1}
                    </BSPagination.Item>
                  })
                }
                {
                  next
                  && <BSPagination.Next
                    onClick={() => _currentPage < totalPages ? updatePaginationInfo(_currentPage + 1, _pageSize) : null}
                    disabled={_currentPage >= totalPages}
                    className="pagination-arrow" />
                }
                {
                  last
                  && <BSPagination.Last
                    onClick={() => _currentPage < totalPages ? updatePaginationInfo(totalPages, _pageSize) : null}
                    disabled={_currentPage >= totalPages}
                    className="pagination-arrow" />
                }
              </BSPagination>
            )}
          </>
        )}
      </div>
    </>
  )
}

Pagination.propTypes = {
  totalItems: PropTypes.number.isRequired,
  pageSizes: PropTypes.arrayOf(PropTypes.number),
  between: PropTypes.number,
  next: PropTypes.bool,
  last: PropTypes.bool,
  ellipsis: PropTypes.number,
  hideBelow: PropTypes.number,
}

Pagination.defaultProps = {
  pageSizes: [25, 50, 100],
  between: 3,
  next: true,
  last: true,
  ellipsis: 0,
  hideBelow: 25,
}

export default Pagination;