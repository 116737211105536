import React from 'react';
import { toggleSidebar } from 'app/store/actions/config';
import { sidebarSelector } from 'app/store/selectors/config';
import { currentUserSelector } from 'app/store/selectors/user';
import { useDispatch, useSelector } from 'react-redux';
import { OrderSearchBar } from 'app/components';
import { ReactComponent as Logo } from 'app/assets/images/logos/main-logo.svg';
import { ReactComponent as HamburgerIconCollapse } from 'app/assets/images/icons/hamburger-collapse.svg';
import { ReactComponent as HamburgerIconExpand } from 'app/assets/images/icons/hamburger-expand.svg';
import { ReactComponent as ProfileIcon } from 'app/assets/images/icons/profile-icon.svg';
import { ChevronDown } from 'react-bootstrap-icons';
import GroupsMenu from 'app/pages/Users/GroupsMenu';
import "./index.scss";

const Topbar = () => {
  const dispatch = useDispatch();
  const sidebar = useSelector(sidebarSelector);
  const currentUser = useSelector(currentUserSelector);

  return (
    <div className='topbar'>
      <div className="logo-container">
        {
          sidebar.open
            ? <HamburgerIconCollapse
              onClick={() => dispatch(toggleSidebar())}
              className="hamburber-icon-collapse"
            />
            : <HamburgerIconExpand
              onClick={() => dispatch(toggleSidebar())}
              className="hamburber-icon-expand"
            />
        }
        <div className="order-mesh-logo">
          <Logo />
        </div>
      </div>
      <GroupsMenu />
      <OrderSearchBar />
      <div className="profile-container">
        <div className="profile-name">{`${currentUser?.firstName} ${currentUser?.lastName}`}</div>
        <div className="profile-avatar">
          <ProfileIcon />
        </div>
        <div className="profile-caret">
          <ChevronDown />
        </div>
      </div>
    </div>
  );
}

export default Topbar;
