import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowLeft, Trash3, ExclamationCircle } from 'react-bootstrap-icons';
import { getMerchantDetails } from 'app/store/actions/merchant';
import { getStrategiesForMerchant, activateStrategy, deleteRoutingStrategy, reset } from 'app/store/actions/routing';
import { merchantDetailsSelector, merchantDetailsLoadingSelector } from 'app/store/selectors/merchant';
import { merchantStrategiesSelector, merchantStrategiesLoadingSelector, merchantStrageiesErrorSelector, activateStrategyLoadingSelector, deleteStrategyLoadingSelector } from 'app/store/selectors/routing';
import { Card, LoadingAnimation, ToggleSwitch, Modal, Button, ButtonIcon, Table, TableHeader, Link } from 'app/components';
import { toast } from 'react-toastify';
import './index.scss';

const RoutingDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { merchantId } = useParams();

  const merchantStrategies = useSelector(merchantStrategiesSelector);
  const merchantStrategiesLoading = useSelector(merchantStrategiesLoadingSelector);
  const merchantStrategiesError = useSelector(merchantStrageiesErrorSelector);
  const merchantDetailsLoading = useSelector(merchantDetailsLoadingSelector);
  const merchantDetails = useSelector(merchantDetailsSelector);
  const activateStrategyLoading = useSelector(activateStrategyLoadingSelector);
  const deleteStrategyLoading = useSelector(deleteStrategyLoadingSelector);

  const activeStrategies = merchantStrategies && merchantStrategies.length > 0 ? merchantStrategies.filter(strategy => strategy.isActive) : null;
  const inactiveStrategies = merchantStrategies && merchantStrategies.length > 0 ? merchantStrategies.filter(strategy => !strategy.isActive) : null;

  const [activateStrategyModal, showActivateStrategyModal] = useState(null);
  const [deleteStrategyModal, showDeleteStrategyModal] = useState(null);

  useEffect(() => {
    dispatch(getMerchantDetails(merchantId));
    dispatch(getStrategiesForMerchant(merchantId));
  }, [merchantId, dispatch]);

  return (
    <>
      {(merchantDetailsLoading || merchantStrategiesLoading || activateStrategyLoading || deleteStrategyLoading) && <LoadingAnimation />}
      {(merchantStrategies || merchantStrategiesError) && (
        <div className="routing-details">
          <Card>
            <div className="merchant-name-container">
              <div className="merchant-name">
                <ButtonIcon icon={<ArrowLeft />} onClick={() => navigate('/admin/routing')} />
                {merchantDetails?.name || merchantDetails?.legalBusinessName || 'Merchant Strategies'}
              </div>
              <div>
                <Button
                  label="Create New Strategy"
                  variant="primary"
                  size="medium"
                  onClick={() => {
                    dispatch(reset());
                    navigate(`/admin/routing/add-new/${merchantId}`)
                  }}
                />
              </div>
            </div>
          </Card>
          <Card>
            <Card.Header>
              Active Strategy
            </Card.Header>
            <Card.Body>
              <Table size="medium">
                <TableHeader
                  options={[
                    { id: 'id', label: 'Strategy ID', width: '350px' },
                    { id: 'strategyName', label: 'Strategy Name' },
                    { id: 'createdOn', label: 'Created On' },
                    { id: 'status', label: 'Status', align: 'center' },
                    { id: 'actions', label: 'Actions', align: 'center' },
                  ]}
                />
                {activeStrategies && activeStrategies.length > 0 && (
                  <tbody className="table-body">
                    {activeStrategies.map(strategy => (
                      <tr key={strategy.id}>
                        <td>
                          <Link
                            label={strategy.id}
                            onClick={() => {
                              dispatch(reset());
                              navigate(`/admin/routing/${merchantId}/${strategy.id}`)
                            }}
                          />
                        </td>
                        <td>{strategy.routingStrategyName}</td>
                        <td>-</td>
                        <td className="status-column">
                          <ToggleSwitch
                            isOn={true}
                            onToggle={() => toast.info("At least one strategy must be active.  Activate a different strategy to deactivate this one.")}
                          />
                        </td>
                        <td className="actions-column">
                          <ButtonIcon
                            icon={<Trash3 />}
                            destructive={true}
                            onClick={() => showDeleteStrategyModal(strategy.id)}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                )}
                {(!activeStrategies || activeStrategies.length === 0) && !merchantStrategiesError && (
                  <tbody><tr><td className="no-strategies" colSpan={5}>No Active Strategies Found</td></tr></tbody>
                )}
                {merchantStrategiesError && (
                  <tbody><tr><td className="no-strategies" colSpan={5}><ExclamationCircle />Strategy Data failed to load.  Refresh the page to try again.</td></tr></tbody>
                )}
              </Table>
            </Card.Body>
          </Card>
          {!merchantStrategiesError && (
            <Card>
              <Card.Header>
                Available Strategies
              </Card.Header>
              <Card.Body>
                <Table size="medium">
                  <TableHeader
                    options={[
                      { id: 'id', label: 'Strategy ID', width: '350px' },
                      { id: 'strategyName', label: 'Strategy Name' },
                      { id: 'createdOn', label: 'Created On' },
                      { id: 'status', label: 'Status', align: 'center' },
                      { id: 'actions', label: 'Actions', align: 'center' },
                    ]}
                  />
                  {inactiveStrategies && inactiveStrategies.length > 0 && (
                    <tbody className="table-body">
                      {inactiveStrategies.map(strategy => (
                        <tr key={strategy.id}>
                          <td>
                            <Link
                              label={strategy.id}
                              onClick={() => {
                                dispatch(reset());
                                navigate(`/admin/routing/${merchantId}/${strategy.id}`)
                              }}
                            />
                          </td>
                          <td>{strategy.routingStrategyName}</td>
                          <td>-</td>
                          <td className="status-column">
                            <ToggleSwitch
                              isOn={false}
                              onToggle={() => showActivateStrategyModal(strategy.id)}
                            />
                          </td>
                          <td className="actions-column">
                            <ButtonIcon
                              icon={<Trash3 />}
                              destructive={true}
                              onClick={() => showDeleteStrategyModal(strategy.id)}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                  {(!inactiveStrategies || inactiveStrategies.length === 0) && (
                    <tbody><tr><td className="no-strategies" colSpan={5}>No Available Strategies Found</td></tr></tbody>
                  )}
                </Table>
              </Card.Body>
            </Card>
          )}
        </div>
      )}
      {activateStrategyModal && (
        <Modal
          secondaryButtonLabel="Cancel"
          secondaryButtonOnClick={() => { showActivateStrategyModal(null) }}
          primaryButtonLabel="Yes, Activate It"
          primaryButtonVariant="primary"
          primaryButtonOnClick={() => {
            dispatch(activateStrategy({ strategyId: activateStrategyModal }));
            showActivateStrategyModal(null);
          }}
          onClose={() => showActivateStrategyModal(null)}
        >
          <div>Are you sure you want to activate this strategy?</div>
        </Modal>
      )}
      {deleteStrategyModal && (
        <Modal
          secondaryButtonLabel="No, Keep It"
          secondaryButtonOnClick={() => { showDeleteStrategyModal(null) }}
          primaryButtonLabel="Yes, Delete It"
          primaryButtonVariant="primary"
          primaryButtonDestructive
          primaryButtonOnClick={() => {
            dispatch(deleteRoutingStrategy({ merchantId, strategyId: deleteStrategyModal }));
            showDeleteStrategyModal(null);
          }}
          onClose={() => showDeleteStrategyModal(null)}
        >
          <div>Are you sure you want to delete this strategy?</div>
          <div>This action is not reversible.</div>
        </Modal>
      )}
    </>
  )
}

export default RoutingDetails;