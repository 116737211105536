import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Card, ButtonIcon, StatusChip, IconData } from 'app/components';
import PackageCard from './PackageCard';
import { getFacilityDetails, getVendorDetails } from 'app/store/actions/vendor';
import { capitalizeFirstLetter } from 'app/utils';
import { ThreeDotsVertical, ChevronDown, ChevronLeft, Truck, Calendar2Event, Box, Radar, Receipt, BoxArrowUpRight } from 'react-bootstrap-icons';
import { ReactComponent as IndustryWindows } from 'app/assets/images/icons/industry-windows.svg';
import './index.scss';

const ShipmentCard = props => {
  const {
    orderDetails,
    shipment, 
    menuState, 
    toggleMenu,
    showCancelShipmentModal,
    showCancelOrderItemModal,
    showCarrierLabelModal,
    showChangeVendorModal,
  } = props;

  const dispatch = useDispatch();
  const [facilityDetails, setFacilityDetails] = useState(null);
  const [vendorDetails, setVendorDetails] = useState(null);

  useEffect(() => {
    dispatch(getFacilityDetails({
      vendorId: shipment.vendorId,
      facilityId: shipment.facilityId,
      cb: (resp) => { setFacilityDetails(resp); }
    }));
    dispatch(getVendorDetails({
      vendorId: shipment.vendorId,
      cb: (resp) => { setVendorDetails(resp); }
    }));

  }, [dispatch, shipment.vendorId, shipment.facilityId]);

  const shipmentOptions = () => [
    { value: 'Mark as Fulfilled', label: 'Mark as Fulfilled', onClick: () => { console.log("Not implemented yet") }},
    { value: 'Submit', label: 'Submit', onClick: () => { console.log("Not implemented yet") }},
    { value: 'Reject', label: 'Reject', onClick: () => { console.log("Not implemented yet") } },
    { value: 'Unassign', label: 'Unassign', onClick: () => console.log("Not implemented yet") },
    { value: 'Reroute', label: 'Reroute', onClick: () => showChangeVendorModal({shipmentId: shipment.id, shipmentShortId: shipment.shortId}) },
    { value: 'Select Items to Cancel', label: 'Select Items to Cancel', onClick: () => console.log("Not implemented yet") },
    { value: 'Cancel Shipment', label: 'Cancel Shipment', destructive: true, onClick: () => showCancelShipmentModal(shipment.id) },
  ];

  const PackageIcon = () => {
    const packagesCount = shipment.packages.length;
    const itemsCount = shipment.packages.reduce((total, pkg) => total + pkg.orderItemIDs.length, 0);
    
    const packagesLabel = `${packagesCount} ${packagesCount === 1 ? 'Package' : 'Packages'}`;
    const itemsLabel = `${itemsCount} ${itemsCount === 1 ? 'Item' : 'Items'}`;

    return (
      <IconData
        label={`${packagesLabel} ${itemsLabel}`}
        icon={<Box />}
      />
    );
  };

  const numItems = shipment.packages.reduce((total, pkg) => total + pkg.orderItemIDs.length, 0);

  return (
    <Card className="shipment-card">
      <Card.Header className={menuState[shipment.shortId] && 'collapsed'}>
        <div className="shipment-header">
          <div className="id-status">
              <div>{`Shipment ${shipment.shortId}`}</div>
              <StatusChip type="shipment" size="slim" status={shipment.shipmentStatus} />
          </div>
          <div className="shipment-options">
            <ButtonIcon
              icon={<ThreeDotsVertical />}
              options={shipmentOptions()}
            />
            {numItems > 5 && (
              <ButtonIcon
                icon={menuState[shipment.shortId] ? <ChevronLeft /> : <ChevronDown />}
                onClick={() => toggleMenu(shipment.shortId)}
              />
            )}
          </div>
        </div>
        <div className="shipment-data">
          {shipment.orderCreatedDate && (
            <IconData
              label={shipment.orderCreatedDate}
              icon={<Calendar2Event />}
              enableClipboardCopy={false}
            />
          )}
          {shipment.facilityId && facilityDetails?.name && (
            <IconData
              label={facilityDetails.name}
              icon={<IndustryWindows />}
              enableClipboardCopy={false}
            />
          )}
          {shipment.vendorName && (
            <IconData
              label={shipment.vendorName}
              icon={<Truck />}
              enableClipboardCopy={false}
            />
          )}
          {orderDetails.customerShippingMethod && (
            <IconData
              label={capitalizeFirstLetter(orderDetails.customerShippingMethod)}
              icon={<Truck />}
              enableClipboardCopy={false}
            />
          )}
          {shipment.packages.length === 1 && shipment.packages[0].carrierName && (
            <IconData
              label={shipment.packages[0].carrierName}
              icon={<Radar />}
              onClick={() => {
                window.open(shipment.packages[0].carrierLink, '_blank');
              }}
            />
          )}
          {shipment.packages.length === 1 && shipment.packages[0].carrierName && (
            <IconData
              label="View Shipping Label"
              icon={<Receipt />}
              onClick={() => {showCarrierLabelModal(true)}}
            />
          )}
          {vendorDetails?.vendor?.url && (
            <IconData
              label="Go to Vendor Portal"
              icon={<BoxArrowUpRight />}
              onClick={() => {
                window.open(vendorDetails.vendor?.url, '_blank');
              }}
            />
          )}
        </div>
      </Card.Header>
      {!menuState[shipment.shortId] ? (
        <Card.Body>
          {shipment.packages.map((orderPackage) => (
            <PackageCard
              key={orderPackage.id}
              orderId={orderDetails.id}
              shipment={shipment}
              orderPackage={orderPackage} 
              numPackages={shipment.packages.length}
              showCancelOrderItemModal={showCancelOrderItemModal}
              showCarrierLabelModal={showCarrierLabelModal}
              showChangeVendorModal={showChangeVendorModal}
            />
          ))}
          {shipment.packages.length === 0 && (
            <div>No packages found in shipment</div>
          )}
        </Card.Body>
      ) : (
        <Card.Body>
          <PackageIcon />
       </Card.Body>
      )}
    </Card>
  );
}

export default ShipmentCard;