import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { reset } from 'app/store/actions/notification';
import { Card, TabbedMenu, Button } from 'app/components';
import WebhooksList from './WebhooksList';
import WebhooksActionHistory from './WebhooksActionHistory';
import './index.scss';

const tabs = [
  {
    path: '/admin/webhooks',
    label: 'Webhooks',
    content: <WebhooksList />
  },
  {
    path: 'action-history',
    label: 'Action History',
    content: <WebhooksActionHistory />
  },
];

const WebhooksView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <div className="webhooks-view">
      <Card>
        <div className="webhook-view-header">
          Webhooks
          <Button
            variant="primary"
            size="medium"
            label={"Create a Webhook"}
            onClick={() => {
              dispatch(reset());
              navigate(`/admin/webhooks/create-webhook`);
            }}
          />
        </div>
        <TabbedMenu tabs={tabs} />
      </Card>
    </div>
  )
}

export default WebhooksView;