/* eslint-disable no-new-func */
import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { ArrowRight } from 'react-bootstrap-icons';
import { transformationSelector, transformationLoadingSelector } from 'app/store/selectors/transformation';
import { merchantsSelector, merchantsLoadingSelector } from 'app/store/selectors/merchant';
import { vendorsDataSelector, vendorsLoadingSelector } from 'app/store/selectors/vendor';
import { getTransformationDetails, createTransformation, testDeployedTransformation, promoteTransformation } from 'app/store/actions/transformation';
import { getMerchants } from 'app/store/actions/merchant';
import { getVendors } from 'app/store/actions/vendor';
import { Card, Button, LoadingAnimation, Input, Dropdown, TextArea } from 'app/components';
import Editor from '@monaco-editor/react';
import { generateGUID } from 'app/utils';
import { object, string } from 'yup';
import moment from 'moment';
import './index.scss';

const TransformationDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { transformationId } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();

  const transformationLoading = useSelector(transformationLoadingSelector);
  const transformationDetails = useSelector(transformationSelector);

  const merchantsData = useSelector(merchantsSelector);
  const merchantsLoading = useSelector(merchantsLoadingSelector);
  const vendorsData = useSelector(vendorsDataSelector);
  const vendorsLoading = useSelector(vendorsLoadingSelector);

  const creatorType = searchParams.get('creatorType') || null;
  const topic = searchParams.get('topic') || null;
  const transformationType = searchParams.get('transformationType') || null;
  const entityId = searchParams.get('entityId') || null;
  const sourceVer = searchParams.get('sourceVer') || null;

  const [editMode, setEditMode] = useState(transformationId ? false : true);
  const [testAgainst, setTestAgainst] = useState('aboveCode');
  const [highlighted, setHighlighted] = useState(null);

  const creatorTypes = [{ "Name": "Merchant", "Value": "Merchant" }, { "Name": "Vendor", "Value": "Vendor" }];
  const transformationTypes = [{ "Name": "Inbound", "Value": "inbound" }, { "Name": "Outbound", "Value": "outbound" }];
  const contentMethods = [{ "Name": "GET", "Value": "GET" }, { "Name": "POST", "Value": "POST" }, { "Name": "PUT", "Value": "PUT" }, { "Name": "PATCH", "Value": "PATCH" }, { "Name": "DELETE", "Value": "DELETE" }, { "Name": "HEAD", "Value": "HEAD" }, { "Name": "OPTIONS", "Value": "OPTIONS" }];
  const contentTypes = [{ "Name": "application/json", "Value": "application/json" }, { "Name": "application/xml", "Value": "application/xml" }];

  const editorOptions = {
    overviewRulerLanes: 0,
    minimap: { enabled: false }, // Disabling the minimap
  };

  useEffect(() => {
    // if a transformation id was passed in, then we are viewing an existing transformation
    if (transformationId) {
      dispatch(getTransformationDetails({ transformationId }));
    } else {
      setEditMode(true);
    }
    if (!merchantsData) {
      dispatch(getMerchants({ currentPage: 1, pageSize: 250 }));
    }
    if (!vendorsData) {
      dispatch(getVendors({ currentPage: 1, pageSize: 250 }));
    }
  }, []);

  const getUserOptions = (creatorType) => {
    if (creatorType === 'Merchant') {
      const merchants = merchantsData.merchants.map(merchant => (
        { value: merchant.id, label: merchant.name }
      ));
      return merchants;
    } else if (creatorType === 'Vendor') {
      const vendors = vendorsData.vendors.map(vendor => (
        { value: vendor.id, label: vendor.name }
      ));
      return vendors;
    } else {
      return [];
    }
  }

  const getTopicOptions = (creatorType, transformationType) => {
    if (creatorType === 'Merchant') {
      if (transformationType === 'inbound') {
        return [{ value: 'OrderCreated', label: 'Order Created' }, { value: 'CreateOrder', label: 'Create Order' }];
      } else if (transformationType === 'outbound') {
        return [{ value: 'OrderCreated', label: 'Order Created' }, { value: 'OrderUpdated', label: 'Order Updated' }, { value: 'ShipmentCreated', label: 'Shipment Created' }, { value: 'ShipmentUpdated', label: 'Shipment Updated' }];
      }
    } else if (creatorType === 'Vendor') {
      if (transformationType === 'inbound') {
        return [{ value: 'ShipmentUpdated', label: 'Shipment Updated' }, { value: 'UpdateShipment', label: 'Update Shipment' }];
      } else if (transformationType === 'outbound') {
        return [{ value: 'ShipmentCreated', label: 'Shipment Created' }];
      }
    }
    return [{ value: '', label: 'No Topics Found' }];
  }

  const onTransformationCreated = (data, transformationId) => {
    if (snippetVersions) {
      const updatedSearchParams = new URLSearchParams(searchParams.toString());
      updatedSearchParams.set('sourceVer', transformationId);
      setSearchParams(updatedSearchParams.toString());
    }
    navigate(`/admin/transformations/${transformationId}`);
  }

  const onTransformationPromoted = (transformationId) => {
    if (snippetVersions) {
      const updatedSearchParams = new URLSearchParams(searchParams.toString());
      updatedSearchParams.set('sourceVer', transformationId);
      setSearchParams(updatedSearchParams.toString());
    }
  }

  const getInitValues = () => {
    const initValues = {
      creatorType: '',
      entityId: '',
      snippetId: '',
      transformationType: '',
      topic: '',
      sourceVer: '',
      codeSnippet: '',
      snippetInput: '',
      snippetOutput: '',
      destinationUrl: '',
      destinationName: '',
      destinationVer: '',
      contentMethod: 'GET',
      contentType: 'application/json',
      contentHeaders: [{
        key: '',
        value: '',
      }],
      published: false,
    }

    if (transformationLoading || merchantsLoading || vendorsLoading) {
      return initValues;
    }

    if (snippetVersions) {
      const snippet = sourceVer ? snippetVersions.find(snippet => snippet.snippetId === sourceVer) : snippetVersions[snippetVersions.length - 1];

      initValues.creatorType = creatorType;
      initValues.entityId = entityId;
      initValues.snippetId = snippet.snippetId;
      initValues.transformationType = transformationType;
      initValues.topic = topic;
      initValues.sourceVer = snippet.creationDate;
      initValues.codeSnippet = snippet.code;
      initValues.published = snippet.published;
    }

    return initValues;
  }

  const snippetVersions = transformationDetails?.snippetVersions;

  return (
    <div className="transformation-details">
      {(transformationLoading || merchantsLoading || vendorsLoading) && <LoadingAnimation />}
      {!transformationLoading && !merchantsLoading && !vendorsLoading && vendorsData && merchantsData && (transformationId ? transformationDetails !== null : true) && (
        <Formik
          enableReinitialize
          initialValues={getInitValues()}
          validationSchema={() =>
            object().shape({
              creatorType: string().required('User Type is required'),
              entityId: string().required('Name is required'),
              transformationType: string().required('Transformation Type is required'),
              topic: string().required('Topic is required'),
              codeSnippet: string().required('Code Snippet is required'),
            })
          }
          onSubmit={async (values) => {
            const data = {
              "code": values.codeSnippet,
              "creatorId": values.entityId,
              "creatorType": values.creatorType,
              "topic": values.topic,
              "transformationType": values.transformationType,
              "entityId": values.entityId,
            }

            if (values.published) {
              dispatch(promoteTransformation({ snippetId: values.snippetId, cb: onTransformationPromoted }));
            } else {
              dispatch(createTransformation({ data, published: values.published, cb: onTransformationCreated }));
            }
          }}
        >
          {({
            values,
            errors,
            setFieldValue,
            handleChange,
            handleSubmit,
            submitCount,
            getFieldMeta,
            resetForm,
          }) => {
            const isDirty = getFieldMeta("codeSnippet").value !== getFieldMeta("codeSnippet").initialValue ||
              getFieldMeta("creatorType").value !== getFieldMeta("creatorType").initialValue ||
              getFieldMeta("entityId").value !== getFieldMeta("entityId").initialValue ||
              getFieldMeta("transformationType").value !== getFieldMeta("transformationType").initialValue ||
              getFieldMeta("topic").value !== getFieldMeta("topic").initialValue

            return (
              <form>
                <>
                  <div className="transformation-header">
                    {transformationId ? 'Transformation Code Snippet' : 'New Transformation Snippet'}
                    <div className="action-buttons">
                      <Button
                        variant="secondary"
                        size="small"
                        label={transformationDetails && !isDirty ? 'Back' : 'Cancel'}
                        // disabled={transformationDetails}
                        onClick={() => (snippetVersions && editMode) ? setEditMode(false) : navigate('/admin/transformations/')}
                      />
                      <Button
                        size="small"
                        label={snippetVersions && !isDirty ? 'Publish' : 'Save as Draft'}
                        disabled={transformationLoading}
                        onClick={() => {
                          setFieldValue('published', snippetVersions && !isDirty ? true : false);
                          handleSubmit();
                        }}
                      />
                    </div>
                  </div>
                  <Card className="transformation-data-card">
                    <Card.Header>
                      Snippet Details
                      {snippetVersions?.length > 0 && (
                        <div className="button-container d-flex justify-content-between align-items-center" onClick={e => e.stopPropagation()}>
                          <Dropdown
                            name="topic"
                            className="snippet-version-dropdown"
                            value={values.sourceVer}
                            readonly={transformationLoading}
                            showErrorMessages={false}
                            onChange={(e) => {
                              const snippet = snippetVersions.find(snippet => snippet.creationDate === e.target.value);
                              
                              resetForm({
                                values: {
                                  creatorType: creatorType,
                                  entityId: entityId,
                                  snippetId: snippet.snippetId,
                                  transformationType: transformationType,
                                  topic: topic,
                                  sourceVer: snippet.creationDate,
                                  codeSnippet: snippet.code,
                                  snippetInput: values.snippetInput || '',
                                  snippetOutput: '',
                                  destinationUrl: '',
                                  destinationName: snippet.destinationName,
                                  destinationVer: snippet.destinationVersion,
                                  contentMethod: 'GET',
                                  contentType: 'application/json',
                                  contentHeaders: values.contentHeaders || [{ key: '', value: '' }],
                                  published: false,
                                }
                              });
                            }}
                            options={snippetVersions
                              .sort((a, b) => moment(a.creationDate).unix() - moment(b.creationDate).unix())
                              .map(snippet => ({
                                value: snippet.creationDate,
                                label: `${moment(snippet.creationDate).format('MMMM Do, YYYY, h:mm:ss A')} - ${!snippet.published ? 'Draft' : 'Published'}`
                            }))}
                          />
                        </div>
                      )}
                    </Card.Header>
                    <Card.Body>
                      <div className="transformation-data">
                        <Dropdown
                          label="User Type"
                          name="creatorType"
                          value={values.creatorType}
                          readonly={!editMode}
                          onChange={(e) => {
                            setFieldValue('entityId', '');
                            setFieldValue('transformationType', '');
                            setFieldValue('topic', '');
                            handleChange(e);
                          }}
                          options={creatorTypes.map(creatorType => (
                            { value: creatorType.Value, label: creatorType.Name }
                          ))}
                          errorMessage={submitCount > 0 && errors.creatorType}
                        />
                        {values.creatorType !== '' && (
                          <Dropdown
                            label={values.creatorType === 'Merchant' ? 'Merchant Name' : 'Vendor Name'}
                            name="entityId"
                            value={values.entityId}
                            readonly={!editMode}
                            disabled={values.creatorType === ''}
                            onChange={(e) => {
                              setFieldValue('transformationType', '');
                              setFieldValue('topic', '');
                              handleChange(e);
                            }}
                            options={getUserOptions(values.creatorType)}
                            errorMessage={submitCount > 0 && errors.entityId}
                          />
                        )}
                        {values.creatorType !== '' && values.entityId !== '' && (
                          <Dropdown
                            label="Transformation Type"
                            name="transformationType"
                            value={values.transformationType}
                            readonly={!editMode}
                            disabled={values.creatorType === '' || values.entityId === ''}
                            onChange={(e) => {
                              setFieldValue('topic', '');
                              handleChange(e);
                            }}
                            options={values.creatorType === '' || values.entityId === '' ? [] : transformationTypes.map(transformationType => (
                              { value: transformationType.Value, label: transformationType.Name }
                            ))}
                            errorMessage={submitCount > 0 && errors.transformationType}
                          />
                        )}
                        {values.creatorType !== '' && values.transformationType !== '' && (
                          <Dropdown
                            label="Topic"
                            name="topic"
                            value={values.topic}
                            readonly={!editMode}
                            disabled={values.creatorType === '' || values.transformationType === ''}
                            onChange={handleChange}
                            options={values.creatorType === '' || values.entityId === '' || values.transformationType === '' ? [] : getTopicOptions(values.creatorType, values.transformationType)}
                            errorMessage={submitCount > 0 && errors.topic}
                          />
                        )}
                      </div>
                      <div>
                        {/* <TextArea
                          label="Code Snippet"
                          className="code-snippet"
                          name="codeSnippet"
                          value={values.codeSnippet}
                          readonly={transformationLoading}
                          onChange={handleChange}
                          placeholder="Paste Code Snippet Here"
                          errorMessage={submitCount > 0 && errors.codeSnippet}
                        /> */}
                        <Editor
                          className="code-snippet"
                          options={editorOptions}
                          height="300px"
                          defaultLanguage="javascript"
                          defaultValue="// add your code here"
                        />
                      </div>
                    </Card.Body>
                  </Card>
                  <Card className="test-snippet-card">
                    <Card.Header>
                      Test Code Snippet
                      {values.sourceVer && (
                        <div className="button-container" onClick={e => e.stopPropagation()}>
                          <Dropdown
                            name="testAgainst"
                            className="test-against-dropdown"
                            value={testAgainst}
                            readonly={transformationLoading}
                            tooltip="Testing against a deployed snippet will use the deployed version of the code above"
                            onChange={(e) => {
                              setFieldValue('snippetOutput', '');
                              setTestAgainst(e.target.value);
                            }}
                            options={[
                              { value: 'aboveCode', label: `Test Against : Above Code Snippet` },
                              { value: 'deployedCode', label: `Test Against : Deployed Code Snippet` }
                            ]}
                            showErrorMessages={false}
                          />
                        </div>
                      )}
                    </Card.Header>
                    <Card.Body className="test-snippet-body">
                      {testAgainst === 'aboveCode' && (
                        <TextArea
                          label="Input String"
                          className="code-snippet-input"
                          name="snippetInput"
                          value={values.snippetInput}
                          readonly={transformationLoading}
                          onChange={handleChange}
                          placeholder="Paste Test String Here"
                          errorMessage={submitCount > 0 && errors.snippetInput}
                        />
                      )}
                      {testAgainst === 'deployedCode' && (
                        <div className="test-against-deployed">
                          <Input
                            label="Destination URL"
                            name={`destinationUrl`}
                            className="destination-url"
                            value={values.destinationUrl}
                            tooltip="Optional : If provided, the transformed payload will be sent to this URL"
                            onChange={handleChange}
                            placeholder="Destination URL"
                            errorMessage={submitCount > 0 && errors.destinationUrl}
                          />
                          <div className="content-type-method-container">
                            <Dropdown
                              label="Content Method"
                              name="contentMethod"
                              className="content-method"
                              value={values.contentMethod}
                              onChange={handleChange}
                              options={contentMethods.map(contentMethod => (
                                { value: contentMethod.Value, label: contentMethod.Name }
                              ))}
                              errorMessage={submitCount > 0 && errors.contentMethod}
                            />
                            <Dropdown
                              label="Content Type"
                              name="contentType"
                              className="content-type"
                              value={values.contentType}
                              onChange={handleChange}
                              options={contentTypes.map(contentType => (
                                { value: contentType.Value, label: contentType.Name }
                              ))}
                              errorMessage={submitCount > 0 && errors.contentType}
                            />
                          </div>
                          {values.contentHeaders.map((header, index) => (
                            <div className="content-type-method-container" key={`${header}-${index}`}>
                              <Input
                                label="Header Key"
                                name={`contentHeaders[${index}].key`}
                                className="header-key"
                                value={values.contentHeaders[index].key}
                                onChange={(e) => {
                                  // check to see if this is the last header, if so, add a new one
                                  if (index === values.contentHeaders.length - 1) {
                                    setFieldValue('contentHeaders', [...values.contentHeaders, { key: '', value: '' }]);
                                  }
                                  handleChange(e);
                                }}
                                placeholder="Header Key"
                                errorMessage={submitCount > 0 && errors.contentHeaders && errors.contentHeaders[index] && errors.contentHeaders[index].key}
                              />
                              <Input
                                label="Header Value"
                                name={`contentHeaders[${index}].value`}
                                className="header-value"
                                value={values.contentHeaders[index].value}
                                tooltip="Optional : Pass additional headers to the destination URL"
                                onChange={(e) => {
                                  // check to see if this is the last header, if so, add a new one
                                  if (index === values.contentHeaders.length - 1) {
                                    setFieldValue('contentHeaders', [...values.contentHeaders, { key: '', value: '' }]);
                                  }
                                  handleChange(e);
                                }}
                                placeholder="Header Value"
                                errorMessage={submitCount > 0 && errors.contentHeaders && errors.contentHeaders[index] && errors.contentHeaders[index].value}
                              />
                            </div>
                          ))}

                          <TextArea
                            label="Input String"
                            className="code-snippet-input2"
                            name="snippetInput"
                            value={values.snippetInput}
                            onChange={handleChange}
                            placeholder="Paste Test String Here"
                            errorMessage={submitCount > 0 && errors.snippetInput}
                          />
                        </div>
                      )}
                      <div className="transform-button">
                        <Button
                          variant="secondary"
                          size="small"
                          label="Transform"
                          disabled={transformationLoading || values.snippetInput.length === 0}
                          imageRight={<ArrowRight />}
                          onClick={() => {
                            // cancel the previous timeout (if any)
                            if (highlighted) {
                              clearTimeout(highlighted);
                              setHighlighted(null);
                            }

                            if (testAgainst === 'aboveCode') {
                              // create a function from the code
                              try {
                                const dynamicFunction = new Function("payload", values.codeSnippet);
                                const result = dynamicFunction(JSON.parse(values.snippetInput));
                                const resultJSON = JSON.stringify(result, null, 2);
                                setFieldValue('snippetOutput', resultJSON || '< no output returned >');
                              } catch (error) {
                                setFieldValue('snippetOutput', error);
                              }
                              requestAnimationFrame(() => {
                                const timeoutId = setTimeout(() => {
                                  setHighlighted(null);
                                }, 2500);
                                setHighlighted(timeoutId);
                              });
                            } else {
                              setFieldValue('snippetOutput', '');

                              const headerArray = values.contentHeaders
                                .filter(item => item.key !== '' && item.value !== '') // Filter out objects with empty key or value
                                .map(item => `${item.key}:${item.value}`);

                              dispatch(testDeployedTransformation({
                                data: {
                                  body: values.snippetInput,
                                  requestId: generateGUID(),
                                  destinationName: values.destinationName,
                                  destinationVersion: values.destinationVer,
                                  destinationMethod: values.contentMethod,
                                  destinationMediaType: values.contentType,
                                  destinationMediaUri: values.destinationUrl,
                                  destinationHeaders: headerArray,
                                  snippetId: values.snippetId,
                                },
                                cb: (resp) => {
                                  setFieldValue('snippetOutput', JSON.stringify(resp));

                                  const timeoutId = setTimeout(() => {
                                    setHighlighted(null);
                                  }, 2500);
                                  setHighlighted(timeoutId);
                                }
                              }));
                            }
                          }}
                        />
                      </div>
                      <TextArea
                        label="Transformation Results"
                        className={`code-snippet-output ${highlighted ? 'highlight-animation' : ''}`}
                        name="snippetOutput"
                        value={values.snippetOutput}
                        readonly={true}
                        onChange={handleChange}
                        errorMessage={submitCount > 0 && errors.snippetOutput}
                      />
                    </Card.Body>
                  </Card>
                </>
              </form>
            )
          }}
        </Formik>
      )}
    </div>
  )
}

export default TransformationDetails;