import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Modal, ButtonMenu } from 'app/components';
import { deleteRoutingStrategy } from 'app/store/actions/routing'
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import './index.scss';

const AdditionalStrategyOptions = props => {
  const {strategyId, onEditStrategy, onRoutingStrategyDeleted} = props; 
  const [deleteStrategyModal, showDeleteStrategyModal] = useState(false);
  const dispatch = useDispatch();
  const { merchantId } = useParams();

  return (
    <div className="additional-strategy-options">
      <ButtonMenu
        label="Strategy Options"
        variant="primary"
        size="small"
        options={ [
          { value: 'Activate Strategy', label: 'Activate Strategy', onClick: () => toast.error("Activate Strategy Not Implemented Yet", {
            theme: 'colored',
          })},
          { value: 'Edit Strategy', label: 'Edit Strategy', onClick: () => onEditStrategy() },
          { value: 'Delete Strategy', label: 'Delete Strategy', destructive: true, onClick: () => showDeleteStrategyModal(true) },
        ]}
        width={168}
      />
      {deleteStrategyModal && (
        <Modal
          secondaryButtonLabel="No, Keep It"
          secondaryButtonOnClick={() => { showDeleteStrategyModal(false)}}
          primaryButtonLabel="Yes, Delete It"
          primaryButtonVariant="primary"
          primaryButtonDestructive
          primaryButtonOnClick={() => {
            dispatch(deleteRoutingStrategy({merchantId, strategyId, cb: onRoutingStrategyDeleted}));
            showDeleteStrategyModal(false);
          }}
          onClose={() => showDeleteStrategyModal(false)}
        >
          <div>Are you sure you want to delete this strategy?</div>
          <div>This action is not reversible.</div>
        </Modal>
      )}
    </div>
  )
}

export default AdditionalStrategyOptions;