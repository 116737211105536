import {
  getVendors,
  getVendorsSuccess,
  getVendorsFailure,
  getVendorsBulk,
  getVendorsBulkSuccess,
  getVendorsBulkFailure,
  getVendorDetails,
  getVendorDetailsSuccess,
  getVendorDetailsFailure,
  createVendor,
  createVendorSuccess,
  createVendorFailure,
  updateVendor,
  updateVendorSuccess,
  updateVendorFailure,
  getFacilityDetails,
  getFacilityDetailsSuccess,
  getFacilityDetailsFailure,
  getProductsByVendor,
  getProductsByVendorSuccess,
  getProductsByVendorFailure,
  addProductsToVendor,
  addProductsToVendorSuccess,
  addProductsToVendorFailure,
  addFacility,
  addFacilitySuccess,
  addFacilityFailure,
  updateFacility,
  updateFacilitySuccess,
  updateFacilityFailure,
  getAuditLogs,
  getAuditLogsSuccess,
  getAuditLogsFailure,
  getAllValueAddedServices,
  getAllValueAddedServicesSuccess,
  getAllValueAddedServicesFailure,
  clearErrors, 
  reset
} from "app/store/actions/vendor"
import { handleActions } from 'redux-actions';

const initialState = {
  vendors: {
    loading: false,
    data: null,
    error: null,
  },
  vendor: {
    loading: false,
    data: null,
    error: null,
  },
  vendorsBulk: {
    loading: false,
    data: null,
    error: null,
  },
  facility: {
    loading: false,
    data: null,
    error: null,
  },
  products: {
    loading: false,
    data: null,
    error: null,
  },
  auditLogs: {
    loading: false,
    data: null,
    error: null,
  },
  addUpdateProducts: {
    loading: false,
    error: null,
  },
  vas: {
    loading: false,
    data: null,
    error: null,
  }
}

const vendorReducer = handleActions(
  {
    [getVendors]: (state) => ({ 
      ...state,
      vendors: {
        ...state.vendors,
        loading: true,
        error: null,
      }
    }),

    [getVendorsSuccess]: (state, data) => ({
      ...state,
      vendors: {
        loading: false,
        data: data.payload,
        error: null,
      }
    }),

    [getVendorsFailure]: (state, data) => ({ 
      ...state,
      vendors: {
        ...state.vendors,
        loading: false,
        error: data.payload,
      }
    }),

    [getVendorsBulk]: (state) => ({
      ...state,
      vendorsBulk: {
        ...state.vendorsBulk,
        loading: true,
        error: null,
      }
    }),

    [getVendorsBulkSuccess]: (state, data) => ({
      ...state,
      vendorsBulk: {
        loading: false,
        data: data.payload,
        error: null,
      }
    }),

    [getVendorsBulkFailure]: (state, data) => ({
      ...state,
      vendorsBulk: {
        loading: false,
        data: null,
        error: data.payload,
      }
    }),
    
    [getVendorDetails]: (state) => ({ 
      ...state,
      vendor: {
        ...state.vendor,
        loading: true,
        error: null,
      },
    }),

    [getVendorDetailsSuccess]: (state, data) => ({
      ...state,
      vendor: {
        loading: false,
        data: data.payload.vendor,
        error: null,
      },
    }),

    [getVendorDetailsFailure]: (state, data) => ({ 
      ...state,
      vendor: {
        loading: false,
        data: null,
        error: data.payload,
      },
    }),

    [createVendor]: (state) => ({ 
      ...state,
      vendor: {
        ...state.vendor,
        loading: true,
        error: null,
      },
    }),

    [createVendorSuccess]: (state) => ({
      ...state,
      vendor: {
        ...state.vendor,
        loading: false,
        error: null,
      },
    }),

    [createVendorFailure]: (state, data) => ({ 
      ...state,
      vendor: {
        ...state.vendor,
        loading: false,
        error: data.payload,
      },
    }),

    [updateVendor]: (state) => ({ 
      ...state,
      vendor: {
        ...state.vendor,
        loading: true,
        error: null,
      },
    }),

    [updateVendorSuccess]: (state, data) => ({
      ...state,
      vendor: {
        ...state.vendor,
        loading: false,
        data: {
          ...state.vendor.data,
          ...data.payload.data,
        },
        error: null,
      },
    }),

    [updateVendorFailure]: (state, data) => ({ 
      ...state,
      vendor: {
        ...state.vendor,
        loading: false,
        error: data.payload,
      },
    }),

    [getFacilityDetails]: (state) => ({
      ...state,
      facility: {
        ...state.facility,
        loading: true,
        error: null,
      },
    }),

    [getFacilityDetailsSuccess]: (state, data) => ({
      ...state,
      facility: {
        loading: false,
        data: data.payload.facility,
        error: null,
      },
    }),

    [getFacilityDetailsFailure]: (state, data) => ({
      ...state,
      facility: {
        loading: false,
        data: null,
        error: data.payload,
      },
    }),

    [getProductsByVendor]: (state) => ({ 
      ...state,
      products: {
        ...state.products,
        loading: true,
        error: null,
      },
    }),

    [getProductsByVendorSuccess]: (state, data) => ({
      ...state,
      products: {
        loading: false,
        data: data.payload?.data,
        error: null,
      },
    }),

    [getProductsByVendorFailure]: (state, data) => ({ 
      ...state,
      products: {
        loading: false,
        data: null,
        error: data.payload,
      },
    }),

    [addProductsToVendor]: (state) => ({ 
      ...state,
      addUpdateProducts: {
        loading: true,
        error: null,
      },
    }),

    [addProductsToVendorSuccess]: (state) => ({
      ...state,
      addUpdateProducts: {
        loading: false,
        error: null,
      },
    }),

    [addProductsToVendorFailure]: (state, data) => ({ 
      ...state,
      addUpdateProducts: {
        loading: false,
        error: data.payload,
      },
    }),

    [addFacility]: (state) => ({ 
      ...state,
      facility: {
        ...state.facility,
        loading: true,
        error: null,
      },
    }),

    [addFacilitySuccess]: (state, data) => ({
      ...state,
      facility: {
        loading: false,
        data: data.payload.data,
        error: null,
      },
    }),

    [addFacilityFailure]: (state, data) => ({ 
      ...state,
      facility: {
        ...state.facility,
        loading: false,
        error: data.payload,
      },
    }),

    [updateFacility]: (state) => ({ 
      ...state,
      facility: {
        ...state.facility,
        loading: true,
        error: null,
      },
    }),

    [updateFacilitySuccess]: (state, data) => {
      const { resp } = data.payload;

      return ({
      ...state,
      facility: {
        loading: false,
        data: resp.facility,
        error: null,
      },
    })},

    [updateFacilityFailure]: (state, data) => ({ 
      ...state,
      facility: {
        ...state.facility,
        loading: false,
        error: data.payload,
      },
    }),

    [getAuditLogs]: (state) => ({
      ...state,
      auditLogs: {
        ...state.auditLogs,
        loading: true,
        error: null,
      },
    }),

    [getAuditLogsSuccess]: (state, data) => ({
      ...state,
      auditLogs: {
        loading: false,
        data: data.payload,
        error: null,
      },
    }),

    [getAuditLogsFailure]: (state, data) => ({
      ...state,
      auditLogs: {
        loading: false,
        data: null,
        error: data.payload,
      },
    }),

    [getAllValueAddedServices]: (state) => ({
      ...state,
      vas: {
        ...state.vas,
        loading: true,
        error: null,
      },
    }),

    [getAllValueAddedServicesSuccess]: (state, data) => ({
      ...state,
      vas: {
        loading: false,
        data: data.payload.vas,
        error: null,
      },
    }),

    [getAllValueAddedServicesFailure]: (state, data) => ({
      ...state,
      vas: {
        loading: false,
        data: null,
        error: data.payload,
      },
    }),

    [clearErrors]: (state) => ({ 
      ...state,
      vendors: {
        ...state.vendors,
        error: null,
      },
      vendor: {
        ...state.vendor,
        error: null,
      },
      products: {
        ...state.products,
        error: null,
      },
      addUpdateProducts: {
        ...state.addUpdateProducts,
        error: null,
      },
    }),

    [reset]: () => ({ 
      ...initialState
     })
  },
  initialState,
)

export default vendorReducer;