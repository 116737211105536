import {
  getAllQueries,
  getAllQueriesSuccess,
  getAllQueriesFailure,
  runQueryById,
  runQueryByIdSuccess,
  runQueryByIdFailure,
  reset,
} from "app/store/actions/insight"
import { handleActions } from 'redux-actions';

const initialState = {
  queries: {
    loading: false,
    data: null,
    error: null,
  },
  queryData: {
  },
}

const insightReducer = handleActions(
  {
    [getAllQueries]: (state) => ({ 
      ...state,
      queries: {
        loading: true,
        data: null,
        error: null,
      },
    }),

    [getAllQueriesSuccess]: (state, data) => ({
      ...state,
      queries: {
        loading: false,
        data: data.payload,
        error: null,
      },
    }),

    [getAllQueriesFailure]: (state, data) => ({ 
      ...state,
      queries: {
        loading: false,
        data: null,
        error: data.payload,
      },
    }),

    [runQueryById]: (state, data) => {
      const { query: { queryId }, dateRange } = data.payload;

      const id = `${queryId}-${dateRange}`;

      // make a copy of the queryData in the state
      const queryData = { ...state.queryData };

      // initialize the queryId object if it does not exist
      if (!queryData[queryId]) {
        queryData[id] = {};
      }

      // the data object has the queryId.  We will set the queryId in the queryData object
      queryData[id].loading = true;
      queryData[id].data = null;
      queryData[id].refreshedAt = null;
      queryData[id].error = null;
      
      return ({ 
        ...state,
        queryData,
      })
    },

    [runQueryByIdSuccess]: (state, data) => {
      const { queryId, dateRange, resp, refreshedAt } = data.payload;
      // make a copy of the queryData in the state
      const queryData = { ...state.queryData };
      // create an id based on the queryId and dateRange
      const id = `${queryId}-${dateRange}`;

      // the data object has the queryId.  We will set the queryId in the queryData object
      queryData[id].loading = false;
      queryData[id].data = resp.data;
      queryData[id].error = null;

      refreshedAt && (queryData[id].refreshedAt = refreshedAt);

      return ({
        ...state,
        queryData,
      })
    },

    [runQueryByIdFailure]: (state, data) => {
      const { queryId, dateRange, error } = data.payload;
      // make a copy of the queryData in the state
      const queryData = { ...state.queryData };
      // create an id based on the queryId and dateRange
      const id = `${queryId}-${dateRange}`;
      // the data object has the queryId.  We will set the queryId in the queryData object
      queryData[id].loading = false;
      queryData[id].data = null;
      queryData[id].refreshedAt = null;
      queryData[id].error = error;
      
      return ({ 
        ...state,
        queryData,
      })
    },

    [reset]: () => ({ 
     ...initialState
    })
  },
  initialState,
)

export default insightReducer;