import {
  getMerchants,
  getMerchantsSuccess,
  getMerchantsFailure,
  getMerchantDetails,
  getMerchantDetailsSuccess,
  getMerchantDetailsFailure,
  createMerchant,
  createMerchantSuccess,
  createMerchantFailure,
  updateMerchant,
  updateMerchantSuccess,
  updateMerchantFailure,
  updateMerchantsParent,
  updateMerchantsParentSuccess,
  updateMerchantsParentFailure,
  updateMerchantsChildren,
  updateMerchantsChildrenSuccess,
  updateMerchantsChildrenFailure,
  removeAllAssociations,
  removeAllAssociationsSuccess,
  removeAllAssociationsFailure,
  clearErrors,
  reset,
} from "app/store/actions/merchant"
import { handleActions } from 'redux-actions';

const initialState = {
  merchants: {
    loading: false,
    data: null,
    error: null,
  },
  merchant: {
    loading: false,
    data: null,
    error: null,
  },
  merchantProducts: {
    loading: false,
    data: null,
    error: null,
  },
}

const merchantReducer = handleActions(
  {
    [getMerchants]: (state) => ({ 
      ...state,
      merchants: {
        ...state.merchants,
        loading: true,
        error: null,
      }
    }),

    [getMerchantsSuccess]: (state, data) => {
      // remove any merchants that do not have a name
      const merchants = data.payload.merchants.filter(merchant => merchant.name);
      
      return ({
      ...state,
      merchants: {
        ...state.merchants,
        loading: false,
        data: { merchants, total: data.payload.total },
      }
    })},

    [getMerchantsFailure]: (state, data) => ({ 
      ...state,
      merchants: {
        ...state.merchants,
        loading: false,
        error: data.payload,
      }
    }),

    [getMerchantDetails]: (state) => ({ 
      ...state,
      merchant: {
        ...state.merchant,
        loading: true,
        error: null,
      },
    }),

    [getMerchantDetailsSuccess]: (state, data) => ({
      ...state,
      merchant: {
        loading: false,
        data: data.payload,
        error: null,
      },
    }),

    [getMerchantDetailsFailure]: (state, data) => ({ 
      ...state,
      merchant: {
        loading: false,
        data: null,
        error: data.payload,
      },
    }),

    [createMerchant]: (state) => ({ 
      ...state,
      merchant: {
        ...state.merchant,
        loading: true,
        error: null,
      },
    }),

    [createMerchantSuccess]: (state) => ({
      ...state,
      merchant: {
        ...state.merchant,
        loading: false,
      },
    }),

    [createMerchantFailure]: (state, data) => ({ 
      ...state,
      merchant: {
        ...state.merchant,
        loading: false,
        error: data.payload,
      },
    }),

    [updateMerchant]: (state) => ({ 
      ...state,
      merchant: {
        ...state.merchant,
        loading: true,
        error: null,
      },
    }),

    [updateMerchantSuccess]: (state, data) => ({
      ...state,
      merchant: {
        loading: false,
        data: data.payload,
        error: null,
      },
    }),

    [updateMerchantFailure]: (state, data) => ({ 
      ...state,
      merchant: {
        ...state.merchant,
        loading: false,
        error: data.payload,
      },
    }),

    [updateMerchantsParent]: (state) => ({ 
      ...state,
      merchant: {
        ...state.merchant,
        loading: true,
        error: null,
      },
    }),

    [updateMerchantsParentSuccess]: (state, data) => ({
      ...state,
      merchant: {
        loading: false,
        data: data.payload,
        error: null,
      },
    }),

    [updateMerchantsParentFailure]: (state, data) => ({ 
      ...state,
      merchant: {
        ...state.merchant,
        loading: false,
        error: data.payload,
      },
    }),

    [updateMerchantsChildren]: (state) => ({ 
      ...state,
      merchant: {
        ...state.merchant,
        loading: true,
        error: null,
      },
    }),

    [updateMerchantsChildrenSuccess]: (state, data) => ({
      ...state,
      merchant: {
        loading: false,
        data: data.payload,
        error: null,
      },
    }),

    [updateMerchantsChildrenFailure]: (state, data) => ({ 
      ...state,
      merchant: {
        ...state.merchant,
        loading: false,
        error: data.payload,
      },
    }),

    [removeAllAssociations]: (state) => ({ 
      ...state,
      merchant: {
        ...state.merchant,
        loading: true,
        error: null,
      },
    }),

    [removeAllAssociationsSuccess]: (state, data) => ({
      ...state,
      merchant: {
        loading: false,
        data: data.payload,
        error: null,
      },
    }),

    [removeAllAssociationsFailure]: (state, data) => ({ 
      ...state,
      merchant: {
        ...state.merchant,
        loading: false,
        error: data.payload,
      },
    }),

    [clearErrors]: (state) => ({ 
      ...state,
      merchants: {
        ...state.merchants,
        error: null,
      },
      merchant: {
        ...state.merchant,
        error: null,
      },
      merchantProducts: {
        ...state.merchantProducts,
        error: null,
      },
      
    }),

    [reset]: () => ({ 
     ...initialState
    })
  },
  initialState,
)

export default merchantReducer;