import React from 'react';
import { useDispatch } from 'react-redux';
import { cancelOrderItems } from 'app/store/actions/order';
import { Modal } from 'app/components';

const CancelOrderItemModal = props => {
  const {
    orderId,
    itemId,
    showCancelOrderItemModal,
  } = props;
  const dispatch = useDispatch();

  const itemData = {
    orderItemIds: [itemId],
  }

  return (
    <Modal
      secondaryButtonLabel="No, Keep It"
      secondaryButtonOnClick={() => { showCancelOrderItemModal(false)}}
      primaryButtonLabel="Yes, Cancel It"
      primaryButtonVariant="primary"
      primaryButtonDestructive
      primaryButtonOnClick={() => {
        dispatch(cancelOrderItems({orderId, data: itemData}));
        showCancelOrderItemModal(false);
      }}
      onClose={() => showCancelOrderItemModal(false)}
    >
      <div>Are you sure you want to cancel this item?</div>
      <div>This action is not reversible.</div>
    </Modal>
  )
}

export default CancelOrderItemModal;