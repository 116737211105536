import {
  createOrder,
  createOrderSuccess,
  createOrderFailure,
  globalOrdersSearch,
  globalOrdersSearchSuccess,
  globalOrdersSearchFailure,
  globalOrdersSearchClear,
  getOrders,
  getOrdersSuccess,
  getOrdersFailure,
  getOrderDetails,
  getOrderDetailsSuccess,
  getOrderDetailsFailure,
  updateShippingAddress,
  updateShippingAddressSuccess,
  updateShippingAddressFailure,
  updateBillingAddress,
  updateBillingAddressSuccess,
  updateBillingAddressFailure,
  cancelOrder,
  cancelOrderSuccess,
  cancelOrderFailure,
  cancelOrderItems,
  cancelOrderItemsSuccess,
  cancelOrderItemsFailure,
  updateItemImageUrl,
  updateItemImageUrlSuccess,
  updateItemImageUrlFailure,
  orderItemSelection,
  saveSelectedProducts,
  clearErrors,
  reset,
} from "app/store/actions/order"
import { handleActions } from 'redux-actions';

const initialState = {
  createOrder: {
    loading: false,
    data: null,
    error: false,
  },
  orders: {
    loading: false,
    data: null,
    error: false,
  },
  order: {
    loading: false,
    data: null,
    error: null,
  },
  globalSearchResults: {
    loading: false,
    data: null,
    error: null,
  },
  placeAnOrder: {
    loading: false,
    selectedProducts: null,
    error: null,
  }
}

const orderReducer = handleActions(
  {
    [createOrder]: (state) => ({ 
      ...state,
      createOrder: {
        ...state.createOrder,
        loading: true,
        error: false,
      }
    }),

    [createOrderSuccess]: (state, data) => ({
      ...state,
      createOrder: {
        loading: false,
        data: data.payload,
        error: false,
      }
    }),

    [createOrderFailure]: (state) => ({ 
      ...state,
      createOrder: {
        loading: false,
        data: null,
        error: true,
      }
    }),

    [globalOrdersSearch]: (state) => ({ 
      ...state,
      globalSearchResults: {
        ...state.globalSearchResults,
        loading: true,
        error: false,
      }
    }),

    [globalOrdersSearchSuccess]: (state, data) => ({
      ...state,
      globalSearchResults: {
        loading: false,
        data: data.payload,
        error: false,
      }
    }),

    [globalOrdersSearchFailure]: (state) => ({ 
      ...state,
      globalSearchResults: {
        loading: false,
        data: null,
        error: true,
      }
    }),

    [globalOrdersSearchClear]: (state) => ({ 
      ...state,
      globalSearchResults: {
        loading: false,
        data: null,
        error: false,
      }
    }),

    [getOrders]: (state) => ({ 
      ...state,
      orders: {
        ...state.orders,
        loading: true,
        error: false,
      }
    }),

    [getOrdersSuccess]: (state, data) => ({
      ...state,
      orders: {
        loading: false,
        data: data.payload,
        error: false,
      }
    }),

    [getOrdersFailure]: (state) => ({ 
      ...state,
      orders: {
        loading: false,
        data: null,
        error: true,
      }
    }),

    [getOrderDetails]: (state) => ({ 
      ...state,
      order: {
        loading: true,
        data: null,
        error: null,
      },
    }),

    [getOrderDetailsSuccess]: (state, data) => ({
      ...state,
      order: {
        loading: false,
        data: data.payload.order,
        error: null,
      },
    }),

    [getOrderDetailsFailure]: (state, data) => ({ 
      ...state,
      order: {
        loading: false,
        data: null,
        error: data.payload,
      },
    }),

    [updateShippingAddress]: (state) => ({ 
      ...state,
      order: {
        ...state.order,
        loading: true,
      },
    }),

    [updateShippingAddressSuccess]: (state, data) => ({
      ...state,
      order: {
        ...state.order,
        data: {
          ...state.order.data,
          shippingAddress: data.payload.data.shippingAddress,
        },
        loading: false,
      },
    }),

    [updateShippingAddressFailure]: (state, data) => ({ 
      ...state,
      order: {
        ...state.order,
        loading: false,
        error: data.payload,
      },
    }),

    [updateBillingAddress]: (state) => ({ 
      ...state,
      order: {
        ...state.order,
        loading: true,
      },
    }),

    [updateBillingAddressSuccess]: (state, data) => ({
      ...state,
      order: {
        ...state.order,
        data: {
          ...state.order.data,
          billingAddress: data.payload.data.billingAddress,
        },
        loading: false,
      },
    }),

    [updateBillingAddressFailure]: (state, data) => ({ 
      ...state,
      order: {
        ...state.order,
        loading: false,
        error: data.payload,
      },
    }),

    [cancelOrder]: (state) => ({ 
      ...state,
      order: {
        ...state.order,
        loading: true,
        error: null,
      },
    }),

    [cancelOrderSuccess]: (state) => ({
      ...state,
      order: {
        loading: false,
        data: {
          ...state.order.data,
          status: 'canceled'
        },  
        error: null,
      },
    }),

    [cancelOrderFailure]: (state, data) => ({ 
      ...state,
      order: {
        ...state.order,
        loading: false,
        error: data.payload,
      },
    }),

    [cancelOrderItems]: (state) => ({ 
      ...state,
      order: {
        ...state.order,
        loading: true,
        error: null,
      },
    }),

    [cancelOrderItemsSuccess]: (state, data) => {
      const canceledItemsIds = [...data.payload.orderItemIds];
      const items = [...state.order.data.items];

      for (let i = 0; i < items.length; i++) {
        if (canceledItemsIds.includes(items[i].id)) {
          items[i].status = 'canceled';
        }
        items[i].selected = false;
      }

      return ({
      ...state,
      order: {
        loading: false,
        data: {
          ...state.order.data,
          items
        },  
        error: null,
      },
    })},

    [cancelOrderItemsFailure]: (state, data) => ({ 
      ...state,
      order: {
        ...state.order,
        loading: false,
        error: data.payload,
      },
    }),

    [updateItemImageUrl]: (state) => ({ 
      ...state,
      order: {
        ...state.order,
        loading: true,
        error: null,
      },
    }),

    [updateItemImageUrlSuccess]: (state, data) => {
      const itemId = data.payload.itemId;
      let items = [...state.order.data.items];

      for (let i = 0; i < items.length; i++) {
        if (items[i].id === itemId) {
          items[i].images = data.payload.data.images;
          break;
        }
      }

      return ({
      ...state,
      order: {
        loading: false,
        data: {
          ...state.order.data,
          items
        },  
        error: null,
      },
    })},

    [updateItemImageUrlFailure]: (state) => ({ 
      ...state,
      order: {
        ...state.order,
        loading: false,
      },
    }),

    [orderItemSelection]: (state, data) => {
      let filteredOrderItems;
      let itemsFilter = data.payload.filter || null;
      const filter = data.payload.filter;
      const itemId = data.payload.itemId;
      const orderItems = [...state.order.data.items];

      if (itemId) {
        filteredOrderItems = orderItems.map(item => ({
          ...item,
          selected: itemId === item.id ? !item.selected : item.selected,
        }))
      } else if (filter === 'all') {
        filteredOrderItems = orderItems.map(item => ({
          ...item,
          selected: true
        }))
      } else if (filter) {
        filteredOrderItems = orderItems.map(item => ({
          ...item,
          selected: item.status === filter
        }))
      } else {
        filteredOrderItems = orderItems;
      } 

      return ({ 
      ...state,
      order: {
        ...state.order,
        data: {
          ...state.order.data,
          items: filteredOrderItems,
          itemsFilter
        }
      },
    })},
    
    [saveSelectedProducts]: (state, data) => ({ 
      ...state,
      placeAnOrder: {
        ...state.placeAnOrder.selectedProducts,
        selectedProducts: data.payload.selectedProducts
      },
    }),

    [clearErrors]: (state) => ({ 
      ...state,
      orders: {
        ...state.orders,
        loading: false,
      },
      order: {
        ...state.order,
        loading: false,
      },
    }),

    [reset]: () => ({ 
     ...initialState
    })
  },
  initialState,
)

export default orderReducer;