import React from 'react';
import { Routes, Route } from 'react-router-dom';
import WebhooksView from './WebhooksView';
import WebhooksDetails from './WebhooksDetails';
import Customize from './WebhooksDetails/Customize';

function Webhooks() {
  return (
    <Routes>
      <Route path="/" element={<WebhooksView />} />
      <Route path="/action-history" element={<WebhooksView />} />
      <Route path="/create-webhook/*" element={<WebhooksDetails />} />
      <Route path="/:webhookId/customize" element={<Customize />} />
      <Route path="/:webhookId/edit" element={<WebhooksDetails startEdit={true} />} />
      <Route path="/:webhookId/*" element={<WebhooksDetails />} />
    </Routes>
  );
}

export default Webhooks;