import axios from 'axios';
import Config from '../config';
import SetupInterceptors from './axiosInterceptors';

class VendorServices {
  constructor() {
    this.api = axios.create({
      baseURL: Config.VendorMS.Url
    });

    SetupInterceptors(this.api);
  }

  createVendor = async (data) => {
    try {
      const response = await this.api.post(`/v1/vendors`, data);
      return response.data;
    } catch (error) {
      throw new Error('Error creating vendor');
    }
  };

  getVendors = async (data) => {
    const {
      searchString,
      currentPage,
      pageSize,
      sortBy,
      sortDir
    } = data;

    let queryString = "";

    // helper function to append parameter if it's not null
    const addParam = (key, value) => {
      if (value !== null && value !== undefined && value !== "") {
        queryString += `&${key}=${encodeURIComponent(value)}`;
      }
    };

    // append parameters dynamically if they are not null
    addParam('searchAll', searchString);
    addParam('page', currentPage);
    addParam('pageSize', pageSize);
    addParam('sortBy', sortBy);
    addParam('sortAsc', sortDir === 'asc');

    try {
      const response = await this.api.get(`/v1/vendors?${queryString}`);
      return response.data;
    } catch (error) {
      throw new Error('Error fetching data from API');
    }
  };

  getVendorsBulk = async (data) => {
    const queryString = data.map(id => `bulk=${id}`).join('&');

    try {
      const response = await this.api.get(`/v1/vendors/bulk?${queryString}`);
      return response.data;
    } catch (error) {
      throw new Error('Error fetching data from API');
    }
  };

  getVendorDetails = async (vendorId) => {
    try {
      const response = await this.api.get(`/v1/vendors/${vendorId}`);
      return response.data;
    } catch (error) {
      throw new Error('Error fetching vendor details');
    }
  };

  updateVendor  = async (vendorId, data) => {
    try {
      const response = await this.api.patch(`/v1/vendors/${vendorId}`, data);
      return response.data;
    } catch (error) {
      throw new Error('Error updating vendor details');
    }
  };

  deleteVendor  = async (vendorId) => {
    try {
      const response = await this.api.delete(`/v1/vendors/${vendorId}`);
      return response.data;
    } catch (error) {
      throw new Error('Error deleting vendor');
    }
  };

  getProductsByVendor = async (vendorId, facilityId, searchString, currentPage, pageSize, sortBy, sortDir) => {
    let queryString = "";

    // helper function to append parameter if it's not null
    const addParam = (key, value) => {
      if (value !== null && value !== undefined && value !== "") {
        queryString += `&${key}=${encodeURIComponent(value)}`;
      }
    };

    // append parameters dynamically if they are not null
    addParam('vendorId', vendorId);
    addParam('facilityId', facilityId);
    addParam('sku', searchString);
    addParam('page', currentPage);
    addParam('pageSize', pageSize);
    addParam('sortBy', sortBy);
    addParam('sortAsc', sortDir === 'asc');

    try {
      const response = await this.api.get(`/v1/vendorProducts?${queryString}`);
      return response.data;
    } catch (error) {
      throw new Error('Error fetching vendor details');
    }
  };

  addProductsToVendor = async (data) => {
    const response = await this.api.post('/v1/vendorProducts/bulk', data);
    return response.data;
  };

  addFacility = async (vendorId, data) => {
    try {
      const response = await this.api.post(`/v1/vendors/${vendorId}/facilities`, data);
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  };

  updateFacility = async (vendorId, facilityId, data) => {
    try {
      const response = await this.api.put(`/v1/vendors/${vendorId}/facilities/${facilityId}`, data);
      return response.data;
    } catch (error) {
      throw new Error('Error updating facility details');
    }
  };

  getFacilityDetails = async (vendorId, facilityId) => {
    try {
      const response = await this.api.get(`/v1/vendors/${vendorId}/facilities/${facilityId}`);
      return response.data;
    } catch (error) {
      throw new Error('Error fetching facility details');
    }
  };

  getAuditLogs = async (vendorId) => {
    try {
      const response = await this.api.get(`/v1/vendors/${vendorId}/auditLogs`);
      return response.data;
    } catch (error) {
      throw new Error('Error fetching audit logs');
    }
  }

  getAllValueAddedServices = async () => {
    try {
      const response = await this.api.get(`/v1/vas`);
      return response.data;
    } catch (error) {
      throw new Error('Error fetching value added services');
    }
  };
}

export default new VendorServices();