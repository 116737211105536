import { createAction } from 'redux-actions';

const prefix = "TRANSFORMATION";

export const getAllTransformations = createAction(`${prefix}/GET_ALL_TRANSFORMATIONS`);
export const getAllTransformationsSuccess = createAction(`${prefix}/GET_ALL_TRANSFORMATIONS_SUCCESS`);
export const getAllTransformationsFailure = createAction(`${prefix}/GET_ALL_TRANSFORMATIONS_FAILURE`);

export const getTransformationDetails = createAction(`${prefix}/GET_TRANSFORMATION_DETAILS`);
export const getTransformationDetailsSuccess = createAction(`${prefix}/GET_TRANSFORMATION_DETAILS_SUCCESS`);
export const getTransformationDetailsFailure = createAction(`${prefix}/GET_TRANSFORMATION_DETAILS_FAILURE`);

export const createTransformation = createAction(`${prefix}/CREATE_TRANSFORMATION`);
export const createTransformationSuccess = createAction(`${prefix}/CREATE_TRANSFORMATION_SUCCESS`);
export const createTransformationFailure = createAction(`${prefix}/CREATE_TRANSFORMATION_FAILURE`);

export const testDeployedTransformation = createAction(`${prefix}/TEST_DEPLOYED_TRANSFORMATION`);
export const testDeployedTransformationSuccess = createAction(`${prefix}/TEST_DEPLOYED_TRANSFORMATION_SUCCESS`);
export const testDeployedTransformationFailure = createAction(`${prefix}/TEST_DEPLOYED_TRANSFORMATION_FAILURE`);

export const promoteTransformation = createAction(`${prefix}/PROMOTE_TRANSFORMATION`);
export const promoteTransformationSuccess = createAction(`${prefix}/PROMOTE_TRANSFORMATION_SUCCESS`);
export const promoteTransformationFailure = createAction(`${prefix}/PROMOTE_TRANSFORMATION_FAILURE`);

export const clearErrors = createAction(`${prefix}/CLEAR_ERRORS`);
export const reset = createAction(`${prefix}/RESET`);