import { put, takeEvery, call, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import {
  getMerchants,
  getMerchantsSuccess,
  getMerchantsFailure,
  getMerchantDetails,
  getMerchantDetailsSuccess,
  getMerchantDetailsFailure,
  createMerchant,
  createMerchantSuccess,
  createMerchantFailure,
  updateMerchant,
  updateMerchantSuccess,
  updateMerchantFailure,
  updateMerchantsParent,
  updateMerchantsParentSuccess,
  updateMerchantsParentFailure,
  updateMerchantsChildren,
  updateMerchantsChildrenSuccess,
  updateMerchantsChildrenFailure,
  removeAllAssociations,
  removeAllAssociationsSuccess,
  removeAllAssociationsFailure,
} from "app/store/actions/merchant"
import MerchantServices from 'app/services/merchantServices';

function* fetchMerchants(action) {
  const { searchString, currentPage, pageSize, sortBy, sortDir } = action.payload;
  
  try {
    const data = yield call([MerchantServices, MerchantServices.getMerchants], searchString, currentPage, pageSize, sortBy, sortDir);

    data.merchants.sort((a, b) => {
      // fallback name if not present
      const nameA = a.name ? a.name.toLowerCase() : '';
      const nameB = b.name ? b.name.toLowerCase() : '';
  
      return nameA.localeCompare(nameB);
    });

    yield put(getMerchantsSuccess(data));
  } catch (error) {
    console.error('error', error);
    yield put(getMerchantsFailure(error));
  }
}

function* fetchMerchantDetails(action) {
  const merchantId = action.payload;
  try {
    const resp = yield call([MerchantServices, MerchantServices.getMerchantById], merchantId);
    yield put(getMerchantDetailsSuccess(resp));
  } catch (error) {
    console.error('error', error);
    yield put(getMerchantDetailsFailure(error));
  }
}

function* doCreateMerchant(action) {
  const { data, cb } = action.payload;
  try {
    const resp = yield call([MerchantServices, MerchantServices.createMerchant], data);
    yield put(createMerchantSuccess(resp));
    if(cb) cb(resp.id);
    toast.success("New Merchant Successfully Created", {
      theme: 'colored',
    });
  } catch (error) {
    console.error('error', error);
    yield put(createMerchantFailure(error));
    toast.error(error.toString(), {
      theme: 'colored',
    });
  }
}

function* doUpdateMerchant(action) {
  const { data, cb } = action.payload;

  try {
    const resp = yield call([MerchantServices, MerchantServices.updateMerchant], data);
    yield put(updateMerchantSuccess(resp));
    if(cb) cb(data.id);
    toast.success("Merchant Successfully Updated", {
      theme: 'colored',
    });
  } catch (error) {
    console.error('error', error);
    yield put(updateMerchantFailure(error));
    toast.error(error.toString(), {
      theme: 'colored',
    });
  }
}

function* doUpdateMerchantsParent(action) {
  const { merchantDetails, cb } = action.payload;

  // we are assigning a new parent to this merchant, if they have any existing children, we need to remove them first
  if (merchantDetails.children.length > 0) {
    const childrenSuccessfullyRemoved = [];

    // first remove all children from this parent
    for (let i = 0; i < merchantDetails.children.length; i++) {
      try {
        const childMerchant = merchantDetails.children[i];
        childMerchant.parentId = null;
        yield call([MerchantServices, MerchantServices.updateMerchant], childMerchant);
        childrenSuccessfullyRemoved.push(childMerchant);
      } catch (error) {
        console.error('error', error);
        break;
      }
    }

    // did all merchants get removed successfully?
    if (merchantDetails.children.length !== childrenSuccessfullyRemoved.length) {
      yield put(updateMerchantsParentFailure());
      toast.error("Merchant Association Failed To Update", {
        theme: 'colored',
      })
      return;
    }
  }

  // clear out the child merchants array
  merchantDetails.children = [];
  // remove the parent flag
  merchantDetails.isParent = false;

  // we have the entire parent object stored in the merchantDetails.  The api doesn't use that, but instead just needs the parent id
  merchantDetails.parentId = merchantDetails.parent.id;

  try {
    yield call([MerchantServices, MerchantServices.updateMerchant], merchantDetails);
    yield put(updateMerchantsParentSuccess(merchantDetails));
    if(cb) cb();
    toast.success("Merchant Association Successfully Updated", {
      theme: 'colored',
    });
  } catch (error) {
    console.error('error', error);
    yield put(updateMerchantsParentFailure(error));
    toast.error(error.toString(), {
      theme: 'colored',
    });
  }
}

function* doUpdateMerchantsChildren(action) {
  const { merchantDetails, updatedMerchantDetails, childrenToUpdate, cb } = action.payload;
  const childrenSuccessfullyAdded = [];

  // before adding or removing children, update the parent merchant to make sure they are a parent (only if they were not previously a parent)
  if (!merchantDetails.isParent) {
    try {
      merchantDetails.isParent = true;
      merchantDetails.parentId = null;
      yield call([MerchantServices, MerchantServices.updateMerchant], merchantDetails);
    } catch (error) {
      console.error('error', error);
      yield put(updateMerchantsChildrenFailure());
      toast.error("Merchant Association Failed To Update", {
        theme: 'colored',
      })
      return;
    }
  }

  // update all the merchants what were added or removed from the parent merchant
  for (let i = 0; i < childrenToUpdate.length; i++) {
    try {
      yield call([MerchantServices, MerchantServices.updateMerchant], childrenToUpdate[i]);
      childrenSuccessfullyAdded.push(childrenToUpdate[i]);
    } catch (error) {
      console.error('error', error);
      break;
    }
  }

  try {
    // did all merchants get updated successfully?
    if (childrenToUpdate.length === childrenSuccessfullyAdded.length) {
      updatedMerchantDetails.isParent = true;
      updatedMerchantDetails.parentId = null;
      yield call(fetchMerchants, { payload: { currentPage: 1, pageSize: 250 } });
      yield put(updateMerchantsChildrenSuccess(updatedMerchantDetails));
      toast.success("Merchant Association Successfully Updated", {
        theme: 'colored',
      });
      if(cb) cb();
    } else {
      yield put(updateMerchantsChildrenFailure());
      toast.error("Merchant Association Failed To Update", {
        theme: 'colored',
      })
    }
  } catch (error) {
    console.error('error', error);
    yield put(updateMerchantsChildrenFailure());
    toast.error("Merchant Association Failed To Update", {
      theme: 'colored',
    })
  }
}

function* doRemoveAllAssociations(action) {
  const { merchantDetails, cb } = action.payload;
  const childrenSuccessfullyRemoved = [];

  // are they are a parent trying to remove all their associations?
  if (merchantDetails.isParent) {
    // first remove all children from this parent 
    for (let i = 0; i < merchantDetails.children.length; i++) {
      try {
        const childMerchant = merchantDetails.children[i];
        childMerchant.parentId = null;
        yield call([MerchantServices, MerchantServices.updateMerchant], childMerchant);
        childrenSuccessfullyRemoved.push(childMerchant);
      } catch (error) {
        console.error('error', error);
        break;
      }
    }

    // did all merchants get removed successfully?
    if (merchantDetails.children.length === childrenSuccessfullyRemoved.length) {
      // now update the parent merchant to remove their parent flag
      try {
        merchantDetails.parent = null;
        merchantDetails.isParent = false;
        merchantDetails.children = [];
        yield call([MerchantServices, MerchantServices.updateMerchant], merchantDetails);
      } catch (error) {
        console.error('error', error);
        yield put(removeAllAssociationsFailure());
        toast.error("Merchant Association Failed To Update", {
          theme: 'colored',
        })
        return;
      }

      yield put(removeAllAssociationsSuccess(merchantDetails));
      toast.success("Merchant Association Successfully Updated", {
        theme: 'colored',
      });
      if(cb) cb();
    } else {
      yield put(removeAllAssociationsFailure());
      toast.error("Merchant Association Failed To Update", {
        theme: 'colored',
      })
    }
  } else {
    // they are a child merchant, just remove their parent
    try {
      merchantDetails.parent = null;
      merchantDetails.parentId = null;
      yield call([MerchantServices, MerchantServices.updateMerchant], merchantDetails);
      yield put(removeAllAssociationsSuccess(merchantDetails));
      toast.success("Merchant Association Successfully Updated", {
        theme: 'colored',
      });
      if(cb) cb();
    } catch (error) {
      console.error('error', error);
      yield put(removeAllAssociationsFailure());
      toast.error("Merchant Association Failed To Update", {
        theme: 'colored',
      })
    }
  }
}

function* watchData() {
  yield takeEvery(getMerchants().type, fetchMerchants);
  yield takeEvery(getMerchantDetails.toString(), fetchMerchantDetails);
  yield takeEvery(createMerchant.toString(), doCreateMerchant);
  yield takeEvery(updateMerchant.toString(), doUpdateMerchant);
  yield takeEvery(updateMerchantsParent.toString(), doUpdateMerchantsParent);
  yield takeEvery(updateMerchantsChildren.toString(), doUpdateMerchantsChildren);
  yield takeEvery(removeAllAssociations.toString(), doRemoveAllAssociations);

}

export default function* rootSaga() {
  yield all([
    watchData(),
  ]);
}