import { put, takeEvery, call, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import {
  getShipmentsByOrderId,
  getShipmentsByOrderIdSuccess,
  getShipmentsByOrderIdFailure,
  updateShipmentById,
  updateShipmentByIdSuccess,
  updateShipmentByIdFailure,
  rerouteShipment,
  rerouteShipmentSuccess,
  rerouteShipmentFailure,
  cancelShipment,
  cancelShipmentSuccess,
  cancelShipmentFailure,
} from "app/store/actions/shipment"
import ShipmentServices from 'app/services/shipmentServices';

function* fetchShipmentsByOrderId(action) {
  const orderId = action.payload;
  try {
    const resp = yield call([ShipmentServices, ShipmentServices.getShipmentsByOrderId], orderId);
    yield put(getShipmentsByOrderIdSuccess(resp));
  } catch (error) {
    console.error('error', error);
    yield put(getShipmentsByOrderIdFailure(error));
    toast.error("Loading Shipment Data Failed", {
      theme: 'colored',
    });
  }
}

function* doUpdateShipmentById(action) {
  const { shipmentId, data } = action.payload;
  try {
    yield call([ShipmentServices, ShipmentServices.updateShipmentById], shipmentId, data);
    yield put(updateShipmentByIdSuccess({shipmentId, data}));
    toast("Shipment successfully updated");
  } catch (error) {
    console.error('error', error);
    yield put(updateShipmentByIdFailure(error));
    toast.error("Failed to update shipment", {
      theme: 'colored',
    })
  }
}

function* doRerouteShipment(action) {
  const { data, cb } = action.payload;
  try {
    yield call([ShipmentServices, ShipmentServices.rerouteShipment], data);
    yield put(rerouteShipmentSuccess());
    toast.success("Your routing request was received", {
      theme: 'colored',
    });
    if(cb) cb();
  } catch (error) {
    console.error('error', error);
    yield put(rerouteShipmentFailure(error));
    toast.error("Failed to reroute shipment", {
      theme: 'colored',
    })
  }
}

function* doCancelShipment(action) {
  const { shipmentId } = action.payload;
  try {
    yield call([ShipmentServices, ShipmentServices.cancelShipment], shipmentId);
    yield put(cancelShipmentSuccess({shipmentId}));
    toast("Shipment cancellation successful");
  } catch (error) {
    console.error('error', error);
    yield put(cancelShipmentFailure(error));
    toast.error("Shipment cancellation Failed", {
      theme: 'colored',
    })
  }
}

function* watchData() {
  yield takeEvery(getShipmentsByOrderId.toString(), fetchShipmentsByOrderId);
  yield takeEvery(updateShipmentById.toString(), doUpdateShipmentById);
  yield takeEvery(rerouteShipment.toString(), doRerouteShipment);
  yield takeEvery(cancelShipment.toString(), doCancelShipment);
}

export default function* rootSaga() {
  yield all([
    watchData(),
  ]);
}