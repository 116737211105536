import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Image, Dropdown, LoadingAnimation } from 'app/components'
import { DashCircleFill } from 'react-bootstrap-icons';
import { getProductVariants } from 'app/store/actions/catalog';
import './index.scss';

const SelectedProductCard = props => {
  const {
    values,
    errors,
    handleChange,
    setFieldValue,
    submitCount,
    productIndex,
    productInfo,
    onProductRemoved
  } = props;
  const dispatch = useDispatch();

  const [itemInfo, setItemInfo] = useState(null);

  useEffect(() => {
    if (productInfo) {
      dispatch(getProductVariants({productId: productInfo.id, currentPage: 1, pageSize: 250, cb: onVariantInfo}));
    }
  }, []);

  const onVariantInfo = (data) => {
    data.variants = data.variants.filter(variant => variant.status === 'active');
    data.variants.sort((a, b) => a.sku.localeCompare(b.sku, undefined, { sensitivity: 'base' }));
    data.variants.unshift({ id: null, sku: ''})
    setItemInfo(data);
  }

  const getPrintLocations = (selectedVariantId) => {
    const selectedVariantInfo = itemInfo.variants.find(variant => variant.id === selectedVariantId);
    if (selectedVariantInfo) {
      const printAreasSet = new Set();
      selectedVariantInfo.decorations.forEach(decoration => {
        printAreasSet.add(decoration.area);
      });
      const printAreas = Array.from(printAreasSet).map(area => ({ value: area, label: area }));
      return printAreas;
    }
    return [];
  }

  if (!productInfo) return;

  return (
    <div className={`selected-product-card ${productInfo.selected ? 'selected' : ''}`}>
      <div className="item-image">
        <Image key={productInfo.images[0]} src={productInfo.images[0]} />
        <div className="item-title">
          <div className="item-sku">{productInfo.title}</div>
        </div>
      </div>
      <div className={`item-info ${!itemInfo ? 'loading' : ''} `}>
        {!itemInfo ? (
          <LoadingAnimation variant="secondary" fullscreen={false} />
        ) : (
          <>
            <Dropdown
              label="Variant"
              name={`selectedProducts.${productIndex}.variant`}
              value={values.selectedProducts[productIndex].variant}
              onChange={(e) => {
                const loc = getPrintLocations(e.target.value);
                if (loc?.length > 0) {
                  setFieldValue(`selectedProducts.${productIndex}.printLocation`, loc[0].value);
                  setFieldValue(`selectedProducts.${productIndex}.sku`, e.target.options[e.target.selectedIndex].text);
                } else {
                  setFieldValue(`selectedProducts.${productIndex}.printLocation`, '');
                }
                handleChange(e);
              }}
              options={itemInfo.variants.map(item => (
                { value: item.id, label: item.sku}
              ))}
              errorMessage={submitCount > 0 && errors.selectedProducts?.[productIndex]?.variant}
            />
            <Dropdown
              label="Print Location"
              name={`selectedProducts.${productIndex}.printLocation`}
              value={values.selectedProducts[productIndex].printLocation}
              onChange={handleChange}
              options={getPrintLocations(values.selectedProducts[productIndex].variant)}
              disabled={!values.selectedProducts[productIndex].variant}
            />
          </>
        )}
      </div>
      <DashCircleFill
        className="red-fill"
        onClick={() => onProductRemoved(productInfo.id)}
      />
    </div>
  )
}

export default SelectedProductCard;