import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMerchants } from 'app/store/actions/merchant';
import { createOrder } from 'app/store/actions/order';
import {
  createOrderLoadingSelector,
  placeAnOrderProductsSelector,
  placeAnOrderLoadingSelector,
  placeAnOrderErrorsSelector
} from 'app/store/selectors/order';
import {
  merchantsSelector,
  merchantsLoadingSelector,
  merchantDetailsErrorsSelector
} from 'app/store/selectors/merchant';
import { Formik } from 'formik';
import { object, string } from 'yup';
import { Card, Button } from 'react-bootstrap';
import { LoadingAnimation, Dropdown, Input } from 'app/components';
import { ExclamationCircle } from 'react-bootstrap-icons';
import { CountryList } from 'app/constants';
import { useNavigate } from 'react-router-dom';
import './index.scss';

const OrderDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const createOrderLoading = useSelector(createOrderLoadingSelector);
  const merchantsData = useSelector(merchantsSelector);
  const merchantsLoading = useSelector(merchantsLoadingSelector);
  const merchantsErrors = useSelector(merchantDetailsErrorsSelector);
  const selectedProducts = useSelector(placeAnOrderProductsSelector);
  const selectedProductsLoading = useSelector(placeAnOrderLoadingSelector);
  const selectedProductsErrors = useSelector(placeAnOrderErrorsSelector);

  const shippingMethods = [{"id":"","name":"-"},{"id":"Standard","name":"Standard"},{"id":"Expedited","name":"Expedited"},{"id":"Overnight","name":"Overnight"}];
 
  useEffect(() => {
    if (!selectedProducts) {
      navigate('/place-an-order');
    }
    if (!merchantsData) {
      dispatch(getMerchants({ currentPage: 1, pageSize: 250 }));
    }
  }, [dispatch, navigate, selectedProducts, merchantsData]);

  let filteredMerchants; 
  if (merchantsData) {
    filteredMerchants = merchantsData
      .filter(obj => obj.name && obj.name.trim() !== "")
      .sort((a, b) => a.name.localeCompare(b.name));
    filteredMerchants.unshift({id: '', name: '-'})
  }

  const onOrderCreated = () => {
    navigate('/orders');
  }

  return (
    <div className="place-an-order-details">
      {(selectedProductsLoading || merchantsLoading || createOrderLoading) && <LoadingAnimation />}
      {(selectedProductsErrors || merchantsErrors) && (
        <div className="vendor-load-failed"><ExclamationCircle />Data failed to load.  Refresh the page to try again.</div>
      )}
      {selectedProducts && merchantsData && (
        <Formik
          enableReinitialize
          initialValues={{
            merchantId: '',
            firstName: '',
            lastName: '', 
            address1: '',
            address2: '',
            city: '',
            state: '',
            zipPostalCode: '',
            countryCode: 'US',
            phoneNumber: '',
            email: '',
            shippingMethod: '',
          }}
          validationSchema={() =>
            object().shape({
              merchantId: string().required('Select a Merchant'), // required
              firstName: string()
                .matches(/^[^\d!@$%^&*()[\]:;",]*$/, 'Enter a valid First Name') // name
                .matches(/[\x20-\x7F]+/, 'Enter a valid First Name') // onlyEnglish
                .required('Enter a valid First Name'), // required
              lastName: string()
                .matches(/^[^\d!@$%^&*()[\]:;",]*$/, 'Enter a valid Last Name') // name
                .matches(/[\x20-\x7F]+/, 'Enter a valid Last Name') // onlyEnglish
                .required('Enter a valid Last Name'), // required
              address1: string()
                .matches(/^[^!@$%^&*()[\]:;"]*$/, 'Enter a valid Address 1') // address
                .matches(/[\x20-\x7F]+/, 'Enter a valid Address 1') // onlyEnglish
                .max(35, 'Please limit to 35 characters')
                .required('Enter a valid Address 1'), // required
              address2: string().when(['address1', 'country'], (address1, country) => {
                if (country !== 'US') {
                  if (address1) {
                    return string()
                      .matches(/^[^!@$%^&*()[\]:;"]*$/, 'Enter a valid Address 2') // address
                      .matches(/[\x20-\x7F]+/, 'Enter a valid Address 2') // onlyEnglish
                      .max(
                        35 - address1.length,
                        'Please limit to 35 characters for Address 1 + Address 2'
                      );
                  } else {
                    return string()
                      .matches(/^[^!@$%^&*()[\]:;"]*$/, 'Enter a valid Address 2') // address
                      .matches(/[\x20-\x7F]+/, 'Enter a valid Address 2') // onlyEnglish
                      .max(35, 'Please limit to 35 characters for Address 1 + Address 2');
                  }
                } else {
                  return string()
                    .matches(/^[^!@$%^&*()[\]:;"]*$/, 'Enter a valid Address 2') // address
                    .matches(/[\x20-\x7F]+/, 'Enter a valid Address 2') // onlyEnglish
                    .max(35, 'Please limit to 35 characters');
                }
              }),
              city: string()
                .matches(/^[^!@$%^&*()[\]:;"]*$/, 'Enter a valid City') // address
                .matches(/[\x20-\x7F]+/, 'Enter a valid City') // onlyEnglish
                .required('Enter a valid City'), // required
              state: string()
                .when('country', {
                  is: (country) => ['CA', 'US'].includes(country),
                  then: () => string().required('Enter a valid State/Province'),
                  otherwise: () => string()
                })
                .matches(/^[^!@$%^&*()[\]:;"]*$/, 'Enter a valid State/Province')
                .matches(/[\x20-\x7F]+/, 'Enter a valid State/Province'),
              zipPostalCode: string().when('country', {
                is: country => country === 'US',
                then: () => string()
                  .matches(/^[a-zA-Z\d-]*$/, 'Enter a valid zip code') // zipcode
                  .required('Enter a valid zip code'),
                otherwise: () => string().when('country', {
                  is: country => country === 'CA',
                  then: () => string()
                    .matches(/^[a-zA-Z\d- ]*$/, 'Enter a valid postal code') // postalcode
                    .required('Enter a valid postal code'),
                  otherwise: () => string().matches(/^[a-zA-Z\d- ]*$/, 'Enter a valid postal code') // postalcode
                })
              }),
              countryCode: string().required('Enter a Country'), // required
              phoneNumber: string()
                .matches(
                  /^[+\-\s./0-9]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/,
                  'Enter a valid Phone Number'
                )
                .matches(/^.{0,15}$/, 'Please limit to 15 characters')
                .required('Enter a valid Phone Number'),
              email: string()
                .matches(
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  'Enter a valid Email'
                )
                .required('Enter a valid Email'),
              shippingMethod: string().required('Select a Shipping Method'), // required
            })
          }
          onSubmit={async (values) => {
            const data = {
              // "safeId": "string",
              "merchantId": values.merchantId,
              // "merchantName": "string",
              "shippingAddress": {
                "firstName": values.firstName,
                "lastName": values.lastName,
                "line1": values.address1,
                "line2": values.address2,
                // "line3": "string",
                "city": values.city,
                "state": values.state,
                "zip": values.zipPostalCode,
                "countryCode": values.countryCode,
                "phone": values.phoneNumber,
                "email": values.email,
                // "location": {
                //   "longitude": 0,
                //   "latitude": 0
                // }
              },
              "billingAddress": {
                "firstName": values.firstName,
                "lastName": values.lastName,
                "line1": values.address1,
                "line2": values.address2,
                // "line3": "string",
                "city": values.city,
                "state": values.state,
                "zip": values.zipPostalCode,
                "countryCode": values.countryCode,
                "phone": values.phoneNumber,
                "email": values.email,
                // "location": {
                //   "longitude": 0,
                //   "latitude": 0
                // }
              },
              "externalId": Math.floor(1000000 + Math.random() * 9000000).toString(),
              "languageCode": "en",
              // "isTest": true,
              // "client": {
              //   "source": "string",
              //   "details": "string",
              //   "version": "string",
              //   "ip": "string"
              // },
              // "meta": {
              //   "additionalProp1": "string",
              //   "additionalProp2": "string",
              //   "additionalProp3": "string"
              // },
              "currencyCode": "USD",
              "customerShippingMethod": values.shippingMethod,
              // "customerTotal": 0,
              "items": []
            }

            

            for (let i = 0; i < selectedProducts.length; i++) {
              const sp = selectedProducts[i];

              const orderItem = {
                "sku": sp.sku,
                "quantity": 1,
                "images": [],
                // "options": [
                //   {
                //     "name": "string",
                //     "value": "string"
                //   }
                // ],
                // "customerSubtotal": 0,
                // "customerPrice": 0,
                // "customerShippingPrice": 0,
                // "clientAttributes": {
                //   "additionalProp1": "string",
                //   "additionalProp2": "string",
                //   "additionalProp3": "string"
                // },
                // "meta": {
                //   "additionalProp1": "string",
                //   "additionalProp2": "string",
                //   "additionalProp3": "string"
                // },
                // "isSample": true,
                // "externalId": "string"
              }

              const image = {
                "url": "https://hips.hearstapps.com/hmg-prod/images/cute-cat-photos-1593441022.jpg",
                "manipCommand": "",
                "thumbnailUrl": "https://hips.hearstapps.com/hmg-prod/images/cute-cat-photos-1593441022.jpg",
                "area": sp.printLocation,
                "printMethod": "DTG"
              }

              orderItem.images.push(image);

              data.items.push(orderItem);

            }

            dispatch(createOrder({data, cb: onOrderCreated}));
          }}
        >
          {({
            values,
            errors,
            handleChange,
            handleSubmit,
            isSubmitting,
            submitCount,
          }) => (
            <form onSubmit={handleSubmit}>
              <Card className="mb-4">
                <Card.Header as="h6" className="p-0">
                  <div className="d-flex justify-content-between">
                    <div className="py-2 px-3">Merchant Selection</div>
                  </div>
                </Card.Header>
                <Card.Body className="data-dropdown pb-0">
                  <Dropdown
                    label="Merchant Name"
                    name="merchantId"
                    value={values.merchantId}
                    onChange={handleChange}
                    options={filteredMerchants.map(merchant => (
                      { value: merchant.id, label: merchant.name }
                    ))}
                    errorMessage={submitCount > 0 && errors.merchantId}
                  />
                </Card.Body>
              </Card>
              <Card className="merchant-card mb-4">
                <Card.Header as="h6" className="p-0">
                  <div className="d-flex justify-content-between">
                    <div className="py-2 px-3">Shipping Address</div>
                  </div>
                </Card.Header>
                <Card.Body className="pb-0">
                  <div className="merchant-details">
                    <Input
                      label="First Name"
                      name="firstName"
                      value={values.firstName}
                      onChange={handleChange}
                      placeholder="First Name"
                      errorMessage={submitCount > 0 && errors.firstName}
                    />
                    <Input
                      label="Last Name"
                      name="lastName"
                      value={values.lastName}
                      onChange={handleChange}
                      placeholder="Last Name"
                      errorMessage={submitCount > 0 && errors.lastName}
                    />
                    <Input
                      label="Address Line 1"
                      name="address1"
                      value={values.address1}
                      onChange={handleChange}
                      placeholder="Address Line 1"
                      errorMessage={submitCount > 0 && errors.address1}
                    />
                    <Input
                      label="Address Line 2"
                      name="address2"
                      value={values.address2}
                      onChange={handleChange}
                      placeholder="Address Line 2"
                      errorMessage={submitCount > 0 && errors.address2}
                    />
                    <Input
                      label="Email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      placeholder="Email"
                      errorMessage={submitCount > 0 && errors.email}
                    />
                    <Input
                      label="City"
                      name="city"
                      value={values.city}
                      onChange={handleChange}
                      placeholder="City"
                      errorMessage={submitCount > 0 && errors.city}
                    />
                    <Input
                      label="State / Province"
                      name="state"
                      value={values.state}
                      onChange={handleChange}
                      placeholder="State / Province"
                      errorMessage={submitCount > 0 && errors.state}
                    />
                    <Input
                      label="Zip / Postal Code"
                      name="zipPostalCode"
                      value={values.zipPostalCode}
                      onChange={handleChange}
                      placeholder="Zip / Postal Code"
                      errorMessage={submitCount > 0 && errors.zipPostalCode}
                    />
                    <Dropdown
                      label="Country"
                      name="countryCode"
                      value={values.countryCode}
                      onChange={handleChange}
                      options={CountryList.map(country => (
                        { value: country.CountryCode, label: `${country.CountryCode} - ${country.Name}`}
                      ))}
                      errorMessage={submitCount > 0 && errors.countryCode}
                    />
                    <Input
                      label="Phone Number"
                      name="phoneNumber"
                      value={values.phoneNumber}
                      onChange={handleChange}
                      placeholder="Phone Number"
                      errorMessage={submitCount > 0 && errors.phoneNumber}
                    />
                  </div>
                </Card.Body>
              </Card>
              <Card className="mb-4">
                <Card.Header as="h6" className="p-0">
                  <div className="d-flex justify-content-between">
                    <div className="py-2 px-3">Shipping Method</div>
                  </div>
                </Card.Header>
                <Card.Body className="data-dropdown pb-0">
                  <Dropdown
                    label="Shipping Method"
                    name="shippingMethod"
                    value={values.shippingMethod}
                    onChange={handleChange}
                    options={shippingMethods.map(method => (
                      { value: method.id, label: method.name }
                    ))}
                    errorMessage={submitCount > 0 && errors.shippingMethod}
                  />
                </Card.Body>
              </Card>
              <div className="action-buttons">
                <Button variant="secondary" onClick={() => navigate('/place-an-order')}>Back</Button>
                <Button onClick={() => (isSubmitting ? null : handleSubmit())}>Submit Order</Button>
              </div>
              {/* <pre>{JSON.stringify(values, null, 2)}</pre>
              <pre>{JSON.stringify(errors, null, 2)}</pre> */}
            </form>
          )}
        </Formik>
      )}
    </div>
  )
}

export default OrderDetails;