import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { orderDetailsSelector } from 'app/store/selectors/order';
import { shipmentsSelector } from 'app/store/selectors/shipment';
import { Card, Link, ButtonIcon, IconData } from 'app/components';
import { ChevronDown, ChevronLeft, Box } from 'react-bootstrap-icons';
import OrderItem from '../SummaryView/Cards/OrderItem';
import './index.scss';

const ItemDetailsView = () => {
  const [menuState, setMenuState] = useState({'unassignedItems': false});
  const orderDetails = useSelector(orderDetailsSelector);
  const shipments = useSelector(shipmentsSelector);

  useEffect(() => {
    if (shipments) {
      const updatedMenuState = { ...menuState };

      shipments.forEach((shipment) => {
        updatedMenuState[shipment.shortId] = false;
      });
      setMenuState(updatedMenuState);
    }
  }, [shipments]);

  const toggleMenu = (menuId) => {
    setMenuState((prevState) => ({
      ...prevState,
      [menuId]: !prevState[menuId]
    }));
  };

  const { parsedShipments, unmatchedOrderItems } = useMemo(() => {
    if (!orderDetails || !shipments) {
      return { parsedShipments: [], unmatchedOrderItems: [] };
    }

    const parsedShipments = [];
    const unmatchedOrderItems = [...orderDetails.items];

    shipments.filter(shipment => shipment.shipmentStatus !== 'Archived').forEach((shipment) => {
      const shipmentWithItems = { ...shipment, orderItems: [] };
      shipment.orderItemIDs.forEach((itemId) => {
        const orderItemIndex = unmatchedOrderItems.findIndex(item => item.id === itemId);
        if (orderItemIndex !== -1) {
          shipmentWithItems.orderItems.push(unmatchedOrderItems.splice(orderItemIndex, 1)[0]);
        }
      });

      parsedShipments.push(shipmentWithItems);
    });

    return { parsedShipments, unmatchedOrderItems };
  }, [shipments, orderDetails]);

  const toggleAllMenus = () => {
    const allCollapsed = Object.values(menuState).every(value => value);
    const newState = Object.keys(menuState).reduce((acc, key) => ({ ...acc, [key]: !allCollapsed }), {});
    setMenuState(newState);
  };

  const allCollapsed = Object.values(menuState).every(value => value);

  return (
    <div className="item-details-view">
      <div className="sort-by-collpase">
        <div className="sort-by">
          Sort By: Errors
        </div>
        <div className="collapse-all">
          <Link label={allCollapsed ? "Expand All" : "Collapse All"} onClick={toggleAllMenus} />
        </div>
      </div>
      {unmatchedOrderItems.length > 0 && (
        <>
          <div className="unassigned-items-header">
            {`No Shipments Assigned ${!menuState['unassignedItems'] ? '(' + unmatchedOrderItems.length+ ')' : ''}`}
            <ButtonIcon
              icon={menuState['unassignedItems'] ? <ChevronLeft /> : <ChevronDown />}
              onClick={() => toggleMenu('unassignedItems')}
            />
          </div>
          {!menuState['unassignedItems'] ? (
            <div className="item-cards">
              {unmatchedOrderItems.map((itemInfo) => (
                <Card key={itemInfo.id}>
                  <OrderItem
                    key={itemInfo.id}
                    itemInfo={itemInfo}
                    showAll={true}
                  />
                </Card>
              ))}
            </div>
          ) : (
            <Card.Body>
              <IconData
                label={`${unmatchedOrderItems.length} Unassigned Item${unmatchedOrderItems.length === 1 ? '' : 's'}`}
                icon={<Box />}
              />
            </Card.Body>
          )}
        </>
      )}
      {parsedShipments.map((shipment) => {
        let foundOrderItems = [];

          shipment.packages.forEach(p => {
            p.orderItemIDs.forEach(itemId => {
              const orderItem = shipment.orderItems.find(item => item.id === itemId);
              if (orderItem) {
                foundOrderItems.push(orderItem);
              }
            });
          });

        return (
          <React.Fragment key={shipment.shortId}>
            <div className="unassigned-items-header">
              {`Shipment ${shipment.shortId} ${!menuState[shipment.shortId] ? '(' + shipment.orderItems.length + ')' : ''}`}
              <ButtonIcon
                icon={menuState[shipment.shortId] ? <ChevronLeft /> : <ChevronDown />}
                onClick={() => toggleMenu(shipment.shortId)}
              />
            </div>
            {!menuState[shipment.shortId] ? (
              <div className="item-cards">
                {foundOrderItems.map((itemInfo) => (
                  <Card key={itemInfo.id}>
                    <OrderItem
                      key={itemInfo.id}
                      itemInfo={itemInfo}
                      showAll={true}
                    />
                  </Card>
                ))}
              </div>
            ) : (
              <Card.Body>
                <IconData
                  label={`${shipment.orderItems.length} Item${shipment.orderItems.length === 1 ? '' : 's'}`}
                  icon={<Box />}
                />
              </Card.Body>
            )}
          </React.Fragment>
        );
    })}
    </div>
  )
}

export default ItemDetailsView;