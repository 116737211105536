import React, { useState } from 'react';
import { Image, Link, Table, TableHeader } from 'app/components';
import { ChevronLeft, ChevronDown } from 'react-bootstrap-icons';
import './index.scss';

const AdditionalItemDetails = (props) => {
  const {
    itemInfo
  } = props;

  const [imageDetails, showImageDetails] = useState(false);

  // if the options array is empty, add a default item detail
  if (!itemInfo.options) {
    itemInfo.options = [];
  }

  const getFilenameFromUrl = (url) => {
    return url?.substring(url.lastIndexOf('/') + 1);
  }

  return (
    <div className="additional-item-details">
      <div className="details-container">
        <div className="item-image">
          <Image src={itemInfo.images[0].ourUrl || itemInfo.images[0].url || itemInfo.images[0].thumbnailUrl} alt={itemInfo.name} />
        </div>
        <div className={`item-details ${itemInfo.options.length > 4 ? 'many' : ''}`}>
          {itemInfo.options.map(option => (
            <div className="item-property" key={option.name}>
              <div className="property-name">{option.name}</div>
              <div className="property-value">{option.value}</div>
            </div>
          ))}
        </div>
      </div>
      <div className="image-details">
        <div className="image-details-header" onClick={() => showImageDetails(!imageDetails)}>
          Image Details
          {imageDetails ? <ChevronDown /> : <ChevronLeft />}
        </div>
        {imageDetails && (
          <Table size="small" className="image-details-table">
            <TableHeader
              options={[
                { id: '', label: ''},
                { id: 'Vendor Image', label: 'Vendor Image' },
                { id: 'Attributes', label: 'Attributes' },
                { id: 'Image', label: 'Image' },
                // { id: 'Actions', label: 'Actions', align: 'center' },
              ]}
            />
            <tbody className="table-body">
              {itemInfo.images.map((image) => (
                <tr className="image-row" key={image.url}>
                  <td className="item-image-container">
                    <div className="item-image">
                      <Image src={image.ourUrl || image.url || image.thumbnailUrl} alt={itemInfo.name} />
                    </div>
                  </td>
                  <td className="their-url">
                    {image.url ? (
                      <Link
                        label={getFilenameFromUrl(image.url)}
                        isRoute={false}
                        url={image.url}
                      />
                    ) : (
                      <div>-</div>
                    )}
                  </td>
                  <td>
                    {image.area && (
                      <div>{`Print Location: ${image.area}`}</div>
                    )}
                    {image.printMethod && (
                      <div>{`Print Method: ${image.printMethod}`}</div>
                    )}
                    </td>
                  <td className="our-url">
                    {image.ourUrl || image.url || image.thumbnailUrl ? (
                      <Link
                        label={getFilenameFromUrl(image.ourUrl || image.url || image.thumbnailUrl)}
                        isRoute={false}
                        url={image.ourUrl || image.url || image.thumbnailUrl}
                      />
                    ) : (
                      <div>-</div>
                    )}
                  </td>
                  {/* <td className="image-actions">
                    <ButtonIcon
                      icon={<PencilSquare />}
                      onClick={() => console.log("Not implemented yet")}
                    />
                  </td> */}
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </div>
    </div>
  )
}

export default AdditionalItemDetails;