import { put, takeEvery, call, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import {
  getVendors,
  getVendorsSuccess,
  getVendorsFailure,
  getVendorsBulk,
  getVendorsBulkSuccess,
  getVendorsBulkFailure,
  getVendorDetails,
  getVendorDetailsSuccess,
  getVendorDetailsFailure,
  createVendor,
  createVendorSuccess,
  createVendorFailure,
  updateVendor,
  updateVendorSuccess,
  updateVendorFailure,
  getFacilityDetails,
  getFacilityDetailsSuccess,
  getFacilityDetailsFailure,
  getProductsByVendor,
  getProductsByVendorSuccess,
  getProductsByVendorFailure,
  addProductsToVendor,
  addProductsToVendorSuccess,
  addProductsToVendorFailure,
  addFacility,
  addFacilitySuccess,
  addFacilityFailure,
  updateFacility,
  updateFacilitySuccess,
  updateFacilityFailure,
  getAuditLogs,
  getAuditLogsSuccess,
  getAuditLogsFailure,
  getAllValueAddedServices,
  getAllValueAddedServicesSuccess,
  getAllValueAddedServicesFailure,
} from "app/store/actions/vendor"
import VendorServices from 'app/services/vendorServices'

function* fetchVendors(action) {
  try {
    const data = yield call([VendorServices, VendorServices.getVendors], action.payload);
    yield put(getVendorsSuccess(data));
  } catch (error) {
    console.error('error', error);
    yield put(getVendorsFailure(error));
  }
}

function* fetchVendorsBulk(action) {
  try {
    const data = yield call([VendorServices, VendorServices.getVendorsBulk], action.payload);
    yield put(getVendorsBulkSuccess(data));
  } catch (error) {
    console.error('error', error);
    yield put(getVendorsBulkFailure(error));
  }
}

function* fetchVendorDetails(action) {
  const { vendorId, cb } = action.payload;
  try {
    const resp = yield call([VendorServices, VendorServices.getVendorDetails], vendorId);
    yield put(getVendorDetailsSuccess(resp));
    if(cb) cb(resp);
  } catch (error) {
    console.error('error', error);
    yield put(getVendorDetailsFailure(error));
  }
}

function* fetchProductsByVendor(action) {
  const { vendorId, facilityId, searchString, currentPage, pageSize, sortBy, sortDir } = action.payload;
  try {
    const resp = yield call([VendorServices, VendorServices.getProductsByVendor], vendorId, facilityId, searchString, currentPage, pageSize, sortBy, sortDir);
    yield put(getProductsByVendorSuccess(resp));
  } catch (error) {
    console.error('error', error);
    yield put(getProductsByVendorFailure(error));
  }
}

function* doCreateVendor(action) {
  const { data, cb } = action.payload;
  try {
    const resp = yield call([VendorServices, VendorServices.createVendor], data);
    yield put(createVendorSuccess(resp));
    if(cb) cb(resp.vendorId);
    toast.success("New Vendor Successfully Created", {
      theme: 'colored',
    });
  } catch (error) {
    console.error('error', error);
    yield put(createVendorFailure(error));
    toast.error("Create Vendor Failed", {
      theme: 'colored',
    });
  }
}

function* doUpdateVendor(action) {
  const { vendorId, data, cb } = action.payload;
  try {
    const resp = yield call([VendorServices, VendorServices.updateVendor], vendorId, data);
    yield put(updateVendorSuccess(data));
    if(cb) cb(resp);
    toast.success("Vendor Successfully Updated", {
      theme: 'colored',
    });
  } catch (error) {
    console.error('error', error);
    yield put(updateVendorFailure(error));
    toast.error("Update Vendor Failed", {
      theme: 'colored',
    });
  }
}

function* fetchFacilityDetails(action) {
  const { vendorId, facilityId, cb } = action.payload;
  try {
    const resp = yield call([VendorServices, VendorServices.getFacilityDetails], vendorId, facilityId);
    yield put(getFacilityDetailsSuccess(resp));
    if(cb) cb(resp);
  } catch (error) {
    console.error('error', error);
    yield put(getFacilityDetailsFailure(error));
  }
}

function* doAddProductsToVendor(action) {
  const { data, cb } = action.payload;
  try {
    const resp = yield call([VendorServices, VendorServices.addProductsToVendor], data);
    yield put(addProductsToVendorSuccess());
    if(cb) cb(resp);
    const numAdded = resp?.inventoryIds?.length || '';
    toast.success(`${numAdded} Products Successfully Added`, {
      theme: 'colored',
    });
  } catch (error) {
    console.error('error', error);
    yield put(addProductsToVendorFailure(error));
  }
}

function* doAddFacility(action) {
  const { vendorId, data, cb } = action.payload;

  try {
    const resp = yield call([VendorServices, VendorServices.addFacility], vendorId, data);
    yield put(addFacilitySuccess({data, resp}));
    if(cb) cb(resp);
    toast.success(`Facility Successfully Added`, {
      theme: 'colored',
    });
  } catch (error) {
    console.error('error', error);
    yield put(addFacilityFailure(error));
    toast.error("Adding Facility Failed", {
      theme: 'colored',
    });
  }
}

function* doUpdateFacility(action) {
  const { vendorId, facilityId, data, cb } = action.payload;

  try {
    const resp = yield call([VendorServices, VendorServices.updateFacility], vendorId, facilityId, data);
    const resp2 = yield call([VendorServices, VendorServices.getVendorDetails], vendorId);
    yield put(getVendorDetailsSuccess(resp2));
    toast.success(`Facility Successfully Updated`, {
      theme: 'colored',
    });
    yield put(updateFacilitySuccess({resp}));
    if(cb) cb(resp);
  } catch (error) {
    console.error('error', error);
    yield put(updateFacilityFailure(error));
    toast.error("Facility Update Failed", {
      theme: 'colored',
    });
  }
}

function* fetchAuditLogs(action) {
  const { vendorId } = action.payload;
  try {
    const resp = yield call([VendorServices, VendorServices.getAuditLogs], vendorId);
    yield put(getAuditLogsSuccess(resp));
  } catch (error) {
    console.error('error', error);
    yield put(getAuditLogsFailure(error));
  }
}

function* fetchAllValueAddedServices() {
  try {
    const resp = yield call([VendorServices, VendorServices.getAllValueAddedServices]);
    yield put(getAllValueAddedServicesSuccess(resp));
  } catch (error) {
    console.error('error', error);
    yield put(getAllValueAddedServicesFailure(error));
  }
}

function* watchData() {
  yield takeEvery(getVendors().type, fetchVendors); 
  yield takeEvery(getVendorsBulk().type, fetchVendorsBulk);
  yield takeEvery(getVendorDetails.toString(), fetchVendorDetails);
  yield takeEvery(getProductsByVendor.toString(), fetchProductsByVendor);
  yield takeEvery(createVendor.toString(), doCreateVendor);
  yield takeEvery(updateVendor.toString(), doUpdateVendor);
  yield takeEvery(getFacilityDetails.toString(), fetchFacilityDetails);
  yield takeEvery(addProductsToVendor.toString(), doAddProductsToVendor);
  yield takeEvery(addFacility.toString(), doAddFacility);
  yield takeEvery(updateFacility.toString(), doUpdateFacility);
  yield takeEvery(getAuditLogs.toString(), fetchAuditLogs);
  yield takeEvery(getAllValueAddedServices.toString(), fetchAllValueAddedServices);
  
}

export default function* rootSaga() {
  yield all([
    watchData(),
  ]);
}