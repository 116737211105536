import React from 'react';
import PropTypes from 'prop-types';
import { SortArrow } from 'app/components';
import { useSearchParams } from 'react-router-dom';
import './index.scss';

const TableHeader = props => {
  const { options, sortBy, sortDir } = props;
  const [searchParams, setSearchParams] = useSearchParams();

  const headerClicked = (headerName) => {
    const updatedSearchParams = new URLSearchParams(searchParams.toString());
    updatedSearchParams.set('sortDir', headerName === sortBy ? sortDir === 'desc' ? 'asc' : 'desc' : 'desc');
    updatedSearchParams.set('sortBy', headerName);
    setSearchParams(updatedSearchParams.toString());
  }

  return (
    <thead className={`gooten-table-header`}>
      <tr>
        {options.map((option) => (
          <React.Fragment key={option.id}>
            {option.orderable ? (
              <th className="orderable" style={{ width: option.width }} onClick={() => headerClicked(option.id)}>
                <div className={`column-header ${option.align || ''}`}>
                  {option.label}
                  <SortArrow direction={sortBy === option.id ? sortDir : null} />
                </div>
              </th>
            ) : (
              <th style={{ width: option.width }}>
                <div className={`column-header ${option.align || ''}`}>
                  {option.label}
                </div>
              </th>
            )}
          </React.Fragment>
        ))}
      </tr>
    </thead>
  )
}

TableHeader.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string,
      orderable: PropTypes.bool,
      width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), 
      align: PropTypes.oneOf(['left', 'center', 'right']),
    })
  ).isRequired,
  sortBy: PropTypes.string,
  sortDir: PropTypes.string,
};

TableHeader.defaultProps = {
  sortBy: null,
  sortDir: null,
};

export default TableHeader;