import React, { useState, useEffect } from 'react';
import { Card, Accordion, Table } from 'react-bootstrap';
import ProductCard from '../ProductCard';
import './index.scss';

const ProductSelector = props => {
  const {
    products,
    categories,
    searchString,
    onProductSelected,
    currentlySelectedProducts
  } = props;

  const [currentCategory, setCategory] = useState(0);
  const [displayedCategory, setDisplayedCategory] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState(0);

  const categoriesCopy = [...categories];

  categoriesCopy.forEach(category => {
    category.products = [];
    category.children = [];
  });

  products
    .filter(product => !currentlySelectedProducts.includes(product.id))
    .filter(product => (product.status === 'active' && (searchString === null || product.title.toLowerCase().includes(searchString.toLowerCase()))))
    .forEach(product => {
      const uniqueCategories = [...new Set(product.categories)];
      const uniqueSubCategories = [...new Set(product.subCategories)];

      uniqueCategories.forEach(categoryName => {
        const category = categoriesCopy.find(category => category.title === categoryName);
        if (category) {
          category.products.push(product);
        }
      });
      uniqueSubCategories.forEach(categoryName => {
        const category = categoriesCopy.find(category => category.title === categoryName);
        if (category) {
          category.products.push(product);
        }
      });
    });

  for (let i = categoriesCopy.length - 1; i >= 0; i--) {
    const category = categoriesCopy[i];
    if (category.parentId) {
      const parentCategory = categoriesCopy.find(parent => parent.id === category.parentId);
      if (parentCategory) {
        parentCategory.children.push(category);
        categoriesCopy.splice(i, 1);
      }
    }
  }

  const topLevelCategories = categoriesCopy.filter(category => {
    if (category.children.length > 0) {
      const filteredChildren = category.children.filter(child => child.products.length > 0);
      category.children = filteredChildren;
    }
    return category.products.length > 0 || category.children.length > 0;
  });

  useEffect(() => {
    // Set default selection to the first item if categories are available
    if (topLevelCategories.length > 0) {
      setCategory(0);
      setDisplayedCategory(0);
      setSelectedCategory(0);
    }
  }, [categories]);

  const displayProducts = () => {
    if (searchString.length > 0) {
      return (
        topLevelCategories
          .filter(topLevelCategory => topLevelCategory.id !== 'trending' && topLevelCategory.id !== 'all')
          .map(topLevelCategory => {
            if (topLevelCategory.children.length > 0) {
              return topLevelCategory.children.map(category => (
                <React.Fragment key={category.title}>
                  {category.products.length > 0 && (
                    <div className="category-container">
                      <div className="category-title" id={category.title.replace(/[^A-Za-z]/g, '')} dangerouslySetInnerHTML={{ __html: category.title }}></div>
                      <div className="product-container">
                        {category.products.map(product => (
                          <ProductCard
                            key={`${category.title}-${product.id}`}
                            productInfo={product}
                            onProductSelected={() => onProductSelected(product.id)}
                            isSelected={false}
                          />
                        ))}
                        <div>&nbsp;</div>
                        <div>&nbsp;</div>
                      </div>
                    </div>
                  )}
                </React.Fragment>
              ));
            } else {
              return (
                <div className="category-container" key={topLevelCategory.title}>
                  <div className="category-title" id={topLevelCategory.title.replace(/[^A-Za-z]/g, '')} dangerouslySetInnerHTML={{ __html: topLevelCategory.title }}></div>
                  <div className="product-container">
                    {topLevelCategory.products.map(product => (
                      <ProductCard
                        key={`${topLevelCategory.title}-${product.id}`}
                        productInfo={product}
                        onProductSelected={() => onProductSelected(product.id)}
                        isSelected={false}
                      />
                    ))}
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                  </div>
                </div>
              );
            }
          })
      );
    }

    if (topLevelCategories[displayedCategory]?.children.length > 0) {
      return (
        topLevelCategories[displayedCategory].children.map(category => (
          <React.Fragment key={category.id}>
            {category.products.length > 0 && (
              <div className="category-container">
                <div className="category-title" id={category.title.replace(/[^A-Za-z]/g, '')} dangerouslySetInnerHTML={{ __html: category.title }}></div>
                <div className="product-container">
                  {category.products.map(product => (
                    <ProductCard
                      key={`${category.title}-${product.id}`}
                      productInfo={product}
                      onProductSelected={() => onProductSelected(product.id)}
                      isSelected={false}
                    />
                  ))}
                  <div>&nbsp;</div>
                  <div>&nbsp;</div>
                </div>
              </div>
            )}
          </React.Fragment>
        ))
      );
    }

    return (
      <div className="category-container">
        <div className="category-title" dangerouslySetInnerHTML={{ __html: topLevelCategories[displayedCategory]?.title }}></div>
        <div className="product-container">
          {topLevelCategories[displayedCategory]?.products.map(product => (
            <ProductCard
              key={`${displayedCategory}-${product.id}`}
              productInfo={product}
              onProductSelected={() => onProductSelected(product.id)}
              isSelected={false}
            />
          ))}
          <div>&nbsp;</div>
          <div>&nbsp;</div>
        </div>
      </div>
    );
  };

  const scrollToDiv = div => {
    const targetDiv = document.querySelector("#" + div);
    const offset = -100;
    const elementPosition = targetDiv.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.scrollY + offset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth"
    });
  };

  return (
    <div className="product-selector">
      <Card.Body className="product-selector-body">
        {topLevelCategories.length === 0 && (
          <div className="no-matching-products">No products match your search.</div>
        )}
        {searchString.length === 0 && (
          <Accordion activeKey={currentCategory}>
            {topLevelCategories.map((category, index) => (
              <Accordion.Item key={category.id} eventKey={index}>
                <div
                  className={selectedCategory === index ? 'selected-category' : ''}
                  onClick={() => {
                    const newCategory = currentCategory === index ? null : index;
                    setCategory(newCategory);
                    setSelectedCategory(index);
                    if (newCategory !== null) {
                      setDisplayedCategory(newCategory);
                    }
                  }}>
                  <Accordion.Header className={category.children.length ? '' : 'empty-header'}>
                    <span dangerouslySetInnerHTML={{ __html: category.title }}></span>
                  </Accordion.Header>
                </div>
                {category.children.length > 0 && (
                  <Accordion.Body className="p-2">
                    <Table>
                      <tbody className="table-body">
                        {category.children.map(subCategory => (
                          <tr className="order-row" key={subCategory.title} onClick={() => scrollToDiv(subCategory.title.replace(/[^A-Za-z]/g, ''))}>
                            <td dangerouslySetInnerHTML={{ __html: subCategory.title }}></td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Accordion.Body>
                )}
              </Accordion.Item>
            ))}
          </Accordion>
        )}
        <div className="product-display">
          {displayProducts()}
        </div>
      </Card.Body>
    </div>
  );
};

export default ProductSelector;
