import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import './index.scss';
import { ButtonMenu } from "app/components";
import { getGroups, setSelectedGroup } from "app/store/actions/user";
import { groupsSelector, selectedGroupSelector } from "app/store/selectors/user";

const GroupsMenu = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const [isVisible, setVisible] = useState(false);

  const groups = useSelector(groupsSelector);
  const selectedGroup = useSelector(selectedGroupSelector);

  useEffect(() => {
    const isUserPage = location.pathname.includes('users');

    if (isUserPage) {
      dispatch(getGroups());

      if (!selectedGroup && groups?.length > 0) {
        dispatch(setSelectedGroup(groups[0]));
      }
    }

    // groups menu compoment should be visible only when user is on the "Users & Roles" page
    setVisible(isUserPage);

  }, [location]);

  const optionFromGroup = group => ({
    label: group.name,
    value: group.id,
    onClick: () => dispatch(setSelectedGroup(group)),
  });

  return (
    (isVisible &&
      <div className="groups-menu">
        <ButtonMenu
          label={selectedGroup?.name}
          variant="primary"
          size="small"
          options={groups.map(g => optionFromGroup(g))}
          width={155}
        />
      </div>)
  );
}

export default GroupsMenu;
