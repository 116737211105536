const OrderStatusDetails = {
  'open': {
    color: '#15602B', // dark green
    bgColor: 'rgba(29, 177, 72, 0.10)', // light green
    borderColor: 'rgba(29, 177, 72, 0.10)', // light green
    displayName: 'Open',
    description: ''
  },
  'active': {
    color: '#15602B', // dark green
    bgColor: 'rgba(29, 177, 72, 0.10)', // light green
    borderColor: 'rgba(29, 177, 72, 0.10)', // light green
    displayName: 'Active',
    description: ''
  },
  'success': {
    color: '#15602B', // dark green
    bgColor: 'rgba(29, 177, 72, 0.10)', // light green
    borderColor: 'rgba(29, 177, 72, 0.10)', // light green
    displayName: 'Success',
    description: ''
  },
  'enabled': {
    color: '#15602B', // dark green
    bgColor: 'rgba(29, 177, 72, 0.10)', // light green
    borderColor: 'rgba(29, 177, 72, 0.10)', // light green
    displayName: 'Enabled',
    description: ''
  },
  'available': {
    color: '#15602B', // dark green
    bgColor: 'rgba(29, 177, 72, 0.10)', // light green
    borderColor: 'rgba(29, 177, 72, 0.10)', // light green
    displayName: 'Available',
    description: ''
  },
  'hold': {
    color: '#655006', // dark yellow
    bgColor: 'rgba(241, 194, 27, 0.15)', // light yellow
    borderColor: 'rgba(241, 194, 27, 0.15)', // light yellow
    displayName: 'Hold',
    description: ''
  },
  'disabled': {
    color: '#821218', // dark red
    bgColor: 'rgba(218, 30, 40, 0.10)', // light red
    borderColor: 'rgba(218, 30, 40, 0.10)', // light red
    displayName: 'Disabled',
    description: ''
  },
  'inactive': {
    color: '#595959', // gray
    bgColor: 'rgba(135, 135, 135, 0.10)', // light gray
    borderColor: 'rgba(135, 135, 135, 0.10)', // light gray
    displayName: 'Disabled',
    description: ''
  },
  'error': {
    color: '#821218', // dark red
    bgColor: 'rgba(218, 30, 40, 0.10)', // light red
    borderColor: 'rgba(218, 30, 40, 0.10)', // light red
    displayName: 'Error',
    description: ''
  },
  'addresserror': {
    color: '#821218', // dark red
    bgColor: 'rgba(218, 30, 40, 0.10)', // light red
    borderColor: 'rgba(218, 30, 40, 0.10)', // light red
    displayName: 'Address Error',
    description: ''
  },
  'imageerror': {
    color: '#821218', // dark red
    bgColor: 'rgba(218, 30, 40, 0.10)', // light red
    borderColor: 'rgba(218, 30, 40, 0.10)', // light red
    displayName: 'Image Error',
    description: ''
  },
  'fulfillmenterror': {
    color: '#821218', // dark red
    bgColor: 'rgba(218, 30, 40, 0.10)', // light red
    borderColor: 'rgba(218, 30, 40, 0.10)', // light red
    displayName: 'Fulfillment Error',
    description: ''
  },
  'pendingtimehold': {
    color: '#655006', // dark yellow
    bgColor: 'rgba(241, 194, 27, 0.15)', // light yellow
    borderColor: 'rgba(241, 194, 27, 0.15)', // light yellow
    displayName: 'Pending Time Hold',
    description: ''
  },
  'customercarehold': {
    color: '#655006', // dark yellow
    bgColor: 'rgba(241, 194, 27, 0.15)', // light yellow
    borderColor: 'rgba(241, 194, 27, 0.15)', // light yellow
    displayName: 'Customer Care Hold',
    description: ''
  },
  'reviewhold': {
    color: '#655006', // dark yellow
    bgColor: '#FCF4D4', // light yellow
    borderColor: 'rgba(241, 194, 27, 0.15)', // light yellow
    displayName: 'Review Hold',
    description: ''
  },
  'readyforfulfillment': {
    color: '#15602B', // dark green
    bgColor: 'rgba(29, 177, 72, 0.10)', // light green
    borderColor: 'rgba(29, 177, 72, 0.10)', // light green
    displayName: 'Ready For Fulfillment',
    description: ''
  },
  'inproduction': {
    color: '#15602B', // dark green
    bgColor: 'rgba(29, 177, 72, 0.10)', // light green
    borderColor: 'rgba(29, 177, 72, 0.10)', // light green
    displayName: 'In Production',
    description: ''
  },
  'partial': {
    color: '#0E50B3', // dark blue
    bgColor: 'rgba(25, 153, 255, 0.10)', // light blue
    borderColor: 'rgba(25, 153, 255, 0.10)', // light blue
    displayName: 'Partial',
    description: ''
  },
  'canceled': {
    color: '#821218', // dark red
    bgColor: 'rgba(218, 30, 40, 0.10)', // light red
    borderColor: 'rgba(218, 30, 40, 0.10)', // light red
    displayName: 'Canceled',
    description: ''
  },
  'fulfilled': {
    color: '#0E50B3', // dark blue
    bgColor: 'rgba(25, 153, 255, 0.10)', // light blue
    borderColor: 'rgba(25, 153, 255, 0.10)', // light blue
    displayName: 'Fulfilled',
    description: ''
  }
};

export default OrderStatusDetails;