import { createSelector } from 'reselect';

const vendorSelector = state => state.vendor;

export const vendorsDataSelector = createSelector([vendorSelector], vendorStore => vendorStore.vendors.data);
export const vendorsLoadingSelector = createSelector([vendorSelector], vendorStore => vendorStore.vendors.loading);
export const vendorsErrorsSelector = createSelector([vendorSelector], vendorStore => vendorStore.vendors.error);

export const vendorsBulkDataSelector = createSelector([vendorSelector], vendorStore => vendorStore.vendorsBulk.data);
export const vendorsBulkLoadingSelector = createSelector([vendorSelector], vendorStore => vendorStore.vendorsBulk.loading);
export const vendorsBulkErrorsSelector = createSelector([vendorSelector], vendorStore => vendorStore.vendorsBulk.error);

export const vendorDetailsSelector = createSelector([vendorSelector], vendorStore => vendorStore.vendor.data);
export const vendorDetailsLoadingSelector = createSelector([vendorSelector], vendorStore => vendorStore.vendor.loading);
export const vendorDetailsErrorsSelector = createSelector([vendorSelector], vendorStore => vendorStore.vendor.error);

export const facilityDetailsSelector = createSelector([vendorSelector], vendorStore => vendorStore.facility.data);
export const facilityDetailsLoadingSelector = createSelector([vendorSelector], vendorStore => vendorStore.facility.loading);
export const facilityDetailsErrorsSelector = createSelector([vendorSelector], vendorStore => vendorStore.facility.error);

export const vendorProductDataSelector = createSelector([vendorSelector], vendorStore => vendorStore.products.data);
export const vendorProductDataLoadingSelector = createSelector([vendorSelector], vendorStore => vendorStore.products.loading);
export const vendorProductDataErrorsSelector = createSelector([vendorSelector], vendorStore => vendorStore.products.error);

export const addUpdateProductsLoadingSelector = createSelector([vendorSelector], vendorStore => vendorStore.addUpdateProducts.loading);
export const addUpdateProductsErrorsSelector = createSelector([vendorSelector], vendorStore => vendorStore.addUpdateProducts.error);

export const auditLogsSelector = createSelector([vendorSelector], vendorStore => vendorStore.auditLogs.data);
export const auditLogsLoadingSelector = createSelector([vendorSelector], vendorStore => vendorStore.auditLogs.loading);
export const auditLogsErrorsSelector = createSelector([vendorSelector], vendorStore => vendorStore.auditLogs.error);

export const vasSelector = createSelector([vendorSelector], vendorStore => vendorStore.vas.data);
export const vasLoadingSelector = createSelector([vendorSelector], vendorStore => vendorStore.vas.loading);
export const vasErrorsSelector = createSelector([vendorSelector], vendorStore => vendorStore.vas.error);