const OrderStatusDetails = {
  'open': {
    color: '#15602B', // dark green
    bgColor: 'rgba(29, 177, 72, 0.10)', // light green
    borderColor: 'rgba(29, 177, 72, 0.10)', // light green
    displayName: 'Open',
    description: 'Order and items were created and ready for next steps.'
  },
  'hold': {
    color: '#655006', // dark yellow
    bgColor: 'rgba(241, 194, 27, 0.15)', // light yellow
    borderColor: 'rgba(241, 194, 27, 0.15)', // light yellow
    displayName: 'Hold',
    description: 'Generic Hold state that pauses the workflow for the entity.'
  },
  'addresserror': {
    color: '#821218', // dark red
    bgColor: 'rgba(218, 30, 40, 0.10)', // light red
    borderColor: 'rgba(218, 30, 40, 0.10)', // light red
    displayName: 'Address Error',
    description: 'Address Error is raised when the shipping address in the order fails validation (similar to Address Issue).'
  },
  'imageerror': {
    color: '#821218', // dark red
    bgColor: 'rgba(218, 30, 40, 0.10)', // light red
    borderColor: 'rgba(218, 30, 40, 0.10)', // light red
    displayName: 'Image Error',
    description: 'Image Error is raised when the image associated with an item fails validation (similar to Image Issue).'
  },
  'fulfillmenterror': {
    color: '#821218', // dark red
    bgColor: 'rgba(218, 30, 40, 0.10)', // light red
    borderColor: 'rgba(218, 30, 40, 0.10)', // light red
    displayName: 'Fulfillment Error',
    description: 'Fulfillment Error is raised when a shipment submitted to a vendor fails (similar to VAI).'
  },
  'pendingtimehold': {
    color: '#655006', // dark yellow
    bgColor: 'rgba(241, 194, 27, 0.15)', // light yellow
    borderColor: 'rgba(241, 194, 27, 0.15)', // light yellow
    displayName: 'Pending Time Hold',
    description: 'Configurable processing hold for orders received from a merchant or channel.'
  },
  'customercarehold': {
    color: '#655006', // dark yellow
    bgColor: 'rgba(241, 194, 27, 0.15)', // light yellow
    borderColor: 'rgba(241, 194, 27, 0.15)', // light yellow
    displayName: 'Customer Care Hold',
    description: 'The order is held for review by a customer care agent. For e.g., If routing ran successfully, but an assignment could not be found, the order should be placed in a Customer Care Hold for further manual action.'
  },
  'reviewhold': {
    color: '#655006', // dark yellow
    bgColor: 'rgba(241, 194, 27, 0.15)', // light yellow
    borderColor: 'rgba(241, 194, 27, 0.15)', // light yellow
    displayName: 'Review Hold',
    description: 'Configurable processing hold to prevent the order from being fulfilled until an action is complete (similar to Needs Manual Approval).'
  },
  'readyforfulfillment': {
    color: '#15602B', // dark green
    bgColor: 'rgba(29, 177, 72, 0.10)', // light green
    borderColor: 'rgba(29, 177, 72, 0.10)', // light green
    displayName: 'Ready For Fulfillment',
    description: 'All shipments in the order are ready for submission to the vendor for fulfillment.'
  },
  'inproduction': {
    color: '#15602B', // dark green
    bgColor: 'rgba(29, 177, 72, 0.10)', // light green
    borderColor: 'rgba(29, 177, 72, 0.10)', // light green
    displayName: 'In Production',
    description: 'All shipments in the order are submitted to the vendor.'
  },
  'partial': {
    color: '#0E50B3', // dark blue
    bgColor: 'rgba(25, 153, 255, 0.10)', // light blue
    borderColor: 'rgba(25, 153, 255, 0.10)', // light blue
    displayName: 'Partial',
    description: 'Shipments within an order are in multiple different states.'
  },
  'canceled': {
    color: '#821218', // dark red
    bgColor: 'rgba(218, 30, 40, 0.10)', // light red
    borderColor: 'rgba(218, 30, 40, 0.10)', // light red
    displayName: 'Canceled',
    description: 'Order, shipment, or item was canceled.'
  },
  'fulfilled': {
    color: '#0E50B3', // dark blue
    bgColor: 'rgba(25, 153, 255, 0.10)', // light blue
    borderColor: 'rgba(25, 153, 255, 0.10)', // light blue
    displayName: 'Fulfilled',
    description: 'Shipped = Fulfilled; All shipments in the order are shipped; Shipment has been shipped by the vendor; Item has been shipped by the vendor.'
  }
};

export default OrderStatusDetails;