import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { vendorsBulkDataSelector } from 'app/store/selectors/vendor';
import { integrationsDataSelector, integrationsLoadingSelector, integrationsErrorsSelector } from 'app/store/selectors/notification';
import { getIntegrations, reset } from 'app/store/actions/notification';
import { Link, Card, Button, ButtonIcon, Table, TableHeader, Pagination, LoadingAnimation, SearchBar } from 'app/components';
import { ThreeDotsVertical } from 'react-bootstrap-icons';
import DeleteIntegrationModal from './DeleteIntegrationModal';
import './index.scss';

const IntegrationsView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [deleteIntegration, showDeleteIntegrationModal] = useState(null);

  const integrationData = useSelector(integrationsDataSelector);
  const loading = useSelector(integrationsLoadingSelector);
  const errorsFound = useSelector(integrationsErrorsSelector);
  const vendorsBulkData = useSelector(vendorsBulkDataSelector);

  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = parseInt(searchParams.get('page') || '1', 10);
  const pageSize = parseInt(searchParams.get('pageSize') || '50', 10);
  const searchString = searchParams.get('searchString') || null;
  const sortBy = searchParams.get('sortBy') || 'id';
  const sortDir = searchParams.get('sortDir') || 'desc';

  useEffect(() => {
    if (!searchString || searchString.length !== 1) {
      dispatch(getIntegrations({
        searchString,
        currentPage,
        pageSize,
        sortBy,
        sortDir
      }));
    }
  }, [searchString, currentPage, pageSize, sortBy, sortDir, dispatch]);

  const onSearchStringUpdated = (searchString) => {
    const updatedSearchParams = new URLSearchParams(searchParams.toString());
    searchString ? updatedSearchParams.set('searchString', searchString) : updatedSearchParams.delete('searchString');
    updatedSearchParams.set('page', 1);
    setSearchParams(updatedSearchParams.toString());
  };

  const integrationOptions = (plugin) => {
    return [
      { value: 'Edit Basic Settings', label: 'Edit Basic Settings', onClick: () => { navigate(`/admin/integrations/${plugin.id}/basic-settings`) }},
      { value: 'Manage Versions', label: 'Manage Versions', onClick: () => { console.log("Not implemented yet") }},
      { value: 'Delete Integration', label: 'Delete Integration', destructive: true, onClick: () => { showDeleteIntegrationModal(plugin) }},
    ];
  }

  const getNameById = (integratorId) => {
    const vendor = vendorsBulkData.bulk.find(plugin => plugin.response?.vendor?.vendorId === integratorId);
    return vendor ? vendor.response.vendor.name : integratorId;
  }

  return (
    <div className="integrations-view">
      {loading && <LoadingAnimation />}
      {(integrationData || errorsFound) && (
        <Card>
          <div className="merchants-header">
            Integrations
            <Button
              variant="primary"
              size="medium"
              label={"Create Integration"}
              // disabled={errorsFound}
              onClick={() => {
                dispatch(reset());
                navigate(`/admin/integrations/create-integration`);
              }}
            />
          </div>
          <div className="search-bar-and-filters">
            <SearchBar
              searchPlaceholderText="Search for an Integration"
              onSearchStringUpdated={onSearchStringUpdated}
              debounceDelay={500}
              disabled={errorsFound}
            />
          </div>
          <Table size="medium">
            <TableHeader
              sortBy={sortBy}
              sortDir={sortDir}
              options={[
                { id: 'integrationName', label: 'Integration Name' },
                { id: 'type', label: 'Type' },
                { id: 'integratorName', label: 'Integrator Name' },
                { id: 'audience', label: 'Audience' },
                { id: 'actions', label: 'Actions', align: 'center' },
              ]}
            />
            <tbody className="table-body">
              {integrationData?.plugins?.map((plugin) => (
                <tr className="plugin-row" key={plugin.id}>
                  <td>
                    <Link label={plugin.name} onClick={() => {
                      dispatch(reset());
                      navigate(`/admin/integrations/${plugin.id}/0`);
                    }}
                  />
                  </td>
                  <td>{plugin.type}</td>
                  <td>{getNameById(plugin.integratorId)}</td>
                  <td>{plugin.isPrivate ? 'Private' : 'Public'}</td>
                  <td className="integration-actions"><ButtonIcon icon={<ThreeDotsVertical />} options={integrationOptions(plugin)} /></td>
                </tr>
              ))}
              {integrationData?.length === 0 && !errorsFound && !loading && (
                <tr>
                  <td colSpan="6" className="error-message">No Matching Results Found</td>
                </tr>
              )}
              {errorsFound && !loading && (
                <tr>
                  {/* <td colSpan="6" className="error-message"><ExclamationCircle />Error fetching integration data</td> */}
                  <td colSpan="6" className="error-message">No Integations Found</td>
                </tr>
              )}
            </tbody>
          </Table>
          {integrationData && (
            <Pagination
              totalItems={integrationData?.total || 0}
            />
          )}
          {deleteIntegration && (
            <DeleteIntegrationModal
              plugin={deleteIntegration}
              showDeleteIntegrationModal={showDeleteIntegrationModal}
            />
          )}
        </Card>
      )}
    </div>
  )
}

export default IntegrationsView;