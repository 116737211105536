import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { queryDataSelector } from 'app/store/selectors/insight';
import { Card,  ButtonMenu } from 'app/components';
import { runQueryById } from 'app/store/actions/insight';
import { InfoCircle } from 'react-bootstrap-icons';
import OrdersByStatus from './OrdersByStatus';
import LateOrdersInDays from './LateOrdersInDays';
import LateOrdersByFacility from './LateOrdersByFacility';
import AverageFulfillmentTimeByVendor from './AverageFulfillmentTimeByVendor';
import TopProductsFulfilledByCategory from './TopProductsFulfilledByCategory';
import AverageFulfillmentTimeByFacility from './AverageFulfillmentTimeByFacility';
import TotalFulfilledShipmentCountByFacility from './TotalFulfilledShipmentCountByFacility';
// import TopProductsFulfilledBySku from './TopProductsFulfilledBySku';
// import TopVendorSkuFilfilled from './TopVendorSkuFilfilled';
import './index.scss';

const Dashboard = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const queryData = useSelector(queryDataSelector);

  const queries = [
    { queryId: 'orders-count-by-status', runAs: 'admin' }, // 9 - half done, Ahad doing rework (combined with 11)
    { queryId: 'late-order-shipments', runAs: 'admin' }, // 10 and 18 - done
    // { queryId: 'shipments-count-by-status', runAs: 'admin' }, // 11 - half done, Ahad doing rework (combined with 9)
    { queryId: 'shipments-count-by-status-and-vendor-facility', runAs: 'admin' }, // 12 - done
    // { queryId: 'top-5-sku-fulfilled', runAs: 'admin' }, // 13 - not needed
    { queryId: 'top-5-product-category-fulfilled', runAs: 'admin' }, // 14 - done
    // { queryId: 'top-5-us-state-by-orders-count', runAs: 'admin' }, // 15 - not needed
    // { queryId: 'top-5-vendor-sku-fulfilled', runAs: 'admin' }, // 16 - not needed
    { queryId: 'avg-fulfillment-time-by-vendor', runAs: 'admin' }, // 17 - done
    // { queryId: 'late-order-shipments-count-by-vendor-facility', runAs: 'admin' }, // 18 - done (uses same query as 10)
    { queryId: 'avg-fulfillment-time-by-vendor-facility', runAs: 'admin' }, // 19 - done
  ];

  const dateRange = parseInt(searchParams.get('dateRange') || '30');

  useEffect(() => {
    runQueries();
  }, [dateRange]);

  const runQueries = () => {
    queries.forEach(query => {
      const id = `${query.queryId}-${dateRange}`;
      if (!queryData[id] || !queryData[id].refreshedAt || (new Date() - new Date(queryData[id].refreshedAt)) > 300000) { // 5 minutes
        dispatch(runQueryById({query, dateRange}));
      }
    });
  }

  const updateDateRange = (val) => {
    const updatedSearchParams = new URLSearchParams(searchParams.toString());
    updatedSearchParams.set('dateRange', val.value);
    setSearchParams(updatedSearchParams.toString());
  }

  return (
    <div className="dashboard-view">
      <Card className="dashboard-view-header">
        <div className="title-and-date">
          Dashboard
          <ButtonMenu
            label={`Last ${dateRange} Days`}
            variant="secondary"
            size="small"
            options={[
              { value: '7', label: '7 Days' },
              { value: '30', label: '30 Days' },
              { value: '90', label: '90 Days' },
            ].map(val => ({
              value: val.value,
              label: val.label,
              onClick: () => updateDateRange(val)
            }))}
            width={140}
          />
        </div>
        <div className="last-updated">
          {queryData['orders-count-by-status' + '-' + dateRange]?.refreshedAt ? (
            <>
              <InfoCircle /> {`Last Updated: ${queryData['orders-count-by-status' + '-' + dateRange].refreshedAt}`}
            </>
          ) : (
            <>
              &nbsp;
            </>
          )}
        </div>
      </Card>
      <OrdersByStatus queryData={queryData['orders-count-by-status' + '-' + dateRange]} /> {/* 9 & 11 - half done */}
      <div className="charts-container">
        <LateOrdersInDays queryData={queryData['late-order-shipments' + '-' + dateRange]} /> {/* 10 - done */}
        <LateOrdersByFacility queryData={queryData['late-order-shipments' + '-' + dateRange]} /> {/* 18 - done */}
        <TotalFulfilledShipmentCountByFacility queryData={queryData['shipments-count-by-status-and-vendor-facility' + '-' + dateRange]} /> {/* 12 - done */}
        <AverageFulfillmentTimeByVendor queryData={queryData['avg-fulfillment-time-by-vendor' + '-' + dateRange]} /> {/* 17 - done */}
        <AverageFulfillmentTimeByFacility queryData={queryData['avg-fulfillment-time-by-vendor-facility' + '-' + dateRange]} /> {/* 19 - done */}
        {/* <TopProductsFulfilledBySku queryData={queryData['top-5-sku-fulfilled' + '-' + dateRange]} /> */} {/* 13 - not needed */}
        <TopProductsFulfilledByCategory queryData={queryData['top-5-product-category-fulfilled' + '-' + dateRange]} /> {/* 14 - done */}
        {/* <TopVendorSkuFilfilled queryData={queryData['top-5-vendor-sku-fulfilled' + '-' + dateRange]} /> */} {/* 16 - not needed */}
      </div>
    </div>
  )
}

export default Dashboard;