import React from 'react';
import { Routes, Route } from 'react-router-dom';
import IntegrationsView from './IntegrationsView';
import IntegrationDetails from './IntegrationDetails';
import BasicDetails from './BasicDetails';

function Integrations() {
  return (
    <Routes>
      <Route exact path="/" element={<IntegrationsView />} />
      <Route exact path="/create-integration" element={<BasicDetails />} />
      <Route path="/:pluginId/basic-settings" element={<BasicDetails />} />
      <Route path="/:pluginId/:pluginVersionId/*" element={<IntegrationDetails />} />
      <Route path="/:pluginId/*" element={<IntegrationDetails />} />
    </Routes>
  );
}

export default Integrations;